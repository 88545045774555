import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subject } from 'rxjs';
import {
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { Customer } from 'app/modules/common/models/Customer';
import { environment } from '../../../../../environments/environment';
import { FirebaseAnalyticsService } from '../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { License } from '../../services/licensing.interfaces';
import { LittlesisLicensingService } from '../../services/littlesis-licensing.service';

@Component({
  selector: 'licensing-card',
  templateUrl: './licensing-card.component.html',
  styleUrls: ['./licensing-card.component.scss']
})
export class LicensingCardComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  customer: Customer;
  license: License;
  bundle: string;

  licensed = false;
  verifyingLicense = true;
  expiryMessage = '';
  iconColor = 'white';

  constructor(
    private _firebaseAnalytics: FirebaseAnalyticsService,
    private _licensingSvc: LittlesisLicensingService
  ) { }

  ngOnInit() {
    this.verifyLicense();
  }

  verifyLicense(forceRefresh = false) {
    this._licensingSvc.checkLicense(forceRefresh)
      .pipe(takeUntil(this.onDestroy$),
        tap(licensingState => {this.verifyingLicense = true;}),
        filter(licensingState => !!licensingState))
      .subscribe((licenseState) => {
        this.verifyingLicense = false;
        this.license = licenseState.license;
        this.licensed = licenseState.details === 'ACTIVE';
        this.bundle = licenseState.bundle;
        if (licenseState.expiresIn <= 10) {
          this.iconColor = 'red';
        } else if (licenseState.expiresIn <= 30) {
          this.iconColor = 'amber';
        }

        if (licenseState.expiresIn > 1 && licenseState.expiresIn < 30) {
          this.expiryMessage = `Your Little SIS license will expire in ${licenseState.expiresIn} days`;
        } else if (licenseState.expiresIn === 2) {
          this.expiryMessage = `Your Little SIS license expires tomorrow`;
        } else if (licenseState.expiresIn === 1) {
          this.expiryMessage = `Your Little SIS license expires today`;
        }
        if(licenseState.bundle === 'ait-atu' && this.expiryMessage.length) {
          this.expiryMessage += ' as part of your Admin Tools Unlimited subscription';
        }
      });
  }

  getQuote() {
    this._firebaseAnalytics.sendEvent({ action: 'Quote request',  properties: { category: 'App', label: 'Quote' } });
    window.open(environment.links.purchase);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}
