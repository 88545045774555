import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {

  header?: string;
  message: string;
  okBtnLabel?: string;
  cancelBtnLabel?: string;
  actionTrigger?: string;
  numOfClasses?: number;

}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {

  }

  async confirm(): Promise<void> {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }




  ngOnInit() {}

}
