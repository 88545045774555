import { Injectable } from '@angular/core';

export interface ColumnDefinitionHash {
  [s: string]: {
    headerName: string;
  };
}

interface ActiveFilter {
  key: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class GridService {

  mapGridStateFiltersToActiveFilters(fields: string[], columnDefHash: ColumnDefinitionHash): ActiveFilter[] {
    return fields.map((col) => {
      const columnDef = columnDefHash[col];
      if (columnDef) {
        return {
          key: col,
          name: columnDef.headerName
        };
      } else {
        return null;
      }
    }).filter(column => !!column);
  }

}
