import { Component } from '@angular/core';
import {versions} from '../../../../environments/versions';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  versions: {
    version: string;
    branch: string;
    revision: string;
  };

  /**
   * Constructor
   */
  constructor() {
    this.versions = versions;
  }

}
