import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {MatMenuTrigger} from '@angular/material/menu';
import {
  NavigationEnd,
  Router,
} from '@angular/router';

import {
  Observable,
  Subject,
} from 'rxjs';
import {
  delay,
  filter,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';

import {AppNavigationService} from '@amplabs/components/navigation/navigation.service';
import {AppSidebarService} from '@amplabs/components/sidebar/sidebar.service';
import {FusePerfectScrollbarDirective} from '@amplabs/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {AppConfigService} from '@amplabs/services/config.service';

import {AuthService} from '../../../../../modules/auth/services/Auth/auth.service';
import {LittlesisLicensingService} from '../../../../../modules/licensing/services/littlesis-licensing.service';

@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy
{
    appConfig: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {AppConfigService} _AppConfigService
     * @param {AppNavigationService} _appNavigationService
     * @param {AppSidebarService} _appSidebarService
     * @param {Router} _router
     * @param acf
     * @param authService
     * @param licenseService
     */
    constructor(
        private _AppConfigService: AppConfigService,
        private _appNavigationService: AppNavigationService,
        private _appSidebarService: AppSidebarService,
        private _router: Router,
        private acf: AngularFireFunctions,
        private authService: AuthService,
        private licenseService: LittlesisLicensingService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    set directive(theDirective: FusePerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._appNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        const activeNavItem: any = document.querySelector('navbar .nav-link.active');

                        if ( activeNavItem )
                        {
                            const activeItemOffsetTop       = activeNavItem.offsetTop;
                                  const activeItemOffsetParentTop = (activeNavItem.offsetParent) ? activeNavItem.offsetParent.offsetTop : 0;
                                  const scrollDistance            = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3) - 168;

                            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                        }
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
      window.name = 'LittleSISforClassroom';


      this.hasPremiumAccess$ =  this.licenseService.isPremium()
        .pipe(map((isPremium) => isPremium && (this.authService.hasPermission('sync_admin'))));
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._appSidebarService.getSidebar('navbar') )
                    {
                        this._appSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._AppConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.appConfig = config;
            });

        // Get current navigation
        this._appNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._appNavigationService.getCurrentNavigation();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
  public hasPremiumAccess$: Observable<any>;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  private betaStatusChecked = false;

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._appSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._appSidebarService.getSidebar('navbar').toggleFold();
    }

  goToLittleSISWeb(): void {
    this._router.navigate(['/home']);
  }
  closeAppLauncherMenu() {
    this.trigger.closeMenu();
  }

  goToLittleSISSync(): void {
    this._appNavigationService.navigationEvents$.next('launchSyncPremium');
  }

  setFolded(): void {
      this._appSidebarService.getSidebar('navbar').unfold();
  }

  setunFolded(): void {
    this._appSidebarService.getSidebar('navbar').fold();
  }

  openAppMenu(): void {
    this._appSidebarService.getSidebar('navbar').openAppMenu();
  }

  closeAppMenu(): void {
    this._appSidebarService.getSidebar('navbar').closeAppMenu();
  }
}
