import {Component, Input, Output, EventEmitter} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { PermissionDescriptionDialogComponent} from './dialogs/permission-description/permission-description.component';
import {PermissionSet} from '../../../user/components/user-permissions/user-permissions.component';


@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss']

})

export class PermissionListComponent {
  @Input() groupPermissions: PermissionSet[];
  @Input()  displayCheckbox: boolean;
  @Input() readOnly: boolean;
  @Output() selectionChange = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  checkboxStatus() {
    this.selectionChange.emit(this.groupPermissions);
  }

  async openPermissionDialog(group, permission) {
    const dialogData = {
      permission,
      group
    };
    const dialogRef = this.dialog.open( PermissionDescriptionDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: dialogData,
      width: '700px',
      height: 'auto'
    });
  }

}
