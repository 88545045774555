import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import {NotificationSnackbarComponent} from 'app/modules/common/components/notification-snackbar/notification-snackbar.component';
import {navigation} from 'app/navigation/navigation';
import {Subject} from 'rxjs';
import {
  merge,
  takeUntil,
  take
} from 'rxjs/operators';

import {AppConfigService} from '@amplabs/services/config.service';

import {
  Notification,
  NotificationService,
} from '../../../services/notification.service';
import { LittlesisLicensingService } from 'app/modules/licensing/services/littlesis-licensing.service';

@Component({
    selector     : 'vertical-layout-1',
    templateUrl  : './layout-1.component.html',
    styleUrls    : ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    appConfig: any;
    navigation: any;
    loading = true;

    // Private
    private _unsubscribeAll: Subject<any>;
    private _closeAllNotifications: Subject<boolean>;

    /**
     * Constructor
     *
     * @param {AppConfigService} _AppConfigService
     */
    constructor(
        private _AppConfigService: AppConfigService,
        private _notificationService: NotificationService,
        private _snackBar: MatSnackBar,
        private _licensingSvc: LittlesisLicensingService
    )
    {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._closeAllNotifications = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
      // Subscribe to config changes
      this._AppConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (config) => {
            this.appConfig = config;
          }
        );

        this._notificationService.notifications$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (messages) => {
            this.processNotifications(messages);
          }
        );

        this._licensingSvc.checkLicense().pipe(take(1)).subscribe((response) => {
          if (response) {
            this.loading = false;
            return true;
          }
        });
    }


    processNotifications(notifications: Notification[]): void {
      let snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>;
      const visibleCount = notifications.filter(notification => !notification.dismissed).length;
      let visibleIndex = 0;
      this._closeAllNotifications.next(true);

      const closeNotifications$ = this._closeAllNotifications.pipe(merge(this._unsubscribeAll));

      // Create an observable or subject that emits when we need a new snackbar
      const notifications$ = new Subject<number>();

      // Open snackbar on notification
      notifications$
      .pipe(
        takeUntil(closeNotifications$),
      )
      .subscribe(
        (lastProcessedIndex: number) => {
          let inProgressIndex: number;
          // Find the next non-dismissed notification
          for (let index = lastProcessedIndex; index < notifications.length; index++) {
            if (!notifications[index].dismissed) {
              snackBarRef = this._snackBar.openFromComponent(NotificationSnackbarComponent, {
                data: {
                  message: notifications[index].message,
                  count: visibleCount,
                  index: visibleIndex++
                },
              });
              inProgressIndex = index;

              snackBarRef.afterDismissed().subscribe((method: {dismissedByAction: boolean}) => {
                if (method.dismissedByAction) {
                  this._notificationService.dismiss(inProgressIndex);
                  notifications$.next(inProgressIndex);
                }
              });
              break;
            }
          }
        }
      );

      notifications$.next(0);
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._notificationService.clearNotifications();
    }
}
