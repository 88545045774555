import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
} from '@angular/router';

import {Subject} from 'rxjs';
import {
  delay,
  filter,
  take,
  takeUntil,
} from 'rxjs/operators';

import {AppNavigationService} from '@amplabs/components/navigation/navigation.service';
import {AppSidebarService} from '@amplabs/components/sidebar/sidebar.service';
import {FusePerfectScrollbarDirective} from '@amplabs/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {AppConfigService} from '@amplabs/services/config.service';

@Component({
    selector     : 'navbar-vertical-style-2',
    templateUrl  : './style-2.component.html',
    styleUrls    : ['./style-2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy
{
    appConfig: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {AppConfigService} _AppConfigService
     * @param {AppNavigationService} _appNavigationService
     * @param {AppSidebarService} _appSidebarService
     * @param {Router} _router
     */
    constructor(
        private _AppConfigService: AppConfigService,
        private _appNavigationService: AppNavigationService,
        private _appSidebarService: AppSidebarService,
        private _router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    set directive(theDirective: FusePerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._appNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        const activeNavItem: any = document.querySelector('navbar .nav-link.active');

                        if ( activeNavItem )
                        {
                            const activeItemOffsetTop       = activeNavItem.offsetTop;
                                  const activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop;
                                  const scrollDistance            = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3);

                            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                        }
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._appSidebarService.getSidebar('navbar') )
                    {
                        this._appSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Get current navigation
        this._appNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._appNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._AppConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.appConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._appSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._appSidebarService.getSidebar('navbar').toggleFold();
    }
}
