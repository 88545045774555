import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  Injectable,
  OnDestroy,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import {
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { environment } from '../../../../environments/environment';
import { MessagesService } from '../../../core/services/messages/messages.service';
import { AuthService } from '../../auth/services/Auth/auth.service';
import { Customer } from '../models/Customer';
import { InstallState } from '../models/InstallState';

export interface CustomerSettings {
  /**
   *  Optional reply email to be given to all teachers effected by the changes
   */
  replyEmail?: string;

  /**
   *  action type context for which entry in the database to get
   */
  actionType?: string;

  /**
   *  default domain template related to the customer Id
   */
  customerActionTemplate?: object | string;

  /**
   *  default user template related to the user Id
   */
  userActionTemplate?: object | string;

}

interface ClientInstallResponse {
  success: boolean;
  message: string;
}

export interface OrgUnit {
  orgUnitId: number;
  orgUnitPath: string;
  userCount: number;
  totalUserCount: number; // Count including all sub-orgs
}

export interface StudentCountResponse {
  studentCount: number;
}

@Injectable({providedIn: 'root'})
export class CustomerService implements OnDestroy {
  gettingStartedRef: AngularFirestoreDocument<any>;

  customerFirestoreRef: any;

  private customerFirestoreRef$ = new BehaviorSubject({loading: true});

  private onDestroy$: Subject<void> = new Subject<void>();

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;

  private customer = {
    loading: true
  } as Customer;

  private installState: InstallState;

  private customer$: BehaviorSubject<Customer>;

  private setting$ = new  BehaviorSubject({});

  private gettingStarted$ = new BehaviorSubject({});

  private settingsBinding;

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private router: Router,
    private _authSvc: AuthService,
    private _messageSvc: MessagesService,
    private fns: AngularFireFunctions,
    ) {

    this.customer$ = new BehaviorSubject(this.customer);

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

      this._authSvc.getActiveUser()
        .pipe(
          filter(activeUser => !!activeUser),
          takeUntil(this.onDestroy$),
        )
        .subscribe((activeUser) => {
          this.bindToCustomerSettings(activeUser);
          this.bindToGettingStarted(activeUser);
          if (!this.customerFirestoreRef$ || this.customerFirestoreRef$.value?.loading === true) {
            this.bindToCustomer(activeUser);
          }
      });

      this.getSettings()
      .pipe(
        takeUntil(this.onDestroy$),
        )
      .subscribe(settings => {
        this.installState = settings?.installState;
      });

    this._authSvc.loggedOutTrigger.subscribe(() => {
      this.ngOnDestroy();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  bindToCustomerSettings(user) {

    if (!user || !user.auth.fbToken) {
      return;
    }

    const customerId = user.gSuiteId;

    this.settingsBinding = this.firestore.collection(`customers/${customerId}/settings`);

    this.settingsBinding.snapshotChanges().pipe(takeUntil(this.onDestroy$)).subscribe(data => {

      const settings = {
        terms: {
          accepted: false
        }
      };

      data.map(e => {
        settings[e.payload.doc.id] = e.payload.doc.data();
      });

      this.setting$.next(settings);

      const termsRef = data.find((d) => d.payload.doc.id === 'terms');
      const termsAcceptance = termsRef?.payload.doc.data();

      if (termsAcceptance && termsAcceptance.accepted === false && this.router.url !== '/product/terms' && this.router.url !== '/product/privacy' && this.router.url !== '/admin/getting-started') {
        const message = {
          id: 'accept_terms',
          critical: true,
          text: '<p>You need to review and accept the terms and conditions for Little SIS.</p>',
          link: '/admin/getting-started',
          actionText: 'Review',
          icon: 'feedback'
        };
        this._messageSvc.addMessage(message);
      }
    });
  }

  bindToGettingStarted(user) {

    if (!user || !user.auth.fbToken) {
      return;
    }

    if (!this.gettingStartedRef) {

      const customerId = user.gSuiteId;

      this.gettingStartedRef = this.firestore.doc(`customers/${customerId}/sync/gettingStarted`);

      this.gettingStartedRef.snapshotChanges().pipe(takeUntil(this.onDestroy$)).subscribe(data => {

        // TODO: If complete and monitor === true we should refresh the registration information

        this.gettingStarted$.next(data.payload.data());

      });

    }
  }

  bindToCustomer(user) {

    if (!user || !user.auth.fbToken) {
      return;
    }

    this.customerFirestoreRef = this.firestore.doc(`customers/${user.gSuiteId}`);

    this.customerFirestoreRef.snapshotChanges().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.customerFirestoreRef$.next(data.payload.data());
    });

  }

  getSettings(): Observable<any> {
    return this.setting$.asObservable();
  }

  getGettingStarted(): Observable<any> {
    return this.gettingStarted$.asObservable();
  }

  getCustomerFireStore(): Observable<any> {
    return this.customerFirestoreRef$.asObservable();
  }

  initGettingStarted() {

    this.gettingStartedRef.set({end: null, next: null}, {
      merge: true
    });

    // Determine where to start

    const init = {
      classrooms: this.installState.classrooms,
      users: this.installState.users,
      timeframes: this.installState.timeframes || false,
      metadata: this.installState.metadata || false,
      monitor: this.installState.metadata || false,
      supportConfigured: this.installState.supportConfigured || false,
      alertNotifications: this.installState.alertNotifications || false,
      next: 'users',
      type: 'gs'
    };

    if (this.installState.classrooms) {
      init.next = 'metadata';
      init.users = true;
    } else if (this.installState.users) {
      init.next = 'classrooms';
    }

    this.gettingStartedRef.set(init, {
      merge: false
    });

  }

  acceptTerms(update) {
    const ref = this.firestore.doc(`customers/${this.customer.customerId}/settings/terms`);
    ref.set(update, {merge: true});
    if (update.accepted) {
      this._messageSvc.removeMessage({id: 'accept_terms'});
    }
  }

  updateInstallState(update) {
    const ref = this.firestore.doc(`customers/${this.customer.customerId}/settings/installState`);
    ref.set(update, {merge: true});
  }

  getCurrentCustomer(): Observable<Customer> {
    return this.customer$.asObservable().pipe(switchMap((customer)=>
      (customer.loading)?
        this._authSvc.getActiveUser().pipe(filter(activeUser => !!activeUser),
          filter(activeUser => !!activeUser.auth.jwt),
          switchMap(() => this.refreshCustomer().pipe(switchMap(() => this.customer$.asObservable())))):
        this.customer$.asObservable()
    ));
  }

  refreshCustomer(): Observable<Customer> {
    const url = this.serviceUrls.getCustomer;
    return this.http.get<Customer>(url, this.httpOptions)
    .pipe(
      tap(response => {
        this.customer = response;
        this.customer$.next(this.customer);
      }));
  }

  getCustomerSettings(actionType?: string): Observable<CustomerSettings> {
    const url = this.serviceUrls.getCustomerSettings.replace(':actionType', actionType);
    return this.http.get<CustomerSettings>(url, this.httpOptions);
  }

  updateCustomer(customerData: Customer): Observable<Customer> {
    const url = this.serviceUrls.updateCustomer;

    if (customerData.apiKey === null) {
      delete customerData.apiKey;
    }

    return this.http.patch<Customer>(url, customerData, this.httpOptions).pipe(
      map(response => {
        this.customer = response;
        this.customer$.next(this.customer);
        return response;
      }));
  }

  updateStudentOU(studentOU): Observable<StudentCountResponse> {
    const url = this.serviceUrls.updateCustomerStudentOU;

    return this.http.put<StudentCountResponse>(url, {studentOU}, this.httpOptions);
  }

  generateAPIKey() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getOUs(): Observable<OrgUnit[]> {
    const url = this.serviceUrls.getOUs;
    return this.http.get<OrgUnit[]>(url);
  }

  async updateNotificationEmailList(emails: string): Promise<void> {
    const ref = this.firestore.doc(`customers/${this.customer.customerId}`);
    await ref.set({ notificationEmailList: emails }, { merge: true });
  }

  updateNotificationEmailListOptOut(optOut: boolean) {
    return this.fns.httpsCallable('customers-updateNotificationEmailListOptOut')({optOut});
  }
}
