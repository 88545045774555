
import { map } from 'rxjs/operators';
import { Injectable, Optional } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DialogData } from '../modules/admin/components/user-accounts/user-accounts.component';
import { ApiResponse } from './util.service';

import * as _ from 'lodash';


import { Tag } from './tags.service';
import { ColumnSettings } from '../modules/explorer/services/grid-state.service';
import { Params } from '@angular/router';

export interface Permission {
  /**
   * Unique id
   */
  id?: number;
  /**
   * Name/Title of the LSC permission
   */
  name: string;
  /**
   * High level description of the permission
   */
  description: string;
  /**
   * Permission grouping
   */
  group: string;

  /**
   *  Label for the permission
   */
  label: string;

  /**
   *  URL for external help page of permission
   */
  help_link: string;
}

export interface User {
  /**
   * Unique userId within LSC
   */
  id?: number;
  /**
   * Unique Google User Id
   */
  gId: string;
  /**
   * Primary Email address from Google
   */
  primaryEmail?: string;
  /**
   * Url for the users photo
   */
  photoUrl?: string;
  /**
   * Url for the users photo icon
   */
  thumbnailPhotoUrl?: string;
  /**
   * Name to be displayed within LSC
   */
  displayName?: string;
  /**
   * Full Name details from Google
   */
  name?: string;
  /**
   * Role assigned to the user within LSC
   */
  role?: string | number;
  /**
   * Short code string related to predefined Little SIS roles (e.g. used to identify *super admin* users via 'sa')
   */
  role_key?: string;
  /**
   * Subscription details inherited from the user's associated customer
   */
  subscription?: {};
  /**
   * Token to be used for API calls
   */
  token?: string;
  /**
   * Id of the user's associated customer record
   */
  customerId?: string;
  /**
   * Is the user currently disabled within LSC?
   */
  isDisabled?: boolean;
  /**
   * Is the user currently suspended within Google
   */
  suspended?: boolean;
  /**
   * Current JWT token to be used for API calls
   */
  jwt?: string;
  /**
   * All permissions applied to the user account.
   */
  permissions?: Permission[];
  /**
   * All tags associated with the user account. These are used for class visibility on the classroom grid
   */
  tags?: Tag[];

  gSuiteId?: string;

  domain? : string;

  schools?: [];

  accessFilterId?: number;

  fbToken?: string;

}

export interface UserResponse {
  fbToken?: string;
  /**
   * Associated User record
   */
  user?: User;
  /**
   * Action specific to the request
   */
  action?: string;
  /**
   * Current status of the request
   */
  status: string;
}

export interface AppUser {
  /**
   * Unique id within LSC
   */
  id: number;
  /**
   * Is the user currently disabled within LSC?
   */
  isDisabled: boolean;
  /**
   * Role assigned to the user within LSC
   */
  role: {
    id: number;
    name: string;
    permissions?: Permission[];
  };

  /**
   * Role Id during creation
   */
  roleId?: number;
  /**
   * Associated User record within LSC
   */
  profile: User;
  /**
   * All tags associated with the user account. These are used for class visibility on the classroom grid
   */
  tags?: Tag[];

  access_filter?: any;

  schools?: [];
}

export interface Role {
  /**
   * Unique id for the role
   */
  id?: number;
  /**
   * Name/Title of the role created by the LSC user
   */
  name: string;
  /**
   * Customer Id for the Customer associated with the Role
   */
  customerId: number;
  /**
   * Additional field included in API responses - The number of users with a related role
   */
  userCount?: number;
  /**
   * Description of the role created by LSC user
   */
  description?: string;
  /**
   * Short key for the role (if a default role)
   */
  role_key?: string;
  /**
   * Permissions associated with the role
   */
  permissions?: Permission[];
  /**
   * Permission IDs for the permissions associated with the role
   */
  permissionIds?: number[];
}

export interface UserListResponse {
  /**
   * Paged list of logs
   */
  data: AppUser[];
  /**
   * Total number of logs
   */
  count: number;
}

export interface DirectoryListResponse {
  /**
   * Paged list of logs
   */
  data: User[];
  /**
   * Total number of logs
   */
  count: number;
}

export interface EmailCheckResponse {

  alreadyUsers: string[];
  validNewUsers: string[];
  invalidUsers: string[];
}

export interface ValidEmailResponse {
  validUsers: string[];
  invalidUsers: string[];
  valid: any[];
  invalid: string[];
}

export interface AccessFilters {
  id: number;
  customer_id: number;
  label?: string;
  description?: string;
  settings?: {
    filters: {
      [column: string]: {
        type: string;
        filterType: string;
        values?: string[];
        filter?: string;
      };
    };
  };
}

export interface ClassroomAccessDetails {
  [classroomId: string]: {
    student: boolean;
    teacher: boolean;
  };
}

@Injectable()
export class UserService {

  teachers: User[];

  private httpOptions: { headers: HttpHeaders; body?: any };
  private serviceUrls = environment.urls.userServices;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }

  compactFilter(filterObj) {

    return {
      c: filterObj.field,
      f: {
        type: 'contains',
        filterType: 'text',
        filter: filterObj.value
      }
    };

  }

  compactAGFilter(filterObj, field) {

    return {
      c: field,
      f: filterObj
    };

  }

  find(options = {}, opts: any = { useCache: true }): Observable<UserListResponse> {

    let url = this.serviceUrls.users.route;

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filterModel') {

          _.each(options[param], (filter, field) => {

            // if (options[param][field].filterType === "set") {
            //   _.each(filter.values, function(val) {
            //
            //     url += `filter[]=${JSON.stringify(_this.compactAGFilter({filter: val, operator: 'equals' }, field))}&`;
            //   });
            //
            // } else
            if (options[param][field].filterType === 'date') {

              url += `filter[]=${JSON.stringify(_this.compactAGFilter(options[param][field], field))}&`;

            } else {
              // _.each(options[param], (filter, field) => {
              url += `filter[]=${JSON.stringify(_this.compactAGFilter(filter, field))}&`;
              // });
            }

          });

        } else

        if (param === 'filter') {

          _.each(options[param].filters, function(f) {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });
        } else if (param === 'sortModel') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.sort) {
                url += `sortBy[]=${srt.colId}&`;
                url += `sortDir[]=${srt.sort}&`;
              }

            });

          }

        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<UserListResponse>(url, this.httpOptions);
  }

  getUsers(): Observable<AppUser[]> {
    const url = this.serviceUrls.users.getAllUsers;
    return this.http.get<AppUser[]>(url,
      this.httpOptions
    );
  }

  getUserById(userId): Observable<AppUser> {
    const url = this.serviceUrls.users.getUser.replace(':id', userId);
    return this.http.get<AppUser>(url, this.httpOptions);
  }

  getUserFromEmails(emails: string[]): Observable<ValidEmailResponse> {
    const url = this.serviceUrls.users.getUsersFromEmail;
    const body = {
      emails
    };
    return this.http.post<ValidEmailResponse>(url, body, this.httpOptions);
  }

  checkUserEmails(emails): Observable<ValidEmailResponse> {
    const url = this.serviceUrls.users.checkEmail;
    const body = {
      emails
    };
    return this.http.post<ValidEmailResponse>(url, body, this.httpOptions);
  }

  findStudents(options = {}, opts: any = { useCache: true }): Observable<DirectoryListResponse> {
    let url = this.serviceUrls.users.findStudents;

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filter') {

          if (options[param].op) {
            url += `${param}Op=${options[param].op}&`;
          }

          _.each(options[param].filters, function(f) {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });


        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<DirectoryListResponse>(url, this.httpOptions);
    // .pipe(
      // map(response => {
      //   this.teachers = [... response.data];
      //   return response;
      // }));
  }

  findStudentsByList(body = {}, opts: any = { useCache: true }): Observable<DirectoryListResponse> {
    let url = this.serviceUrls.users.findStudents;

    if (opts.useCache) {
      url += `?useCache=true`;
    }

    return this.http.post<DirectoryListResponse>(url, body, this.httpOptions);
  }

  getTeachers(options = {}, opts: any = { useCache: true }): Observable<DirectoryListResponse> {

    let url = this.serviceUrls.users.getTeachers;

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filter') {

          if (options[param].op) {
            url += `${param}Op=${options[param].op}&`;
          }

          _.each(options[param].filters, function(f) {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });


        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<DirectoryListResponse>(url, this.httpOptions).pipe(
      map(response => {
        this.teachers = [... response.data];
        return response;
      }));
  }

  getStudents(options = {}, opts: any = { useCache: true }): Observable<DirectoryListResponse> {

    let url = this.serviceUrls.users.getStudents;

    const _this = this;

    if (options) {

      url += '?';
      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<DirectoryListResponse>(url, this.httpOptions).pipe(
      map(response => {
        this.teachers = [... response.data];
        return response;
      }));
  }

  getAdmins(options = {}, opts: any = { useCache: true }): Observable<DirectoryListResponse> {

    let url = this.serviceUrls.users.getAdmins;

    const _this = this;

    // url += '?';
    //
    // const adminFilter = {
    //   c: "isAdmin",
    //   f: {
    //     type: "equals",
    //     filterType: "text",
    //     filter: true
    //   }
    // };
    //
    // url += `filterOp=AND&filter[]=${JSON.stringify(adminFilter)}&`;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filter') {

          if (options[param].op) {
            url += `${param}Op=${options[param].op}&`;
          }

          _.each(options[param].filters, function(f) {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });


        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<DirectoryListResponse>(url, this.httpOptions).pipe(
      map(response => response));
  }

  emailCheck(emails): Observable<EmailCheckResponse> {
    const body = {emails};
    const url = this.serviceUrls.users.checkUserEmails;
    return this.http.post<EmailCheckResponse>(url, body, this.httpOptions);
  }

  getStudentList(details): Observable<DirectoryListResponse>  {
    const body = details;
    const url = this.serviceUrls.users.getStudentList;
    return this.http.post<DirectoryListResponse>(url, body, this.httpOptions);
  }

  getRosters(classIds) {
    const url = this.serviceUrls.users.getRosters;
    const body = classIds;
    return this.http.post(url, body, this.httpOptions);
  }

  addUser( data: AppUser): Observable<User> {
    // @ts-ignore
    const postBody: User = {
      id: data.id,
      // @ts-ignore
      gId: data.gId,
      role: data.roleId,
      isDisabled: data.isDisabled || false
    };

    const url = this.serviceUrls.users.createUser;
    return this.http.post<User>(url, postBody, this.httpOptions);
  }

  bulkAddUsers(data): Observable<void> {
    const body = data;
    const url = this.serviceUrls.users.bulkCreateUsers;
    return this.http.post<any>(url, body, this.httpOptions);
  }

  softDelete(data: any[]) {
    const url = this.serviceUrls.users.route;
    const requestOptions = this.httpOptions;
    requestOptions.body = { ids: data };
    return this.http.request<void>('delete', url, this.httpOptions);
  }

  directorySync(): Observable<any> {
    const url = this.serviceUrls.users.usersDirectorySync;
    return this.http.post<any>(url, { type: 'user-sync' }, this.httpOptions);
  }

  getRoles(): Observable<Role[]> {
    const url = this.serviceUrls.roles.all;
    return this.http.get<Role[]>(url, this.httpOptions);
  }

  getRolePermissions(roleId: string | number): Observable<Permission[]> {
    const url = this.serviceUrls.getRolePermissions.replace(':id', roleId.toString());
    return this.http.get<Permission[]>(url, this.httpOptions);
  }

  getAllPermissions(): Observable<Permission[]> {
    const url = this.serviceUrls.getPermissions;
    return this.http.get<Permission[]>(url, this.httpOptions);
  }

  setRolePermissions(roleId: any, permissionIds: number[]): Observable<void> {
    const url = this.serviceUrls.addRolePermissions.replace(':id', roleId.toString());
    const body = { permissionIds };
    return this.http.post<void>(url, body, this.httpOptions);
  }

  createRole(role: Role): Observable<Role> {
    const url = this.serviceUrls.createRole;
    const body = {roleName: role.name, roleDescription: role.description};
    return this.http.post<Role>(url, body, this.httpOptions);
  }

  updateRole(role: Role): Observable<Role> {
    const url = this.serviceUrls.updateRole.replace(':id', role.id.toString());
    const body = { role };
    return this.http.put<Role>(url, body, this.httpOptions);
  }

  deleteRole(roleId: string): Observable<void> {
    const url = this.serviceUrls.deleteRole.replace(':id', roleId);
    return this.http.delete<void>(url, this.httpOptions);
  }

  updateUser(usersDetails): Observable<ApiResponse> {
    const url = this.serviceUrls.users.updateUser;
    const payload = usersDetails;
    return this.http.patch<ApiResponse>(url, payload, this.httpOptions);
  }

  resendWelcomeEmail(messageDetails): Observable<ApiResponse> {
    const url = this.serviceUrls.users.welcomeEmail;
    return this.http.post<ApiResponse>(url, messageDetails, this.httpOptions);
  }

  updateUsers(usersDetails): Observable<ApiResponse> {
    const url = this.serviceUrls.users.bulkUpdateUsers;
    const payload = usersDetails;
    return this.http.patch<ApiResponse>(url, payload, this.httpOptions);
  }

  updateAccessFilters(filter) {
    const url = this.serviceUrls.users.gridFilters.route;
    const payload = { filter };
    return this.http.post<ApiResponse>(url, payload, this.httpOptions);
  }

  deleteAccessFilters(id: number) {
    const url = this.serviceUrls.users.gridFilters.single.replace(':id', id.toString());
    return this.http.delete<ApiResponse>(url, this.httpOptions);
  }

  getUserFilters() {
    const url = this.serviceUrls.users.gridFilters.route;
    return this.http.get<ApiResponse>(url, this.httpOptions);
  }

  updateUserFilters(filter) {
    const url = this.serviceUrls.users.gridFilters.route;
    const payload = { filter };
    return this.http.post<ApiResponse>(url, payload, this.httpOptions);
  }


  getClassroomAccessStats(opts) {
    const url = this.serviceUrls.users.access.route;
    const payload = { schoolIds: opts.schoolIds, accessFilter: opts.accessFilter };
    return this.http.post<ApiResponse>(url, payload, this.httpOptions);
  }

  getUserClassroomAccess(data) {
    const replaceId = this.serviceUrls.users.access.profile.replace(':id', data.userId);
    const url = replaceId.replace(':timeframeId', data.timeframeId);
    return this.http.get<ApiResponse>(url, this.httpOptions);
  }

  getSchoolDetails(data) {
    const url = this.serviceUrls.users.getSchoolDetails;
    const payload = { userId: data.userId, timeframeId: data.timeframeId };
    return this.http.post<ApiResponse>(url, payload, this.httpOptions);
  }

  getTeacherDetails(options = {},data) {
    let url = this.serviceUrls.users.getTeacherDetails;
    const payload = { userId: data.userId, timeframeId: data.timeframeId };
    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filterModel') {

          _.each(options[param], (filter, field) => {
            if (options[param][field].filterType === 'date') {

              url += `filter[]=${JSON.stringify(_this.compactAGFilter(options[param][field], field))}&`;

            } else {
              url += `filter[]=${JSON.stringify(_this.compactAGFilter(filter, field))}&`;
            }
          });
        } else
        if (param === 'filter') {
          _.each(options[param].filters, function(f) {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });
        } else if (param === 'sortModel') {
          if (options[param].length) {
            _.each(options[param], function(srt) {
              if (srt.sort) {
                url += `sortBy[]=${srt.colId}&`;
                url += `sortDir[]=${srt.sort}&`;
              }
            });
          }
        } else if (param === 'sort') {
          if (options[param].length) {
            _.each(options[param], function(srt) {
              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }
            });
          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }
    }

    return this.http.post<ApiResponse>(url, payload, this.httpOptions).pipe(
      map(response => response));
  }

  getTotalClassroomStats() {
    const url = this.serviceUrls.users.getClassroomCount;
    return this.http.get<ApiResponse>(url, this.httpOptions);
  }

// {
//   "37213156587": {
//     "student": false,
//     "teacher": true
// },
// "39790387123": {
//     "student": false,
//     "teacher": true
// },
// "20755025912": {
//     "student": false,
//     "teacher": false
// }
// }

  getClassroomAccessDetails(userId: string, classroomIds: string[]): Observable<ClassroomAccessDetails> {
    const url = this.serviceUrls.users.route + '/' + userId + '/classroomAccess';
    const params: HttpParams = new HttpParams().set('courses', JSON.stringify(classroomIds));

    return this.http.get<ClassroomAccessDetails>(url, { params });


    // {{apiURI}}/api/v1/users/115482652672052281779/classroomAccess?courses=["37213156587","39790387123","20755025912"]
  }

}

