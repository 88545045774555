import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LscEvents } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';

export interface SaveGridDialogData {
  settings: object;
  label?: string;
  type?: string;
}

@Component({
  selector: 'app-save-grid-settings-dialog',
  templateUrl: './save-grid-settings.html'
})

export class SaveGridSettingsDialogComponent implements OnInit {
  type = 'layout';

  saveStateLabel = new FormControl('', [
    Validators.required
  ]);
  private filters = false;


  constructor(
    public dialogRef: MatDialogRef<SaveGridSettingsDialogComponent>,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: SaveGridDialogData
    ) {
    if (data?.type) {
      this.type = data.type;
    }
    if((data as any).filters){
      this.filters = true;
    }
  }

  async confirm(): Promise<void> {
    if(this.filters) {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.savedCustomFilter as AnalyticsEvent);
    } else {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.savedCustomLayout as AnalyticsEvent);
    }

    this.dialogRef.close({
      label: this.saveStateLabel.value,
      settings: this.data
    });

  }

  cancel(): void {
    this.dialogRef.close('cancelled');
  }

  ngOnInit() { }

}
