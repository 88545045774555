import {Injectable, Optional} from '@angular/core';

import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

export interface Job {
  /**
   * Unique id for the job
   */
  id: number;
  /**
   * User id of the initiator of the job
   */
  user_id?: number;
  /**
   * Text summary of the action taken in the job
   */
  action: string;
  /**
   * Current state of the job
   */
  state: string;
  /**
   * Start timestamp of the job
   */
  startTimestamp: string;
  /**
   * Last known update timestamp for the job
   */
  lastUpdateTime?: string;
  /**
   * String message at last update timestamp
   */
  lastUpdateMessage?: string;
  /**
   * If completed, the timestamp at which the job completed.
   */
  finishedTimestamp?: string;
}

@Injectable()
export class JobService {

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls;


  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      })
    };
  }

  // getJob(jobId: string): Observable<Job> {
  //   const url = this.serviceUrls.kue.singleJob.replace(':id', jobId);
  //   return this.http.get<Job>(url, this.httpOptions);
  // }

  getLastJob(jobType: string): Observable<Job> {
    const url = this.serviceUrls.userServices.getLastJob.replace(':jobType', jobType);
    return this.http.get<Job>(url, this.httpOptions);
  }

}
