import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface ErrorDialogData {

  actionTrigger?: string;
  okBtnLabel?: string;

}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})

export class ErrorDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData
  ) {
  }

  ngOnInit() {}

  async onSaveClick(): Promise<void> {

    this.dialogRef.close();

  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}
