import {Component, OnInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import {AuthService} from '../../../auth/services/Auth/auth.service';

@Component({
  selector: 'app-user-central',
  templateUrl: './user-central.component.html',
  styleUrls: ['./user-central.component.scss']
})

export class UserCentralComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(public router: Router,
              public auth: AuthService) {
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  home() {
    this.router.navigate(['/']);
  }

}
