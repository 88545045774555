import {EventEmitter, Output, Injectable, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';
import { Course } from '../../../services/classroom.service';
import { Tag } from '../../../services/tags.service';
import {AuditsService} from '../../../services/Audits/audits.service';
import {takeUntil} from 'rxjs/operators';
import {AuthService, User} from '../../auth/services/Auth/auth.service';



export interface ClassroomTagDialogData {

  id?: number;
  cls: Course;
  selectedTags: number[];
  tags: Tag[];
  updatedEmailTMPL?: string;
  replyEmail?: string;

}

@Injectable({
  providedIn: 'root'
})

export class ClassroomGridService implements OnDestroy {

  state: {
    busy: boolean;
  };

  // @Output() filterTeacher: EventEmitter<object> = new EventEmitter();

  filterTeacher: Subject<string> = new Subject<string>();
  dialogTrigger: Subject<string> = new Subject<string>();
  gridServiceAction: Subject<string> = new Subject<string>();

  contextActions = [
    { service: 'classroom', label: 'View Classroom Info', action: 'viewInfo_' },
    { service: 'classroom', label: 'Filter to classes taught by teacher', action: 'filterByTeacher' },
    { service: 'classroom', label: 'Refresh Roster and Classwork', action: 'refreshClass' },
  ];

  private onDestroy$: Subject<void> = new Subject<void>();
  private user: User;

  constructor(
    private _auditSvc: AuditsService,
    private _authSvc: AuthService
  ) {
    this.state = {
      busy: false
    };

    this._authSvc.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  filterByTeacher(row: Course): void {
    // this.filterTeacher.emit({row: row} );
    this.filterTeacher.next(row.ownerId);
  }

  refreshClass(row: Course): void {

    const msg = {
      row,
      type: 'refreshClass'
    };

    this.gridServiceAction.next(JSON.stringify(msg) as string);
  }

  async viewInfo_(row: Course): Promise<void> {

    await this._auditSvc.createAuditLog({
      typeId: 2,
      actionId: 999,
      customer_id: this.user.profile.customerId,
      user_id: this.user.profile.id,
      data: {
        name: row.name,
        section: row.section,
        teacher: {
          primaryEmail: row.Owner?.primaryEmail || row.ownerId
        }
      }
    }).toPromise();

    const msg = {
      row,
      type: 'info'
    };

    this.dialogTrigger.next(JSON.stringify(msg) as string);

  }

}
