// TODO: Move this to monorepo so it can be reused
import { AngularFirestore, AngularFirestoreDocument, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AngularFirestoreRepositoryCollection<T> {
  private collectionLookup: Record<string, T> = {};

  constructor(
    private firestore: AngularFirestore,
    private createInstanceOfType: (firestoreDoc: AngularFirestoreDocument) => T
  ) {}

  public getCollection(
    path: string,
    orderBy: { value: string; order: 'asc' | 'desc' },
    limit?: number
  ): Observable<T[]> {
    const collection = limit ?
      this.firestore.collection(path, ref => ref.orderBy(orderBy.value, orderBy.order).limit(limit)) :
      this.firestore.collection(path, ref => ref.orderBy(orderBy.value, orderBy.order));
    return collection.snapshotChanges().pipe(map(changes => this.onUpdate(changes)));
  }

  public onUpdate(changes: DocumentChangeAction<unknown>[]): T[] {
    this.collectionLookup = changes.reduce((accumulator, job) => {
      const path = job.payload.doc.ref.path;
      accumulator[path] =
        this.collectionLookup?.[path] ??
        this.createInstanceOfType(this.firestore.doc(path));
      return accumulator;
    }, {});
    return Object.values(this.collectionLookup);
  }
};
