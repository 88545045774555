import * as moment from 'moment';

import { transformAliases } from '../../services/aliases-mapping.service';

export const formatBooleanToYN = (data) => {
  switch (data.value) {
    case '1':
    case 1:
    case true:
      return 'Y';
    case '0':
    case 0:
    case false:
      return 'N';
    default:
      return null;
  }
};

export const getColumnDefs = (_this): any[] => (
  [
    {
      field: '',
      headerName: '',
      width: 40,
      sortable: false,
      resizable: false,
      filter: false,
      hide: false,
      checkboxSelection: true,
      headerComponent: 'selectAllHeader',
      headerComponentParams: {
        selectedItems: _this.selectedItems
      },
      pinned: 'left',
      lockPinned: true,
      lockPosition: true,
      lockVisible: true,
      suppressSizeToFit: true
    },

    {
      cellRenderer: 'classroomStateCellRenderer',
      field: 'courseState',
      headerName: 'State',
      width: 120,
      sortable: true,
      suppressMenu: true,
      hide: false,
      pinned: 'left',
      lockPinned: true,
      lockPosition: true,
      lockVisible: true,
      filter: 'agSetColumnFilter',
      filterParams: { cellHeight: 20, values: ['ACTIVE', 'PROVISIONED', 'DECLINED', 'ARCHIVED', 'SUSPENDED', 'NOT_FOUND'], newRowsAction: 'keep', debounceMs: 500 }
    },

    {
      field: 'name',
      headerName: 'Classroom name',
      width: 360,
      autoHeight: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        debounceMs: 500,
        newRowsAction: 'keep'
      },
      sortable: true,
      hide: false,
      suppressMenu: true,
      cellRenderer: 'classroomCellRenderer',
      cellRendererParams: { currentUserEmail: _this.currentUserEmail },
      pinned: false,
    },

    {
      field: 'section',
      headerName: 'Section',
      width: 150,
      sortable: true,
      filter: 'agTextColumnFilter',
      hide: false,
      headerComponentParams: { menuIcon: 'fa-external-link-alt' }
    },

    {
      field: 'id',
      headerName: 'Class Id',
      width: 150,
      sortable: true,
      filter: 'agTextColumnFilter',
      hide: true
    },

    {
      field: 'gAliasesCount',
      headerName: 'Alias count',
      width: 60,
      sortable: false,
      cellClass: 'text-center',
      cellRenderer: 'aliasesCountCellRenderer',
      filter: false,
      hide: false,
      valueGetter: (params) => {
        if (params.data.gAliases) {
          return params.data.gAliases;
        } else {
          return [];
        }
      }
    },

    {
      field: 'gAliases.alias',
      headerName: 'Aliases',
      width: 150,
      sortable: false,
      cellClass: 'text-center',
      cellRenderer: 'aliasesCellRenderer',
      cellRendererParams: {
        nodesState: {}
      },
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        if (params.data.gAliases) {
          return params.data.gAliases;
        } else {
          return [];
        }
      },
      processCellForClipboard: (params) => transformAliases(params).join(', '),
      hide: true,
      autoHeight: true
    },

    {
      headerName: 'Timeframe',
      field: 'tf.id',
      width: 150,
      sortable: false,
      cellRenderer: 'tagCellRenderer',
      cellRendererParams: {
        key: 'tf'
      },
      suppressMenu: false,
      menuTabs: ['filterMenuTab'],
      hide: false,
      cellClass: 'text-center',
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        cellHeight: 20,
        apply: true,
        cellRenderer: (params) => {
          const tag = _this.context.componentParent.timeframeSvc.single(params.value);
          return (tag) ? tag.label : params.value;
        },
        values: (params) => {
          setTimeout(() => {
            params.success(_this.timeframeIds);
          }, 0);
        },
        comparator: (a, b) => {

          // Force 'Blanks' to appear at the top...
          if (a === '(Missing)') {
            return -1;
          } else if (b === '(Missing)') {
            return 1;
          }

          const atag = _this.context.componentParent.timeframeSvc.single(a);
          const btag = _this.context.componentParent.timeframeSvc.single(b);

          if (atag.label > btag.label) {
            return 1;
          } else if (atag.label < btag.label) {
            return -1;
          } else {
            return 0;
          }
        },
        newRowsAction: 'keep',
        debounceMs: 500,
        textFormatter: (data) => {
          const tag = _this.context.componentParent.timeframeSvc.single(data);
          return (tag) ? tag.label : data;
        }
      },
      valueFormatter: (data) => {

        if (data.value) {
          if (data.value.id) {
            return data.value.id.toString();
          } else {
            return data.value.toString();
          }
        } else {
          return '';
        }
      },

      floatingFilterComponent: 'selectedCountFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false
      },
    },

    {
      headerName: 'School',
      field: 'sch.id',
      width: 150,
      sortable: false,
      cellRenderer: 'tagCellRenderer',
      cellRendererParams: {
        key: 'sch'
      },
      suppressMenu: false,
      menuTabs: ['filterMenuTab'],
      hide: false,
      cellClass: 'text-center',
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        cellHeight: 20,
        apply: true,
        cellRenderer: (params) => {
          const tag = _this.context.componentParent.schoolSvc.single(params.value);
          return (tag) ? tag.name : params.value;
        },
        values: (params) => {
          setTimeout(() => {
            params.success(_this.schoolIds);
          }, 10);
        },
        comparator: (a, b) => {

          // Force 'Blanks' to appear at the top...
          if (a === '(Missing)') {
            return -1;
          } else if (b === '(Missing)') {
            return 1;
          }

          const atag = _this.context.componentParent.schoolSvc.single(a);
          const btag = _this.context.componentParent.schoolSvc.single(b);

          if (atag.name > btag.name) {
            return 1;
          } else if (atag.name < btag.name) {
            return -1;
          } else {
            return 0;
          }
        },
        newRowsAction: 'keep',
        debounceMs: 500,
        textFormatter: (data) => {
          const tag = _this.context.componentParent.schoolSvc.single(data);
          return (tag) ? tag.name : data;
        }
      },
      valueFormatter: (data) => {

        if (data.value) {
          if (data.value.id) {
            return data.value.id.toString();
          } else {
            return data.value.toString();
          }
        } else {
          return '';
        }
      },

      floatingFilterComponent: 'selectedCountFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: false
      },
    },

    {
      headerName: 'School Count',
      field: 'schoolCount',
      width: 50,
      sortable: true,
      suppressMenu: true,
      hide: true,
      cellClass: 'text-center',

      headerComponent: 'iconHeader',
      headerComponentParams: {
        headerIcon: 'business',
        enableMenu: false,
        enableSorting: false,
        menuIcon: 'fa-bars',
        description: 'School count'
      },
      filter: 'agNumberColumnFilter',
      autoHeight: true
    },

    {
      field: 'guardiansEnabled',
      headerName: 'Guardians Enabled?',
      width: 80,
      cellClass: 'text-center',
      headerClass: 'text-center',
      sortable: true,
      suppressMenu: true,
      hide: false,
      filter: 'agSetColumnFilter',
      filterParams: { cellHeight: 20, values: [1, 0], valueFormatter: formatBooleanToYN, newRowsAction: 'keep', debounceMs: 500 },
      headerComponent: 'iconHeader',
      headerComponentParams: {
        headerIcon: 'people',
        description: 'Guardians enabled?'
      },
      // cellRenderer: 'booleanCheckCellRenderer',
      valueFormatter: formatBooleanToYN,
    },

    {
      field: 'ownerId',
      headerName: 'Primary Teacher Id',
      width: 200,
      filter: 'agTextColumnFilter',
      cellClass: 'text-center',
      hide: true,
    },
    {
      field: 'Owner.suspended',
      headerName: 'Teacher Suspended?',
      width: 80,
      cellClass: 'text-center',
      headerClass: 'text-center',
      sortable: false, //TODO: Fix the issue with sorting on associations/relations,
      suppressMenu: true,
      hide: true,
      filter: 'agSetColumnFilter',
      filterParams: { cellHeight: 20, values: [1, 0], valueFormatter: formatBooleanToYN, newRowsAction: 'keep', debounceMs: 500 },
      headerComponent: 'iconHeader',
      headerComponentParams: {
        fontSet: 'material-symbols-outlined',
        headerIcon: 'supervised_user_circle_off',
        description: 'Teacher suspended?'
      },
      valueFormatter: formatBooleanToYN,
      cellRenderer: 'textWithDefaultIconCellRenderer',
      defaults: {
        tooltip: 'Account not found',
      },
    },
    {
      field: 'Owner.primaryEmail',
      headerName: 'Teacher Email',
      width: 120,
      filter: 'agTextColumnFilter',
      filterParams: {
        debounceMs: 500,
        newRowsAction: 'keep'
      },
      sortable: false, //TODO: Fix the issue with sorting on associations/relations
      suppressMenu: true,
      hide: true,
      cellRenderer: 'defaultTextCellRenderer',
      defaults: {
        value: 'Unknown'
      },
    },
    {
      headerName: 'Teacher Name',
      field: 'Owner.name',
      filterParams: {
        debounceMs: 500,
        newRowsAction: 'keep'
      },
      filter: 'agTextColumnFilter',
      width: 150,
      autoHeight: true,
      cellRenderer: 'primaryTeacherProfileCellRenderer',
      defaults: {
        name: 'Unknown Teacher',
        email: 'User not found'
      },
      sortable: false, //TODO: Fix the issue with sorting on associations/relations,
    },

    {
      field: 'teacherCount',
      headerName: 'Teacher Count',
      width: 50,
      cellClass: 'text-center',
      headerClass: 'text-center',
      filter: 'agNumberColumnFilter',
      sortable: true,
      suppressMenu: true,
      hide: true,
      headerComponent: 'iconHeader',
      headerComponentParams: {
        headerIcon: 'person_outline',
        enableMenu: true,
        enableSorting: true,
        menuIcon: 'fa-bars',
        description: 'Teacher count'
      },
      cellRenderer: 'valueCellRenderer'
    },

    {
      field: 'coteachers.primaryEmail',
      headerName: 'Teachers',
      width: 180,
      filter: 'agTextColumnFilter',
      sortable: false,
      autoHeight: true,
      hide: false,
      cellRenderer: 'coteacherCellRenderer',
    },

    {
      field: 'teacherOUs',
      headerName: 'Teacher OUs',
      width: 200,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true,
      hide: true,
      cellRenderer: 'oUsCellRenderer',
      cellRendererParams: {
        nodesState: {}
      },
    },

    {
      field: 'studentCount',
      headerName: 'Student Count',
      width: 50,
      cellClass: 'text-center',
      headerClass: 'text-center',
      filter: 'agNumberColumnFilter',
      sortable: true,
      suppressMenu: true,
      hide: false,
      headerComponent: 'iconHeader',
      headerComponentParams: {
        headerIcon: 'account_box',
        enableMenu: true,
        enableSorting: true,
        menuIcon: 'fa-bars',
        description: 'Student count'
      },
      cellRenderer: 'valueCellRenderer'
    },

    {
      field: 'studentOUs',
      headerName: 'Student OUs',
      width: 200,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true,
      hide: true,
      cellRenderer: 'oUsCellRenderer',
      cellRendererParams: {
        nodesState: {}
      }
    },

    {
      field: 'courseworkCount',
      headerName: 'Classwork count',
      width: 50,
      cellClass: 'text-center',
      headerClass: 'text-center',
      filter: 'agNumberColumnFilter',
      sortable: true,
      suppressMenu: true,
      hide: false,
      headerComponent: 'iconHeader',
      headerComponentParams: {
        headerIcon: 'class',
        description: 'Classwork count'
      },
      cellRenderer: 'valueCellRenderer'
    },

    {
      field: 'maxCDT',
      headerName: 'Most recent classwork',
      width: 140,
      cellClass: 'text-center',
      filter: 'agDateColumnFilter',
      sortable: true,
      suppressMenu: true,
      hide: false,
      valueFormatter: (data) => {
        if (data.value === null) {
          return 'N/A';
        }
        return moment(data.value).format('MM/DD/YY HH:mm');
      }
    },

    {
      field: 'enrollmentCode',
      headerName: 'Join Code',
      width: 100,
      cellClass: 'text-center',
      filter: 'agTextColumnFilter',
      sortable: false,
      suppressMenu: true,
      hide: false
    },
    // {
    //   field: 'tags',
    //   headerName: 'Tags',
    //   width: 100,
    //   cellClass: 'text-center',
    //   headerClass: 'text-center',
    //   filter: false,
    //   sortable: false,
    //   suppressMenu: true,
    //   hide: false,
    //   cellRenderer: 'tagCellRenderer',
    //   cellRendererParams: {
    //     key: 'tags'
    //   },
    //   autoHeight: true
    //   // header: {
    //   //   icon: 'label',
    //   //   description: 'Tags'
    //   // },
    // },

    {
      field: 'refreshTime',
      headerName: 'Last refresh',
      width: 140,
      cellClass: 'text-center',
      filter: 'agDateColumnFilter',
      sortable: true,
      suppressMenu: true,
      hide: true,
      valueFormatter: (data) => {
        if (data.value === null) {
          return 'N/A';
        }
        return moment(data.value).format('MM/DD/YY HH:mm');
      }
    },

    {
      field: 'recentActivity',
      headerName: 'Most recent activity',
      width: 80,
      cellClass: 'text-center',
      headerClass: 'text-center',
      sortable: false,
      suppressMenu: true,
      hide: false,
      filter: false,
      // cellRenderer: 'booleanCheckCellRenderer',
      valueFormatter: (data) => {

        // Most recent of creationTime/lastUpdateTime/maxCDT

        if (!data.node.data) {
          return '';
        }

        const dates = [
          moment(data.node.data.creationTime),
          moment(data.node.data.updateTime)
        ];

        if (data.node.data.maxCDT) {
          dates.push(moment(data.node.data.maxCDT));
        }

        const max = moment.max(dates);

        return max.format('MM/DD/YY HH:mm');

      }
      // valueFormatter: (data) => {
      //   return (!data.value) ? 'N' : 'Y';
      // },
    },

    {
      field: 'creationTime',
      headerName: 'Created',
      width: 140,
      cellClass: 'text-center',
      filter: 'agDateColumnFilter',
      sortable: true,
      suppressMenu: true,
      hide: false,
      valueFormatter: (data) => moment(data.value).format('MM/DD/YY HH:mm')
    },
    {
      field: 'updateTime',
      headerName: 'Last updated',
      width: 140,
      cellClass: 'text-center',
      filter: 'agDateColumnFilter',
      sortable: true,
      suppressMenu: true,
      hide: true,
      valueFormatter: (data) => moment(data.value).format('MM/DD/YY HH:mm')
    },

  ]
);
