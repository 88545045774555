import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@amplabs/directives/directives';
import { FusePipesModule } from '@amplabs/pipes/pipes.module';
import {JobModule} from '../app/modules/jobs/job.module';
import { InstallAdditionalScopeDialogComponent } from './components/install-additional-scope-dialog/install-additional-scope-dialog.component';
import { CoModule } from 'app/modules/common/co.module';

// import {ColorPickerModule} from 'ngx-color-picker';

@NgModule({
  declarations: [
    InstallAdditionalScopeDialogComponent
  ],
  imports  : [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    FuseDirectivesModule,
    FusePipesModule,
    JobModule,
    CoModule,
    // ColorPickerModule
  ],
  exports  : [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    FuseDirectivesModule,
    FusePipesModule,
    JobModule,
    // ColorPickerModule
  ]
})
export class AppSharedModule { }
