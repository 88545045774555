import { Injectable } from '@angular/core';
import { TimeRange } from 'app/modules/insights/services/insights.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import * as moment from 'moment';

import { LocalStorageService } from '../core/services/LocalStorage/local-storage.service';
import { AuthService } from '../modules/auth/services/Auth/auth.service';
import { ClassworkLoadingState, ClassworkLoadingStateForStudent, Student, Tab } from '../modules/students/students.interfaces';
import { TimeframeService } from './timeframes.service';



@Injectable({ providedIn: 'root' })
export class StudentExplorerStateService {

  private LOCAL_STORAGE_TABS_KEY = 'studentExplorerTabs';

  private explorerTabs: Tab[];
  private searchResultHistory: Student[] = [];
  private searchResultHistoryMaxItems = 5;

  private menuExpanded$ = new BehaviorSubject({student: '', state: true});
  private activeView$ = new BehaviorSubject({student: '', view: 'detail'});

  private classworkLoadingState$ = new BehaviorSubject<ClassworkLoadingState>({});

  private timeRanges: {[id: string]: BehaviorSubject<TimeRange>} = {};

  constructor(
    private localStorageService: LocalStorageService,
    private _timeframeSvc: TimeframeService,
    private _authSvc: AuthService
  ) {
    this._setInitialTabState();
  }

  getExplorerTabs(): Tab[] {
    return this.explorerTabs.slice();
  }

  setExplorerTabs(tabs: Tab[]): number {
    this.explorerTabs = tabs.slice();
    this.localStorageService.set(this.LOCAL_STORAGE_TABS_KEY, JSON.stringify(this.explorerTabs));
    return this.explorerTabs.length;
  }

  appendToSearchResultHistory(student: Student) {
    const existingHistoryItem: Student = this.searchResultHistory.find(historicStudent => student.gId === historicStudent.gId);
    if (existingHistoryItem) {
      return;
    }

    this.searchResultHistory.push(student);
    this.searchResultHistory = this.searchResultHistory.slice(this.searchResultHistoryMaxItems * -1);
  }

  getSearchResultHistory() {
    return this.searchResultHistory.slice();
  }

  getGridsLocalStorageKey(studentId: string, viewName: 'classroom' | 'classwork') {
    let keyPrefix;
    if (viewName === 'classroom') {
      keyPrefix = 'studentClassrooms_';
    } else if (viewName === 'classwork') {
      keyPrefix = 'studentClasswork_';
    }
    const keyName = keyPrefix + studentId;
    return keyName;
  }

  clearGridState(studentId: string, viewName: 'classroom' | 'classwork') {
    const keyName = this.getGridsLocalStorageKey(studentId, viewName);
    this.localStorageService.remove(keyName);
  }

  _setInitialTabState() {
    try {
      const storedTabs = this.localStorageService.get(this.LOCAL_STORAGE_TABS_KEY);
      if (storedTabs) {
        this.explorerTabs = JSON.parse(storedTabs);
      } else {
        this.explorerTabs = [];
      }
    } catch (error) {
      this.explorerTabs = [];
    }
  }

  getMenuState() {
    return this.menuExpanded$.asObservable();
  }

  toggleMenuState(studentId: string, state: boolean) {
    this.menuExpanded$.next({student: studentId, state});
  }

  getActiveView() {
    return this.activeView$.asObservable();
  }

  toggleView(studentId: string, newActiveView: 'detail' | 'classroom' | 'classwork') {
    this.activeView$.next({student: studentId, view: newActiveView});
  }

  setClassworkLoadingState(studentId: string, studentState: ClassworkLoadingStateForStudent) {
    // TODO: Ger - At the moment, we never set the error count - it is always 0. Need to do this on an API failure.
    this.classworkLoadingState$
    .pipe(take(1))
    .subscribe(
      state => {
        state[studentId] = studentState;
        this.classworkLoadingState$.next(state);
      }
    );
  }

  getClassworkLoadingState(studentId: string): Observable<ClassworkLoadingStateForStudent> {
    return this.classworkLoadingState$
    .asObservable()
    .pipe(
      map(state => state[studentId])
    );
  }

  clearClassworkLoadingState(studentId: string) {
    this.classworkLoadingState$
    .pipe(take(1))
    .subscribe(
      state => {
        delete state[studentId];
        this.classworkLoadingState$.next(state);
      }
    );
  }

  createRange(gId: string) {
    // See a time range with a new starting date range
    const currentAcademicYear = this._timeframeSvc.getCurrentAcademicYear();
    if (currentAcademicYear) {
      this.timeRanges[gId] = new BehaviorSubject({
        startDate: moment(currentAcademicYear.startDT),
        endDate: moment(currentAcademicYear.endDT)
      });
    } else {
      const now = new Date();
      const nowString = now.toISOString();
      const rangeStart = new Date();
      rangeStart.setFullYear(rangeStart.getFullYear() - 1);
      const rangeStartString = rangeStart.toISOString();

      this.timeRanges[gId] = new BehaviorSubject({
        startDate: moment(rangeStartString),
        endDate: moment(nowString)
      });

    }
  }

  updateRange(gId: string, timeRange: TimeRange) {
    this.timeRanges[gId].next(timeRange);
  }

  getRange(gId: string) {
    if (!gId) {
      throw new Error('Cannot subscribe to a date range without a student ID');
    }
    if (!this.timeRanges[gId]) {
      this.createRange(gId);
    }
    return this.timeRanges[gId].asObservable();
  }

  destroyRange(gId: string) {
    // To prevent keeping references to ranges for tabs we no longer need, we must destroy the range. We must still
    // unsubscribe to prevent memory leaks. Only call this function when no more references to the range are needed (for
    // example when destroying the student tab).
    delete this.timeRanges[gId];
  }
}
