declare let google: any;
import 'firebase/auth';

import {
  Inject,
  Injectable,
  NgZone,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import firebase from 'firebase/compat/app';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  from,
  iif,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from '../../../../../environments/environment';
import { AlertComponent } from '../../../../core/components/alert/alert.component';
import { LocalStorageService } from '../../../../core/services/LocalStorage/local-storage.service';
import { AuditsService } from '../../../../services/Audits/audits.service';
import {
  CustomerFirestore,
  FirestoreCustomerService,
} from '../../../../services/firestore-customer.service';
import { Tag } from '../../../../services/tags.service';
import { Permission } from '../../../../services/user.service';
import { UserLaneService } from '../../../common/services/user-lane.service';
import { AdminSDKService } from '../AdminSDK/admin-sdk.service';
import { ApiLookupService } from '../api-lookup/api-lookup.service';

import UserCredential = firebase.auth.UserCredential;

interface Auth {
  token: string;
  expires_at?: number;
  expires_in?: number;
  role?: string | number;
  subscription?: {};
  isDisabled?: boolean;
  jwt?: string;
  fbToken?: string;
  permissions?: Permission[];
  permissionMap?: {};
  tags?: Tag[];
  role_key?: string;
  schools?: any;
}

interface UserInfo {
  displayName: string;
  primaryEmail: string;
  gId: string;
  photoUrl: string;
  customerId?: string;
  id?: number;
  givenName?: string;
  domain?: string;
  accessFilterId?: number;
  uid?: string;
}

export interface User {
  profile: UserInfo;
  auth: Auth;
  gSuiteId?: string;
  logout?: boolean; // Used to trigger any cleanup tasks in places subscribed to the activeUser
  lastAuthentication?: Date;
  encodedUser?: string;
}

interface UserProfile {
  picture: string;
  name: string;
  given_name: string;
  id: string;
  hd: string;
  email: string;
}

export interface DirUserData {
  agreedToTerms: boolean;
  archived: boolean;
  changePasswordAtNextLogin: boolean;
  creationTime: string;
  customerId: string;
  emails: object[];
  etag: string;
  id: string;
  includeInGlobalAddressList: boolean;
  ipWhitelisted: boolean;
  isAdmin: boolean;
  isDelegatedAdmin: boolean;
  isEnforcedIn2Sv: boolean;
  isEnrolledIn2Sv: boolean;
  isMailboxSetup: boolean;
  kind: string;
  lastLoginTime: string;
  name: object;
  nonEditableAliases: string[];
  orgUnitPath: string;
  primaryEmail: string;
  suspended: boolean;
}


@Injectable({providedIn: 'root'})
export class AuthService {

  private static STORAGE_KEY = 'user';

  public loggedOutTrigger: Subject<void> = new Subject<void>();
  public loggedInTrigger: Subject<void> = new Subject<void>();

  customerId;
  customer: CustomerFirestore;

  private activeSessionCustomerId = new BehaviorSubject<string>(null);
  private activeUser = new BehaviorSubject<User>(null);
  private nextAction = new BehaviorSubject<string>(null);

  private userProperties: User;
  private userSession: firebase.User;
  private userCredentials: firebase.auth.UserCredential;


  constructor(
    private afAuth: AngularFireAuth,
    private adminSDK: AdminSDKService,
    private apiLookup: ApiLookupService,
    private localStorage: LocalStorageService,
    private ngZone: NgZone,
    private router: Router,
    public dialog: MatDialog,
    private auditService: AuditsService,
    private _userLaneSvc: UserLaneService,
    private _firestoreCustomerService: FirestoreCustomerService,
    private activeRoute: ActivatedRoute,
    private _acf: AngularFireFunctions,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService
  ) {


    combineLatest([this.activeRoute.queryParams.pipe(filter((params) => params.hasOwnProperty('sso'))),
      this.getActiveUser().pipe(filter(user => !!user?.auth?.fbToken))])
      .pipe(switchMap(async ([routeParams, user]) => this.makeSSOUrl('admin')))
      .subscribe((ssoUrl) => {
        window.open(ssoUrl, '_self');
      });


    this.afAuth.credential.subscribe(credential => {
      if(credential) {
        this.userCredentials = credential;
      }
    });

    // This block of code will log a user at KB out due to inactivity
    // This would log a user out if 2 hours passes with no activity
    // combineLatest([
    //   this.isUserSignedIn(),
    //   this.router.events.pipe(
    //     debounce((i) => interval(5000 )))
    // ]).pipe(switchMap(() => timer(60 * 60 * 2).pipe(
    //     takeUntil(this.loggedOutTrigger),
    //     tap(() => this.signOut(true)),
    //   ))
    // ).subscribe(()=>{});


    this.afAuth.authState.subscribe(async (userSession) => {
      this.userSession = userSession;
      if (userSession) {
       // clean up refresh classroom selections stored in local storage
       this.sessionStorage.remove('optRefresh');
        this.adminSDK.loadClient();
        let localCachedUserProperties;
        try {
          localCachedUserProperties = JSON.parse(
            this.localStorage.get(AuthService.STORAGE_KEY));
        } catch (error) {
          console.error('Error loading local user data', error);
          this.localStorage.remove(AuthService.STORAGE_KEY);
        }
        if (localCachedUserProperties) {
          this.userProperties = localCachedUserProperties;
          if(!localCachedUserProperties.hasOwnProperty('lastAuthentication') ||
            moment().diff(moment(localCachedUserProperties.lastAuthentication), 'hours') >= 24) {
            return this.signOut(true);
          } else {
            console.log(`${24 - moment().diff(moment(localCachedUserProperties.lastAuthentication), 'hours')} hours left on session`);
          }
          //check if user has proper token claims
          try {
            await this.hasClaims(
              {
                customerId: localCachedUserProperties.gSuiteId,
                googleUserId: localCachedUserProperties.profile.gId,
                userId: localCachedUserProperties.profile.id
              },
              'force'
            );
          } catch (error) {
            console.error(`Unable to set required claims on user`, error);
            return this.signOut(false);
          }

          this._firestoreCustomerService.bindToCustomer(localCachedUserProperties.gSuiteId);
          this.activeSessionCustomerId.next(this.userProperties.gSuiteId);
          this.userProperties.auth.fbToken = await userSession.getIdToken(true);
          this.activeUser.next(this.userProperties);

          const userLaneUserObj = {
            id: localCachedUserProperties.profile.id,
            role: localCachedUserProperties.auth.role,
            permissions: _.keys(localCachedUserProperties.auth.permissionMap)
          };
          try {
            this._userLaneSvc.identify(userLaneUserObj);
          } catch (error){
            // catch local error
          }
        }
        this.loggedInTrigger.next();
      }
    });
  }

  getCurrentUserIdToken(): Promise<string> {
    return this.afAuth.currentUser.then(user => user.getIdToken(true));
  }

  isSSOLogin(): boolean {
    return this.activeRoute.snapshot.queryParams.hasOwnProperty('sso');
  }

  makeSSOUrl(role: string): Promise<string> {
    return this.getCurrentUserIdToken().then(idToken => {
      const user = this.getCurrentUser();
      const ssoObj = {
        email: user.profile.primaryEmail,
        uid: user.profile.uid,
        profile: user.profile,
        role,
        id_token: idToken
      };
      const ssoencode = window.btoa(JSON.stringify(ssoObj));
      return `${environment.links.ssoService}?q=${ssoencode}`;
    });
  }

  setUser(user: User): void {
    this.userProperties = user;
  }

  getCurrentUser(): User {
    return this.userProperties;
  }

  getActiveUser(): Observable<User> {
    return this.activeUser.asObservable();
  }

  getNextAction(): Observable<string> {
    return this.nextAction.asObservable();
  }

  getCustomerId(): Observable<any> {
    return this.activeSessionCustomerId.asObservable();
  }

  hasPermission(permissionName: string) {

    if (!this.userProperties) {
      return false;
    }

    if (this.userProperties.auth.role_key === 'sa') {
      return true;
    }

    if (!this.userProperties.auth || !this.userProperties.auth.permissionMap) {
      return false;
    }

    return this.userProperties.auth.permissionMap[permissionName] || false;
  }

  isSuperAdmin(): boolean {
    return (this.userProperties && this.userProperties.auth.role_key === 'sa');
  }

  hasAllSchoolAccess() {
    return this.isSuperAdmin() || !this.userProperties.auth.schools || !this.userProperties.auth.schools.length;
  }

  getRole() {
    if (this.userProperties) {
      return this.userProperties.auth.role;
    } else {
      return 'none';
    }
  }

  isUserSignedIn(): Observable<boolean>  {
    return this.afAuth.authState.pipe(switchMap(authState => of(Boolean(authState))));
  }

  signOut(log = true): void {
    iif(
      () => log,
      this.auditService.createAuditLog({
        typeId: '1',
        actionId: '1',
        customer_id: this.userProperties.profile.customerId,
        user_id: this.userProperties.profile.id,
        data: {
          success: true
        }
      }),
      EMPTY
    ).pipe(
      switchMap(() => from(this.afAuth.signOut())),
      tap(async () => await this.ngZone.run(async () => await this.signOutCleanup()))
    ).subscribe();
  }

  async signOutCleanup() {
    this._userLaneSvc.hide();
    this.dialog.closeAll();
    this.localStorage.remove(AuthService.STORAGE_KEY);
    this.userProperties = undefined;
    this.userSession = undefined;
    this.customer = undefined;
    this.customerId = undefined;
    this.activeSessionCustomerId.next(null);
    this.activeUser.next(null);
    // TODO: REMOVE THIS HARD CODED IN LSC-1455
    this.localStorage.remove('studentExplorerTabs');
    this.loggedOutTrigger.next();
    this._firestoreCustomerService.userLoggedOut();
    await this.router.navigate(['/']);
    // clean up refresh classroom selections stored in local storage
    this.sessionStorage.remove('optRefresh');
  }

  async signIn(options: { testLogin?: boolean }) {
    await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    let userCredential: UserCredential;
    if (options.testLogin) {
      const testUser = JSON.parse(this.localStorage.get('testUser'));
      this.localStorage.remove('testUser');
      const credential = firebase.auth.GoogleAuthProvider.credential(testUser.idToken, testUser.accessToken);
      userCredential = await this.afAuth.signInWithCredential(credential);
      userCredential.additionalUserInfo.profile = {
        ...testUser.userProfile,
        ...userCredential.additionalUserInfo.profile
      };
    } else {
      const provider = new firebase.auth.GoogleAuthProvider();
      userCredential = await this.afAuth.signInWithPopup(provider);
    }

    if (userCredential) {
      this.loggedInTrigger.next();
      await this.welcomeHandler(userCredential);
    }
  }



  // Firebase auth can not get a google offline token.
  // Need to use Google 3P Authorization JavaScript Library to handle this
  // https://developers.google.com/identity/oauth2/web/reference/js-reference#CodeClientConfig
   authorizeDesignatedAdmin(loginCallback) {
     const requestClient = google.accounts.oauth2.initCodeClient({
       client_id: environment.web_client_id,
       ux_mode: 'popup',
       prompt: 'consent',
       scope: environment.offlineScopes.join(' '),
       callback: (credentials) => this.ngZone.run(()=>{

         if(credentials.error){
           this.signInErrorHandler({type: 'access_denied'});
           return;
         }
         const missingScopes = this.validateScopes(environment.offlineScopes,credentials.scope.split(' '));
         if (missingScopes.length > 0){
           this.missingScopesHandler(missingScopes);
           return;
         }
         this.offlineSignInSuccessHandler(credentials, loginCallback);
       }),
       error_callback: (error) => this.ngZone.run(() => this.signInErrorHandler(error))
     });
     requestClient.requestCode();
  }

  async getUser(): Promise<DirUserData> {
    const authToken = await this.getAuthForScopes(['https://www.googleapis.com/auth/admin.directory.user.readonly']);
    const dirUserData = await this.adminSDK.getDirectoryUser(this.userProperties.profile.primaryEmail, authToken.access_token);
    return dirUserData;
  }

  async checkSuperAdmin(): Promise<boolean> {
    try {
      const user = await this.getUser();
      return user.isAdmin;
    } catch (err) {
      console.warn(err);
    }
    return false;
  }

  async checkAdminStatus(): Promise<boolean> {
    let dirUserData;

    try {
      dirUserData = await this.getUser();
    } catch (err: any) {
      if (err?.result.error?.code === 403) {
        console.error(`No access to Admin SDK`);
        this.nextAction.next('noAdminSDK');
        return false;
      }
    }

    try {

      if (!dirUserData) {
        throw new Error('Error getting directory Data');
      }

      this.userProperties.auth.role_key = (dirUserData.isAdmin) ? 'sa' : '';
      // temporarily set gSuiteID to customerId. will be fixed after API call
      this.userProperties.profile.customerId = dirUserData.customerId;

      // Confirmed that the user is a super admin and we can proceed to create customer / user and login

      this.apiLookup.create(this.userProperties).subscribe(async (resp) => {

        this.userProperties.profile.uid = this.userCredentials.user.uid;
        this.userProperties.profile.id = resp.user.id;
        this.userProperties.profile.customerId = resp.user.customerId;
        this.userProperties.gSuiteId = resp.user.gSuiteId;
        this.userProperties.auth.isDisabled = resp.user.isDisabled;
        this.userProperties.auth.role = resp.user.role;
        this.userProperties.auth.role_key = resp.user.role_key;
        this.userProperties.auth.subscription = resp.user.subscription;
        this.userProperties.auth.permissions = resp.user.permissions;
        this.userProperties.lastAuthentication = new Date();

        // Build a permission hash for swift look up within the app...
        this.userProperties.auth.permissionMap = resp.user.permissions.reduce((col, p) => {
          col[p.name] = true;
          return col;
        }, {});

        this.userProperties.auth.tags = resp.user.tags;
        this.userProperties.auth.jwt = resp.user.jwt;
        this.userProperties.auth.fbToken = resp.user.fbToken;
        this.activeUser.next(this.userProperties);

        this.localStorage.set(AuthService.STORAGE_KEY, JSON.stringify(this.userProperties));
        this.activeSessionCustomerId.next(this.userProperties.gSuiteId);
        this.userSession = this.userCredentials.user;
        this.router.navigate(['/admin/getting-started']);
        return false;
      });

    } catch (err) {
      console.error(err);
      this.nextAction.next('adminSetup');
      return false;
    }
  }

  disconnect(): void {
    this.afAuth.signOut().then(async () => {
      this.setUser(null);
      this.userSession = null;
      this.activeUser.next(null);
      this.localStorage.remove(AuthService.STORAGE_KEY);
      await this.router.navigate(['/']);
    });
  }


  async getAuthForScopes(scopes: string[]): Promise<{access_token: string}> {
    if(this.userCredentials) {
      const currentScopes = (this.userCredentials.additionalUserInfo.profile['granted_scopes'].split(' '));
      if(_.isEqual(scopes, currentScopes)){
        return {
          access_token: this.userCredentials.credential['accessToken']
        };
      }
    }

    const provider = new firebase.auth.GoogleAuthProvider();
    scopes.forEach(scope => provider.addScope(scope));
    provider.setCustomParameters({
      login_hint: this.userProperties.profile.primaryEmail
    });
    this.userCredentials =  await this.afAuth.signInWithPopup(provider);
    return {
      access_token: this.userCredentials.credential['accessToken']
    };
  }

  async getUserCredentials() {
    if (this.userCredentials) {
      return this.userCredentials;
    }
    const provider = new firebase.auth.GoogleAuthProvider();
    return await this.afAuth.signInWithPopup(provider);
  }

  // Setting custom claims always overrides other custom claims.
  // Need to perform a diff to ensure claims don't get overridden, especially if user logs in from SSO and refreshes.
  // https://firebase.google.com/docs/auth/admin/custom-claims#set_and_validate_custom_user_claims_via_the_admin_sdk
  // https://stackoverflow.com/a/54870651
  async hasClaims(
    claims: { [key: string]: string },
    strategy = 'none'
  ): Promise<boolean> {
    const lockedClaims = [
      'aud',
      'auth_time',
      'exp',
      'iat',
      'iss',
      'sub',
      'firebase',
    ];
    const userClaimToken = await this.userSession.getIdTokenResult();
    const missingClaims = Object.keys(claims).reduce((missingAcc, key) => {
      if (!userClaimToken.claims.hasOwnProperty(key)) {
        missingAcc[key] = claims[key];
      }
      return missingAcc;
    }, {});
    const existingCustomClaims = Object.keys(userClaimToken.claims).reduce(
      (customAcc, key) => {
        if (!lockedClaims.includes(key)) {
          customAcc[key] = userClaimToken.claims[key];
        }
        return customAcc;
      },
      {}
    );

    if(strategy === 'force') {
      await this._acf
        .httpsCallable('auth-addClaimToUser')({
          claims: Object.assign(existingCustomClaims, claims)
        })
        .toPromise();
      //force refresh so claims can be used in this session
      await this.userSession.getIdTokenResult(true);
      return true;
    }else if (Object.keys(missingClaims).length > 0 && strategy === 'missing') {
      try {
        await this._acf
          .httpsCallable('auth-addClaimToUser')({
            claims: Object.assign(existingCustomClaims, missingClaims),
          })
          .toPromise();
        //force refresh so claims can be used in this session
        await this.userSession.getIdTokenResult(true);
        return  true;
      } catch (error) {
        console.error('Unable to add custom token claims');
      }
    } else if (Object.keys(missingClaims).length > 0) {
      return false;
    } else if (Object.keys(missingClaims).length === 0) {
      return true;
    }
  }


  utf8ToB64(str: string): string {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  b64ToUtf8(str: string): string {
    return decodeURIComponent(escape(window.atob(str)));
  }

  /**
   *
   * @param expectedScopes array of OAuth Scopes required
   * @param foundScopes array of OAuth found on a token
   * @return {array} Array of scopes missing from the given token
   */
  private validateScopes(expectedScopes, foundScopes) {
    return expectedScopes.reduce((acc, curr) => {
      if (foundScopes.filter((item) => item === curr).length > 0) {return acc;}
      acc.push(curr);
      return acc;
    }, []);
  }


  private missingScopesHandler(missingScopes: string[]): void {
    this.dialog.open(AlertComponent, {
      panelClass: ['ait-alert', 'error'],
        data: {
          title: 'All scopes are required',
          icon:'error',
          message: `<p>The following scopes were not authorized:</p>
                    <ul>${missingScopes.reduce((a,c) => a + '<li>' + c + '</li>', '') }</ul>
                    <p>All scopes are needed for Little Sis to work</p>`
        },
        width: '540px'
      }
    );
  }

  private signInErrorHandler(err) {
    const reasons = {
      popup_failed_to_open: 'The authorization window failed to open.',
      popup_closed: 'The popup window was closed before authorization was given.',
      unknown: 'We don\'t know what, but something unexpected happened.',
      access_denied: 'You did not or are not able to authorize the access needed.'
    };

    this.dialog.open(AlertComponent, {
      panelClass: ['ait-alert', 'error'],
      data:{
        title: 'Something went wrong',
        incon:'error',
        message: `<p>We were not able to save the token.<br> ${reasons[err?.type]}</p>`
      },
      width: '480px'
    });
    console.warn(err);
  }

  private offlineSignInSuccessHandler(res, callback) {
    this.apiLookup.offlineLogin(res.code).subscribe({
      next: (resp) => callback(resp),
      error: (error) => callback(error)
    });
  }

  private async welcomeHandler(userLoginCredentials: UserCredential) {
    this.userSession = userLoginCredentials.user;

    if (!userLoginCredentials.additionalUserInfo.profile.hasOwnProperty('hd')) {
      this.dialog.open(AlertComponent, {
        panelClass: ['ait-alert', 'error'],
        data: {
          title: 'Missing Google Workspace?',
          icon: 'error',
          message: `
            <p>Little SIS is an application that works with Google Workspace.</p>
            <p>We were unable to identify a Google Workspace domain for your account.</p>
            <div class="text-center">
              <strong class="text-boxed">${userLoginCredentials.user.email}</strong>
            </div>
            <p>If you believe you should be able to access Little SIS, please contact your domain admin or our support helpdesk.</p>
          `
        },
        width: '480px'
      });
      this.userProperties = undefined;
      this.userSession = undefined;
      this.localStorage.remove(AuthService.STORAGE_KEY);
      this.router.navigate(['/']);
      return; // User in not using a Workspace login
    }

    // Google domain detected
    const userProfile = userLoginCredentials.additionalUserInfo.profile as UserProfile;
    const auth = userLoginCredentials.credential && userLoginCredentials.credential['accessToken'];
    const domain = userProfile.hd;

    this.userProperties = {
      profile: {
        displayName: userProfile.name,
        givenName: userProfile.given_name,
        primaryEmail: userProfile.email,
        gId: userProfile.id,
        photoUrl: userProfile.picture,
        domain
      }, auth: {
        token: auth?.access_token
      }
    };

    // Look user up in LSC DB
    const apiCredentialResponse = await this.apiLookup.login(this.userProperties).toPromise();

    if (apiCredentialResponse.action === 'adminSetup' ||
      apiCredentialResponse.action === 'newCustomer') {
      this.activeUser.next(this.userProperties);
      this.nextAction.next(apiCredentialResponse.action);
      return;
    }


    if (!apiCredentialResponse.user || apiCredentialResponse.action === 'userNotFound') {

      this.dialog.open(AlertComponent, {
        panelClass: ['ait-alert', 'error'],
        data: {
          title: 'Account not found',
          message: `
                <p>It looks like you do not have an active Little SIS account.</p>
                <p>Please contact your Google Workspace admin to request access.</p>
                <p>Are you a teacher trying to review your classes? <a href="${environment.links.teacherReview}">Click Here!</a></p>
              `
        },
        width: '480px'
      });

      this.userProperties = undefined;
      this.userSession = undefined;
      this.localStorage.remove(AuthService.STORAGE_KEY);
      await this.router.navigate(['/']);
      return; // Account not found in LSC DB
    }

    if (apiCredentialResponse.user.isDisabled) {
      this.dialog.open(AlertComponent, {
        panelClass: ['ait-alert', 'error'],
        data: {
          title: 'Account not active',
          icon:'error',
          message: `
                  <p>Your account on Little SIS is not currently active.</p>
                  <p>Please contact your Google Workspace admin to request access.</p>
                `
        },
        width: '480px'
      });

      this.userProperties = undefined;
      this.userSession = undefined;
      this.localStorage.remove(AuthService.STORAGE_KEY);
      await this.router.navigate(['/']);
      return; //user is disabled
    }

    //Valid workspace loginCredential and user is found in LSC DB
    try {
      await this.hasClaims(
        {
          customerId: apiCredentialResponse.user.gSuiteId,
          googleUserId: apiCredentialResponse.user.gId,
          userId: apiCredentialResponse.user.id.toString(),
        },
        'force'
      );
    } catch (error) {
      console.error(`Unable to set required claims on user`, error);
      return this.signOut(false);
    }

    const userLaneUserObj = {
      id: apiCredentialResponse.user.id,
      role: apiCredentialResponse.user.role,
      permissions: apiCredentialResponse.user.permissions
    };

    try {
      this._userLaneSvc.identify(userLaneUserObj);
    } catch (error) {
      // catch userlane errors from local
    }

    // Bind to customer firestore node
    this._firestoreCustomerService.bindToCustomer(apiCredentialResponse.user.gSuiteId);
    this.userProperties.profile.uid = userLoginCredentials.user.uid;
    this.userProperties.profile.id = apiCredentialResponse.user.id;
    this.userProperties.profile.customerId = apiCredentialResponse.user.customerId;
    this.userProperties.profile.accessFilterId = apiCredentialResponse.user.accessFilterId;
    this.userProperties.gSuiteId = apiCredentialResponse.user.gSuiteId;
    this.userProperties.auth.isDisabled = apiCredentialResponse.user.isDisabled;
    this.userProperties.auth.role = apiCredentialResponse.user.role;
    this.userProperties.auth.role_key = apiCredentialResponse.user.role_key;
    this.userProperties.auth.subscription = apiCredentialResponse.user.subscription;

    this.userProperties.auth.permissions = apiCredentialResponse.user.permissions;
    this.userProperties.auth.schools = apiCredentialResponse.user.schools;
    this.userProperties.lastAuthentication = new Date();

    // Build a permission hash for swift look up within the app...
    this.userProperties.auth.permissionMap = apiCredentialResponse.user.permissions.reduce((col, p) => {
      col[p.name] = true;
      return col;
    }, {});

    this.userProperties.auth.tags = apiCredentialResponse.user.tags;
    this.userProperties.auth.jwt = apiCredentialResponse.user.jwt;
    this.userProperties.auth.fbToken = apiCredentialResponse.user.fbToken;
    this.localStorage.set(AuthService.STORAGE_KEY, JSON.stringify(this.userProperties));
    this.activeSessionCustomerId.next(this.userProperties.gSuiteId);
    this.activeUser.next(this.userProperties);
    this.setUser(this.userProperties);
    this.loggedInTrigger.next();
    this.nextAction.next('route');
  }

}
