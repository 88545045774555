import {GridApi} from '@ag-grid-community/core';

export interface RendererParams {
  value: {alias: string}[];
  // value: {
  //   [s: string]: number;
  // };
  api: GridApi;
  nodesState?: {
    [nodeId: string]: {
      expanded: boolean;
    };
  };
  node: {
    id: string;
  };
}

const removeLSPrefix = (alias: string): string => {
  if (alias?.substring(0,5) === 'd:LS_') {
    return alias.substring(5);
  } else {
    return alias;
  }
};

export const transformAliases = (params: RendererParams): string[] => {
  if (!params?.value) {
    return [];
  }
  return params?.value?.map(aliasObject => removeLSPrefix(aliasObject?.alias)).sort();
};
