import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Customer } from 'app/modules/common/models/Customer';
import { InstallState } from 'app/modules/common/models/InstallState';
import { AuthService } from '../../../modules/auth/services/Auth/auth.service';
import {
  CustomerService,
} from '../../../modules/common/services/customer.service';
import { IntroJsService } from '../../../services/intro-js.service';

@Component({
  selector: 'app-splash-page',
  templateUrl: './splash-page.component.html',
  styleUrls: ['./splash-page.component.css']
})
export class SplashPageComponent implements OnInit, OnDestroy, AfterViewInit {

  customer: Customer;

  isSuperAdmin: boolean;

  blankInstallState = {
    designatedAdmin: false,
    users: false,
    classrooms: false,
    client: false,
    metadata: false
  } as InstallState;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _authSvc: AuthService,
    private _customerSvc: CustomerService,
    private _intro: IntroJsService,
  ) {

    this.isSuperAdmin = this._authSvc.isSuperAdmin();

  }


  ngOnInit() {

    this._customerSvc.getSettings().pipe(takeUntil(this.onDestroy$)).subscribe(settings => {
      if (!settings?.installState) {
        this._customerSvc.updateInstallState(this.blankInstallState);
      }
    });

  }

  ngAfterViewInit() {

    // Use setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError error
    setTimeout(() => {

      this._customerSvc.getCurrentCustomer().subscribe(customer => {
        this.customer = customer as Customer;
      });
    }, 0);

  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  guidedTour() {
    this._intro.guidedTour('welcome');
  }

}
