
import * as _ from 'lodash';
import * as moment from 'moment';

import {map,  shareReplay } from 'rxjs/operators';
import * as observable from 'rxjs';
import {Observable, BehaviorSubject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';

import {environment} from '../../environments/environment';

import {ApiResponse} from './util.service';
import {ListResponse} from '../core/models/api.interface';
import {LocalStorageService} from '../core/services/LocalStorage/local-storage.service';

export interface Timeframe {

  /**
   * Unique id for the timeframe
   */
  id?: number;

  /**
   * Label for the timeframe
   */
  label?: string;

  /**
   * Start date for the time frame
   */
  startDT?: string;

  /**
   * End date for the time frame
   */
  endDT?: string;

  /**
   * Customer Id for the Customer associated with the school
   */
  customer_id?: string;

  /**
   * Hex color to be used when rendering the tag
   */
  hex: string;

  /**
   * The number of classes with timeframe tag applied
   */
  classCount?: number;

  teachers?: number;

  classrooms?: number;

  academicYear?: boolean;
}

export interface DynamicTimeframe {
  id: number;
  label: string;
}

export interface TimeframeDialogData {

  id?: number;
  label: string;
  startDT: string;
  endDT: string;
  hex: string;
  customer_id?: string;

}

interface ApiResponseTimeframes {
  data: Timeframe[];
  blank: number;
}

@Injectable()
export class TimeframeService {

  timeframes: Timeframe[];
  timeframe$;

  observable$: {
    timeframes: {
      [id: string]: Observable<Timeframe>;
    };
  };

  presets: {};
  private preset$ = new BehaviorSubject({});

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;

  cacheKey = 'timeframe$';

  compactFilter(filterObj) {
    return {
      c: filterObj.field,
      f: {
        type: 'contains',
        filterType: 'text',
        filter: filterObj.value
      },
    };
  }

  orderComparision(a, b) {
    let comparison = 0;
    if (a.endDT < b.endDT) {
      comparison = 1;
    } else if (a.endDT > b.endDT) {
      comparison = -1;
    }
    return comparison;
  }

  constructor(
    private http: HttpClient,
    private _localStorage: LocalStorageService) {

    const stored = this._localStorage.get(this.cacheKey);

    if (stored) {
      this.timeframes = JSON.parse(stored);
      this.timeframe$ = new BehaviorSubject(this.timeframes);
    } else {
      this.timeframe$ = new BehaviorSubject({});
    }


    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.observable$ = {
      timeframes: {}
    };

    this.init();
  }

  init() {

    const url = this.serviceUrls.timeframes.route;

    this.http.get<ListResponse>(url, this.httpOptions).pipe(
      map(response => {
        this.timeframes = response.data.reduce((list, s) => {
          list[s.id] = s;
          return list;
        }, {});
        return this.timeframes;
      })).subscribe((data) => {
      this._localStorage.set(this.cacheKey, JSON.stringify(data));
      this.timeframe$.next(data);
      this.setupPresets();
    });

  }

  getAll(): Observable<Timeframe[]> {
    return this.timeframe$.asObservable();
  }

  watchPresets(): Observable<any> {
    return this.preset$.asObservable();
  }

  // helper function to create initial presets
  setupPresets() {

    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    today.setHours(23,59,59,999);

    this.presets = {
      'Last 30 days': [backDate(30), today],
      'Last 90 days': [backDate(90), today],
      'Last 180 days': [backDate(180), today],
    };

    _.each(this.timeframes, (tf) => {
      this.presets[tf.label] = [moment(tf.startDT), moment(tf.endDT)];
    });

    this.presets['All time'] = [moment('2014-08-12'), today];

    this.preset$.next(this.presets);

  }

  list(opts: {academicYearsOnly?: boolean} = {}): Observable<Timeframe[]> {

    const stored = this._localStorage.get(this.cacheKey);

    if (stored) {
      this.timeframes = JSON.parse(stored);
      if (opts && opts.academicYearsOnly) {
        return observable.of(Object.values(_.filter(this.timeframes, 'academicYear')).sort(this.orderComparision));
      } else {
        return observable.of(Object.values(this.timeframes).sort(this.orderComparision));
      }
    }

    const url = this.serviceUrls.timeframes.route;

    return this.http.get<ListResponse>(url, this.httpOptions).pipe(
      map(response => {
        this.timeframes = Object.assign(this.timeframes, response.data.reduce((list, s) => {
          list[s.id] = s;
          return list;
        }, {}));
        return response.data;
      }));

  }

  find(options = {}, opts: any = { useCache: true }): Observable<ApiResponseTimeframes> {

    let url = this.serviceUrls.timeframes.route;

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filter') {

          _.each(options[param].filters, (f) => {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });


        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<ApiResponseTimeframes>(url, this.httpOptions).pipe(
      map(response => {
        // TODO: Ger - This conditional is a bit of a hacky fix to get around the issue where a new user can't load the
        // time frame because the service constructor was called before the user logged into the app and didn't complete
        // the constructor function. It should be fixed when working on LSC-403
        if (!this.timeframes) {
          this.timeframes = response.data.reduce((list, s) => {
            list[s.id] = s;
            return list;
          }, []);
        } else {
          this.timeframes = Object.assign(this.timeframes, response.data.reduce((list, s) => {
            list[s.id] = s;
            return list;
          }, {}));
        }

        this._localStorage.set(this.cacheKey, JSON.stringify(this.timeframes));
        return response;
      }));
  }

  get(id: string): Observable<Timeframe> {

    const timeframe = this.timeframes.find(t => t.id === parseInt(id, 10));

    if (timeframe) {

      // ensure that original observable is deleted
      if (this.observable$.timeframes[id]) {
        delete this.observable$.timeframes[id];
      }

      return observable.of(timeframe);

    } else {

      // Is there a request in flight?

      if (!this.observable$.timeframes[id]) {
        const url = this.serviceUrls.timeframes.single.replace(':id', id);

        this.observable$.timeframes[id] = this.http.get<Timeframe>(url, this.httpOptions).pipe(
          map(response => {

            this.timeframes.push(response);
            return response;

          })).pipe(shareReplay(1));

      } else {
      }

      return this.observable$.timeframes[id];
    }
  }

  single(id: string): Timeframe | boolean {
    return this.timeframes[id] || false;
  }

  /**
   * Checks whether a label is already in use in current timeframes
   *
   * @param label
   * @returns {boolean}
   */
  validate(label: string, id: number) {
    let found = false;

    for (const timeframeId in this.timeframes) {
      if (this.timeframes.hasOwnProperty(timeframeId)) {
        if (this.timeframes[timeframeId].label === label && this.timeframes[timeframeId].id !== id) {
          found = true;
        }
      }
    }

    return !Boolean(found);
  }

  currentAcademicYear(): number {
    const now = moment();
    let academicYearId = 0;

    for (const timeframeId in this.timeframes) {
      if (this.timeframes.hasOwnProperty(timeframeId)) {
        if (this.timeframes[timeframeId].academicYear && now.isBetween(moment(this.timeframes[timeframeId].startDT), moment(this.timeframes[timeframeId].endDT))) {
          academicYearId = this.timeframes[timeframeId].id;
        }
      }
    }
    return academicYearId;
  }

  getCurrentAcademicYear() {
    const now = moment();
    let academicYear;

    for (const timeframeId in this.timeframes) {
      if (this.timeframes.hasOwnProperty(timeframeId)) {
        if (this.timeframes[timeframeId].academicYear && now.isBetween(moment(this.timeframes[timeframeId].startDT), moment(this.timeframes[timeframeId].endDT))) {
          academicYear = this.timeframes[timeframeId];
        }
      }
    }
    return academicYear;
  }

  create(opts: {timeframe: Timeframe}): Observable<Timeframe> {
    const url = this.serviceUrls.timeframes.route;
    const body = { timeframe: opts.timeframe };
    return this.http.post<Timeframe>(url, body, this.httpOptions);
  }

  update(opts: {timeframe: Timeframe}): Observable<Timeframe> {
    const url = this.serviceUrls.timeframes.single.replace(':id', opts.timeframe.id.toString());

    const body = { timeframe: opts.timeframe};

    return this.http.put<Timeframe>(url, body, this.httpOptions);
  }

  delete(id: number): Observable<void> {
    const url = this.serviceUrls.timeframes.single.replace(':id', id.toString(10));
    return this.http.delete<void>(url, this.httpOptions);
  }

  removeTimeframe(id: number) {
    delete this.timeframes[id];
  }

  apply(opts): Observable<ApiResponse> {
    const url = this.serviceUrls.timeframes.apply;
    return this.http.post(url, opts, this.httpOptions);
  }

  academicYearTransition(opts) {
    const url = this.serviceUrls.timeframes.transition;
    return this.http.post<void>(url, opts, this.httpOptions);
  }

  listDynamicTimeframes(): DynamicTimeframe[] {
    return [
      {
        id: -100,
        label: 'This week',
      },
      {
        id: -99,
        label: 'Next week',
      },
      {
        id: -98,
        label: 'Last week',
      },
      {
        id: -97,
        label: 'Last 30 days',
      },
      {
        id: -96,
        label: 'Last 60 days',
      },
      {
        id: -95,
        label: 'Last 90 days',
      }
    ];
  }

}
