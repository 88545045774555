import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { LscEvents } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';

export interface RefreshClassroomDialogData {
  selected: number[];
  refreshing?: {
    metadata: boolean;
    teachers: boolean;
    roster: boolean;
    courseWork: boolean;
  };
}

@Component({
  selector: 'app-refresh-selected-dialog',
  templateUrl: './refresh-selected.component.html',
  styleUrls: ['./refresh-selected.component.scss']
})

export class RefreshSelectedDialogComponent implements OnInit {

  refreshing: {
    metadata: boolean;
    teachers: boolean;
    roster: boolean;
    courseWork: boolean;
    aliases: boolean;
  };

  disabled: boolean;
  all: boolean;

  private refreshOptionsChanged = false;

  constructor(
    public dialogRef: MatDialogRef<RefreshSelectedDialogComponent>,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: RefreshClassroomDialogData,
    @Inject(SESSION_STORAGE) private storage: StorageService
    ) {

    // Load the last refresh settings for the current user

    this.refreshing = this.storage.get('optRefresh') || {
      metadata: true,
      teachers: false,
      roster: false,
      courseWork: false,
      aliases: false,
    };

    this.disabled = false;

  }

  ngOnInit() {

    this.checkboxStatus({});

  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    if(this.refreshOptionsChanged === true) {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.refreshClassesAndOptionsChanged as AnalyticsEvent);
    } else {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.refreshClasses as AnalyticsEvent);
    }

    this.data.refreshing = this.refreshing;
    this.dialogRef.close(this.data);
  }

  selectAll(event: any): void {

    const state = event.checked;
    const _this = this;

    Object.keys(this.refreshing).forEach(function(key) {
      _this.refreshing[key] = state;
    });

    this.checkboxStatus(event);

  }

  checkboxStatus(event: any) {
    if(event instanceof MatCheckboxChange) {
      this.refreshOptionsChanged = true;
    }
    let checked  = 0;

    for (const cb in this.refreshing) {
      if (this.refreshing[cb]) {
        checked++;
      }
    }

    this.disabled = (checked === 0);
    this.all = (checked === Object.keys(this.refreshing).length);
    this.storage.set('optRefresh', this.refreshing);
  }

}
