import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppSidebarService} from '@amplabs/components/sidebar/sidebar.service';
import {AppConfigService} from '@amplabs/services/config.service';

@Component({
    selector     : 'app-nav-toggler',
    templateUrl  : './nav-toggler.component.html',
    styleUrls    : ['./nav-toggler.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NavTogglerComponent implements OnInit, OnDestroy {
  rightNavbar: boolean;
  hiddenNavbar: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AppConfigService} _AppConfigService
   * @param {AppSidebarService} _appSidebarService
   */
  constructor(
      private _AppConfigService: AppConfigService,
      private _appSidebarService: AppSidebarService,
  ) {

      // Set the private defaults
      this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
      // Subscribe to the config changes
      this._AppConfigService.config
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((settings) => {
              this.rightNavbar = settings.layout.navbar.position === 'right';
              this.hiddenNavbar = settings.layout.navbar.hidden === true;
          });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
      this._appSidebarService.getSidebar(key).toggleOpen();
  }

}
