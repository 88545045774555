import {Component, OnInit, OnDestroy, Input, EventEmitter, Output} from '@angular/core';
import { Subject } from 'rxjs';
import * as Moment from 'moment';

import {TaskService} from '../../services/task.service';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})

export class ProgressIndicatorComponent implements OnInit, OnDestroy {

  @Input()type?: string;
  @Input()id?: number;
  @Input()data?: object;
  @Input()actions?: boolean;


  state: any;

  busy: boolean;
  background: boolean;
  progress: number;
  runTime: number;

  progressMessage: string;
  progressData: any;

  trigger: number;

  visible: boolean;

  @Output()emitRemove: EventEmitter<number> = new EventEmitter<number>();

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private taskSvc: TaskService) {

    this.visible = false;

    this.taskSvc.getUser().subscribe((user) => {
      this.state = user.active;
      this.visible = (this.state.status && this.state.status !== 'complete');
    });

  }

  ngOnInit() {
    setInterval(() => this.trigger = Math.random(), 1 * 1000);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  secondsUntil(time) {
    const now = Moment();
    const then = Moment.unix(time);
    return then.diff(now, 's');
  }



}
