import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  Injectable,
  OnDestroy,
} from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  NavigationEnd,
  Router,
} from '@angular/router';

import {
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  filter,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../modules/auth/services/Auth/auth.service';
import { MessagesService } from '../messages/messages.service';

type InstalledState = 'notify' | 'installed' | 'required';
type DesignatedAdminState = 'notify' | 'ok' | 'unableToCheck' | 'required';

interface ClientInstallResponse {
  success: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class InstallationService implements OnDestroy {

  state: {
    verifyingAccess: boolean;
  };

  installed$ = new ReplaySubject<InstalledState>(1);
  designatedAdmin$ = new ReplaySubject<DesignatedAdminState>(1);

  active: boolean;

  callableCF: {
    checkDesignatedAdmin: any;
  };

  skippedRoutesForNotification: string[];
  skippedRoutesForVerification: string[];

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _messageSvc: MessagesService,
    private _authSvc: AuthService,
    private router: Router,
    private http: HttpClient,
    private fns: AngularFireFunctions
  ) {

    this.skippedRoutesForNotification = [
      '/product/terms',
      '/product/privacy',
      '/admin/getting-started',
      '/admin/settings'
    ];

    this.skippedRoutesForVerification = [
      '/product/terms',
      '/product/privacy'
    ];

    this.state = {
      verifyingAccess: false
    };

    this.active = false;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.callableCF = {
      checkDesignatedAdmin: fns.httpsCallable('customers-checkDesignatedAdminCallable')
    };

    this._authSvc.getActiveUser()
      .pipe(
        filter(activeUser => !this.active && !!activeUser),
        switchMap(actionState => this._authSvc.getNextAction()),
      filter(nextAction => !!nextAction && nextAction === 'route' )) // Any action besides 'route' needs to be handled by the welcome service
       .subscribe(async () => {
        if (this.skippedRoutesForVerification.indexOf(this.router.url) === -1) {
          await this.verifyAccess({notify: (this.skippedRoutesForNotification.indexOf(this.router.url) === -1)});
          await this.verifyDesignatedAdminAccess({notify: (this.skippedRoutesForNotification.indexOf(this.router.url) === -1)});
        }
    });

    this.router.events
      .pipe(filter(val => !this.active && (val instanceof NavigationEnd)),
      switchMap(actionState => this._authSvc.getNextAction()),
      filter(nextAction => !!nextAction && nextAction === 'route' ), // Any action besides 'route' needs to be handled by the welcome service
      switchMap(val => this._authSvc.getActiveUser()),
      filter(activeUser => !!activeUser))
      .subscribe(async () => {
        if (this.skippedRoutesForVerification.indexOf(this.router.url) === -1) {
          await this.verifyAccess({notify: (this.skippedRoutesForNotification.indexOf(this.router.url) === -1), useCache: true});
        }
    });
  }

  listenForEvents(): void {
    // I implemented this as a workaround because too much was happening in the constructor.
    // Just importing this service triggers watchers that alert for the lifetime of the session.
    // It would be better with further refactoring.

    this.listenToDesignatedAdminChanges();
    this.listenToInstalledScopeChanges();
  }

  listenToDesignatedAdminChanges(): void {
    this.designatedAdmin$
    .pipe(takeUntil(this.onDestroy$)).subscribe(async (state) => {

      if (state === 'notify') {

        if (this.skippedRoutesForNotification.indexOf(this.router.url) === -1) {
          let message;

          if (this._authSvc.isSuperAdmin()) {
            message = {
              id: 'designatedAdmin',
              critical: true,
              text: '<p>An issue was detected with your Little SIS setup.</p>' +
                '<p>You need to review/reset your designated admin.</p>',
              link: '/admin/settings',
              actionText: 'Review',
              icon: 'feedback',
              logout: false
            };
          } else {
            message = {
              id: 'designatedAdmin',
              critical: true,
              text: '<p>An issue was detected with Little SIS installation on your domain.</p>' +
                '<p>Please contact a super admin to resolve this.</p>',
              link: '',
              actionText: 'Review',
              icon: 'error',
              logout: true
            };
          }

          this._messageSvc.addMessage(message);
        }
      } else if (state === 'ok') {
        this._messageSvc.removeMessage({id: 'designatedAdmin'});
      }
    });
  }

  listenToInstalledScopeChanges(): void {
    this.installed$.pipe(takeUntil(this.onDestroy$))
    .subscribe(async (state) => {

      if (state === 'notify') {

        if (this.skippedRoutesForNotification.indexOf(this.router.url) === -1) {

          let message;

          if (this._authSvc.isSuperAdmin()) {
             message = {
                id: 'install',
                critical: true,
                text: '<p>An issue was detected with the Little SIS client installation on your domain.</p>' +
                  '<p>You may need to re-auth the Little SIS client.</p>',
                link: '/admin/settings',
                actionText: 'Review',
                icon: 'feedback',
                logout: false
              };
          } else {
            message = {
              id: 'install',
              critical: true,
              text: '<p>An issue was detected with Little SIS installation on your domain.</p>' +
                '<p>Please contact a super admin to resolve this.</p>',
              link: '',
              actionText: 'Review',
              icon: 'error',
              logout: true
            };
          }

          this._messageSvc.addMessage(message);
        }

      } else if (state === 'installed') {
        this._messageSvc.removeMessage({id: 'install'});
      }
    });
  }

  getInstalledState(): Observable<InstalledState> {
    return this.installed$.asObservable();
  }

  checkAuth(opts: {notify?: boolean; useCache?: boolean}): Promise<ClientInstallResponse> {

    let url = this.serviceUrls.adminConsole.validate;

    if (opts.useCache) {
      url += '?useCache=true';
    }

    return this.http.get<ClientInstallResponse>(url, this.httpOptions).toPromise();
  }

  verifyAccess(opts: {notify?: boolean; useCache?: boolean}): Promise<ClientInstallResponse> {

    this.state.verifyingAccess = true;
    this.active = true;

    return this.checkAuth(opts).then((response) => {

      this.state.verifyingAccess = false;
      this.active = false;

      let state: InstalledState = 'installed';

      if (response.success) {
        state = 'installed';
      } else {
        state = opts.notify ? 'notify' : 'required';
      }

      this.installed$.next(state);
      return response;
    });
  }

  async checkDesignatedAdmin(burstCache: boolean) {
    let request = {};

    if (burstCache) {
      request = { burstCache };
    }

    return this.callableCF.checkDesignatedAdmin(request).toPromise();
  }

  verifyDesignatedAdminAccess(opts) {

    this.state.verifyingAccess = true;
    this.active = true;

    return this.checkDesignatedAdmin(opts.burstCache).then((response) => {

      this.state.verifyingAccess = false;
      this.active = false;

      let state: DesignatedAdminState = 'ok';

      if (!response || !response.result) {
        state = 'unableToCheck';
      } else if (
        response.error && (
          response.error.includes('No designated admin specified') ||
          response.error.includes('No active classrooms for cw registration test')
        )
      ) {
        state = 'unableToCheck';
      } else {
        if (response.success) {
          state = 'ok';
        } else {
          state = opts.notify ? 'notify' : 'required';
        }
      }

      this.designatedAdmin$.next(state);
      return response;
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
