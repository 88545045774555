import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exportType'
})

export class ExportTypePipe implements PipeTransform {

  transform(type: string): string {

    let str;

    switch (type) {
      case 'teacherAnalysis':
        str = 'Teacher Analysis';
        break;
      default:
        str = 'Analysis';
        break;
    }

    return str;
  }
}
