
import {map} from 'rxjs/operators';
import {Injectable, Optional} from '@angular/core';

import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DialogData} from '../modules/admin/components/user-accounts/user-accounts.component';
import {ApiResponse} from './util.service';

import * as _ from 'lodash';


import { Tag } from './tags.service';
import {GenericService} from './generic.service';

export interface Permission {
  /**
   * Unique id
   */
  id?: number;
  /**
   * Name/Title of the LSC permission
   */
  name: string;
  /**
   * High level description of the permission
   */
  description: string;
  /**
   * Permission grouping
   */
  group: string;
}

export interface User {
  /**
   * Unique userId within LSC
   */
  id?: number;
  /**
   * Unique Google User Id
   */
  gId: string;
  /**
   * Primary Email address from Google
   */
  primaryEmail?: string;
  /**
   * Url for the users photo
   */
  photoUrl?: string;
  /**
   * Url for the users photo icon
   */
  thumbnailPhotoUrl?: string;
  /**
   * Name to be displayed within LSC
   */
  displayName?: string;
  /**
   * Full Name details from Google
   */
  name?: string;
  /**
   * Role assigned to the user within LSC
   */
  role?: string | number;
  /**
   * Subscription details inherited from the user's associated customer
   */
  subscription?: {};
  /**
   * Token to be used for API calls
   */
  token?: string;
  /**
   * Id of the user's associated customer record
   */
  customerId?: string;
  /**
   * Is the user currently disabled within LSC?
   */
  isDisabled?: boolean;
  /**
   * Is the user currently suspended within Google
   */
  suspended?: boolean;
  /**
   * Current JWT token to be used for API calls
   */
  jwt?: string;
  /**
   * All permissions applied to the user account.
   */
  permissions?: Permission[];
  /**
   * All tags associated with the user account. These are used for class visibility on the classroom grid
   */
  tags?: Tag[];

  gSuiteId?: string;


}

export interface UserResponse {
  /**
   * Associated User record
   */
  user?: User;
  /**
   * Action specific to the request
   */
  action?: string;
  /**
   * Current status of the request
   */
  status: string;
}

export interface AppUser {
  /**
   * Unique id within LSC
   */
  id: number;
  /**
   * Is the user currently disabled within LSC?
   */
  isDisabled: boolean;
  /**
   * Role assigned to the user within LSC
   */
  role: {
    id: number;
    name: string;
  };

  /**
   * Role Id during creation
   */
  roleId?: number;
  /**
   * Associated User record within LSC
   */
  profile: User;
  /**
   * All tags associated with the user account. These are used for class visibility on the classroom grid
   */
  tags?: Tag[];

  access_filter?: any;
}

export interface Role {
  /**
   * Unique id for the role
   */
  id?: string;
  /**
   * Name/Title of the role created by the LSC user
   */
  name: string;
  /**
   * Customer Id for the Customer associated with the Role
   */
  customerId: number;

  /**
   * Additional field included in API responses - The number of users with a related role
   */
  userCount?: number;
  /**
   * Permissions associated with the role
   */
  permissions?: Permission[];
}

export interface UserListResponse {
  /**
   * Paged list of logs
   */
  data: AppUser[];
  /**
   * Total number of logs
   */
  count: number;
}

export interface DirectoryListResponse {
  /**
   * Paged list of logs
   */
  data: User[];
  /**
   * Total number of logs
   */
  count: number;
}

export interface EmailCheckResponse {

  alreadyUsers: string[];
  validNewUsers: string[];
  invalidUsers: string[];
}

export interface AccessFilters {
  id: number;
  customer_id: number;
  label?: string;
  description?: string;
  settings?: object;
}

@Injectable()
export class RolesService extends GenericService {

  serviceUrls;
  type;

  constructor(protected http: HttpClient) {

    super(http, {type: 'roles'});

  }

  getRolePermissions(roleId: string): Observable<Permission[]> {
    const url = this.serviceUrls.getRolePermissions.replace(':id', roleId);
    return this.http.get<Permission[]>(url, this.httpOptions);
  }

  getAllPermissions(): Observable<Permission[]> {
    const url = this.serviceUrls.getPermissions;
    return this.http.get<Permission[]>(url, this.httpOptions);
  }

  setRolePermissions(roleId: any, permissionIds: number[]): Observable<void> {
    const url = this.serviceUrls.addRolePermissions.replace(':id', roleId);
    const body = { permissionIds };
    return this.http.post<void>(url, body, this.httpOptions);
  }

  createRole(role: Role): Observable<Role> {
    const url = this.serviceUrls.createRole;
    const body = {roleName: role.name};
    return this.http.post<Role>(url, body, this.httpOptions);
  }

  updateRole(role: Role): Observable<Role> {
    const url = this.serviceUrls.updateRole.replace(':id', role.id.toString());
    const body = { role };
    return this.http.put<Role>(url, body, this.httpOptions);
  }

  deleteRole(roleId: string): Observable<void> {
    const url = this.serviceUrls.deleteRole.replace(':id', roleId);
    return this.http.delete<void>(url, this.httpOptions);
  }

}
