import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import * as moment from 'moment';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  takeUntil,
} from 'rxjs/operators';

import { Customer } from 'app/modules/common/models/Customer';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../auth/services/Auth/auth.service';
import {
  CustomerService,
} from '../../../common/services/customer.service';

interface Terms {
  accepted: boolean;
  acceptedBy: {
    userId: number;
    email: string;
    name: string;
  };
  acceptedDT: number;
}

@Component({
  selector: 'app-term-acceptance',
  templateUrl: './term-acceptance.component.html',
  styleUrls: ['./term-acceptance.component.scss']
})
export class ProductTermAcceptanceComponent implements OnInit, OnDestroy {

  customer: Customer;
  termsChangedDT: any;

  terms: Terms = {
    accepted: false,
    acceptedBy: {
      userId: null,
      email: null,
      name: null
    },
    acceptedDT: null
  };

  acceptedDT: any;

  acceptanceValid: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  private synced = false;

  constructor(
    private _customerSvc: CustomerService,
    private _authSvc: AuthService
  ) {

    this._unsubscribeAll = new Subject();

    this.acceptanceValid = false;
    this.termsChangedDT = moment(environment.termsChangedDateTime);

    this._customerSvc.getCurrentCustomer().subscribe((customer) => {
      this.customer = customer as Customer;
    });

    this._customerSvc.getSettings()
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .pipe(distinctUntilChanged((previous, next) => JSON.stringify(previous) === JSON.stringify(next)))
      .subscribe((settings) => {
          this.synced = settings?.installState?.acceptedTerms;
          if (settings.terms) {
            this.terms = settings.terms;
            this.acceptedDT = moment.unix(this.terms.acceptedDT);
            this.checkAccepted();
          }
        });

  }

  ngOnInit() {
    this.termsChangedDT = moment(environment.termsChangedDateTime);
  }

  updateAcceptedTerms(accepted) {

    const activeUser = this._authSvc.getCurrentUser();

    if (accepted) {
      this.terms = {
        accepted: true,
        acceptedBy: {
          userId: activeUser.profile.id,
          email: activeUser.profile.primaryEmail,
          name: activeUser.profile.displayName,
        },
        acceptedDT: new Date().getTime() / 1000
      };
    } else {
      this.terms = {
        accepted: false,
        acceptedBy: {
          userId: null,
          email: null,
          name: null,
        },
        acceptedDT: null
      };
    }

    //update firestore node
    this._customerSvc.acceptTerms(this.terms);
    if (this.synced !== !!accepted) {
      this._customerSvc.updateInstallState({ acceptedTerms: accepted });
    }
  }

  checkAccepted() {
    this.acceptanceValid = (this.terms.accepted && moment.unix(this.terms.acceptedDT).isAfter(this.termsChangedDT));

    if (this.synced === !!this.synced && this.synced !== this.acceptanceValid) {
      this.updateAcceptedTerms(this.acceptanceValid);
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
  }
}
