import { NgModule } from '@angular/core';
import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import {NavTogglerModule} from './components/nav-toggler/nav-toggler.module';
import {CoModule} from '../modules/common/co.module';

@NgModule({
  imports: [
    VerticalLayout1Module,
    NavTogglerModule,
    CoModule
  ],
  exports: [
    VerticalLayout1Module,
    NavTogglerModule,
    CoModule
  ]
})
export class LayoutModule { }
