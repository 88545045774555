import {Component, OnInit, OnDestroy, EventEmitter, Output, Input, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import {SheetsService} from '../../services/sheets.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '../../../../core/components/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-export-list-item',
  templateUrl: './export-list-item.component.html',
  styleUrls: ['./export-list-item.component.scss']
})

export class ExportListItemComponent implements OnInit, OnDestroy {

  @Input() ref;
  @Input() exp;
  @Input() type;
  @Input() title;

  trigger: number;
  poll: any;
  showDetails = false;
  panelOpenState = false;
  busy = false;

  dialogData: ConfirmDialogData;

  @Output()emitRemove: EventEmitter<number> = new EventEmitter<number>();

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _sheetsSvc: SheetsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {

    if (!changes.exp.currentValue) {
      return;
    }

    // Status change
    if (!changes.exp.currentValue.complete) {
      if (!this.poll) {
        this.poll = setInterval(() => {

          this.trigger = Math.random();
        }, 1000);
      }
    } else {
      clearInterval(this.poll);
    }

  }

  ngOnDestroy() {
    clearInterval(this.poll);
    this.onDestroy$.next();
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  async removeExport() {

    const dialogData = {
      header: 'Are you sure that you want to remove this export?',
      message: 'This will remove the entry from the list of Exports, but will not delete the related Google Sheet.',
      okBtnLabel: 'Confirm',
      cancelBtnLabel: 'Cancel'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async result => {

      if (result) {

        // Display overlay/spinner
        this.busy = true;

        try {
          await this._sheetsSvc.removeExport(this.ref);
          this.busy = false;
        } catch (e) {
          this.busy = false;
          console.error(e);
        }
      }

    });

  }

}
