import { GridOptions } from '@ag-grid-community/core';
import { Component, Input, OnInit } from '@angular/core';

import { LSCContentType } from '@cdw-ae/components-export-wizard';

import { GridExportsService } from '../../services/gridExports.service';

@Component({
  selector: 'app-ag-grid-exports-button',
  templateUrl: './ag-grid-exports-button.component.html',
  styleUrls: ['./ag-grid-exports-button.component.scss'],
})
export class AgGridExportsButtonComponent implements OnInit {
  @Input() private type!: LSCContentType;

  @Input() private uniqueColumnName = 'id';

  @Input() private gridOptions!: GridOptions;

  constructor(private exportsService: GridExportsService) {}

  ngOnInit(): void {}

  protected async handleClick(): Promise<void> {
    const gridState = GridExportsService.getGridState(this.gridOptions, this.uniqueColumnName);
    await this.exportsService.showExportDialog(this.type, gridState);
  }
}
