import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { ClassActions, Course } from '../../../../../services/classroom.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';

import { ConfirmDialogComponent } from '../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';


export interface Time {
  value: string;
  label: string;
}

interface JoinAsStudentModel extends Course {

  students?: string[];

  alreadyInClass?: boolean;

}

export const JOIN_CLASS_AS_STUDENT_STEPPER_STEPS = 3;
export const JOIN_CLASS_AS_STUDENT = 'explorerJoinClassAsStudent';

@Component({
  selector: 'app-join-class-as-student',
  templateUrl: './join-class-as-student.html',
  styleUrls: ['./join-class-as-student.scss']
})

export class JoinClassAsStudentComponent extends GenericBulkActionComponent implements OnInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  customerActionTemplate: string;
  userActionTemplate: string;
  selectedItems: string[] = [];
  selectedClasses: JoinAsStudentModel[] = [];

  grids: {
    classes: {
      height: number;
    };
  };

  constructor(
    public apiLookup: ApiLookupService,
    public dialogRef: MatDialogRef<JoinClassAsStudentComponent>,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    protected _authSvc: AuthService,
    protected _firestoreCustomerService: FirestoreCustomerService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ClassActions) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    this.sendNotification = true;

  }

  ngOnInit(): void {
    const analyticsEvent = {
      action: 'selfjoinAsStudent',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);

    this.gridOptions.rowData = this.data.selectedClasses;
    this.grids = {
      classes: {
        height: Math.min(32 + 32 + this.data.selectedClasses.length * 60, 300)
      }
    };

    const {primaryEmail, displayName } = this._authSvc.getCurrentUser().profile;

    // TODO: Add a roster check
    this.data.selectedClasses.map( cls => {

      // TODO: PL to speak to SE regarding interface inheritance and best way to approach this
      //@ts-ignore
      cls.students = [];
      //@ts-ignore
      cls.alreadyInClass = Boolean(cls.students.find(student => student === primaryEmail));

    });


    this.validClasses = this.data.selectedClasses.reduce((accum, curr) => {

      //@ts-ignore
      const alreadyInClass = Boolean(curr.students.find(student => student === primaryEmail));

      if (!alreadyInClass) {
        accum.push(curr);
      } else {
        this.invalidClasses.push(curr);
      }
      return accum;
    }, []);

    this.validClasses.sort((a, b) => {
      if (a.primaryTeacherEmail > b.primaryTeacherEmail) {
        return 1;
      } else if (a.primaryTeacherEmail < b.primaryTeacherEmail) {
        return -1;
      }
      return 0;
    });

    this.initialize('join_as_student').subscribe(() => this.generateReplacements(this.validClasses));
  }

  async joinAsStudent() {

    const currentUserEmail = this._authSvc.getCurrentUser().profile.primaryEmail;

    const classroomChanges = this.validClasses.map(selection => ({
        clsId: selection.id,
        toAdd: [
          currentUserEmail
        ]
      }));

    const updatedData = {
      classrooms: classroomChanges,
      actionType: 'join_as_student',
      sendNotification: this.sendNotification,
      customerActionTemplate: this.customerActionTemplate,
      userActionTemplate: this.userActionTemplate,
      replyEmail: this.notificationEmail.value,
      accessTime: '24'
    };

    const dialogRef = this.dialog.open( ConfirmDialogComponent, {
      panelClass: ['ait-little-sis-panel'],
      data: {
        message: 'You will have student access for 24 hours and will be removed automatically.',
        okBtnLabel: 'I understand',
        cancelBtnLabel: 'Cancel'
      }
    });

    await dialogRef.afterClosed().subscribe((confirmed) => {
      if(confirmed) {
        const analyticsEvent = {
          action: 'selfjoinAsStudent',
          properties: {
            category: 'admin',
            label: 'end'
          }
        };
        this._firebaseAnalytics.sendEvent(analyticsEvent);
        this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[JOIN_CLASS_AS_STUDENT][JOIN_CLASS_AS_STUDENT_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);
        this.dialogRef.close(updatedData);
      } else {
        this.cancel(this.stepper);
      }
    });

  }

  goForward(stepper: MatStepper) {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[JOIN_CLASS_AS_STUDENT][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    super.goForward(stepper);

  }
  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[JOIN_CLASS_AS_STUDENT][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

}
