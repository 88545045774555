import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Moment from 'moment';

import { Job, JobListStateService } from '../../services/job-list-state.service';
import { ScheduleService } from '../../services/schedule.service';

@Component({
  selector: 'app-edit-scheduled-removal',
  templateUrl: './edit-scheduled-removal.component.html',
  styleUrls: ['./edit-scheduled-removal.component.scss']
})
export class EditScheduledRemovalComponent implements AfterViewInit, OnChanges {

  @Input() job: Job;
  @Input() jobReference: string;
  @ViewChild('datePicker', {static: false}) datePicker: MatDatepicker<any>;

  busy = false;
  dateValue;
  hours = 12;
  minutes = 0;
  period = 'PM';
  error = '';
  minDate = new Date();

  constructor(
    private jobListStateService: JobListStateService,
    private snackBar: MatSnackBar,
    private scheduleService: ScheduleService
  ) {}

  ngAfterViewInit() {}

  ngOnChanges() {
    if (this.job) {
      const expiry = this.job.request.date.seconds * 1000;
      this.dateValue = Moment(expiry);
      const hr = this.dateValue.hour();

      if (hr > 12) {
        this.hours = hr % 12;
      } else if (hr == 12) {
        this.hours = 12;
      } else {
        this.hours = hr ? hr % 12 : 12;
      }

      this.minutes = this.dateValue.minute();
      this.period = this.dateValue.format('a').toUpperCase();
    }
  }

  onCancel(): void {
    this.jobListStateService.setEditing(null);
  }

  onSave(): void {
    this.busy = true;
    const expiry = Moment(this.dateValue.valueOf());
    let hours = this.hours;
    const minutes = this.minutes;

    if (this.period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (this.period === 'AM' && hours === 12) {
      hours -= 12;
    }

    expiry.set('hour', hours).set('minutes', minutes);

    const now = Moment();
    if (now.isAfter(expiry)) {
      this.error = 'Removal date cannot be in the past';
      this.busy = false;
      return;
    } else if (this.hours < 1 || this.hours > 12 || this.minutes < 0 || this.minutes > 59) {
      this.error = 'Please check the time entered...';
      this.busy = false;
      return;
    }

    this.scheduleService.updateSchedule(this.jobReference, expiry.valueOf())
    .subscribe(
      () => this.jobListStateService.setEditing(null),
      () => {
        this.snackBar.open('Cannot change scheduled removal', 'close', {
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          duration: 10000,
        });
      },
      () => this.busy = false
    );
  }
}
