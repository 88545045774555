// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseUrl = `https://api.amplifiedlabs.xyz`;
const baseAuditLogUrl = `https://saas-audit-log-api-6xbvwuiozq-uc.a.run.app`;
const cloudRunExportsUrl = 'https://cdw-amplified-edu-export-server-6xbvwuiozq-uc.a.run.app';

export const environment = {
  production: false,

  useLocalFunctions: false,
  useLocalFirestore: false,

  cloudRunInstances: {
    exports: `${cloudRunExportsUrl}/api/createExport`
  },

  support:{
    email:'support@amplifiedit.com',
    feedbackFormLink: 'http://bit.ly/labsfeaturerequests',
    helpCenterLink: 'https://amplifiedlabs.zendesk.com/hc/en-us/categories/360001478053-Little-SIS-for-Classroom-web-app-'
  },

  googleAnalytics: {
    domain: 'auto',
    trackingId: 'UA-74242333-9',
    customDimensions: {
      domain: 'dimension1',
      licenseTier: 'dimension2',
      gitHash: 'dimension7'
    }
  },

  web_client_id: '793561203247-bt26srb1jo3kv5nr6g16j0ka0cjk0tu5.apps.googleusercontent.com',
  offlineScopes: [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/classroom.push-notifications',
    'https://www.googleapis.com/auth/classroom.rosters.readonly',
    'https://www.googleapis.com/auth/classroom.student-submissions.students.readonly'
  ],
  adminConsole: {
    client_name: '105054791314183467488',
    scopes: 'https://www.googleapis.com/auth/admin.directory.group.readonly,'
      + ' https://www.googleapis.com/auth/admin.directory.orgunit.readonly,'
      + ' https://www.googleapis.com/auth/admin.directory.user.readonly,'
      + ' https://www.googleapis.com/auth/classroom.courses,'
      + ' https://www.googleapis.com/auth/classroom.rosters,'
      + ' https://www.googleapis.com/auth/classroom.announcements.readonly,'
      + ' https://www.googleapis.com/auth/classroom.coursework.students.readonly,'
      + ' https://www.googleapis.com/auth/classroom.guardianlinks.students,'
      + ' https://www.googleapis.com/auth/classroom.push-notifications,'
      + ' https://www.googleapis.com/auth/classroom.profile.emails'
  },

  features: {
    gridExports: {
      classrooms: true,
      schools: true,
      users: true
    },
    students: true,
    userlane: true
  },

  firebaseConfig: {
    apiKey: 'AIzaSyCAGz8q505onqWf_N47ABYbiOyz0ZF_Qqg',
    authDomain: 'littlesis-cloud.firebaseapp.com',
    databaseURL: 'https://littlesis-cloud.firebaseio.com',
    projectId: 'littlesis-cloud',
    storageBucket: 'littlesis-cloud.appspot.com',
    messagingSenderId: '793561203247',
    appId: '1:793561203247:web:6c7d352118b4ee9fa93cda'
  },

  termsChangedDateTime: '2019-07-01',

  links: {
    purchase: 'https://www.amplifiedit.com/chargify-quote-request/?SKU=AIT-LSW',
    deletionRequest: 'https://docs.google.com/forms/d/e/1FAIpQLScREmQ0FIrRX-Rrz8XEPuy59I1tK6km7PgQ0J2TYv_XWTaP4g/viewform',
    ssoService: 'https://us-central1-little-sis-cloud-sync.cloudfunctions.net/sso-check',
    teacherReview: 'https://sync.littlesis.amplifiedlabs.xyz/teacher-review/login',
    renew: 'https://mail.google.com/mail/?view=cm&source=mailto&to=accounts@amplifiedit.com&su={app_name}%20renewal%20for%20{domain}&body=I%E2%80%99d%20like%20to%20request%20a%20renewal%20quote%20for%20{app_name}%20for%20domain%2C%20{domain}.%0D%0A%0D%0AThank%20you%2C%0D%0A%0D%0A{user_name}',
  },

  licensing: {
    toolLicenseKeys: [
      'LS',
      'LSW'
    ],
    suppressLicensePrompts: [2], // List of customer groups to hide license prompts
    showLicenseTestPanel: false,
    useTestResponse: false
  },

  urls: {
    userServices: {

      adminConsole: {
        validate: `${baseUrl}/api/v1/adminconsole/validate`
      },

      login: `${baseUrl}/api/v1/auth`,
      create: `${baseUrl}/api/v1/auth/create`,
      offlineLogin: `${baseUrl}/api/v1/auth/offline`,

      insights: {
        courseState: `${baseUrl}/api/v1/insights/course/state`,
        rosterSizes: `${baseUrl}/api/v1/insights/course/rosterSize`,
        classCreation: `${baseUrl}/api/v1/insights/course/classCreation`,
        membershipChanges: `${baseUrl}/api/v1/insights/course/membershipChanges`,
        teacherAnalysis: `${baseUrl}/api/v1/insights/teacherAnalysis`,
        schoolTags: `${baseUrl}/api/v1/insights/course/schoolsAnalysis`,
        userAccessAnalysis: `${baseUrl}/api/v1/insights/userAccessAnalysis`,
        student:{
          courses:`${baseUrl}/api/v1/student/:userId/classrooms`,
          submissions:`${baseUrl}/api/v1/submissions/:courseId/:userId`,
        },
        coursework: {
          teacher: `${baseUrl}/api/v1/insights/courseworkAnalysisByTeacher`,
          course: `${baseUrl}/api/v1/insights/courseworkAnalysisByCourse`,
          school: `${baseUrl}/api/v1/insights/courseworkAnalysisBySchool`,
          refreshTeacher: `${baseUrl}/api/v1/insights/refreshCourseworkAnalysisByTeacher`,
          refreshSchool: `${baseUrl}/api/v1/insights/refreshCourseworkAnalysisBySchool`
        }
      },

      tasks: `${baseUrl}/api/v1/tasks`,

      users: {
        route: `${baseUrl}/api/v1/users`,

        createUser: `${baseUrl}/api/v1/users`,
        getAllUsers: `${baseUrl}/api/v1/users`,
        getUsersbyFilter: `${baseUrl}/api/v1/users`,
        getUser: `${baseUrl}/api/v1/user/:id`,
        getUsersFromEmail: `${baseUrl}/api/v1/directory/users`,
        checkEmail: `${baseUrl}/api/v1/users/validate`,

        getTeachers:  `${baseUrl}/api/v1/directory/users/list`,
        getAdmins:  `${baseUrl}/api/v1/directory/users/admins`,

        students: {
          rest: `${baseUrl}/api/v1/student/`
        },

        getStudents:  `${baseUrl}/api/v1/directory/student/list`,
        getStudentList: `${baseUrl}/api/v1/directory/student/list`,
        getRosters: `${baseUrl}/api/v1/get-students`,
        findStudents: `${baseUrl}/api/v1/find-students`,

        updateUser: `${baseUrl}/api/v1/user`,
        bulkUpdateUsers: `${baseUrl}/api/v1/users`,

        checkUserEmails: `${baseUrl}/api/v1/directory/users/validate`,
        bulkCreateUsers: `${baseUrl}/api/v1/users/bulk`,

        welcomeEmail: `${baseUrl}/api/v1/users/welcome-email`,

        setUserStatus: `${baseUrl}/api/v1/users/:id`,
        usersDirectorySync: `${baseUrl}/api/v1/tasks`,
        getSchoolDetails: `${baseUrl}/api/v1/getSchools`,
        getTeacherDetails: `${baseUrl}/api/v1/getTeachers`,
        getClassroomCount: `${baseUrl}/api/v1/get-total-teacher-class-count`,

        gridFilters: {
          accessFilters: `${baseUrl}/api/v1/users/access-filters`,
          deleteAccessFilters: `${baseUrl}/api/v1/users/access-filters/:id`,
          route: `${baseUrl}/api/v1/users/filters`,
          single: `${baseUrl}/api/v1/users/filters/:id`,
        },

        columnViews: {
          route: `${baseUrl}/api/v1/columnViews`,
          single: `${baseUrl}/api/v1/columnViews/:id`,
        },

        access: {
          route: `${baseUrl}/api/v1/user/access`,
          profile: `${baseUrl}/api/v1/users/:id/:timeframeId/access`
        }

      },

      getTags: `${baseUrl}/api/v1/tags`,
      createTag: `${baseUrl}/api/v1/tags`,
      updateTag: `${baseUrl}/api/v1/tags/:id`,
      deleteTag: `${baseUrl}/api/v1/tags/:id`,

      getUserTags: `${baseUrl}/api/v1/users/:id/tags`,
      addUserTags: `${baseUrl}/api/v1/users/:id/tags`,

      jobs: {
        route: `${baseUrl}/api/v1/jobs`,
      },

      getJob: `${baseUrl}/api/v1/jobs/:id`,
      getLastJob: `${baseUrl}/api/v1/jobs/:jobType/latest`,

      classrooms: {
        tasks: `${baseUrl}/api/v1/classrooms/tasks`,
        refresh: `${baseUrl}/api/v1/classrooms/refresh`,
        roster: `${baseUrl}/api/v1/classrooms/:id/roster`,
        courseWork: `${baseUrl}/api/v1/classrooms/:id/coursework`,
        membershipChanges: `${baseUrl}/api/v1/classrooms/:id/membershipChanges`,
        announcements: `${baseUrl}/api/v1/classrooms/:id/announcements`,
        register: `${baseUrl}/api/v1/classrooms/register`,
        dataState: `${baseUrl}/api/v1/classrooms/dataState`,
        rosters: `${baseUrl}/api/v1/rosters`,
        rest: `${baseUrl}/api/v1/classrooms`
      },

      schools: {
        route: `${baseUrl}/api/v1/schools`,
        apply: `${baseUrl}/api/v1/schools/apply`,
        single: `${baseUrl}/api/v1/schools/:id`,
        studentStats: `${baseUrl}/api/v1/schools/studentStats`,

        students: {
          import: `${baseUrl}/api/v1/schools/:id/students/import`,
        },

        teachers: {
          route: `${baseUrl}/api/v1/schools/:id/teachers`,
          single: `${baseUrl}/api/v1/schools/:id/teachers/:timeframeId`,
          add: `${baseUrl}/api/v1/schools/:id/teachers/:timeframeId/add`,
          remove: `${baseUrl}/api/v1/schools/:id/teachers/:timeframeId/remove`,
          import: `${baseUrl}/api/v1/schools/:id/teachers/:timeframeId/import`,
          importByOU: `${baseUrl}/api/v1/schools/:id/teachers/:timeframeId/importByOU`
        },
      },

      timeframes: {
        route: `${baseUrl}/api/v1/timeframes`,
        transition: `${baseUrl}/api/v1/timeframes/transition`,
        apply: `${baseUrl}/api/v1/timeframes/apply`,
        single: `${baseUrl}/api/v1/timeframes/:id`
      },

      getCourses: `${baseUrl}/api/v1/classrooms`,

      getCustomer: `${baseUrl}/api/v1/customer`,
      updateCustomer: `${baseUrl}/api/v1/customer`,
      updateCustomerStudentOU: `${baseUrl}/api/v1/customer/studentOU`,
      getCustomerSettings: `${baseUrl}/api/v1/customer/settings/:actionType`,
      getOUs: `${baseUrl}/api/v1/customer/orgUnit`,

      roles: {
        route: `${baseUrl}/api/v1/roles`,
        single: `${baseUrl}/api/v1/roles/:id`,
        all: `${baseUrl}/api/v1/roles/all`,
      },

      getRoles: `${baseUrl}/api/v1/roles`,
      createRole: `${baseUrl}/api/v1/roles`,
      updateRole: `${baseUrl}/api/v1/roles/:id`,
      deleteRole: `${baseUrl}/api/v1/roles/:id`,

      getPermissions: `${baseUrl}/api/v1/roles/permissions`,
      getRolePermissions: `${baseUrl}/api/v1/roles/:id/permissions`,
      addRolePermissions: `${baseUrl}/api/v1/roles/:id/permissions`,

      archiveClasses: `${baseUrl}/api/v1/classrooms/archive`,
      deleteClasses: `${baseUrl}/api/v1/classrooms/delete`,

      teachers: {
        add: `${baseUrl}/api/v1/classrooms/teachers/add`,
        remove: `${baseUrl}/api/v1/classrooms/teachers/remove`,
        join:  `${baseUrl}/api/v1/classrooms/teachers/join`,
        change:  `${baseUrl}/api/v1/classrooms/teachers/change`,
        rest: `${baseUrl}/api/v1/teachers`
      },

      students: {
        add: `${baseUrl}/api/v1/classrooms/students/add`,
        remove: `${baseUrl}/api/v1/classrooms/students/remove`,
        join: `${baseUrl}/api/v1/classrooms/students/join`,
      },

      tags: {

        applyTags: `${baseUrl}/api/v1/tags/apply`,
        getTag: `${baseUrl}/api/v1/tags/:id`,

        bulkSetClassTags: `${baseUrl}/api/v1/classrooms/bulk/tags`,
        setClassTags: `${baseUrl}/api/v1/classrooms/:id/tags`,

        // Collections
        getCollections: `${baseUrl}/api/v1/collections`,
        getCollection: `${baseUrl}/api/v1/collections/:id`,
        createCollection: `${baseUrl}/api/v1/collections`,
        updateCollection: `${baseUrl}/api/v1/collections/:id`,
        deleteCollection: `${baseUrl}/api/v1/collections/:id`,

        access_groups: {
          route: `${baseUrl}/api/v1/access_groups`,
          single: `${baseUrl}/api/v1/access_groups/:id`,
          users: `${baseUrl}/api/v1/access_groups/:id/users`,
        },

      },

      audits: {
        route: `${baseUrl}/api/v1/logs`,
        create: `${baseAuditLogUrl}/api/audit-log/create`,
        legacyGet: `${baseAuditLogUrl}/api/audit-log/legacy-get`,
        find: `${baseUrl}/api/v1/logs/find`,
        all: `${baseUrl}/api/v1/logs/all`,
      },

      license: {
        route: `${baseUrl}/api/v1/license`,
      },

      studentCollections: {
        getSummaryRows: `${baseUrl}/api/v1/student_collections`
      }

    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
