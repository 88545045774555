import {Component, Input, OnInit} from '@angular/core';
import {LicensingTierTemplate} from '../../interface/licensingTier.interface';
import {LicenseState} from '../../../../services/licensing.interfaces';
import {AuthService, User} from '../../../../../auth/services/Auth/auth.service';
import {MatDialogRef} from '@angular/material/dialog';
import {LicensingRenewalDialogComponent} from '../../licensing-renewal-dialog.component';
import {BaseLicensingInfoComponent} from '../base-licensing-info/base-licensing-info.component';
import {DatePipe} from '@angular/common';
import {FirebaseAnalyticsService} from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {LocalStorageService} from '../../../../../../core/services/LocalStorage/local-storage.service';
import {CustomerService} from '../../../../../common/services/customer.service';

@Component({
  selector: 'app-consultant-licensing-info',
  templateUrl: './consultant-licensing-info.component.html',
  styleUrls: ['../../licensing-renewal-dialog.component.scss']
})
export class ConsultantLicensingInfoComponent extends BaseLicensingInfoComponent implements OnInit, LicensingTierTemplate {

  @Input() data: {
    licenseState: LicenseState,
    activeUser: User
    dialogRef: MatDialogRef<LicensingRenewalDialogComponent>
  }

  constructor( public datePipe: DatePipe,
               public firebaseAnalytics: FirebaseAnalyticsService,
               public localStorageService: LocalStorageService,
               public authService: AuthService,
               public customerService: CustomerService
               ) {
    super( datePipe, firebaseAnalytics,localStorageService, authService, customerService)
  }

  ngOnInit(): void {
    this.sourceData = this.data
    super.ngOnInit();
    this.checkMuteStatus();
    this.buildTemplateData(this.sourceData.licenseState)
  }

  buildTemplateData(licenseState: LicenseState){

    let expiryPrefix = `Your license for ${licenseState.license.domain}`;
    let expiryMessage = '';
    if (licenseState.expiresIn < 0) {
      expiryMessage = `${expiryPrefix} has expired`;
    }
    if (licenseState.expiresIn === 0) {
      expiryMessage = `${expiryPrefix} expires today`;
    }
    if (licenseState.expiresIn === 1) {
      expiryMessage = `${expiryPrefix} expires in 1 day`;
    }

    if (licenseState.expiresIn > 1) {
      expiryMessage = `${expiryPrefix} will expire in ${licenseState.expiresIn} days`;
    }

    if(licenseState.bundle === 'ait-atu') {
      expiryMessage += ' as part of your <b>Admin Tools Unlimited</b> subscription.';
    } else {
      expiryMessage += '.';
    }

    this.templateData = {
      domain: licenseState.license.domain,
      expireDays: licenseState.expiresIn,
      expireDate: this.datePipe.transform(licenseState.license.subscriptions.LSW.expiry, 'MM/dd/yyyy'),
      expiryMessage,
      showIcon: (licenseState.expiresIn <= 7),
      plan: 'Consultant'
    };

  }

}
