import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { combineLatest } from 'rxjs';
import {
  map,
  take,
} from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  CustomerFirestore,
  FirestoreCustomerService,
  Support,
} from '../../../services/firestore-customer.service';
import { AuthService } from '../../auth/services/Auth/auth.service';
import { CustomSupportDialogComponent } from '../components/dialogs/custom-support/custom-support-dialog.component';
import { CustomerService } from './customer.service';

export const loadedCustomSupportMessage: AnalyticsEvent = {
  action: 'loadedCustomSupportMessage',
  properties:{
    category: 'support',
    label: 'loadedMessage',
  }
};

export const loadedDefaultSupportMessage: AnalyticsEvent = {
  action: 'loadedCustomSupportMessage',
  properties:{
    category: 'support',
    label: 'loadedMessage',
  }
};
@Injectable()
export class SupportService {
  defaultCustomSupportMessage = 'Please reach out to your Google Workspace administrator or IT department help desk for help.';

  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    private firestoreCustomerService: FirestoreCustomerService,
    public dialog: MatDialog,
  ) {}

  hasSupportChat(customer, user) {
    return customer.hasSupportChat && (user && user.auth && user.auth.role_key === 'sa');
  }

  launchSupport() {
   combineLatest([this.authService.getActiveUser(), this.customerService.getCurrentCustomer()]).pipe(take(1))
    .subscribe(([user, customer]) => {
      if (customer.hasSupportChat) {
        const urlParams = `view=cm&source=mailto&to=${environment.support.email}&su=Little%20SIS%20for%20Classroom%20support%20request`;
        const url = `https://mail.google.com/mail/?${urlParams}`;
        window.open(url);
      } else {
        this.launchCustomSupport(customer, user);
      }
    });
  }

  launchCustomSupport(currentCustomer, currentUser) {
    this._firebaseAnalytics.sendEvent({ action: 'customSupport',  properties: { category: 'App', label: 'start' } });

    this.firestoreCustomerService.getCustomer()
    .pipe(
      take(1),
      map((customer: CustomerFirestore) => {
        const support = Object.assign({}, customer.support);

        if (!customer.support || !customer.support.active) {
          // Blank out the values to force the defaults
          support.defaultMessage = this.defaultCustomSupportMessage;
          support.description = '';
          support.url = '';
        }

        const superAdmin = currentUser.auth.role_key === 'sa';
        if (superAdmin) {
          support.defaultMessage = this.defaultCustomSupportMessage;
          support.description = currentCustomer.supportMessage;
          support.url = '';
        }
        return support;
      })
    )
    .subscribe((supportConfig: Support) => {
      this.dialog.open( CustomSupportDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: supportConfig,
        width: '700px',
        height: 'auto'
      });

      // TODO: LSC-1938 access LscEvents in this service without breaking app loading


      if(supportConfig.description) {
        this._firebaseAnalytics.sendEvent(loadedCustomSupportMessage);
      } else {
        this._firebaseAnalytics.sendEvent(loadedDefaultSupportMessage);
      }
    });
  }

  launchFeedback() {
    window.open(environment.support.feedbackFormLink);
    const event = {
      action: 'Feedback Clicked',
      properties: {
        category: 'User',
        label: 'Feedback Request',
      }
    };
    this._firebaseAnalytics.sendEvent(event);
  }
}
