import * as _ from 'lodash';

export interface QueryFilter {
  // field name
  c: string;
  // operator
  //   Contains 'c'
  //   Does not contain 'nc'
  //   Starts with 'sw'
  //   Does not start with 'nsw'
  //   Equals 'e'
  //   Not Equals 'ne'
  o: string;
  // value
  v: string;
}

export class GridDataService {

  constructor() {}

  compactFilter(filterObj) {
    const rtn = {
      c: filterObj.field,
      o: 'c',
      v: filterObj.value
    };

    switch (filterObj.operator) {

      case 'gte':
        rtn.o = 'date';
        break;

      case 'lte':
        rtn.o = 'date';
        break;

      case 'contains':
        rtn.o = 'c';
        break;

      case 'equals':
      default:
        rtn.o = 'e';
        break;

    }

    return rtn;
  }

  compactAGFilter(filterObj, field) {
    return { c: field,  f: filterObj };
  }

  generateGridUrl(path, options, opts) {
    let url = `${path}?`;

    for (const param of Object.keys(options)) {

      if (param === 'filterModel') {

        _.each(options[param], (filter, field) => {

          if (options[param][field].filterType === 'date') {
            url += `filter[]=${JSON.stringify(this.compactAGFilter(options[param][field], field))}&`;
          } else {
            url += `filter[]=${JSON.stringify(this.compactAGFilter(filter, field))}&`;
          }

        });

      } else if (param === 'filter') {

        _.each(options[param].filters, (f) => {
          url += `${param}[]=${JSON.stringify(this.compactFilter(f))}&`;
        });

      } else if (param === 'sortModel') {

        if (options[param].length) {

          _.each(options[param], (srt) => {

            if (srt.sort) {
              url += `sortBy[]=${srt.colId}&`;
              url += `sortDir[]=${srt.sort}&`;
            }

          });

        }

      } else {
        url += `${param}=${options[param]}&`;
      }
    }

    if (opts.useCache) {
      url += `useCache=true`;
    }

    return url;
  }


}
