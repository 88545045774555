import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import * as _ from 'lodash';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Quill from 'quill';
import InsertToken from './ngx-quill-InsertToken';

import {Course} from '../../../../services/classroom.service';

Quill.register('modules/insertToken', InsertToken);

@Component({
    selector: 'app-ngx-quill',
    templateUrl: './ngx-quill.component.html',
    styleUrls: ['./ngx-quill.component.scss']
})

export class NgxQuillComponent implements OnInit, OnChanges {

  @Input()content?: string;

  @Input()defaultTMPL?: string;

  @Input()customerActionTemplate?: string;
  @Input()userActionTemplate?: string;

  @Input()actionType?: string; // this will replace context
  @Input()context?: string;
  @Input()tokens?: object[];
  @Input()replacements?: object;
  @Input()disabled?: boolean;

  @Output()templateChange = new EventEmitter();

  parsedHTML: SafeHtml = '';
  customerSettings: object;
  defaultUserTemplate: string;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: Course[]
  ) {}

  ngOnInit() {
    this.defaultUserTemplate = this.userActionTemplate;
    this.defaultTMPL = this.customerActionTemplate;
  }

  renderPreview(template) {

    const replacementsRegex = new RegExp(_.keys(this.replacements).join('|'), 'g');

    if(template && this.replacements) {
      return template.replace(replacementsRegex, entry => this.replacements[entry] );
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    this.disabled = this.disabled || false;

    if (this.customerActionTemplate && this.userActionTemplate && this.replacements && this.replacements['{{myName}}']) {
      this.replacements['{{userTemplate}}'] = this.renderPreview(this.userActionTemplate) || '';
      this.parsedHTML = this.sanitizer.bypassSecurityTrustHtml(this.renderPreview(this.customerActionTemplate));
    }

    if (changes.userActionTemplate) {
      this.defaultUserTemplate = changes.userActionTemplate.currentValue;
    }

    if (changes.customerActionTemplate) {
      this.customerActionTemplate = changes.customerActionTemplate.currentValue;
      this.defaultTMPL = this.customerActionTemplate;
    }
  }

  restoreDefault() {

    this.userActionTemplate = this.defaultUserTemplate;
    this.parsedHTML = this.sanitizer.bypassSecurityTrustHtml(this.renderPreview(this.defaultTMPL));

  }

  onContentChanged = (event) => {

    if (event && this.replacements && this.replacements['{{myName}}']) {
      this.replacements['{{userTemplate}}'] = this.renderPreview(this.userActionTemplate) || '';
      this.parsedHTML = this.sanitizer.bypassSecurityTrustHtml(this.renderPreview(this.customerActionTemplate));
    }

    this.templateChange.emit({[this.actionType]: event.html});

  };
}
