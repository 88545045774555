import {Injectable} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

import {MessageComponent} from '../../components/message/message.component';


export interface Message {
  id: string;
  critical: boolean;
  text: string;
  link: string;
  actionText: string;
  icon?: string;
}

@Injectable()
export class MessagesService {

  messages: Message[] = [];
  message$ = new BehaviorSubject<Message[]>(this.messages);

  _dialogRef: MatDialogRef<MessageComponent, any>;

  constructor(
    private dialog: MatDialog,
    private _router: Router
  ) { }

  addMessage(message: Message): void {

    const exists = this.messages.find((m) => message.id === m.id);

    if (!exists) {

      this.messages.push(message);
      this.message$.next(this.messages);

    }

    // Launch a dialog for this message

    const alreadyActive = this.dialog.openDialogs.filter((m) => m.componentInstance.data.message.id === message.id);

    const okToDisplay = message.critical && this._router.url !== '/admin/getting-started' && this._router.url !== message.link;

    if (!alreadyActive.length && okToDisplay) {

      const dialogData = {
        message
      };

      this._dialogRef = this.dialog.open(MessageComponent, {
        panelClass: 'ait-message',
        data: dialogData,
        width: '480px',
        disableClose: true
      });
    }

  }

  removeMessage(message): void {
    this.messages = this.messages.filter((m) => message.id !== m.id);

    this.message$.next(this.messages);
  }

  getMessages(): Observable<Message[]> {
    return this.message$.asObservable();
  }

}

