import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserEncodingService {
  
  private encodeUserCallable = this.acf.httpsCallable('app-encodeUser');

  constructor(private acf: AngularFireFunctions) { }

  public encodeUser(email: string): Observable<string> {
    return this.encodeUserCallable({data:email}); 
  }
}
