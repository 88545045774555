import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { CustomerFirestore, FeatureLookup, FirestoreCustomerService } from './firestore-customer.service';


@Injectable({ providedIn: 'root' })

export class FeatureToggleService {
  customer: CustomerFirestore;

  constructor(
    private customerService: FirestoreCustomerService
  ) {
    this.customerService.getCustomer().subscribe(customer => {
      this.customer = customer;
    });
  }

  isActive(featureName: string): boolean {

    const featureParts: string[] = featureName.split('.');

    if (!featureParts.reduce((p, c) => p?.[c], environment.features)) {
      return false;
    }

    if (this.customer && this.customer.features) {
      return !!featureParts.reduce((path, c) => path?.[c] as FeatureLookup, this.customer.features);
    }

    return true; // TODO: Fix this as hardset to prevent refresh issue...
  }

  isActiveForEnvironment(featureName: string): boolean {
    const featureParts: string[] = featureName.split('.');
    // Specifically checks the environment toggle, not the customer toggle. It's useful when we want the missing feature for a customer to default as on (e.g. the feature is not toggleable at a customer level)
    if (!featureParts.reduce((p, c) => p?.[c], environment.features)) {
      return false;
    }

    return true;
  }

}
