import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

// import {AuthService} from './services/Auth/auth.service';
import {AuthGuard} from './services/admin-auth-guard/auth.guard';
import {AdminSDKService} from './services/AdminSDK/admin-sdk.service';
import {ApiLookupService} from './services/api-lookup/api-lookup.service';
import {ApiRequestInterceptorService} from './services/api-request-interceptor/api-request-interceptor.service';

import { TagService } from '../../services/tags.service';
import { UserService } from '../../services/user.service';
import { JobService } from '../../services/jobs.service';
import {LoggedInGuard} from './services/admin-auth-guard/loggedIn.guard';
import { PermissionGuard } from './services/admin-auth-guard/permission.guard';
import { AuditsService } from '../../services/Audits/audits.service';
import { RolesService } from '../../services/roles.service';
import {PremiumAccessResolver} from './services/admin-auth-guard/premiumAccess.resolver';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    AuditsService, AuthGuard, AdminSDKService, ApiLookupService, JobService, PermissionGuard, LoggedInGuard,
    RolesService, TagService, UserService, PremiumAccessResolver,
    {provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptorService, multi: true}
  ]
})
export class AuthModule {
}
