import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {QuillModule} from 'ngx-quill';

import { ExportWizardModule } from '@cdw-ae/components-export-wizard';

import {FusePipesModule} from '../../../@amplabs/pipes/pipes.module';
import {AnimatedEllipsisComponent} from '../../core/components/animated-elipsis/animated-ellipsis.component';
import {MessageComponent} from '../../core/components/message/message.component';
import {MaterialModule} from '../../core/services/material/material.module';
import {InstallationComponent} from '../admin/components/installation/installation.component';
import {NgxQuillComponent} from '../explorer/components/ngx-quill/ngx-quill.component';
import {JoinClassDialogComponent} from '../insights/components/dialogs/join-class-dialog/join-class-dialog.component';
import {JobModule} from '../jobs/job.module';
import {ProductTermAcceptanceComponent} from '../product/components/term-acceptance/term-acceptance.component';
import {AnchorTargetPipe} from './anchor-target.pipe';
import {AgGridExportsButtonComponent} from './components/ag-grid-exports-button/ag-grid-exports-button.component';
import {CustomSupportDialogComponent} from './components/dialogs/custom-support/custom-support-dialog.component';
import {ErrorSnackbarComponent} from './components/error-snackbar/error-snackbar.component';
import {IconSnackbarComponent} from './components/icon-snackbar/icon-snackbar.component';
import {NotificationSnackbarComponent} from './components/notification-snackbar/notification-snackbar.component';
import {
  PermissionDescriptionDialogComponent,
} from './components/permission-list/dialogs/permission-description/permission-description.component';
import {PermissionListComponent} from './components/permission-list/permission-list.component';
import {ProfileSummaryComponent} from './components/profile-summary/profile-summary.component';
import {SchoolsStudentConfigStateComponent} from './components/schools-student-config-state/schools-student-config-state.component';
import {UnlicensedWarningBarComponent} from './components/unlicensed-warning-bar/unlicensed-warning-bar.component';
import {UserCentralComponent} from './components/user-central/user-central.component';
import {UserSearchAndValidateComponent} from './components/user-search-and-validate/user-search-and-validate.component';
import {ExactNumberValidatorDirective} from './validators/exact-number-validator.directive';

@NgModule({
  imports  : [
    RouterModule,
    CommonModule,
    FlexLayoutModule,
    FusePipesModule,
    MaterialModule,
    JobModule,
    QuillModule,
    NgxMaterialTimepickerModule,
    ExportWizardModule,
  ],
  declarations: [
    // LittlesisTagComponent,
    UserCentralComponent,
    MessageComponent,
    PermissionListComponent,
    PermissionDescriptionDialogComponent,
    CustomSupportDialogComponent,
    NgxQuillComponent,
    ProductTermAcceptanceComponent,
    AnimatedEllipsisComponent,
    ErrorSnackbarComponent,
    IconSnackbarComponent,
    NotificationSnackbarComponent,
    AnchorTargetPipe,
    JoinClassDialogComponent,
    ProfileSummaryComponent,
    SchoolsStudentConfigStateComponent,
    ExactNumberValidatorDirective,
    UserSearchAndValidateComponent,
    InstallationComponent,
    UnlicensedWarningBarComponent,
    AgGridExportsButtonComponent,
  ],
  exports: [
    // LittlesisTagComponent,
    UserCentralComponent,
    PermissionListComponent,
    ProductTermAcceptanceComponent,
    NgxQuillComponent,
    CommonModule,
    FlexLayoutModule,
    FusePipesModule,
    MaterialModule,
    JobModule,
    QuillModule,
    ExportWizardModule,
    AnimatedEllipsisComponent,
    ErrorSnackbarComponent,
    IconSnackbarComponent,
    NotificationSnackbarComponent,
    AnchorTargetPipe,
    JoinClassDialogComponent,
    ProfileSummaryComponent,
    SchoolsStudentConfigStateComponent,
    ExactNumberValidatorDirective,
    UserSearchAndValidateComponent,
    InstallationComponent,
    UnlicensedWarningBarComponent,
    AgGridExportsButtonComponent,
  ],
  // entryComponents: [
  //   LittlesisTagComponent,
  //   UserCentralComponent,
  //   MessageComponent,
  //   PermissionDescriptionDialogComponent,
  //   CustomSupportDialogComponent,
  //   NgxQuillComponent,
  //   ErrorSnackbarComponent,
  //   IconSnackbarComponent,
  //   NotificationSnackbarComponent,
  //   JoinClassDialogComponent
  // ]
})
export class CoModule { }
