import { Injectable } from '@angular/core';

import { Role } from './user.service';
import { School } from './schools.service';


// This service is used to configure emails. For example, when performing a bulk action, an email is sent. This service powers the UI to show and edit the template.
export interface ReplacementsOptions {
  type: string;
  userName: string;
  adminName: string;
  myName: string;
  profileImageUrl: string;
  role: Role;
  access: string;
  schools: School[];
  // XXX: Ger - some of these should be rolled up, for example, depending on the access we wither have schools or chosenAccessFilter. Perhaps we could just have accessData which is an array but in the case of chosenAccessFilter it only has one value, or many values in schools. We also use classroomCount which can be obtained from different properties but would be better as a top level properly to keep the interface consistent.
  classroomCount: number;
}

export interface TokenReplacements {
  '{{userName}}': string;
  '{{accessList}}': string;
  '{{administratorName}}': string;
  '{{myName}}': string;
  '{{actingUserProfileImageUrl}}': string;
  '{{roleName}}': string;
  '{{permissionList}}': string;
}

@Injectable()
export class MailService {

  constructor(){}

  // processReplacements (type: string, userName: string, adminName: string, myName: string, profileImageUrl: string) {
  processReplacements(config: ReplacementsOptions): TokenReplacements {
    // TODO: Ger - Handle roles etc, then use this everywhere for the welcome email. See the bulk add users component for how it should be done

    const tokenReplacements = {
      '{{userName}}': config.userName,
      '{{accessList}}': '',
      '{{administratorName}}': config.adminName,
      '{{myName}}': config.myName,
      '{{actingUserProfileImageUrl}}': config.profileImageUrl,
      '{{roleName}}': '',
      '{{permissionList}}' : '',
    };

    if (config.role) {
      const permissionListHTML = config.role.permissions.reduce((accum, curr) => { // XXX: There are no roles.....
        accum = accum + `<li>${curr.label}</li>`;
        return accum;
      }, '');


      tokenReplacements['{{roleName}}'] = config.role.name;
      if(config.role.role_key !== 'super_admin') {
        tokenReplacements['{{permissionList}}'] = `<ul>${permissionListHTML}</ul>`;
      } else {
        tokenReplacements['{{permissionList}}'] = `<p>You have been given access to all the features of Little SIS</p>`;
      }
    }
    if (config.access === 'school') {
      const schoolHTMLText = `<p>You will be able to exercise these superpowers on the following set(s) of classes:</p>`;
      const schoolListHTML =   config.schools.reduce((accum, school) => {
        accum = accum + `<li><strong>${school.name}</strong> <em>(${school.totalClassroomCount} classes)</em></li>`;
        return accum;
      }, '');

      tokenReplacements['{{accessList}}'] = `${schoolHTMLText}<ul>${schoolListHTML}</ul>`;
    }

    if (config.access === 'all_classes') { // XXX: Ger - When going via the bulk resend welcome email dialog,we never just get 'all_classes'
      const accessNameHTML = 'You will have access to all Google classroom classes';
      tokenReplacements['{{accessList}}'] = `<p>${accessNameHTML}</p>`;
    }

    return tokenReplacements;
  }
}
