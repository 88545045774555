import {Component, EventEmitter, IterableDiffers, Input, Output } from '@angular/core';

import {DualListComponent} from 'angular-dual-listbox';

import {UtilService} from '../../../../services/util.service';

@Component({
  selector: 'app-user-custom-listbox',
  templateUrl: './user-custom-listbox.html',
  styleUrls: ['./user-custom-listbox.scss']
})
export class CustomListboxComponent extends DualListComponent  {

  @Input() sourceName = '';
  @Input() targetName = '';
  @Output() confirm = new EventEmitter();

  @Output() selectChange = new EventEmitter();

  constructor(differs: IterableDiffers) {
    super(differs);

  }

  moveAll() {
    this.selectAll(this.available);
    this.moveItem(this.available, this.confirmed);
  }

  removeAll() {
    this.selectAll(this.confirmed);
    this.moveItem(this.confirmed, this.available);
  }

  getDefaultPhotoUrl() {
    return UtilService.defaultPhotoUrl;
  }

  // Override function in DualListComponent to add custom selectChange event.
  selectItem(list: Array<any>, item: any) {

    const pk = list.filter( (e: any) => Object.is(e, item));
    if (pk.length > 0) {
      // Already in list, so deselect.
      for (let i = 0, len = pk.length; i < len; i += 1) {
        const idx = list.indexOf(pk[i]);
        if (idx !== -1) {
          list.splice(idx, 1);
          this.selectChange.emit( { key: item._id, selected: false });
        }
      }
    } else {
      list.push(item);
      this.selectChange.emit( { key: item._id, selected: true });

    }
  }

}
