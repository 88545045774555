import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import * as _ from 'lodash';
import {
  Observable,
  of,
} from 'rxjs';
import {
  map,
  tap,
} from 'rxjs/operators';

import { GoogleClassroomCourse } from '@cdw-ae/little-sis-for-classroom-models';
import { environment } from '../../environments/environment';
import { AuthService } from '../modules/auth/services/Auth/auth.service';
import {
  Guardian,
  SchoolStudentCount,
  Student,
  StudentCourseObject,
  StudentCourseResponse,
  StudentListResponse,
} from '../modules/students/students.interfaces';

export interface ActionResponse {
  success: boolean;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class StudentsService {

  private httpOptions: { headers: HttpHeaders; params?: HttpParams };
  private serviceUrls = environment.urls.userServices;

  cache = {};
  maxCacheAge = 60 * 5;

  constructor(
    private angularFireFunctions: AngularFireFunctions,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }

  getByGId(gId): Observable<Student> {

    const cacheKey = `ud_${gId}`;
    const now = Math.floor(Date.now() / 1000);
    const expires = now + (2 * 60); // Cache locally for 2 mins

    if (this.cache[cacheKey]) {
      if (this.cache[cacheKey].expires >= now) {
        return of(this.cache[cacheKey].value);
      }
    }

    const url = this.serviceUrls.users.students.rest + gId;

    return this.http.get<Student>(url).pipe(
      tap(resolvedValue => {
        if (!this.cache[cacheKey]) {
          this.cache[cacheKey] = {};
        }
        this.cache[cacheKey] = {
          expires,
          value: resolvedValue
        };
      }));
  }

  getByEmail(email): Observable<Student> {

    const cacheKey = `ud_${email}`;
    const now = Math.floor(Date.now() / 1000);
    const expires = now + (2 * 60); // Cache locally for 2 mins

    if (this.cache[cacheKey]) {
      if (this.cache[cacheKey].expires >= now) {
        return of(this.cache[cacheKey].value);
      }
    }

    return this.angularFireFunctions.httpsCallable('students-getByEmail')({email}).pipe(
      tap(resolvedValue => {
        if (!this.cache[cacheKey]) {
          this.cache[cacheKey] = {};
        }
        this.cache[cacheKey] = {
          expires,
          value: resolvedValue
        };
      }));
  }

  getBySchool(schoolId: number, timeframeId: number, options, opts): Observable<StudentListResponse> {
    if (!schoolId) {
      return of({ data: [], count: 0});
    }

    let url = this.serviceUrls.schools.single.replace(':id', schoolId.toString()) + '/students?';
    const _this = this;

    for (const param of Object.keys(options)) {
      if (param === 'filterModel') {

        _.each(options[param], (filter, field) => {

          url += `filter[]=${JSON.stringify(_this.compactAGFilter(filter, field))}&`;

        });

      } else if (param === 'sortModel') {

        if (options[param].length) {

          _.each(options[param], (srt) => {

            if (srt.sort) {
              url += `sortBy[]=${srt.colId}&`;
              url += `sortDir[]=${srt.sort}&`;
            }

          });

        }

      } else {
        url += `${param}=${options[param]}&`;
      }
    }

    return this.http.get<StudentListResponse>(url, this.httpOptions);
  }

  // This function checks the current user has access to the student in the student explorer. It does not mean the user can necessarily perform actions on the student's class as they may be in a school the user does not have access to.
  // checkIfCurrentUserHasAccess (student: Student): boolean {
  //   if (this.authService.hasAllSchoolAccess()) {
  //     return true;
  //   }

  //   const userSchools = this.authService.getCurrentUser().auth.schools;
  //   const studentSchools = student.schools;
  //   if (userSchools && userSchools.length) {
  //     // If any of the student's schools match the current users permissions settings, the user can view the student
  //     const permittedSchool = studentSchools.find(studentSchool => {
  //       return userSchools.indexOf(studentSchool.id) !== -1;
  //     });
  //     if (permittedSchool) {
  //       return true;
  //     }
  //   }

  //   return false;
  // }

  getStudentCourses(userId: string, courseFilter: string[], timelineFilter: string, resetCache: boolean): Observable<StudentCourseObject> {
    const cacheKey = `classes`;
    const now = Math.floor(Date.now() / 1000);
    const expires = now + (60 * 5);

    if (this.cache[userId] && this.cache[userId][cacheKey]) {
      if (this.cache[userId][cacheKey].expires >= now) {
        return of(this.cache[userId][cacheKey].value);
      }
    }

    const url = this.serviceUrls.insights.student.courses.replace(':userId', userId);

    const httpOptions = {
      ...this.httpOptions,
      params: new HttpParams()
    };

    if (courseFilter) {
      courseFilter.forEach(filter => {
        httpOptions.params = httpOptions.params.append('state', filter);
      });
    }
    if (timelineFilter) {
      httpOptions.params = httpOptions.params.set('timeframe', timelineFilter);
    }
    if (resetCache) {
      httpOptions.params = httpOptions.params.set('resetCache', resetCache.toString());
    }

    return this.http.get<StudentCourseResponse>(url, httpOptions).pipe(
      // TODO: Review and settle on the target process for handling API errors.
      // Due to the use of the HTTP Interceptor to force a log out on 401/403 we need to think this through as the cached localstorage also does not get cleared...
      // catchError((err) => {
      //
      //   //Handle the error here
      //
      //   return throwError(err);    //Rethrow it back to component
      // }),
      map(response => response.courseList),
      tap(resolvedValue => {
        if (!this.cache[userId]) {
          this.cache[userId] = {};
        }
        this.cache[userId][cacheKey] = {
          expires,
          value: resolvedValue
        };
      })
    );
  }

  getStudentSubmissions(userId: string, courseId: string): Observable<any>{

    const cacheKey = `submissions_${courseId}`;
    const now = Math.floor(Date.now() / 1000);
    const expires = now + this.maxCacheAge;

    if (this.cache[userId] && this.cache[userId][cacheKey]) {
      if (this.cache[userId][cacheKey].expires >= now) {
        return of(this.cache[userId][cacheKey].value);
      }
    }

    const url = this.serviceUrls.insights.student.submissions
      .replace(':userId', userId)
      .replace(':courseId', courseId);

    return this.http.get(url, this.httpOptions).pipe(
        tap(resolvedValue => {
          if (!this.cache[userId]) {
            this.cache[userId] = {};
          }
          this.cache[userId][cacheKey] = {
            expires,
            value: resolvedValue
          };
        }));
  }


  removeStudentFromClassrooms(student: { email: string; gId: string }, classrooms: GoogleClassroomCourse[]): Observable<{success: boolean; message: string}> {
    const request = {
      student,
      classrooms
    };

    return this.angularFireFunctions.httpsCallable('students-removeFromClassrooms')(request).pipe(
      tap(result => result)
    );
  }


  import(schoolId: number, groupEmail: string, action: string): Observable<ActionResponse> {
    // Imports students into a school using the Google group.
    const url = this.serviceUrls.schools.students.import.replace(':id', schoolId.toString());

    const postBody = {
      schoolId,
      groupEmail,
      action
    };

    return this.http.post<ActionResponse>(url, postBody, this.httpOptions);
  }

  countStudentsForUsersSchools(): Observable<SchoolStudentCount[]> {
    const url = this.serviceUrls.schools.studentStats;
    return this.http.get<SchoolStudentCount[]>(url, this.httpOptions);
  }

  compactAGFilter(filterObj, field) {
    const rtn = {
      c: field,
      f: filterObj
    };
    return rtn;
  }

  loadGuardians(gId: string): Observable<Guardian[]> {

    const cacheKey = `guardians`;
    const now = Math.floor(Date.now() / 1000);
    const expires = now + 60 * 5;

    if (this.cache[gId] && this.cache[gId][cacheKey]) {
      if (this.cache[gId][cacheKey].expires >= now) {
        return of(this.cache[gId][cacheKey].value);
      }
    }

    return this.angularFireFunctions.httpsCallable('students-getGuardians')({studentId: gId}).pipe(
      tap(resolvedValue => {
        if (!this.cache[gId]) {
          this.cache[gId] = {};
        }
        this.cache[gId][cacheKey] = {
          expires,
          value: resolvedValue
        };
      }));
  }

  inviteGuardian(gId: string, guardianEmail: string): Observable<Guardian> {
    return this.angularFireFunctions.httpsCallable('students-inviteGuardian')({studentId: gId, guardianEmail});
  }

  withdrawGuardianInvitation(studentGId: string, invitationId: string): Observable<void> {
    return this.angularFireFunctions.httpsCallable('students-withdrawGuardianInvitation')({studentId: studentGId, invitationId});
  }

  removeGuardian(studentGId: string, guardianId: string): Observable<void> {
    return this.angularFireFunctions.httpsCallable('students-removeGuardian')({studentId: studentGId, guardianId});
  }

  removeGuardians(studentGId: string, guardians: Guardian[]): Observable<void[]> {
    const request = {
      studentId: studentGId,
      guardians: []
    };

    guardians.map(guardian => {
      if (guardian.guardianId) {
        request.guardians.push({type: 'guardian', id: guardian.guardianId, email: guardian.invitedEmailAddress});
      } else {
        request.guardians.push({type:'invitation', id: guardian.invitationId, email: guardian.invitedEmailAddress});
      }
    });

    return this.angularFireFunctions.httpsCallable('students-removeGuardians')(request).pipe(
      tap(result => result)
    );
  }

  getGuardianState(guardian: Guardian): string {
    if (guardian.guardianId) {
      return 'active';
    } else if (guardian.state === 'PENDING') {
      return 'invited';
    } else if (guardian.state === 'COMPLETE') {
      return 'declined';
    }
  }

  clearCache(userId) {
    delete this.cache[userId];
  }
}


export const profileSearch = (filter: any): boolean => {

  const filterTextLowerCase = filter.filterText.toLowerCase();
  const name = filter.value.name.toString().toLowerCase();
  const email = filter.value.primaryEmail.toString().toLowerCase();

  switch (filter.filterOption) {
    case 'contains':
      return name.indexOf(filterTextLowerCase) >= 0 || email.indexOf(filterTextLowerCase) >= 0;
    case 'notContains':
      // return valueLowerCase.indexOf(filterTextLowerCase) === -1;
      return false;
    case 'equals':
      return name === filterTextLowerCase || email === filterTextLowerCase;
    case 'notEqual':
      // return valueLowerCase != filterTextLowerCase;
      return false;
    case 'startsWith':
      // return valueLowerCase.indexOf(filterTextLowerCase) === 0;
      return false;
    case 'endsWith':
      // var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
      // return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
      return false;
    default:
      // should never happen
      console.warn('invalid filter type ' + filter);
      return false;
    }
};
