import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {
  Observable,
  of,
} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {AuthService} from '../Auth/auth.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private auth: AuthService, private afAuth: AngularFireAuth,  private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
     return this.afAuth.authState.pipe(
      switchMap((user) => {
        if (!user) {
          this.router.navigate(['/']).catch(()=>{});
        }
        return of(!!user);
    }));

  }
}
