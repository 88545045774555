import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Job {
  request: {
    class: any;
    date: {
      seconds: number;
      nanoseconds: number;
    };
    user: any;
  };
  state: {
    message: string;
    complete: boolean;
  };
  times: any;
  type: string;
}

export interface EditingState {
  id: string;
  job: Job;
}

@Injectable({
  providedIn: 'root'
})
export class JobListStateService {
  editing$: Subject<EditingState> = new Subject();

  constructor() {}

  setEditing(editing: EditingState): EditingState { // id: string, job: Job) {
    // const editing = { id, job };
    this.editing$.next(editing);
    return editing;
  }

  getState(): Observable<EditingState> {
    return this.editing$.asObservable();
  }

}
