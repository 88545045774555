
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { Observable, from, of, throwError } from 'rxjs';
import { AuthService } from '../Auth/auth.service';

@Injectable()
export class ApiRequestInterceptorService implements HttpInterceptor {

  private fbIdToken: string;
  constructor(public auth: AuthService) {
    from(this.auth.getCurrentUserIdToken())
      .subscribe((fbIdToken) => this.fbIdToken = fbIdToken);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.auth.getCurrentUser()) {

      const error = new HttpErrorResponse({
        error: 'No current user',
        headers: request.headers,
        status: 403,
        statusText: 'No current user',
        url: request.url || undefined
      });

      return this.handleAuthError(error);
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getCurrentUser().auth.jwt}`,
        'x-cdw-app-name': 'lsc',
        'x-cdw-fbauth-token': `Bearer ${this.fbIdToken}`
      }
    });


    return next.handle(request).pipe(catchError(error => this.handleAuthError(error)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.auth.signOut(false);
      return of(err.message);
    }
    return throwError(err);
  }
}




