import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  Inject,
  Injectable,
} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

import * as _ from 'lodash';
import {Moment} from 'moment';
import {
  SESSION_STORAGE,
  StorageService,
} from 'ngx-webstorage-service';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {DriveService} from '../../../services/drive.service';
import {UserListResponse} from '../../../services/roles.service';
import {
  Timeframe,
  TimeframeService,
} from '../../../services/timeframes.service';
import {AuthService} from '../../auth/services/Auth/auth.service';
import {CourseworkMaterial} from '../coursework.interface';

export interface StatesResponse {

  data: any[];
  analysis?: any;

}

export interface TimeRange {
  startDate: Moment;
  endDate: Moment;
}

export interface InsightFilters {
  timeframe: {
    label?: string;
    range: TimeRange;
  };
}

interface RefreshResponse {
  data: {
    recordsInserted: number;
  };
}

// Override colors for certain values
// export CustomColors: any[] = [
//   {
//     name: 'ACTIVE',
//     value: '#289b48'
//   },
//   {
//     name: 'ARCHIVED',
//     value: '#847f80'
//   },
//   {
//     name: 'DECLINED',
//     value: '#e94d28'
//   },
//   {
//     name: 'PROVISIONED',
//     value: '#b5d4b3'
//   }
// ];

export interface HeatmapFilterState {
  displayNoData: boolean;
  dateField: string;
}

@Injectable()
export class InsightsService {

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;

  filters: InsightFilters;

  presets: {};

  timeframes: Timeframe[];

  callableCF: {
    refreshTeacherAnalysis: any;
    cancelRefreshTeacherAnalysis: any;
    refreshTeacherClassData: any;

    clearAnalysisCache: any;
    rebuildCourseworkTimeline: any;

    getAnalysisByTeacher: any;
    getAnalysisByCourse: any;
    getDriveThumbnail: any;
  };

  _filterState: BehaviorSubject<HeatmapFilterState> = new BehaviorSubject<HeatmapFilterState>({displayNoData: true, dateField: 'creationTime'});
  readonly filterState$: Observable<HeatmapFilterState> = this._filterState.asObservable();

  constructor(
    private authService: AuthService,
    private _driveSvc: DriveService,
    private http: HttpClient,
    private _timeframeSvc: TimeframeService,
    private fns: AngularFireFunctions,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    // Timeframe and other filters

    this.callableCF = {
      refreshTeacherAnalysis: fns.httpsCallable('teacherAnalysis-precalcTeacherAnalysis'),
      cancelRefreshTeacherAnalysis: fns.httpsCallable('teacherAnalysis-cancelRefreshTeacherAnalysis'),
      refreshTeacherClassData: fns.httpsCallable('teacherAnalysis-refreshTeacherClassData'),

      clearAnalysisCache: fns.httpsCallable('analysis-clearCacheCallable'),
      rebuildCourseworkTimeline: fns.httpsCallable('analysis-populateTimelineCallable'),

      getAnalysisByTeacher: fns.httpsCallable('analysis-getCourseworkAnalysisByTeacherCallableMk'),
      getAnalysisByCourse: fns.httpsCallable('analysis-getCourseworkAnalysisByCourseCallableMk'),
      getDriveThumbnail: fns.httpsCallable('drive-getThumbnailCallableMk')
    };

  }


  get filterState(): HeatmapFilterState {
    return this._filterState.getValue();
  }
  set filterState(filterState: HeatmapFilterState) {
    this._filterState.next(Object.assign({}, filterState));
  }

  compactAGFilter(filterObj, field) {

    return {
      c: field,
      f: filterObj
    };

  }


  getCourseState(params): Observable<StatesResponse> {
    const postBody = params;
    const url = this.serviceUrls.insights.courseState;
    return this.http.post<StatesResponse>(url, postBody, this.httpOptions);
  }

  getSchoolTagAnalysis(params): Observable<StatesResponse> {
    const postBody = params;
    const url = this.serviceUrls.insights.schoolTags;
    return this.http.post<StatesResponse>(url, postBody, this.httpOptions);
  }

  getUserAccessAnalysis(): Observable<{data: {classes: number; noClasses: number}}> {
    const postBody = {};
    const url = this.serviceUrls.insights.userAccessAnalysis;
    return this.http.post<{data: {classes: number; noClasses: number}}>(url, postBody, this.httpOptions);
  }

  getRosterSizes(params): Observable<StatesResponse> {
    const postBody = params;
    const url = this.serviceUrls.insights.rosterSizes;
    return this.http.post<StatesResponse>(url, postBody, this.httpOptions);
  }



  classCreation(params): Observable<StatesResponse> {
    const postBody = params;
    const url = this.serviceUrls.insights.classCreation;
    return this.http.post<StatesResponse>(url, postBody, this.httpOptions);
  }

  membershipChanges(options = {}, opts: any = { useCache: true }): Observable<UserListResponse> {

    let url = this.serviceUrls.insights.membershipChanges;

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filterModel') {

          _.each(options[param], (filter, field) => {

            if (options[param][field].filterType === 'date') {

              url += `filter[]=${JSON.stringify(_this.compactAGFilter(options[param][field], field))}&`;

            } else {
              // _.each(options[param], (filter, field) => {
              url += `filter[]=${JSON.stringify(_this.compactAGFilter(filter, field))}&`;
              // });
            }

          });

        } else if (param === 'sortModel') {

          if (options[param].length) {

            _.each(options[param], (srt) => {

              if (srt.sort) {
                url += `sortBy[]=${srt.colId}&`;
                url += `sortDir[]=${srt.sort}&`;
              }

            });

          }

        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], (srt) => {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<UserListResponse>(url, this.httpOptions);
  }

  teacherAnalysis(options = {}, opts: any = { useCache: true }): Observable<UserListResponse> {

    const url = this.serviceUrls.insights.teacherAnalysis;

    const _this = this;

    const body = {
      filter: [],
      sort: [],
      useCache: false
    };

    if (options) {

      for (const param of Object.keys(options)) {

        if (param === 'filterModel') {

          if (body.filter === undefined) {
            body.filter = [];
          }

          _.each(options[param], (filter, field) => {
            body.filter.push(_this.compactAGFilter(options[param][field], field));
          });

        } else if (param === 'sortModel') {

          if (body.sort === undefined) {
            body.sort = [];
          }

          if (options[param].length) {
            _.each(options[param], (srt) => {
              if (srt.sort) {
                body.sort.push({
                  field: srt.colId,
                  dir: srt.sort
                });
              }
            });
          }
        } else {
          body[param] = options[param];
        }
      }

      if (opts.useCache) {
        body.useCache = true;
      }

    }

    return this.http.post<UserListResponse>(url, body, this.httpOptions);
  }


  getCourseworkByTeacher(params): Observable<StatesResponse> {
    const url = this.serviceUrls.insights.coursework.teacher;
    return this.http.post<StatesResponse>(url, params, this.httpOptions);
  }

  getCourseworkBySchool(params): Observable<StatesResponse> {
    const url = this.serviceUrls.insights.coursework.school;
    return this.http.post<StatesResponse>(url, params, this.httpOptions).pipe(
      map(
        results => {
          results.data.forEach((sch) => {
            const s = results.analysis.find((school) => school.id === sch.i);
            // LSC-1514 - Check to handle for when school not returned in the analysis (HIDOE issue)
            if (s) {
              sch.teacherWithoutCoursework = s.teacherCount - sch.teacherWithCoursework;
            } else {
              console.warn(`School not found [${sch.i}]. Please provide to ${environment.support.email} for investigation`);
              sch.teacherWithoutCoursework = 0;
            }
            return sch;
          });
          return results;
        }
      )
    );
  }

  getCourseworkByCourse(params): Observable<StatesResponse> {
    const url = this.serviceUrls.insights.coursework.course;
    return this.http.post<StatesResponse>(url, params, this.httpOptions);
  }

  refreshCourseworkAnalysisCache(): Observable<number> {
    const schoolUrl = this.serviceUrls.insights.coursework.refreshSchool;
    const teacherUrl = this.serviceUrls.insights.coursework.refreshTeacher;

    return forkJoin(
      this.http.post<RefreshResponse>(schoolUrl, {}, this.httpOptions),
      this.http.post<RefreshResponse>(teacherUrl, {}, this.httpOptions)
    ).pipe(
      map(
        results => results.reduce((total: number, response: RefreshResponse) => total + response.data.recordsInserted, 0)
      )
    );
  }

  loadThumbnailUrl(courseworkId: string, material: CourseworkMaterial, hasClassAccess: boolean) {
    const imageLoadingPath = 'assets/images/loading.gif';
    material.cachedThumbnail = new BehaviorSubject(imageLoadingPath);

    if (!hasClassAccess) {
      material.cachedThumbnail.next(this.thumbnailOrDefault(''));
      return;
    }

    if (material.driveFile) {
      material.cachedThumbnail.next(this.thumbnailOrDefault(material.driveFile.driveFile.thumbnailUrl));
      // // TODO - The following code block gets the cached thumbnail image from our own server, it works but this needs more work / decisions about how long to cache for, error handling etc. before it's production ready. Until then, I've commented it out.
      // this._driveSvc.getThumbnail(courseworkId, material.driveFile.driveFile.id).subscribe((val) => {
      //   material.cachedThumbnail.next(val);
      // });
      // // END OF TODO
    } else if (material.youtubeVideo) {
      material.cachedThumbnail.next(this.thumbnailOrDefault(material.youtubeVideo.thumbnailUrl));
    } else if (material.form) {
      material.cachedThumbnail.next(this.thumbnailOrDefault(material.form.thumbnailUrl));
    } else if (material.link) {
      material.cachedThumbnail.next(this.thumbnailOrDefault(material.link.thumbnailUrl));
    } else {
      material.cachedThumbnail.next(this.thumbnailOrDefault(''));
    }
  }

  private thumbnailOrDefault(thumbnailUrl: string): string {
    const defaultPath = 'https://drive-thirdparty.googleusercontent.com/128/type/application/macbinary';
    return thumbnailUrl ? thumbnailUrl : defaultPath;
  }

}
