import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Working with angular material animations
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {WelcomeComponent} from './welcome.component';
import {MaterialModule} from '../../services/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,

    // Working with angular material animations
    BrowserModule,
    BrowserAnimationsModule,

    MaterialModule

  ],
  declarations: [
    WelcomeComponent
  ]
})
export class WelcomeModule { }
