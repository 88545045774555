import {Injectable} from '@angular/core';

import {TimeRange} from 'app/modules/insights/services/insights.service';

import {AuthService} from '../modules/auth/services/Auth/auth.service';
import {DateParts} from '../modules/students/students.interfaces';
import {Timeframe} from './timeframes.service';

export interface ApiResponse {
  /**
   * Was request successful?
   */
  success?: boolean;

  /**
   * Message returned from API request
   */
  message?: string;

  /**
   * Data returned from API request
   */
  filter?: any|any[];

  /**
   * Error object from API request
   */
  error?: string;

  /**
   * Data returned from API request
   */
  data?: any|any[];

  classroomCount?: number;

  teacherCount?: number;

}

export interface Sort {
  dir: string;
  col: string;
}


@Injectable()
export class UtilService {

  static defaultPhotoUrl = 'https://ssl.gstatic.com/images/branding/product/1x/avatar_circle_blue_512dp.png';

  static errorPhotoUrl = 'https://storage.googleapis.com/amplifiedit-images/custom_avatar_circle_red_512dp.png';

  constants = {
    TIMES: {
      SHORT: 1000,
      MEDIUM: 5000,
      LONG: 10000
    }
  };

  constructor(
    private authService: AuthService
  ) { }

  parseEmails(str: string): string[] {
    return str.match(/[\w'\.-]+@[\w\.-]+\.\w+/g) || [];
  }

  isColorDark(hex: string): boolean {
    const color = +('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
    const red = color >> 16;
    const green = color >> 8 & 255;
    const blue = color & 255;

    const hsp = Math.sqrt(
      0.299 * (red * red) +
      0.587 * (green * green) +
      0.114 * (blue * blue)
    );

    return hsp > 127.5 ? false : true;
  }

  getDateOfISOWeek(week: number, year: number): Date {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const isoWeekStart = simple;
    if (dow <= 4) {
      isoWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      isoWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return isoWeekStart;
  }

  areRangesDifferent(range1: TimeRange, range2: TimeRange) {
    if (!range1 || !range2 || !range1.startDate || !range1.endDate || !range2.startDate || !range2.endDate) {
      // Don't compare falsy values
      return true;
    }
    if (range1.startDate.toString() === range2.startDate.toString() && range1.endDate.toString() === range2.endDate.toString()) {
      return false;
    }
    return true;
  }

  doesTimeframeOverlapRange(timeframes: Timeframe[], timeRange: TimeRange): boolean {
    const isInTimeRange: boolean = timeframes.reduce((isAnyTimeframeOverlapping: boolean, timeframe: Timeframe) => {
      if (isAnyTimeframeOverlapping) {
        return true; // No need to check any more as we need to return true if any timeframe overlaps
      }

      if (timeRange?.startDate?.isSameOrAfter(timeframe.endDT, 'day') || timeRange?.endDate?.isSameOrBefore(timeframe.startDT, 'day')) {
        return false;
      }

      return true;
    }, false);
    return isInTimeRange;
  }

  isDueDateInFuture(due: DateParts): boolean {
    const dueDate = this.convertDatePartsToJSDate(due);
    const now = new Date();

    return (dueDate > now);
  }

  convertDatePartsToJSDate(date: DateParts): Date {
    const dueDate = new Date();

    dueDate.setFullYear(date.year);
    dueDate.setMonth(date.month - 1);
    dueDate.setDate(date.day);
    // Set due time to midnight. We don't have access to the time, so we set the time to midnight for better comparison.
    dueDate.setHours(0);
    dueDate.setMinutes(0);
    dueDate.setSeconds(0);
    dueDate.setMilliseconds(0);

    return dueDate;
  }

  toSentenceCase(value: string): string {
    return value[0].toUpperCase() + value.substring(1);
  }

  pruneUndefinedProperties(source: any): any {
    Object.keys(source).forEach(key => {
      if (source[key] === undefined) {
        delete source[key];
      }
    });
    return source;
  }

  extractFamilyName(name: string): string {
    if (!name) {return '';}
    const nameParts = name.split(' ');
    return nameParts[nameParts.length - 1];
  }
}
