import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SaveGridDialogData {
  settings: object;
  label?: string;
  Description?: string;
}

@Component({
  selector: 'app-save-grid-settings-dialog',
  templateUrl: './save-filters-settings.html'
})

export class SaveAccessFiltersDialogComponent implements OnInit {

  saveStateLabel = new FormControl('', [
    Validators.required
  ]);

  saveStateDescription = new FormControl('', [
    Validators.required
  ]);


  constructor( public dialogRef: MatDialogRef<SaveAccessFiltersDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: SaveGridDialogData) {

  }

  async confirm(): Promise<void> {

    this.dialogRef.close({
      description: this.saveStateDescription.value,
      label: this.saveStateLabel.value,
      settings: this.data
    });

  }

  cancel(): void {
    this.dialogRef.close('cancelled');
  }

  ngOnInit() { }

}
