import {Injectable} from '@angular/core';
import { ClassroomGridService } from './classroom-grid.service';

import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';

import {UserService} from '../../../services/user.service';
import {ApiResponse} from '../../../services/util.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

interface LabelValue {
  label: string;
  value: string|number|null;
}

export interface ColumnSettings {
  field: string;
  filterable: boolean;
  hidden: boolean;
  title?: string;
  filter?: 'string'|'numeric'|'date'|'boolean';
  format?: string;
  width?: number|string;
  _width?: number;
  orderIndex?: number;
  class?: any;
  sortable?: boolean;
  columnMenu?: boolean;
  headerClass?: string;

  filterOpts?: {
    type: string;
    field?: string;
    defaultItem?: LabelValue;
    data?: LabelValue[];
    sourceField?: string;
  };

  cell?: {
    template?: string;
  };
  header?: {
    icon?: string;
    description?: string;
  };
}

export interface GridSettings {
  filters?: {};
  sort?: {};
}

@Injectable({
  providedIn: 'root'
})
export class GridStateService {

  savedStates = [];

  private activeGridSettings = new BehaviorSubject('');
  private $savedViews = new BehaviorSubject(this.savedStates);

  getCurrentGridSettings(): Observable<any> {
    return this.activeGridSettings.asObservable();
  }

  getUserSavedViews(): Observable<any> {
    return this.$savedViews.asObservable();
  }

  actions;
  contextActions;

  gridSettings: GridSettings = {

    sort: {},
    filters: {}
  };

  private httpOptions: { headers: HttpHeaders; body?: any };
  private serviceUrls = environment.urls.userServices;

  constructor(
    public cls: ClassroomGridService,
    // private _userSvc: UserService,
    private http: HttpClient
  ) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.actions = {
      classroom: cls
    };

    this.contextActions = cls.contextActions;

    this.loadUserGridSettings();

    this.applyGridSettings(this.gridSettings);

  }

  get<T>(token: string): T {
    const settings = localStorage.getItem(token);
    this.activeGridSettings.next(settings);
    return settings ? JSON.parse(settings) : settings;
  }

  set(label: string, gridConfig: GridSettings): void {
    localStorage.setItem(label, JSON.stringify(gridConfig));
  }

  applyGridSettings(settings) {
    this.gridSettings = Object.assign({}, this.gridSettings,  _.cloneDeep(settings));
    this.activeGridSettings.next(JSON.stringify(this.gridSettings));
  }

  async loadUserGridSettings() {
    return this.getUserFilters().subscribe(results => {
      if (results.data.userFilters.length) {
        this.savedStates = results.data.userFilters;
        this.$savedViews.next(this.savedStates);
      }
    });
  }

  getUserFilters() {
    const url = this.serviceUrls.users.gridFilters.route;
    return this.http.get<ApiResponse>(url, this.httpOptions);
  }

  updateColumnView(filter) {
    const url = this.serviceUrls.users.columnViews.route;
    const payload = { filter };
    return this.http.post<ApiResponse>(url, payload, this.httpOptions);
  }

  saveColumnView(filter) {
    const url = this.serviceUrls.users.columnViews.route;
    const payload = { filter };
    return this.http.post<ApiResponse>(url, payload, this.httpOptions);
  }

  deleteColumnView(id: number) {
    const url = this.serviceUrls.users.columnViews.single.replace(':id', id.toString());
    return this.http.delete<ApiResponse>(url, this.httpOptions);
  }

  deleteGridFilter(id: number) {
    const url = this.serviceUrls.users.gridFilters.single.replace(':id', id.toString());
    return this.http.delete<ApiResponse>(url, this.httpOptions);
  }

}
