import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

import * as _ from 'lodash';
import {
  Observable,
  of,
} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class DriveService {

  thumbnails: {
    [idHash: string]: string;
  };

  observable$: {
    thumbnails: {
      [id: string]: Observable<string>;
    };
  };

  constructor(
    private fns: AngularFireFunctions,
  ) {

    this.thumbnails = {};
    this.observable$ = {
      thumbnails: {}
    };

  }

  getThumbnail(courseworkId: string, materialFileId: string): Observable<string> {

    const idHash = courseworkId + '_' + materialFileId;

    const imgUri = this.thumbnails[idHash];

    if (imgUri) {
      // ensure that original observable is deleted
      if (this.observable$.thumbnails[idHash]) {
        delete this.observable$.thumbnails[idHash];
      }

      return of(imgUri);

    } else {

      // Is there a request in flight?
      if (!this.observable$.thumbnails[idHash]) {

        // get type
        const thumbnailFn = this.fns.httpsCallable('drive-getThumbnailCallableMk');

        // Trigger onCallable
        this.observable$.thumbnails[idHash] = thumbnailFn({courseworkId, fileId: materialFileId})
          .pipe(map((val) => {

            if (val.uri) {
              this.thumbnails[idHash] = val.uri;
            }
            return val.uri;
          }));

      }

      return this.observable$.thumbnails[idHash];
    }
  }


}
