import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FirebaseAnalyticsService } from 'app/core/services/FirebaseAnalytics/firebase-analytics.service';
import { Customer } from 'app/modules/common/models/Customer';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from '../../../../core/services/LocalStorage/local-storage.service';
import { AuthService } from '../../../auth/services/Auth/auth.service';
import { CustomerService } from '../../../common/services/customer.service';
import { License } from '../../services/licensing.interfaces';
import { LittlesisLicensingService } from '../../services/littlesis-licensing.service';
import { LicensingRenewalDialogComponent } from '../licensing-renewal-dialog/licensing-renewal-dialog.component';


@Component({
  selector: 'licensing-bar',
  templateUrl: './licensing-bar.component.html',
  styleUrls: ['./licensing-bar.component.scss']
})
export class LicensingBarComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  customer: Customer;
  license: License;

  licensed = false;
  verifyingLicense = true;
  isSuperAdmin = false;
  expiryMessage = '';
  iconColor = 'white';
  showIcon = false;
  showLicenseTestPanel = environment.licensing.showLicenseTestPanel;
  private RENEW_DIALOG_KEY_30DAYS = 'mute_renew_dialog_30DAYS';
  private RENEW_DIALOG_KEY_14DAYS = 'mute_renew_dialog_14DAYS';
  private renewalDialogRef: MatDialogRef<LicensingRenewalDialogComponent>

  constructor(
    private _authSvc: AuthService,
    private _customerSvc: CustomerService,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    private _licensingSvc: LittlesisLicensingService,
    private _datePipe: DatePipe,
    private localStorageService: LocalStorageService,
    private matDialog: MatDialog) { }

  ngOnInit() {
      this._authSvc.getActiveUser()
    .pipe(
      takeUntil(this.onDestroy$),
      filter(activeUser => !!activeUser),
    ).subscribe((activeUser) => {
      this.isSuperAdmin = this.isSuperAdmin = activeUser.auth.role_key === 'sa';
    });



    this._licensingSvc.checkLicense().pipe(takeUntil(this.onDestroy$),
    debounceTime(500)).subscribe((state) => {

      this.verifyingLicense = false;
      this.licensed = state.licensed;
      this.license = state.license;
      this.expiryMessage = `License for ${this.license.domain} `;

      if (state.expiresIn > 30 && state.tier !=='lapsed') {
        this.expiryMessage += ` expires on ${this._datePipe.transform(this.license.subscriptions.LSW.expiry, 'MM/dd/yyyy')}`;
        this.showIcon = false;
      } else if (state.expiresIn >= 15 && state.expiresIn <= 30 && state.tier !=='lapsed') {
        this.iconColor = 'amber';
        this.showIcon = true;
        this.expiryMessage += `expires in ${state.expiresIn} days (${this._datePipe.transform(this.license.subscriptions.LSW.expiry, 'MM/dd/yyyy')})`;
        if(this.localStorageService.get(this.RENEW_DIALOG_KEY_30DAYS) !== 'true'){
          if(this.isSuperAdmin) {
            this.openLicensingRenewalDialog();
          }
        }
      } else if (state.expiresIn >= 8 && state.expiresIn < 15 && state.tier !=='lapsed') {
        this.iconColor = 'red';
        this.showIcon = true;
        this.expiryMessage += `expires in ${state.expiresIn} days (${this._datePipe.transform(this.license.subscriptions.LSW.expiry, 'MM/dd/yyyy')})`;
        if(this.localStorageService.get(this.RENEW_DIALOG_KEY_14DAYS) !== 'true'){
          if(this.isSuperAdmin) {
            this.openLicensingRenewalDialog();
          }
        }
      } else if (state.expiresIn >= 0 && state.expiresIn < 8 && state.tier !=='lapsed') {
        this.iconColor = 'red';
        this.showIcon = true
        this.expiryMessage += ` expires in ${state.expiresIn} days (${this._datePipe.transform(this.license.subscriptions.LSW.expiry, 'MM/dd/yyyy')})`;
        if(this.isSuperAdmin) {
          this.openLicensingRenewalDialog();
        }
      } else if (!state.licensed || state.license.subscriptions['LSW'].plan.name === 'unlicensed') {
        this.iconColor = 'red';
        this.showIcon = true;
        this.expiryMessage += ` does not exist.`;
          this.openLicensingRenewalDialog();
      } else {
        this.iconColor = 'red';
        this.showIcon = true;
        this.expiryMessage += ` EXPIRED on ${this._datePipe.transform((this.license.subscriptions.LSW.plan.paid_ended_at? this.license.subscriptions.LSW.plan.paid_ended_at : this.license.subscriptions.LSW.plan.trial_ended_at), 'MM/dd/yyyy')}`;
        if(this.license.subscriptions['LSW'].plan.name === 'lapsed' || this.isSuperAdmin) {
          this.openLicensingRenewalDialog();
        }
      }
    });

  }

  openLicensingRenewalDialog(url?: string) {

    if(this.renewalDialogRef) {
       this.renewalDialogRef.close()
    }

    this.renewalDialogRef =  this.matDialog.open(LicensingRenewalDialogComponent, {
      width: '660px',
      height: '620px',
      panelClass: 'renew-modal'
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}
