import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import {FusePipesModule} from '../../../@amplabs/pipes/pipes.module';

import {MaterialModule} from '../../core/services/material/material.module';
import {ExportsService} from './services/exports.service';
import {ExportListItemComponent} from './components/exportListItem/export-list-item.component';
import {ExportTypePipe} from './pipes/exportType.pipe';


@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FusePipesModule,
    FlexLayoutModule,
  ],
  declarations: [
    ExportListItemComponent,
    ExportTypePipe,
  ],
  exports: [
    ExportListItemComponent,
    ExportTypePipe,
  ],
  // entryComponents: [],
  providers: [
    ExportsService,
  ],
})
export class ExportModule { }
