import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})

export class MinuteSecondsPipe implements PipeTransform {

  transform(value: number): string {
    value = Number(value);
    const h = Math.floor(value / 3600);
    const m = Math.floor(value % 3600 / 60);
    const s = Math.floor(value % 3600 % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? 'hr ' : 'hrs ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? 'min ' : 'mins ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? 's' : 's') : '';
    return hDisplay + mDisplay + sDisplay;
  }
}
