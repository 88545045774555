export const sideBar = {
  position: 'left',
  hiddenByDefault: true,
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: false,
        suppressColumnSelectAll: false,
        suppressColumnExpandAll: false
      }
    },
    {
      id: 'savedViewsToolPanel',
      labelDefault: 'Saved Layouts',
      labelKey: 'savedViews',
      iconKey: 'columns',
      toolPanel: 'savedViewsToolPanel'
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filters',
      toolPanel: 'agFiltersToolPanel'
    },
    {
      id: 'accessFiltersToolPanel',
      labelDefault: 'Saved Filters',
      labelKey: 'accessFilter',
      iconKey: 'filters',
      toolPanel: 'accessFiltersToolPanel'
    }
  ],
  defaultToolPanel: 'none'
};
