import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  REGION,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/compat/functions';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {appConfig} from 'app/app-config';
import firebase from 'firebase/compat/app';
import {ClipboardModule} from 'ngx-clipboard';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {QuillModule} from 'ngx-quill';
import {StorageServiceModule} from 'ngx-webstorage-service';
import {Nl2BrPipeModule} from 'nl2br-pipe';

import {AgGridModule} from '@ag-grid-community/angular';
import {AmplabsModule} from '@amplabs/amplabs.module';
import {FuseProgressBarModule, FuseSidebarModule} from '@amplabs/components';
import {AppSharedModule} from '@amplabs/shared.module';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {CDWAngularMaterialImpModule} from '@cdw-amplified-education/angular-ui-cdw-angular-material-imp';
import {NgSelectModule} from '@ng-select/ng-select';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent, WINDOW} from './app.component';
import {AlertComponent} from './core/components/alert/alert.component';
import {ConfirmDialogComponent} from './core/components/confirm-dialog/confirm-dialog.component';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {SnackbarListComponent} from './core/components/snackbar-list/snackbar-list.component';
import {SplashPageComponent} from './core/components/splash-page/splash-page.component';
import {WelcomeModule} from './core/components/welcome/welcome.module';
import {FirebaseAnalyticsService} from './core/services/FirebaseAnalytics/firebase-analytics.service';
import {MaterialModule} from './core/services/material/material.module';
import {LoginComponent} from './layout/components/login/login.component';
import {LayoutModule} from './layout/layout.module';
import {AuthModule} from './modules/auth/auth.module';
import {CoModule} from './modules/common/co.module';
import {CoreModule} from './modules/core/core.module';
import {ExplorerModule} from './modules/explorer/explorer.module';
import {LittlesisLicensingModule} from './modules/licensing/littlesis-licensing.module';
import {ProductModule} from './modules/product/product.module';
import {FirestoreCustomerService} from './services/firestore-customer.service';
import {GridDataService} from './services/gridData.service';
import {NotificationService} from './services/notification.service';
import {UserEncodingService} from './core/services/FirebaseAnalytics/user-encoding.service';

firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    ConfirmDialogComponent,
    SnackbarListComponent,
    SplashPageComponent,
    PageNotFoundComponent,
    LoginComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AgGridModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    LittlesisLicensingModule,
    ExplorerModule,
    LayoutModule,
    MaterialModule,
    QuillModule.forRoot(),
    NgxMaterialTimepickerModule,
    AppRoutingModule,

    // Fuse modules
    AmplabsModule.forRoot(appConfig),
    FuseProgressBarModule,
    AppSharedModule,
    FuseSidebarModule,
    NgSelectModule,
    FormsModule,
    FormsModule,
    StorageServiceModule,
    Nl2BrPipeModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxSliderModule,
    ClipboardModule,
    WelcomeModule,
    CoModule,
    ProductModule,
    CDWAngularMaterialImpModule,
  ],
  providers: [
    {provide: REGION, useValue: 'us-central1'},
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useLocalFunctions ? ['localhost', 5001] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useLocalFirestore ? ['localhost', 9001] : undefined,
    },
    {provide: WINDOW, useValue: window},
    FirestoreCustomerService,
    NotificationService,
    GridDataService,
    UserEncodingService,
    FirebaseAnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
