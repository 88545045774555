import {Component, OnInit, OnDestroy, EventEmitter, Output, Input, SimpleChanges} from '@angular/core';
import { Subject } from 'rxjs';

import {TaskService} from '../../services/task.service';
import {AuthService} from '../../../auth/services/Auth/auth.service';

@Component({
  selector: 'app-job-list-item',
  templateUrl: './job-list-item.component.html',
  styleUrls: ['./job-list-item.component.scss']
})

export class JobListItemComponent implements OnInit, OnDestroy {

  @Input() job;
  @Input() type;
  @Input() title;

  trigger: number;
  poll: any;
  isSuperAdmin: boolean;

  @Output()emitRemove: EventEmitter<number> = new EventEmitter<number>();

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _taskSvc: TaskService,
    private _authSvc: AuthService
  ) {

    this.isSuperAdmin = this._authSvc.isSuperAdmin();

  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {

    if (!changes.job.currentValue || !changes.job.previousValue) {
      return;
    }

    if (changes.job.currentValue.status !== changes.job.previousValue.status) {
      // Status change
      if (changes.job.currentValue.status === 'active') {
        if (!this.poll) {
          this.poll = setInterval(() => {
            this.trigger = Math.random();
          }, 1000);
        }
      } else {
        clearInterval(this.poll);
      }
    }

  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  cancel() {
    this._taskSvc.cancel(this.type);
  }

}
