import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';

import {
  interval,
  Subject,
} from 'rxjs';
import {
  takeUntil,
  takeWhile,
} from 'rxjs/operators';

import { Router } from '@angular/router';
import { Customer } from 'app/modules/common/models/Customer';
import { environment } from '../../../../../environments/environment';
import { InstallationService } from '../../../../core/services/installation/installation.service';
import { MessagesService } from '../../../../core/services/messages/messages.service';
import { ApiLookupService } from '../../../auth/services/api-lookup/api-lookup.service';
import {
  CustomerService,
} from '../../../common/services/customer.service';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss']
})
export class InstallationComponent implements OnInit, OnDestroy {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @Input() viewType: string;
  @Input() header = true;
  @Input() leadParagraph: string;
  @Input() suppressWatchers = false;
  @Input() isAdmin = true;

  customer: Customer;

  maxRetries = 24;
  msBetweenRetries = 5000;
  progress = 0;

  clientInstalled = false;
  refreshing = false;
  verifyingAccess = false;
  verifyingAdmin = false;
  failed = false;
  checked = false;

  designatedAdminAccess = false;

  public get showCloseButton(): boolean {
    return this.clientInstalled
      && (this.designatedAdminAccess || this.isAdmin)
      && !this.verifyingAccess
      && !this.verifyingAdmin
      && this.viewType === 'dialog';
  }

  public get showDesignatedAdminReset(): boolean {
    return this.viewType !== 'stepper' && !this.designatedAdminAccess && !this.verifyingAdmin;
  }

  public get showActions(): boolean {
    return this.showCloseButton || this.showDesignatedAdminReset;
  }

  private onDestroy$: Subject<void> = new Subject<void>();
  private _installed = false;
  private _initialised = false;

  constructor(
    private router: Router,
    private _customerSvc: CustomerService,
    private _messageSvc: MessagesService,
    private apiLookup: ApiLookupService,
    private _installSvc: InstallationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {

    if (!this.suppressWatchers) {
      this._installSvc.listenForEvents();
    }

    this._customerSvc.getSettings()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(settings => {
        this._installed = settings?.installState?.client;
      });

    this._customerSvc.getCurrentCustomer().pipe(takeUntil(this.onDestroy$)).subscribe((customer) => {

      if (customer?.loading) {
        return;
      }

      this.customer = customer as Customer;

      if (!customer.loading && !this._initialised) {
        this.verifyingAccess = true;
        this._initialised = true;
        this.verifyAccess(false);
        this.checkDesignatedAdmin(false, null);
      }
    });
  }

  goToAdvancedSecurity(): void {

    const clientId = environment.adminConsole.client_name;
    const scopesList = environment.adminConsole.scopes;

    const queryParams = `clientScopeToAdd=${scopesList}%26clientIdToAdd=${clientId}`.replace(/ /g, '');

    let url = 'https://accounts.google.com/ServiceLogin/signinchooser?';
    url += 'passive=1209600&osid=1&continue=https%3A%2F%2Fadmin.google.com%2Fac%2Fowl%2Fdomainwidedelegation%3F';
    url += queryParams;

    window.open(url);
  }

  startChecking(): void {
    this.progress = 0;
    this.failed = false;
    this.refreshing = true;
    this.setInterval();
  }

  setInterval(): void {
    const source = interval(this.msBetweenRetries).pipe(takeWhile(val => (this.refreshing && val <= this.maxRetries)));

    source.subscribe(val => {
      this.progress = (100 / this.maxRetries) * val;
      this.verifyAccess(true);

      if (this.progress === 100) {
        this.refreshing = false;
        this.failed = true;
      }
    });
  }

  cancelInterval(): void {
    this.verifyingAccess = false;
    this.refreshing = false;
  }

  verifyAccess(notify = false): void {

    this.apiLookup.checkAuth().subscribe((response) => {

      this.verifyingAccess = false;

      if (!notify) {
        this.clientInstalled = response.success;

        if (!this._installed && response.success && this.customer) {
          this._installSvc.verifyAccess({notify});
          this._customerSvc.updateInstallState({ client: response.success });
        }

        return;
      }

      if (response.success) {
        this.clientInstalled = true;
        this.snackBar.open('Installation successful...', 'Close', {duration: 3000});
        this.cancelInterval();

        this._customerSvc.updateInstallState({ client: response.success });

        this._installSvc.verifyAccess({notify});

      } else {
        this.clientInstalled = false;
      }

      return this.clientInstalled;
    });

  }

  checkDesignatedAdmin(notify = false, burstCache: boolean): void {

    this.verifyingAdmin = true;
    this.checked = false;

    this._installSvc.verifyDesignatedAdminAccess({notify, burstCache}).then((response) => {
      this.verifyingAdmin = false;

      if (!response) {
        this.checked = false;
        this.snackBar.open('Unable to check designated admin... will retry...', null, {panelClass: 'error-snackBar', duration: 5000});
        this._messageSvc.removeMessage({id: 'designatedAdmin'});
      } else if (response.success || (response.error && response.error === 'No active classrooms for cw registration test')) {
        this.checked = true;
        this.designatedAdminAccess = true;
        this._messageSvc.removeMessage({id: 'designatedAdmin'});
      } else {
        this.checked = true;
        this.designatedAdminAccess = false;
      }
    });
  }

  goTo(path: string) {
    this.router.navigate([path]);
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  cancel(): void {
    this.dialog.closeAll();
  }

}
