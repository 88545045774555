import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anchorTarget'
})
export class AnchorTargetPipe implements PipeTransform {
  // At the moment there's no requirement for any other values here so we only use _blank, however we could accept a target parameter to override it in a future version if needed.
  transform(value: any) {
    const newValue = value.replace(/<a/g, '<a target="_blank"');
    return newValue;
  }
}
