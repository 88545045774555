import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { ClassActions } from '../../../../../services/classroom.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';

import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';

export const ARCHIVE_CLASSES_STEPPER_STEPS = 3;
export const ARCHIVE_CLASSES = 'explorerArchiveClass';
@Component({
  selector: 'app-archive-class-dialog',
  templateUrl: './archive-class-dialog.html',
  styleUrls: ['./archive-class-dialog.scss']
})

export class ClassArchiveDialogComponent extends GenericBulkActionComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  busy = false;

  customerActionTemplate: string | object;
  userActionTemplate: string | object;

  sendNotification: boolean;

  grids: {
    classes: {
      height: number;
    };
  };

  constructor( public apiLookup: ApiLookupService,
               public dialogRef: MatDialogRef<ClassArchiveDialogComponent>,
               private _firebaseAnalytics: FirebaseAnalyticsService,
               protected _authSvc: AuthService,
               protected _firestoreCustomerService: FirestoreCustomerService,
               @Inject(MAT_DIALOG_DATA) public data: ClassActions ) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    this.grids = {
      classes: {
        height: Math.min(80 + this.data.selectedClasses.length * 60, 300)
      }
    };
  }

  goForward(stepper: MatStepper) {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ARCHIVE_CLASSES][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    super.goForward(stepper);
  }

  ngOnInit() {
    const analyticsEvent = {
      action: 'archiveClass',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);

    this.gridOptions.rowData = this.data.selectedClasses;
    this.sendNotification = true;

    this.initialize('archive').subscribe(() => this.generateReplacements(this.data.selectedClasses));
  }

  archiveClasses() {
    const classroomIds = this.data.selectedClasses.map(cls => cls.id);

    const updatedData = {
      ids: classroomIds,
      actionType: 'archive',
      sendNotification: this.sendNotification,
      customerActionTemplate: this.customerActionTemplate,
      userActionTemplate: this.userActionTemplate,
      replyEmail: this.notificationEmail.value
    };

    const analyticsEvent = {
      action: 'archiveClass',
      properties: {
        category: 'admin',
        label: 'end'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ARCHIVE_CLASSES][ARCHIVE_CLASSES_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);

    this.dialogRef.close(updatedData);
  }

  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ARCHIVE_CLASSES][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }
}

