import { Pipe, PipeTransform } from '@angular/core';

interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

@Pipe({
  name: 'secondsAgo'
})

export class SecondsAgoPipe implements PipeTransform {

  transform(value: Timestamp | number): number {

    if (value === undefined) {
      return;
    }

    const seconds = (typeof value === 'number') ? value : value.seconds as number;
    const now = new Date();
    return Math.round(Math.abs((now.getTime()/1000) - seconds));
  }
}
