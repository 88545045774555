import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, Subject } from 'rxjs';
import { filter, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { AngularFirestoreExportJobRepository, ExportCreationStatus } from '@cdw-ae/export-interfaces';

import { AuthService } from '../../auth/services/Auth/auth.service';
import { AngularFirestoreRepositoryCollection } from './angularFirestoreRepositoryCollection.class';

@Injectable({providedIn: 'root'})
export class ExportsService implements OnDestroy {

  exportJobs$: Observable<AngularFirestoreExportJobRepository[]>;
  private angularFirestoreRepositoryCollection!: AngularFirestoreRepositoryCollection<AngularFirestoreExportJobRepository>;

  private status = new Subject();
  private onDestroy$: Subject<void> = new Subject<void>();

  private _bound = false;

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {
    this.angularFirestoreRepositoryCollection = new AngularFirestoreRepositoryCollection(
      this.firestore,
      (firestoreDoc: AngularFirestoreDocument) =>
        new AngularFirestoreExportJobRepository(firestoreDoc as AngularFirestoreDocument<ExportCreationStatus>)
    );
    this.exportJobs$ = this.authService.getActiveUser().pipe(
      takeUntil(this.onDestroy$),
      filter((user) => !(this._bound || !user || !user.auth.fbToken || !this.afAuth.currentUser)),
      switchMap((user) => {
        this._bound = true;
        const customerId = user.gSuiteId;
        const googleUserId = user.profile.gId;
        const userExportCollectionPath = `customers/${customerId}/users/${googleUserId}/exports`;

        return this.angularFirestoreRepositoryCollection.getCollection(
          userExportCollectionPath,
          { value: 'timings.request', order: 'desc' },
          10
        );
      }),
      shareReplay(1)
    );

    this.authService.loggedOutTrigger.subscribe(() => {
      this.ngOnDestroy();
    });
  }

  getStatus(): Observable<any> {
    return this.status.asObservable();
  }

  getUserExportJobs(): Observable<AngularFirestoreExportJobRepository[]> {
    return this.exportJobs$;
  }

  ngOnDestroy() {
    this._bound = false;
    this.onDestroy$.next();
  }

}
