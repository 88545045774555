
import * as moment from 'moment';
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {Timeframe, TimeframeService} from '../../../services/timeframes.service';
import {AuthService} from '../../auth/services/Auth/auth.service';
import {LocalStorageService} from '../../../core/services/LocalStorage/local-storage.service';

export interface InsightFilters {
  timeframe: {
    label?: string;
    range: {
      startDate: moment.Moment;
      endDate: moment.Moment;
    };
  };
}

@Injectable()
export class ContextService {

  private httpOptions: { headers: HttpHeaders };

  filters: InsightFilters;
  private filter$: BehaviorSubject<InsightFilters>;

  presets: {};
  private preset$ = new BehaviorSubject({});

  timeframes: Timeframe[];

  private currentAcademicYear$ = new BehaviorSubject({});

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private _timeframeSvc: TimeframeService,
    private _localStorageSvc: LocalStorageService,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    // const minus30 = this._backDate(30);
    // const today = new Date();

    // Load last selected range from localStorage or set default

    const previous = JSON.parse(this._localStorageSvc.get('selectedTimeframe'));

    if (previous) {

      const start = previous.startDate === null ? null : moment(previous.startDate);
      const end = previous.endDate === null ? null : moment(previous.endDate);

      this.filters = {
        timeframe: {
          label: 'Loaded',
          range: {
            startDate: start,
            endDate: end
          }
        }
      };
    } else {

      this.filters = {
        timeframe: {
          label: 'Loaded',
          range: {
            startDate: null,
            endDate: null
          }
        }
      };

    }

    this.filter$ = new BehaviorSubject(this.filters);

    this.timeframes = [];

    this._timeframeSvc.getAll().subscribe((tf) => {
      this.timeframes = tf;
      this.setupPresets();
    });
  }

  getFilters(): BehaviorSubject<InsightFilters>{
    return this.filter$;
  }

  watchInsightFilters(): Observable<InsightFilters> {
    return this.filter$.asObservable();
  }

  watchPresets(): Observable<any> {
    return this.preset$.asObservable();
  }

  getCurrentAcademicYear(): Observable<any> {
    return this.currentAcademicYear$.asObservable();
  }

  setTimeframe(range) {

    if (range.startDate || range.endDate) {
      this._localStorageSvc.set('selectedTimeframe', JSON.stringify(range));
      this.filters.timeframe.range = range;
      this.filter$.next(this.filters);
    } else if (this.filters.timeframe.range.startDate === null) {
      this._localStorageSvc.set('selectedTimeframe', JSON.stringify(this.filters.timeframe.range));
      this.filter$.next(this.filters);
    }
  }

  private _backDate(numOfDays) {
    const today = new Date();
    return new Date(today.setDate(today.getDate() - numOfDays));
  }

  // helper function to create initial presets
  setupPresets() {

    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    today.setHours(23,59,59,999);

    this.presets = {
      'Last 30 days': [backDate(30), today],
      'Last 90 days': [backDate(90), today],
      'Last 180 days': [backDate(180), today],
    };

    for (const tf in this.timeframes) {
      if (this.timeframes[tf]) {
        this.presets[this.timeframes[tf].label] = [moment(this.timeframes[tf].startDT), moment(this.timeframes[tf].endDT)];
      }
    }

    this.presets['All time'] = [moment('2014-08-12'), today];

    this.preset$.next(this.presets);

  }
}
