import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AppNavigationItem } from '@amplabs/types';
import { AppNavigationService } from '../../navigation.service';


@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class AppNavVerticalItemComponent implements OnInit {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  item: AppNavigationItem;

  @Input()
  hidden: boolean;

  navigationEvents$: Subject<string>;

  /**
   * Constructor
   */
  constructor(
    private appNavigationService: AppNavigationService
  ){}

  ngOnInit() {
    this.navigationEvents$ = this.appNavigationService.navigationEvents$;
  }
}
