import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AppNavigationService} from '@amplabs/components/navigation/navigation.service';
import {AppSidebarService} from '@amplabs/components/sidebar/sidebar.service';
import {AppConfigService} from '@amplabs/services/config.service';
import {FuseSplashScreenService} from '@amplabs/services/splash-screen.service';

import {AuthService} from './modules/auth/services/Auth/auth.service';
import {UserLaneService} from './modules/common/services/user-lane.service';
import {navigation} from './navigation/navigation';

export const WINDOW = new InjectionToken<Window>('WindowToken');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  appConfig: any;
  navigation: any;
  href: any;

  protected isLoggedIn = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {AppConfigService} _AppConfigService
   * @param {AppNavigationService} _appNavigationService
   * @param {AppSidebarService} _appSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {Platform} _platform
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _AppConfigService: AppConfigService,
    private _appNavigationService: AppNavigationService,
    private _appSidebarService: AppSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _platform: Platform,
    public auth: AuthService,
    private renderer: Renderer2,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _userLaneSvc: UserLaneService,
    private router: Router,
    private matDialog: MatDialog,
    private ngZone: NgZone,
    @Inject(WINDOW) private window: Window
  ) {

    this._userLaneSvc.renderer = this.renderer;
    this._userLaneSvc.init();

    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._appNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._appNavigationService.setCurrentNavigation('main');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    const syncAgentSVGIcons = [
      'google_classroom_icon',
      'human-male-board',
      'little_sis_premium'
    ];

    for (const icon in syncAgentSVGIcons) {
      this.matIconRegistry.addSvgIcon(
        syncAgentSVGIcons[icon],
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${syncAgentSVGIcons[icon]}.svg`)
      );
    }

    if (this.window && (this.window as any).Cypress) {
      (this.window as any).cypressNavigateByUrl = (url: string, qs?: Record<string, unknown>) => this.cypressNavigateByUrl(url, qs);
      (this.window as any).cypressCloseAllMatDialogs = () => this.cypressCloseAllMatDialogs();
    }

    this.auth.getActiveUser().subscribe(user => {
      this.isLoggedIn = !!user;
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._AppConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        this.appConfig = config;

        // Boxed
        if (this.appConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        }
        else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.appConfig.colorTheme);
      });

      // this._appNavigationService.navigationEvents$
      // .pipe(takeUntil(this._unsubscribeAll))
      // .subscribe(
      //   (event) => {
      //   }
      // );

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._appSidebarService.getSidebar(key).toggleOpen();
  }

  private cypressNavigateByUrl(url: string, qs?: Record<string, unknown>) {
    this.ngZone.run(() => {
      this.router.navigate([url], { queryParams: qs});
    });
  }

  private cypressCloseAllMatDialogs() {
    this.matDialog.closeAll();
  }
}
