import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import * as _ from 'lodash';
import * as moment from 'moment';
import {
  concat,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  ClassActions,
  Course,
} from '../../../../../services/classroom.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import {
  User,
  UserService,
} from '../../../../../services/user.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';
import { Time } from '../../../../custom-dialogs/components/add-self-to-classes/add-self-to-classes.component';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';
import { GridDataService } from '@cdw-ae/little-sis-for-classroom-ag-grid';

export const ADD_CO_TEACHER_STEPPER_STEPS = 6;
export const ADD_CO_TEACHER = 'explorerBulkAddCoTeacher';
@Component({
  selector: 'app-bulk-add-co-teacher',
  templateUrl: './bulk-addCoTeacher.html',
  styleUrls: ['./bulk-addCoTeacher.scss']
})

export class BulkAddCoTeacherDialogComponent extends GenericBulkActionComponent implements OnInit {

  columnDefsSummary;
  gridOptionsSummary;

  coTeacherTemplate: string | object;
  primaryTeacherTemplate: string | object;
  addCoteacherCoTeacher: string;

  customerActionTemplateCoTeacher: string | object;
  customerActionTemplatePrimaryTeacher: string | object;
  addCoteacherPrimaryTeacher: string;

  unaffectedClasses = [];
  summaryOfChanges = [];

  defaultCoTeacherTMPL: string;
  defaultPrimaryTeacherTMPL: string;

  additions=0;
  nochanges=0;

  showWarning: boolean;

  grids: {
    classes: {
      height: number;
    };
    summary: {
      height: number;
    };
  };

  endDate;
  endTime;

  selectedAccessTime = '999';
  minDate = new Date();

  accessTimes: Time[] = [
    {value: '1', label: 'For 1 hour'},
    {value: '24', label: 'For 1 Day'},
    {value: 'expiry', label: 'Until'},
    {value: '999', label: 'Do not remove'}
  ];

  constructor( public apiLookup: ApiLookupService,
               public dialogRef: MatDialogRef<BulkAddCoTeacherDialogComponent>,
               private _firebaseAnalytics: FirebaseAnalyticsService,
               protected _authSvc: AuthService,
               protected _firestoreCustomerService: FirestoreCustomerService,
               public userSvc: UserService,
               @Inject(MAT_DIALOG_DATA) public data: ClassActions
  ) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    this.data.selectedClasses.sort((a, b) => {
      if (a.primaryTeacherEmail > b.primaryTeacherEmail) {
        return 1;
      } else if (a.primaryTeacherEmail < b.primaryTeacherEmail) {
        return -1;
      }
      return 0;
    });

    // Summary Grid

    this.columnDefsSummary = [
      {
        headerName: 'Co-teacher',
        field: 'teacher',
        width: 80,
        filter: false,
        hide: false,
        cellRenderer: 'profileCellRenderer',
        suppressMenu: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 60,
        filter: false,
        cellClass: 'text-center',
        hide: false,
        suppressMenu: true
      },
      {
        headerName: 'Course',
        field: 'name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: 'classroomCellRenderer',
        autoHeight: true,
        suppressMenu: true
      }

    ];


    this.gridOptionsSummary = {
      columnDefs: this.columnDefsSummary,
      components: this.frameworkComponents,
      context: this.context,

      rowModelType: 'clientSide',
      rowSelection: false,
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      rowData: this.summaryOfChanges,
      animateRows: true,
      clipboardDelimiter: ',',
      pagination: false,
      defaultColDef: {
        filter: false,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: false,
        hide: false,
        resizable: true,
        sortable: true
      },
      rowClassRules: {
        'declined-50': 'data && data.action === "Teacher already exists"',
      },
    };

    this.grids = {
      classes: {
        height: Math.min(80 + this.data.selectedClasses.length * 60, 300)
      },
      summary: {
        height: Math.min(80 + (this.data.selectedClasses.length * this.coTeachers.length) * 60, 300)
      }
    };

  }

  coTeacherReplacements: object;
  priTeacherReplacements: object;

  selectedItems: string[] = [];
  selectedClasses: Course[] = [];

  coTeachers = [];
  atLeastOneCoteacher = false;

  sendNotification: boolean;
  sendCoTeacherNotification: boolean;

  teachers$: Observable<User[]>;
  teachersLoading = false;
  teachersInput$ = new Subject<string>();

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  ngOnInit() {

    const analyticsEvent = {
      action: 'bulkAddCoTeacher',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);

    this.gridOptions.rowData = this.data.selectedClasses;

    this.sendNotification = true;
    this.sendCoTeacherNotification = true;

    this.data.selectedClasses.forEach(cls => {
      if (cls.coteachers.length > 1) {
        this.atLeastOneCoteacher = true;
      }
    });

    this.apiLookup.getCustomerSettings('add_coteacher').subscribe(  results => {

      if (results) {

        // @ts-ignore
        this.customerActionTemplateCoTeacher = results.customerActionTemplate.coTeacher;
        // @ts-ignore
        this.customerActionTemplatePrimaryTeacher = results.customerActionTemplate.primaryTeacher;

        if (!this.isSuperAdmin) {
          this.notificationEmail.setValue(this._authSvc.getCurrentUser().profile.primaryEmail);
          this.notificationEmail.disable();
        } else {
          this.notificationEmail.setValue(results.replyEmail);
        }

        if (results.userActionTemplate) {
          // @ts-ignore
          this.coTeacherTemplate = results.userActionTemplate.coTeacher;
          // @ts-ignore
          this.primaryTeacherTemplate = results.userActionTemplate.primaryTeacher;
        } else {
          this.coTeacherTemplate = _.clone(this.defaultUserTemplate);
          this.primaryTeacherTemplate = _.clone(this.defaultUserTemplate);
        }

      }

    });

    this.loadTeachers();

  }

  onChange(event) {

    // this.coTeachers = event;

    if (this.coTeachers.length) {
      this.nav.nextDisabled = false;
      this.step2Completed = true;
    } else {
      this.nav.nextDisabled = true;
      this.step2Completed = false;
    }

    this.processChanges();

  }

  addCoTeacher() {

    const analyticsEvent = {
      action: 'bulkAddCoTeacher',
      properties: {
        category: 'admin',
        label: 'end'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ADD_CO_TEACHER][ADD_CO_TEACHER_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);
    const newCoTeachers = this.coTeachers.map(teacher => teacher.primaryEmail);

    const clsDetails = this.data.selectedClasses.map(selection => ({
        clsId: selection.id,
        toAdd: newCoTeachers,
      }));

    if (this.endDate) {
      this.endDate = moment(this.endDate.format('YYYY-MM-DD') + ' ' + this.endTime, 'YYYY-MM-DD HH:mm a');
    }

    const updatedData = {
      classrooms: clsDetails,
      actionType: 'add_coteacher',
      customerActionTemplate: {
        coTeacher: this.customerActionTemplateCoTeacher,
        primaryTeacher:  this.customerActionTemplatePrimaryTeacher
      },
      userActionTemplate: {
        coTeacher: this.addCoteacherCoTeacher,
        primaryTeacher: this.addCoteacherPrimaryTeacher
      },
      replyEmail: this.notificationEmail.value,
      sendNotification: this.sendNotification,
      sendCoTeacherNotification: this.sendCoTeacherNotification,
      accessTime: (this.endDate ? this.endDate : this.selectedAccessTime)
    };

    this.dialogRef.close(updatedData);
  }

  goForward(stepper: MatStepper) {
    this.processChanges();
    if(!this.step2Completed) {
      this.showWarning=true;
    }
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ADD_CO_TEACHER][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    stepper.next();
  }

  processChanges() {
    this.summaryOfChanges.length = 0;
    this.additions = 0;
    this.nochanges = 0;
    const hasChanges = this.selectedClasses.reduce((map, cls) => { map[cls.id] = false; return map; }, {});

    this.summaryOfChanges = this.coTeachers.reduce( (accum, userToAdd) => {

      this.selectedClasses.sort((a, b) => {
        if (a.primaryTeacherEmail > b.primaryTeacherEmail) {
          return 1;
        } else if (a.primaryTeacherEmail < b.primaryTeacherEmail) {
          return -1;
        }
        return 0;
      }).forEach( currCls => {

        const hasTeacher = Boolean(currCls.coteachers.find(coteach => coteach.primaryEmail === userToAdd.primaryEmail));

        if (!hasTeacher) {
          const relevantData = {
            teacher: userToAdd,
            action: 'Add co-teacher',
            class: currCls
          };
          this.additions++;
          hasChanges[currCls.id] = true;
          accum.push(relevantData);
        }
        else {

          const relevantData = {
            teacher: userToAdd,
            action: 'Teacher already exists',
            class: currCls
          };
          accum.push(relevantData);

        }
      });

      return accum;
    }, []);
    const noChanges = _.reduce(hasChanges, (col, c, clsId) => {
      if (!c) {
        col.push(clsId);
      }
      return col;
    }, []);

    this.nochanges = noChanges.length;

    if(this.additions === 0 && this.coTeachers.length) {
      this.step2Completed=false;
    }
    else {
      this.step2Completed=true;
      this.showWarning=false;
    }

    this.gridOptionsSummary.api.setRowData(this.summaryOfChanges);
    this.gridOptionsSummary.api.redrawRows();

    this.unaffectedClasses = this.selectedClasses.reduce( (accum, currCls) => {

      let affected = false;

      this.coTeachers.forEach(userToAdd => {

        const hasTeacher = Boolean(currCls.coteachers.find(coteach => coteach.primaryEmail === userToAdd.primaryEmail));

        if (!hasTeacher) {
          affected = true;
        }
      });

      if (!affected) {
        accum.push(currCls);
      }

      return accum;
    }, []);

    this.processReplacements();

    // Recalculate height of summary grid
    this.grids.summary.height = Math.min(80 + (this.data.selectedClasses.length * this.coTeachers.length) * 60, 300);

    this.gridOptionsSummary.api.sizeColumnsToFit();

  }

  processReplacements() {

    if (!this.summaryOfChanges.length) {
      this.templateReplacements = {};
      return;
    }

    this.generateReplacements(this.selectedClasses);

    // Primary Teacher sample
    const firstTeacher = this.summaryOfChanges[0].class.Owner;
    let primaryTeacherClassCount = 0;

    const priTeacherClassList = this.summaryOfChanges.reduce((accum, curr) => {
      if (curr.class.Owner.primaryEmail === firstTeacher.primaryEmail && curr.action !== 'Co-teacher already exists') {

        primaryTeacherClassCount++;

        accum += `<li><a href="${GridDataService.validateAlternateLink(curr.class.alternateLink)}" target="_blank">${curr.class.name}`;

        if (curr.class.section) {
          accum += ' [' + curr.class.section + ']';
        }

        accum += `</a> (<strong>${curr.teacher.name}</strong> added)</li>`;
      }
      return accum;
    }, '');


    // Co teacher sample
    // const firstCoTeacher = this.summaryOfChanges[0].teacher;
    const firstCoTeacherArray = this.summaryOfChanges.find((element) => element.action !==  'Co-teacher already exists');
    if(firstCoTeacherArray) {
      const firstCoTeacher = firstCoTeacherArray.teacher;
      const coteacherClassList = this.summaryOfChanges.reduce((accum, curr) => {

        if (curr.teacher.primaryEmail === firstCoTeacher.primaryEmail && curr.action !== 'Co-teacher already exists') {
          accum += `<li><a href="${GridDataService.validateAlternateLink(curr.class.alternateLink)}" target="_blank">${curr.class.name}`;

          if (curr.class.section) {
            accum += ' [' + curr.class.section + ']';
          }

          accum += '</a> (' + curr.class.Owner.name + ')</li>';
        }

        return accum;
      }, '');

      this.coTeacherReplacements = _.clone(this.templateReplacements);
      this.coTeacherReplacements['{{teacherName}}'] = firstCoTeacher.name;
      this.coTeacherReplacements['{{classList}}'] = '<ul>' + coteacherClassList + '</ul>';
      this.coTeacherReplacements['{{classPlural}}'] = (coteacherClassList.match(/<li>/g) || []).length > 1 ? 'Google Classroom classes' : 'a Google Classroom class';
    }
    this.priTeacherReplacements = _.clone(this.templateReplacements);
    this.priTeacherReplacements['{{teacherName}}'] = firstTeacher.name;
    this.priTeacherReplacements['{{classList}}'] = '<ul>' + priTeacherClassList + '</ul>';
    this.priTeacherReplacements['{{classCount}}'] = primaryTeacherClassCount;
    this.priTeacherReplacements['{{classPlural}}'] = primaryTeacherClassCount > 1 ? 'Google Classroom classes' : 'a Google Classroom class';
  }

  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ADD_CO_TEACHER][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

  // Summary Grid

  onGridReadySummary(params: any) {
    params.api.sizeColumnsToFit();
  }

  private loadTeachers() {
    this.teachers$ = concat(
      of([]), // default items
      this.teachersInput$.pipe(
        debounceTime(750),
        distinctUntilChanged(),
        tap(() => this.teachersLoading = true),
        switchMap(term => this.userSvc.getTeachers({
          filter: {
            op: 'OR',
            filters: [
              {field: 'name', operator: 'contains', value: term},
              {field: 'primaryEmail', operator: 'contains', value: term}
              ]
          }
        }).pipe(
            map(response => response.data),
            catchError(() => of([])), // empty list on error
            tap(() => this.teachersLoading = false)
          ))
      )
    );
  }

}

