import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LocalStorageService } from '../../../../core/services/LocalStorage/local-storage.service';

import { versions } from '../../../../../environments/versions';

@Component({
  selector: 'app-product-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class ProductTermsComponent {

  versions: {
    version: string;
    branch: string;
    revision: string;
  };

  loggedInUser: string;

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog,private localStorage: LocalStorageService,) {
    this.loggedInUser = localStorage.get('user');
    this.versions = versions;
  }


}
