import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import * as _ from 'lodash';

import { GridDataService } from '@cdw-ae/little-sis-for-classroom-ag-grid';
import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  ClassActions,
  Course,
} from '../../../../../services/classroom.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';

export const REMOVE_CO_TEACHER_STEPPER_STEPS = 4;
export const REMOVE_CO_TEACHER = 'explorerRemoveCoTeacher';

@Component({
  selector: 'app-bulk-removeCoteacher-dialog',
  templateUrl: './bulk-removeCoteacher-dialog.html',
  styleUrls: ['./bulk-removeCoteacher-dialog.scss']
})


export class BulkRemoveCoteacherDialogComponent extends GenericBulkActionComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  columnDefsSummary;
  gridOptionsSummary;

  customerActionTemplate: string | object;
  userActionTemplate: string | object;
  removeCoteacher: string;

  sourceList;
  removalList = [];
  message;

  selectedItems: string[] = [];
  selectedClasses: Course[] = [];

  invalidClasses = [];
  validClasses = [];
  coTeacherList = [];

  unaffectedClasses = [];
  summaryOfChanges = [];

  grids: {
    classes: {
      height: number;
    };
    summary: {
      height: number;
    };
  };

  constructor( public dialogRef: MatDialogRef<BulkRemoveCoteacherDialogComponent>,
               protected _authSvc: AuthService,
               protected _firestoreCustomerService: FirestoreCustomerService,
               public apiLookup: ApiLookupService,
               private _firebaseAnalytics: FirebaseAnalyticsService,
               @Inject(MAT_DIALOG_DATA) public data: ClassActions ) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    const additionalCols = [{
      headerName: 'Has co-teachers?',
      field: 'hasCoTeachers',
      width: 80,
      filter: false,
      cellClass: 'text-center',
      hide: false,
      cellRenderer: 'booleanCellRenderer'
    }];


    this.columnDefs = [...this.columnDefs, ...additionalCols];

    this.gridOptions = {
      ...this.gridOptions,
      isRowSelectable: (rowNode) => rowNode.data.hasCoTeachers,
      rowClassRules: {
        'declined-50': 'data && !data.hasCoTeachers',
      },
      // defaultColDef: {
      //   tooltip: (params) => {
      //     if (params.alreadyInClass) {
      //       return 'You are already in this class';
      //     }
      //   }
      // }
    };

    // Summary Grid

    this.columnDefsSummary = [
      {
        headerName: 'Co-teacher',
        field: 'teacher',
        width: 80,
        filter: false,
        hide: false,
        cellRenderer: 'profileCellRenderer',
        suppressMenu: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 60,
        filter: false,
        cellClass: 'text-center',
        hide: false,
        suppressMenu: true
      },
      {
        headerName: 'Course',
        field: 'name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: 'classroomCellRenderer',
        autoHeight: true,
        suppressMenu: true
      }
    ];


    this.gridOptionsSummary = {
      rowModelType: 'clientSide',
      rowSelection: false,
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      rowData: this.summaryOfChanges,

      animateRows: true,

      clipboardDelimiter: ',',

      pagination: false,

      defaultColDef: {
        filter: false,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: false,
        hide: false,
        resizable: true,
        sortable: true
      }
    };

    this.tokens = [
      { id: '1', toolTip: 'Insert teacher name', icon: 'account_box', insertString: '{{teacherName}}' },
      { id: '2', toolTip: 'Insert class list', icon: 'assignment', insertString: '{{classList}}'},
      { id: '3', toolTip: 'Insert teacher to be removed', icon: 'delete', insertString: '{{removedTeacher}}'}
    ];
  }

  ngOnInit() {

    const analyticsEvent = {
      action: 'removeCoTeacher',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);

    this.grids = {
      classes: {
        height: Math.min(32 + 32 + this.data.selectedClasses.length * 60, 300)
      },
      summary:{
        height : Math.min(32 + 32 + this.data.selectedClasses.length * this.coTeacherList.length * 60, 300)
      }
    };

    this.sendNotification = true;
    this.gridOptions.rowData = this.data.selectedClasses;

    // @ts-ignore
    this.validClasses = this.data.selectedClasses.reduce((accum, curr) => {

      if (curr.coteachers.length > 1) {
        accum.push(curr);
      } else {
        this.invalidClasses.push(curr);
      }
      return accum;
    }, []);

    // @ts-ignore
    this.data.selectedClasses.map(cls => {
      if (cls.coteachers.length > 1) {
          cls.hasCoTeachers = true;
      } else {
          cls.hasCoTeachers = false;
      }
    });

    // @ts-ignore
    this.data.selectedClasses.forEach(cls => {

      if (cls.coteachers.length) {
        cls.coteachers.forEach(coteach => {
          if (coteach.gId !== cls.ownerId) {
            this.coTeacherList.push(coteach);
          }
        });
      }

    });

    this.sourceList = _.uniqBy(this.coTeacherList,  (e) => e.gId);

    this.initialize('remove_coteacher').subscribe(() => this.generateReplacements(this.validClasses));
  }

  removeCoTeachers(): void {
    const removeTeachers = this.removalList.reduce( (accum, curr) => {

      accum.push(curr.primaryEmail);
      return accum;

    }, []);

    const clsDetails = this.validClasses.map(selection => ({
        clsId: selection.id,
        toRemove: removeTeachers
      }));

    const updatedData = {
      classrooms: clsDetails,
      actionType: 'remove_coteacher',
      sendNotification: this.sendNotification,
      customerActionTemplate: this.customerActionTemplate,
      userActionTemplate: this.removeCoteacher,
      replyEmail: this.notificationEmail.value
    };

    const analyticsEvent = {
      action: 'removeCoTeacher',
      properties: {
        category: 'admin',
        label: 'end'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[REMOVE_CO_TEACHER][REMOVE_CO_TEACHER_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);

    this.sendNotification = true;
    this.dialogRef.close(updatedData);

  }

  goForward(stepper: MatStepper) {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[REMOVE_CO_TEACHER][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    this.processChanges();
    stepper.next();
  }

  removedTeachersList(e: any) {

    this.processChanges();

  }

  processChanges() {

    this.summaryOfChanges.length = 0;

    this.summaryOfChanges = this.removalList.reduce( (accum, removedTeach) => {

      this.validClasses.sort((a, b) => {
        if (a.primaryTeacherEmail > b.primaryTeacherEmail) {
          return 1;
        } else if (a.primaryTeacherEmail < b.primaryTeacherEmail) {
          return -1;
        }
        return 0;
      }).forEach( currCls => {

        const hasTeacher = Boolean(currCls.coteachers.find(coteach => coteach.primaryEmail === removedTeach.primaryEmail));

        if (hasTeacher) {
          const relevantData = {
            teacher: removedTeach,
            action: 'Remove co-teacher from',
            class: currCls
          };

          accum.push(relevantData);
        }
      });

      return accum;
    }, []);

    this.gridOptionsSummary.api.setRowData(this.summaryOfChanges);

    this.unaffectedClasses = [...this.invalidClasses, ...this.validClasses.reduce( (accum, currCls) => {

      let affected = false;

      this.removalList.forEach(removedTeach => {

        const hasTeacher = Boolean(currCls.coteachers.find(coteach => coteach.primaryEmail === removedTeach.primaryEmail));

        if (hasTeacher) {
          affected = true;
        }
      });

      if (!affected) {
        accum.push(currCls);
      }

      return accum;
    }, [])];

    this.processReplacements();

    // Recalculate height of summary grid
    this.grids.summary.height = Math.min(80 + (this.summaryOfChanges.length) * 60, 300);

  }

  processReplacements() {

    if (!this.summaryOfChanges.length) {
      this.templateReplacements = {};
      return;
    }

    const firstTeacher = this.summaryOfChanges[0].class.Owner;
    let primaryTeacherClassCount = 0;

    const priTeacherClassList = this.summaryOfChanges.reduce((accum, curr) => {
      if (curr.class.Owner.primaryEmail === firstTeacher.primaryEmail) {
        primaryTeacherClassCount++;

        accum += `<li><a href="${GridDataService.validateAlternateLink(curr.class.alternateLink)}" target="_blank">${curr.class.name}`;

        if (curr.class.section) {
          accum += ' [' + curr.class.section + ']';
        }

        accum += `</a> (<strong>${curr.teacher.name}</strong> removed)</li>`;
      }
      return accum;
    }, '');

    this.generateReplacements(this.validClasses);

    this.templateReplacements['{{teacherName}}'] = firstTeacher.name;
    this.templateReplacements['{{classList}}'] = priTeacherClassList;
    this.templateReplacements['{{classCount}}'] = primaryTeacherClassCount;

  }

  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[REMOVE_CO_TEACHER][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

  // Summary Grid

  onGridReadySummary(params) {
    params.api.sizeColumnsToFit();
  }

}

