import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';

import {
  BehaviorSubject,
  Observable,
} from 'rxjs';

import {AuthService} from '../modules/auth/services/Auth/auth.service';

@Injectable()
export class FirestoreGridService {

  private httpOptions: { headers: HttpHeaders };

  private binding;
  private customerGridsFirestoreRef$ = new BehaviorSubject({loading: true});

  private teacherAnalysisGrid$ = new BehaviorSubject({loading:true});

  grids;

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private _authSvc: AuthService) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this._authSvc.getActiveUser().subscribe((user) => {

      if (user && user.auth && user.auth.fbToken) {
        this.bindToGrids(user);
      }

    });

    this.grids = {};
  }

  bindToGrids(user) {

    if (!user || !user.auth.fbToken || this.binding) {
      return;
    }

    const customerId = user.gSuiteId;

    this.binding = this.firestore.collection(`customers/${customerId}/grids`);

    this.binding.snapshotChanges().subscribe(data => {

      data.map(e => {
        this.grids[e.payload.doc.id] = e.payload.doc.data();
        const $o = `${e.payload.doc.id}Grid$`;
        this[$o].next(this.grids[e.payload.doc.id]);
      });

    });
  }

  getCustomerGrids(): Observable<any> {
    return this.customerGridsFirestoreRef$.asObservable();
  }

  getTeacherAnalysisGrid(): Observable<any> {
    return this.teacherAnalysisGrid$.asObservable();
  }

}
