import {Component, ComponentFactoryResolver, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FirebaseAnalyticsService} from '../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {LocalStorageService} from '../../../../core/services/LocalStorage/local-storage.service';
import {filter, takeUntil, tap} from 'rxjs/operators';
import { LittlesisLicensingService} from '../../services/littlesis-licensing.service';
import { Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {CustomerService} from '../../../common/services/customer.service';
import {AuthService} from '../../../auth/services/Auth/auth.service';
import {LicensingTierDirective} from './directive/licensing-tier.directive';
import {PaidLicensingInfoComponent} from './tiers/paid-licensing-info/paid-licensing-info.component';
import {LicensingTierTemplate} from './interface/licensingTier.interface';
import {LapsedLicensingInfoComponent} from './tiers/lapsed-licensing-info/lapsed-licensing-info.component';
import {TrialLicensingInfoComponent} from './tiers/trial-licensing-info/trial-licensing-info.component';
import {ConsultantLicensingInfoComponent} from './tiers/consultant-licensing-info/consultant-licensing-info.component';
import {UnlicensedLicensingInfoComponent} from './tiers/unlicensed-licensing-info/unlicensed-licensing-info.component';

@Component({
  selector: 'app-licensing-renewal-dialog',
  templateUrl: './licensing-renewal-dialog.component.html',
  styleUrls: ['./licensing-renewal-dialog.component.scss']
})
export class LicensingRenewalDialogComponent implements OnInit, OnDestroy {

  @ViewChild(LicensingTierDirective, {static: true}) licensingTierInsert: LicensingTierDirective;

  dialogData: {
    domain: string;
    expireDate: string;
    expireDays: number;
    expiryMessage: string;
    showIcon: boolean;
    showSupportButton?: boolean;
    showRecheckButton?: boolean;
    showRenewalButton?: boolean;
    plan: string;
    pastDeleteDataWindow?: boolean;
    lastTierExpiration?: Date;
  };
  isSuperAdmin: boolean;
  activeUser;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<LicensingRenewalDialogComponent>,
              private _firebaseAnalytics: FirebaseAnalyticsService,
              private licensingService: LittlesisLicensingService,
              private localStorageService: LocalStorageService,
              private authService: AuthService,
              private customerService: CustomerService,
              private _datePipe: DatePipe,
              private componentFactoryResolver: ComponentFactoryResolver,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true; //
  }


  checkLicenseState(forceRefresh = false) {
    this.licensingService.checkLicense(forceRefresh)
      .pipe(takeUntil(this.onDestroy$)).subscribe((licenseState) => {
      let componentFactory;
      if (licenseState.license.subscriptions['LSW'].plan.name === 'paid') {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(PaidLicensingInfoComponent);
      } else if(licenseState.license.subscriptions['LSW'].plan.name === 'lapsed') {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(LapsedLicensingInfoComponent);
      } else if (licenseState.license.subscriptions['LSW'].plan.name === 'trial') {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(TrialLicensingInfoComponent);
      } else if (licenseState.license.subscriptions['LSW'].plan.name === 'consultant') {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsultantLicensingInfoComponent);
      } else {
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(UnlicensedLicensingInfoComponent);
      }
      const viewContainerRef = this.licensingTierInsert.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<LicensingTierTemplate>(componentFactory);
      componentRef.instance.data = {
        licenseState,
        activeUser: this.activeUser,
        dialogRef: this.dialogRef
      };
    });
  }

  ngOnInit(): void {
      this.licensingService.getForcedRecheckTrigger().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.recheckLicenseState();
      });

      this.authService.getActiveUser().pipe(filter(activeUser => !!activeUser), takeUntil(this.onDestroy$))
      .subscribe((user ) => {
      this.isSuperAdmin = user.auth.role_key === 'sa';
      this.activeUser = user;
      this.checkLicenseState();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  recheckLicenseState(){
    this.checkLicenseState(true);
    this.dialogRef.close();
  }
}
