import { NgModule } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { CoModule } from '../common/co.module';
import { MaterialModule } from '../../core/services/material/material.module';
import { ClassroomInfoDialogComponent } from './components/classroom-info/classroom-info.component';
import { AddSelfToClassesComponent } from './components/add-self-to-classes/add-self-to-classes.component';


@NgModule({
  declarations: [
    AddSelfToClassesComponent,
    ClassroomInfoDialogComponent
  ],
  // entryComponents: [
  //   AddSelfToClassesComponent,
  //   ClassroomInfoDialogComponent,
  // ],
  exports: [
    AddSelfToClassesComponent,
    ClassroomInfoDialogComponent
  ],
  imports: [
    NgSelectModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    NgxDaterangepickerMd,
    CoModule,
    AgGridModule
  ],
})
export class CustomDialogsModule { }
