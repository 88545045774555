import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {environment} from '../../environments/environment';
import {versions} from '../../environments/versions';


@Injectable()
export class AnalyticsService {

  customDimensions: any;

  constructor() {
    this.customDimensions = {};
    this.init();
  }

  init() {
    const gitHashDimension = environment.googleAnalytics.customDimensions.gitHash;
    this.customDimensions[gitHashDimension] = `${versions.version}_${versions.revision}`;
  }

  updateSession(props) {

    const dimensions = _.reduce(environment.googleAnalytics.customDimensions, (properties, dimension, val) => {
      if (props[val]) {
        properties[dimension] = props[val];
      }
      return properties;
    }, {});

    this.customDimensions = {...this.customDimensions, ...dimensions};
  }

}
