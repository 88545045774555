import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
// import { Permission } from 'app/services/user.service';

const getWindow = (): any => window;

@Injectable({
  providedIn: 'root'
})
export class UserLaneService {

  renderer: Renderer2;
  private window;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private featureToggleService: FeatureToggleService
  ) {
    this.window = getWindow();
  }

  init(): void {
    if (!this.featureToggleService.isActiveForEnvironment('userlane')) {
      return;
    }

    const scriptElement: HTMLScriptElement = this.renderer.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.text = `
      // load Userlane
      (function(i,s,o,g,r,a,m){i['UserlaneCommandObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://cdn.userlane.com/userlane.js','Userlane');
    `;

    this.renderer.appendChild(this._document.body, scriptElement);

    setTimeout(() => {
      this.hide();
    });
  }

  identify(userObject): void {
    if (!this.featureToggleService.isActiveForEnvironment('userlane')) {
      return;
    }

    this.window.Userlane('identify', userObject.id, {
      role: userObject.role,
      permissions: userObject.permissions
    });

    this.show();
  }

  hide(): void {
    if (!this.featureToggleService.isActiveForEnvironment('userlane')) {
      return;
    }

    this.window.Userlane('hide');
  }

  show(): void {
    if (!this.featureToggleService.isActiveForEnvironment('userlane')) {
      return;
    }

    this.window.Userlane('init', '22d36');
  }
}
