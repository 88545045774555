import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnDestroy,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import {Subject} from 'rxjs';
import {
  filter,
  takeUntil,
} from 'rxjs/operators';

import {versions} from '../../../../environments/versions';
import {AuthService} from '../../../modules/auth/services/Auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '500px',
        width: '800px',
        opacity: 1,
        display: 'block',
        backgroundColor: 'ghostwhite'
      })),
      state('closed', style({
        height: '0',
        width: '0',
        display: 'none',
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('1s')
      ])
    ])
  ]
})
export class LoginComponent implements OnDestroy {

  versions: {
    version: string;
    branch: string;
    revision: string;
  };

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private _auth: AuthService
  ) {

    this.versions = versions;

    this._auth.getActiveUser()
      .pipe(
        filter(activeUser => !!activeUser),
        takeUntil(this.onDestroy$)
      )
      .subscribe( () => this.router.navigate(['/welcome']));

    this.route.queryParams.subscribe(async params => {
      if ('testLogin' in params) {
        await this.logIn(true);
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  async logIn(testLogin = false): Promise<void> {
    await this._auth.signIn({ testLogin});
  }


  goToLabsPage(): void {
    window.open('https://labs.amplifiedit.com/little-sis-for-classroom/');
  }

  goTo(which) {

    let url;

    switch (which) {

      case 'explore':
        url = 'https://amplifiedlabs.zendesk.com/hc/en-us/articles/360033939594-Explore-classes';
        break;

      case 'insights':
        url = 'https://amplifiedlabs.zendesk.com/hc/en-us/sections/360008404573-Gaining-Insights';
        break;

      case 'support':
        url = 'https://amplifiedlabs.zendesk.com/hc/en-us/articles/360034518853-Support-teachers';
        break;

      case 'actions':
        url = 'https://amplifiedlabs.zendesk.com/hc/en-us/articles/360033977294-Take-actions';
        break;

      default:
        break;
    }

    if (url) { window.open(url); }
  }

}
