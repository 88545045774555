import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppNavigationComponent } from './navigation.component';
import { AppNavVerticalItemComponent } from './vertical/item/item.component';
import { AppNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { AppNavVerticalGroupComponent } from './vertical/group/group.component';
import {AuthModule} from '../../../app/modules/auth/auth.module';

@NgModule({
  imports     : [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    MatTooltipModule,
    AuthModule
  ],
  exports     : [
    AppNavigationComponent
  ],
  declarations: [
    AppNavigationComponent,
    AppNavVerticalGroupComponent,
    AppNavVerticalItemComponent,
    AppNavVerticalCollapsableComponent
  ]
})
export class AppNavigationModule
{
}
