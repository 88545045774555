import { GETTING_STARTED_STEPPER_LENGTH } from '../../../modules/admin/components/getting-started/getting-started.component';
import { ADD_TO_COLLECTION_STEPPER, ADD_TO_COLLECTION_STEPPER_STEPS } from '../../../modules/collections/components/add-to-collection/add-to-collection.component';
import { loadedCustomSupportMessage, loadedDefaultSupportMessage } from '../../../modules/common/services/support.service';
import { ADD_SELF_TO_CLASSES, ADD_SELF_TO_CLASSES_STEPPER_STEPS } from '../../../modules/custom-dialogs/components/add-self-to-classes/add-self-to-classes.component';
import { ARCHIVE_CLASSES, ARCHIVE_CLASSES_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/archive-classes/archive-class-dialog.component';
import { ADD_CO_TEACHER, ADD_CO_TEACHER_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/bulk-add-coTeacher/bulk-addCoTeacher.component';
import { ADD_STUDENTS, ADD_STUDENTS_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/bulk-add-students/bulk-add-students.component';
import { DELETE_CLASSES, DELETE_CLASSES_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/bulk-deleteClasses/bulk-deleteClasses-dialog.component';
import { REMOVE_CO_TEACHER, REMOVE_CO_TEACHER_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/bulk-removeCoteacher/bulk-removeCoteacher-dialog.component';
import { REMOVE_STUDENTS, REMOVE_STUDENTS_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/bulk-removeStudents/bulk-removeStudent-dialog.component';
import { CHANGE_PRIMARY_TEACHER, CHANGE_PRIMARY_TEACHER_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/change-primary-teacher/change-primary-teacher.component';
import { CLASSES_BULK_ACTION_STEPPER_LENGTH } from '../../../modules/explorer/components/dialogs/classes-bulk-action/classes-bulk-action.component';
import { JOIN_CLASS_AS_STUDENT, JOIN_CLASS_AS_STUDENT_STEPPER_STEPS } from '../../../modules/explorer/components/dialogs/join-class-as-student/join-class-as-student.component';
import { BulkClassesActions } from '../../../modules/explorer/components/grd/grid.interfaces';
import { JOIN_CLASS_DIALOG, JOIN_CLASS_DIALOG_STEPPER_STEPS } from '../../../modules/insights/components/dialogs/join-class-dialog/join-class-dialog.constant';
import { AnalyticsEvent, loginEvent } from './firebase-analytics.service';

type AdditionalStepAnalyticsEventKeys = 'cancel' | 'stepLaunchSyncPremium' | 'error' | 'addToExistingCollection' | 'createNewCollection' | 'launchJoinClassDialog';
export enum StepAnalyticsEventEnum {
  completed = 'completed',
  cancelled = 'cancelled'
}

type StepAnalyticsEventStatus = StepAnalyticsEventEnum.completed | StepAnalyticsEventEnum.cancelled;

export type StepAnalyticsEvent = {
  [step in number | AdditionalStepAnalyticsEventKeys]: {[status in StepAnalyticsEventStatus]: AnalyticsEvent} | AnalyticsEvent;
};

interface CombinedEvents {
  [key: string]: StepAnalyticsEvent | AnalyticsEvent;
}


interface AdditionalEvent {
  actionKey: AdditionalStepAnalyticsEventKeys;
  actionValue: string;
}

type LscEventKeys = 'home' | 'explorer' | 'studentExplorer' | 'insights' | 'support' | 'administration';

type ILscEvents = {
  [key in LscEventKeys]: CombinedEvents;
};

const EXPLORER_ACTIONS = {
  explorerAddSelfToClasses : ADD_SELF_TO_CLASSES,
  explorerArchiveClass : ARCHIVE_CLASSES,
  explorerBulkAddCoTeacher : ADD_CO_TEACHER,
  explorerBulkAddStudents : ADD_STUDENTS,
  explorerDeleteClasses : DELETE_CLASSES,
  explorerRemoveCoTeacher : REMOVE_CO_TEACHER,
  explorerRemoveStudents : REMOVE_STUDENTS,
  explorerChangePrimaryTeacher : CHANGE_PRIMARY_TEACHER,
  explorerJoinClassAsStudent : JOIN_CLASS_AS_STUDENT,
};


export class LscEvents {

  private static bulkClassesActions: Record<string, number> = {
    [BulkClassesActions.archive]: CLASSES_BULK_ACTION_STEPPER_LENGTH,
    [BulkClassesActions.delete]: CLASSES_BULK_ACTION_STEPPER_LENGTH,
    [BulkClassesActions.clear]: CLASSES_BULK_ACTION_STEPPER_LENGTH,
    [EXPLORER_ACTIONS.explorerAddSelfToClasses]: ADD_SELF_TO_CLASSES_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerArchiveClass]: ARCHIVE_CLASSES_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerBulkAddCoTeacher]: ADD_CO_TEACHER_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerBulkAddStudents]: ADD_STUDENTS_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerDeleteClasses]: DELETE_CLASSES_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerRemoveCoTeacher]: REMOVE_CO_TEACHER_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerRemoveStudents]: REMOVE_STUDENTS_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerChangePrimaryTeacher]: CHANGE_PRIMARY_TEACHER_STEPPER_STEPS,
    [EXPLORER_ACTIONS.explorerJoinClassAsStudent]: JOIN_CLASS_AS_STUDENT_STEPPER_STEPS,
  };

  private static explorerBulkClassesProperties = {
    category: 'explorer',
    label: 'bulkClasses',
  };

  private static loginProperties = {
    category: 'home',
    label: 'login',
  };

  private static gettingStartedProperties = {
    category: 'home',
    label: 'gettingStarted',
  };

  private static studentsSearchProperties = {
    category: 'students',
    label: 'search',
  };
  private static studentsActionProperties = {
    category: 'students',
    label: 'action',
  };
  private static studentsGuardianProperties = {
    category: 'students',
    label: 'guardian',
  };
  private static studentTeachersProperties = {
    category: 'students',
    label: 'teacher',
  };

  private static studentClassroomProperties = {
    category: 'students',
    label: 'classroom',
  };

  private static studentCollectionProperties = {
    category: 'students',
    label: 'collection',
  };

  private static insightsExportProperties = {
    category: 'insights',
    label: 'export',
  };

  private static insightsJoinClassProperties = {
    category: 'insights',
    label: 'joinClass',
  };

  private static insightsViewMaterialsProperties = {
    category: 'insights',
    label: 'viewMaterials',
    numberOfMaterials: 'not_set'
  };

  private static supportMessageProperties = {
    category: 'support',
    label: 'message',
  };

  private static administrationUserProperties = {
    category: 'administration',
    label: 'user',
    totalAccounts: 'not_ready',
    accountsNeverLoggedIn: 'not_ready',
    accountsSuspended: 'not_ready',
  };

  private static administrationRolesProperties = {
    category: 'administration',
    label: 'roles',
    totalRoles: 'not_ready',
  };

  private static administrationSchoolTagsProperties = {
    category: 'administration',
    label: 'schoolTags',
    totalSchoolTags: 'not_ready',
  };

  private static administrationTimeframesProperties = {
    category: 'administration',
    label: 'timeframes',
    totalTimeframes: 'not_ready',
    academicYearTransition: 'not_ready',
  };

  private static capitalize = (s: string) =>
    s && s.charAt(0).toUpperCase() + s.slice(1);

  private static createWizardEvents = (
    actionsRecord: Record<string, number>,
    properties: { [key: string]: string | number },
    additionalEvents?: AdditionalEvent[]
  ): CombinedEvents => {
    const wizardEvents: CombinedEvents = Object.entries(actionsRecord).reduce(
      (outerAcc, action) => {
        const stepArray = Array.from(Array(action[1]).keys());

        const additionalStepperEvents = additionalEvents ? additionalEvents.reduce(
          (
            acc,
            item
          ): {
            [key: string]: AnalyticsEvent;
          } => {
            acc[item.actionKey] = {
              action: `${item.actionValue}_${action}`,
              properties,
            };
            return acc;
          },
          {} as { [key: string]: AnalyticsEvent }
        ) : {};
        outerAcc[action[0]] = stepArray.reduce((innerAcc, step) => {
          innerAcc[step] = {} as AnalyticsEvent;
          let completedStepperEvent = `completed${LscEvents.capitalize(action[0])}Step${step}`;
          let cancelledStepperEvent = `cancelled${LscEvents.capitalize(action[0])}Step${step}`;

          if(step === stepArray.length - 1){
            completedStepperEvent += `_final`;
            cancelledStepperEvent += `_final`;

          }
          innerAcc[step][StepAnalyticsEventEnum.completed] = {
            action: completedStepperEvent,
            properties,
          };
          innerAcc[step][StepAnalyticsEventEnum.cancelled] = {
            action: cancelledStepperEvent,
            properties,
          };
          return innerAcc;
        }, additionalStepperEvents as StepAnalyticsEvent);
        return outerAcc;
      },
      {} as CombinedEvents
    );
    return wizardEvents;
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public static lscEvents: ILscEvents = {
    home: {
      ...LscEvents.createWizardEvents(
        { gettingStartedWizard: GETTING_STARTED_STEPPER_LENGTH },
        LscEvents.gettingStartedProperties,
        [
          { actionKey: 'cancel', actionValue: 'gettingStartedCancel' },
          {
            actionKey: 'stepLaunchSyncPremium',
            actionValue: 'gettingStartedStepLaunchSyncPremium',
          },
        ]
      ),
      // LSC-1938 this is a hack to circumvent
      // what appears to be a circular dependency
      // this analytics event is accessed at the service level
      // and kept here for record keeping
      loginEvent
    },
    explorer: {
      ...LscEvents.createWizardEvents(
        LscEvents.bulkClassesActions,
        LscEvents.explorerBulkClassesProperties,
        [
          {actionKey: 'error', actionValue:'errorBulkClassesActions'},
        ]
      ),
      archiveClassesWithSuspendedTeachersStarted: {
        action: 'archiveClassesWithSuspendedTeachersStarted',
        properties: LscEvents.explorerBulkClassesProperties
      },
      archiveClassesWithSuspendedTeachersActioned: {
        action: 'archiveClassesWithSuspendedTeachersActioned',
        properties: LscEvents.explorerBulkClassesProperties
      },
      refreshClasses: {
        action: 'refreshClasses',
        properties: LscEvents.explorerBulkClassesProperties
      },
      refreshClassesAndOptionsChanged: {
        action: 'refreshClassesAndOptionsChanged',
        properties: LscEvents.explorerBulkClassesProperties
      },
      changedVisibleColumns: {
        action: 'changedVisibleColumns',
        properties: LscEvents.explorerBulkClassesProperties
      },
      switchedToPredefinedLayout: {
        action: 'switchedToPredefinedLayout',
        properties: LscEvents.explorerBulkClassesProperties
      },
      switchedToUserDefinedLayout: {
        action: 'switchedToUserDefinedLayout',
        properties: LscEvents.explorerBulkClassesProperties
      },
      savedCustomLayout: {
        action: 'savedCustomLayout',
        properties: LscEvents.explorerBulkClassesProperties
      },
      switchedToPredefinedFilter: {
        action: 'switchedToPredefinedFilter',
        properties: LscEvents.explorerBulkClassesProperties
      },
      switchedToUserDefinedFilter: {
        action: 'switchedToUserDefinedFilter',
        properties: LscEvents.explorerBulkClassesProperties
      },
      savedCustomFilter: {
        action: 'savedCustomFilter',
        properties: LscEvents.explorerBulkClassesProperties
      },
      updateSchoolTags: {
        action: 'updateSchoolTags',
        properties: LscEvents.explorerBulkClassesProperties
      },
      updateTimeframeTags: {
        action: 'updateTimeframeTags',
        properties: LscEvents.explorerBulkClassesProperties
      },
      updateOtherTags: {
        action: 'updateOtherTags',
        properties: LscEvents.explorerBulkClassesProperties
      }
    },
    studentExplorer: {
      viewStudent: {
        action: 'viewStudent',
        properties: LscEvents.studentsSearchProperties,
      },
      searchStudentName: {
        action: 'searchStudentName',
        properties: LscEvents.studentsSearchProperties,
      },
      searchStudentEmail: {
        action: 'searchStudentEmail',
        properties: LscEvents.studentsSearchProperties,
      },
      inviteGuardians: {
        action: 'inviteGuardians',
        properties: LscEvents.studentsGuardianProperties
      },
      emailGuardians: {
        action: 'emailGuardians',
        properties: LscEvents.studentsGuardianProperties
      },
      removeGuardians: {
        action: 'removeGuardians',
        properties: LscEvents.studentsGuardianProperties
      },
      emailStudent: {
        action: 'emailStudent',
        properties: LscEvents.studentsActionProperties
      },
      refreshStudent: {
        action: 'refreshStudent',
        properties: LscEvents.studentsActionProperties
      },
      openClassworkSummary: {
        action: 'openClassworkSummary',
        properties: LscEvents.studentsActionProperties
      },
      openStudentTeachers: {
        action: 'openStudentTeachers',
        properties: LscEvents.studentTeachersProperties
      },
      emailSelectedTeachers: {
        action: 'emailSelectedTeachers',
        properties: LscEvents.studentTeachersProperties
      },
      openClassworkViewForTeacher: {
        action: 'openClassworkViewForTeacher',
        properties: LscEvents.studentTeachersProperties
      },
      openClassroomViewForTeacher: {
        action: 'openClassroomViewForTeacher',
        properties: LscEvents.studentTeachersProperties
      },
      openClassroomViewForStudent: {
        action: 'openClassroomViewForStudent',
        properties: LscEvents.studentClassroomProperties
      },
      removeStudentFromClassrooms: {
        action: 'removeStudentFromClassrooms',
        properties: LscEvents.studentClassroomProperties
      },
      openClassroomSummary: {
        action: 'openClassroomSummary',
        properties: LscEvents.studentClassroomProperties
      },
      ...LscEvents.createWizardEvents(
          {[ADD_TO_COLLECTION_STEPPER]: ADD_TO_COLLECTION_STEPPER_STEPS},
          LscEvents.studentCollectionProperties,
          [
            {actionKey:'addToExistingCollection', actionValue: 'addToExistingCollection'},
            {actionKey:'createNewCollection', actionValue: 'createNewCollection'},
          ]
        ),
    },
    insights: {
      teacherAnalysisExportToGoogleSheets: {
        action: 'teacherAnalysisExportToGoogleSheets',
        properties: LscEvents.insightsExportProperties
      },
      viewMaterials: {
        action: 'viewMaterials',
        properties: LscEvents.insightsViewMaterialsProperties
      },
      ...LscEvents.createWizardEvents(
        {[JOIN_CLASS_DIALOG]:JOIN_CLASS_DIALOG_STEPPER_STEPS},
        LscEvents.insightsJoinClassProperties,
        [{actionKey: 'launchJoinClassDialog', actionValue: 'launch'}]
      )
    },
    administration: {
      accessedUserAccounts: {
        action: 'accessedUserAccounts',
        properties: LscEvents.administrationUserProperties
      },
      accessedUserRoles: {
        action: 'accessedUserRoles',
        properties: LscEvents.administrationRolesProperties
      },
      accessedSchoolTags: {
        action: 'accessedSchoolTags',
        properties: LscEvents.administrationSchoolTagsProperties
      },
      accessedTimeframes: {
        action: 'accessedTimeframes',
        properties: LscEvents.administrationTimeframesProperties
      }
    },
    support: {
      // LSC-1938 this is a hack to circumvent
      // what appears to be a circular dependency
      // these analytics events are accessed at the component level
      // and kept here for record keeping
      loadedCustomSupportMessage,
      loadedDefaultSupportMessage,
    }
  };
}



