import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { AppSidebarService } from '../../../../../@amplabs/components/sidebar/sidebar.service';
import { Message, MessagesService } from '../../../../core/services/messages/messages.service';
import { ExportsService } from '../../../exports/services/exports.service';
import { TaskService } from '../../services/task.service';

@Component({
  selector: 'app-job-badge',
  templateUrl: './job-badge.component.html',
  styleUrls: ['./job-badge.component.scss'],
})

export class JobBadgeComponent implements OnInit, OnDestroy {

  @Output() emitRemove: EventEmitter<number> = new EventEmitter<number>();

  jobs = [];
  jobCount = 0;
  bulkTasksCount = 0; // TODO: Ger - Combine the count with jobCont? No need to maintain separate counts
  messageCount = 0;
  exportCount = 0;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _appSidebarService: AppSidebarService,
    private _taskSvc: TaskService,
    private _msgSvc: MessagesService,
    private _exportSvc: ExportsService) {
  }

  ngOnInit() {

    this._taskSvc.getStatus().pipe(takeUntil(this.onDestroy$)).subscribe(jobs => {

      this.jobs = jobs?.jobs || [];

      this.jobCount = this.jobs?.length;

      if (jobs?.metadata && jobs?.metadata?.status === 'active') {
        this.jobCount++;
      }

      if (jobs?.users && jobs?.users?.status === 'active') {
        this.jobCount++;
      }

      if (jobs?.classrooms && jobs?.classrooms?.status === 'active') {
        this.jobCount++;
      }

    });

    this._taskSvc.getUser().pipe( // XXX: Ger - Combine the archive and delete tasks with the overall tasks count
      takeUntil(this.onDestroy$),
      filter(tasks => tasks && tasks.archive),
      map(tasks => {
        // if (tasks.archive.status === 'cancelled' || tasks.archive.status === 'complete') {
        if (tasks.archive.isActive) {
          return 1;
        } else {
          return 0;
        }
      })
    ).subscribe((bulkTasksCount: number) => {
      this.bulkTasksCount = bulkTasksCount;
    });


    this._msgSvc.getMessages()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((messages: Message[]) => {
        this.messageCount = messages.length;
      });

    this._exportSvc.getUserExportJobs().pipe(
      takeUntil(this.onDestroy$),
      switchMap(exports => combineLatest(exports.map((exportJob => exportJob.getJob()))))
    ).subscribe(exports => {
      this.exportCount = exports.reduce((count, job) => {
        if (!['FINISHED', 'ERROR', 'CANCELLED'].includes(job.status)) {
          count++;
        }
        return count;
      }, 0);
    });
  }

  incrementCount(): void {
    this.exportCount++;
  }

  toggleSidebarOpen(key): void {
    this._appSidebarService.getSidebar(key).toggleOpen();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
