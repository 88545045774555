import 'quill';

export interface Config {
  container: string;
  icon: 'tag';
  insertString: string;
}

export interface QuillInstance {
  on: any;
  getText: any;
}

export default class InsertToken {

  quill: QuillInstance;
  options: Config;
  disabled: false;
  listeners: any[];

  constructor(quill, options) {

    this.quill = quill;

    this.options = options;

    this.disabled = this.options[1] || false;

    this.listeners = [];

    const container = document.getElementsByClassName('ql-toolbar');


    // @ts-ignore
    Array.from(container).forEach(instance => {

      const l = instance.addEventListener('click', (ev) => {

        const tokens = this.options[0];
        // @ts-ignore
        const lookUp = tokens.filter(token =>
          // @ts-ignore
           token.icon === ev.target.innerHTML
        );

        if (lookUp.length) {
          const selection = quill.getSelection();
          if (selection) {
            const insertPoint = selection;
            quill.insertText(insertPoint, lookUp[0].insertString);
          }
        }

      });

      this.listeners.push(l);

    });

  }

}
