import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {AuthService} from '../../../modules/auth/services/Auth/auth.service';


interface MessageInterface {
  title?: string;
  message: {
    id: string;
    critical: boolean;
    text: string;
    link: string;
    icon: string;
    logout: boolean;
  };
}


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})


export class MessageComponent implements OnInit {
  title: string;
  message: {
    id: string;
    critical: boolean;
    text: string;
    link: string;
    icon: string;
    logout: boolean;
  };

  constructor(private router: Router,
              public dialogRef: MatDialogRef<MessageComponent>,
              private _authSvc: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: MessageInterface) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  goTo(path: string) {
    this.router.navigate([path]);
    this.dialogRef.close();
  }

  logout() {
    this._authSvc.signOut();
    this.dialogRef.close();
  }

}
