import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { CustomerFirestore, CustomerSettings, FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import { UtilService } from '../../../../../services/util.service';
import { CourseworkCourseAndTeacher } from '../../../coursework.interface';
import { JOIN_CLASS_DIALOG, JOIN_CLASS_DIALOG_STEPPER_STEPS } from './join-class-dialog.constant';

export interface JoinClassDialogData {
  classData: CourseworkCourseAndTeacher[];
  userPermissions: {
    joinClassAsCoTeacher: boolean;
    joinClassAsStudent: boolean;
    notificationOptional: boolean;
  };
}

export interface ClassJoinData {
  courses: CourseworkCourseAndTeacher[];
  joinAs: string;
  expiry?: number;
  expiresIn: number; // Time until expiry in hours
  expiresAt: number; // Expiry time as Epoch time
  sendMessage: boolean;
  message: string;
}

interface Time {
  value: string;
  label: string;
}


@Component({
  selector: 'app-join-class-dialog',
  templateUrl: './join-class-dialog.component.html',
  styleUrls: ['./join-class-dialog.component.scss']
})
export class JoinClassDialogComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  private onDestroy$: Subject<void> = new Subject<void>();

  endDate; // Used for the date picker, but it would be better if I could use the form value instead of declaring it on the component scope (it's already in the form value)
  endTime; // Used for the date picker, but it would be better if I could use the form value instead of declaring it on the component scope (it's already in the form value)

  joinData: ClassJoinData = {
    courses: [],
    joinAs: '',
    expiry: null,
    expiresIn: null,
    expiresAt: null,
    sendMessage: true,
    message: ''
  };

  stepperState = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false
  };

  accessTimes: Time[] = [
    {value: '1', label: 'For 1 hour'},
    {value: '24', label: 'For 1 Day'},
    {value: 'until', label: 'Until'},
    {value: '0', label: 'Do not remove'}
  ];

  initialState: {
    joinAs: string;
    timeLimit: string;
    sendNotification: boolean;
  };
  notificationMaxLength = 255;
  requireNotification = true;

  constructor(
    private dialogRef: MatDialogRef<JoinClassDialogData>,
    private utilService: UtilService,
    protected _firestoreCustomerService: FirestoreCustomerService,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: JoinClassDialogData
  ) {

    this._firestoreCustomerService.getCustomer()
      .pipe(
        takeUntil(this.onDestroy$),
        map((customer: CustomerFirestore) => {
          const settings = Object.assign({
            forceTeacherNotification: false
          }, customer.settings);
          return settings;
        })
      )
      .subscribe((settings: CustomerSettings) => {
        this.requireNotification = settings.forceTeacherNotification || false;
      });
  }

  ngOnInit() {
    this.initialState = {
      joinAs: 'coTeacher',
      timeLimit: this.accessTimes[0].value,
      sendNotification: true
    };
  }

  onStepperChange(stepperSelectionEvent: StepperSelectionEvent) {}

  setClassSelection(selectionValue: boolean, formData: NgForm): void {
    for (const field of Object.keys(formData.controls)) {
      if (!formData.controls[field].disabled) {
        formData.controls[field].setValue(selectionValue);
      }
    }
  }

  toggleClassSelection(event: MouseEvent, form: NgForm, courseData) {
    event.preventDefault();

    if (courseData.hasClassAccess) {
      return;
    }

    const formControlName = 'check-' + courseData.course.id;

    const isSelected = form.controls[formControlName].value;
    const newValue = !isSelected;

    form.controls[formControlName].setValue(newValue);
    return newValue;
  }


  checkSelected(formData: NgForm): boolean {
    let allSelected = true;
    for (const field of Object.keys(formData.controls)) {
      if (!formData.controls[field].disabled && (field.indexOf('check') === 0) && formData.controls[field].value !== true) {
        allSelected = false;
      }
    }
    return allSelected;
  }

  toggleAll(form: NgForm): void {
    const allSelected = this.checkSelected(form);
    if (!allSelected) {
      this.setClassSelection(true, form);
    } else {
      this.setClassSelection(false, form);
    }
  }

  getSelectAllTooltip(form: NgForm): string {
    const allSelected = this.checkSelected(form);
    return (allSelected) ? 'Deselect all' : 'Select all';
  }

  reviewCheckedAllStatus(form: NgForm): boolean {
    const allSelected = this.checkSelected(form);
    form.controls.selectAll.setValue(allSelected);
    return allSelected;
  }

  setClasses(formData: NgForm): void {
    this.joinData.courses = [];

    for (const checkbox of Object.keys(formData.value)) {
      if (formData.value[checkbox] && (checkbox.indexOf('check') === 0)) {
        const checkboxId = checkbox.split('-')[1];
        const classData = this.data.classData.find(course => course.course.id === checkboxId);
        this.joinData.courses.push(classData);
      }
    }

    this.stepperState.step1 = true;
    setTimeout(() => {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.insights[JOIN_CLASS_DIALOG][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
      this.stepper.next();
    }, 0);

  }

  setJoinType(formData: NgForm): void {
    this.joinData.joinAs = formData.value.joinAs;

    this.stepperState.step2 = true;
    setTimeout(() => {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.insights[JOIN_CLASS_DIALOG][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
      this.stepper.next();
    },0);
  }


  confirmJoinType(): string {
    let joinType: string;
    if (this.data.userPermissions.joinClassAsCoTeacher) {
      joinType = 'coTeacher';
    } else if (this.data.userPermissions.joinClassAsStudent) {
      joinType = 'student';
    }
    this.joinData.joinAs = joinType;

    this.stepperState.step2 = true;
    setTimeout(() => {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.insights[JOIN_CLASS_DIALOG][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
      this.stepper.next();
    },0);

    return joinType;
  }


  limitAccessTime(formData: NgForm): void {

    switch (formData.value.timeLimit) {

      case '1':
        this.joinData.expiresIn = 1;
        this.joinData.expiry = parseInt(moment.utc(new Date()).add(1, 'hour').format('x'), 10);
        break;
      case '24':
        this.joinData.expiresIn = 24;
        this.joinData.expiry = parseInt(moment.utc(new Date()).add(24, 'hour').format('x'), 10);
        break;
      case 'until':
        // const timeSegments = formData.value.endTime.split(' ')[0].split(':');
        const timeSegments = formData.value.endTime.split(' ');
        const hoursAndMinutes = timeSegments[0].split(':');
        const amPm = timeSegments[1];
        // this.joinData.expiry = parseInt(moment.utc({
        this.joinData.expiresAt = parseInt(moment({
          year: formData.value.endDate.get('year'),
          month: formData.value.endDate.get('month'),
          day: formData.value.endDate.get('date'),
          hour: amPm === 'am' ? parseInt(hoursAndMinutes[0], 10) : parseInt(hoursAndMinutes[0], 10) + 12,
          minute: parseInt(hoursAndMinutes[1], 10),
          seconds: 0
        }).format('x'), 10);
        this.joinData.expiry = this.joinData.expiresAt;
        break;
      case '0':
        break;
      default:
    }

    this.stepperState.step3 = true;
    setTimeout(() => {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.insights[JOIN_CLASS_DIALOG][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
      this.stepper.next();
    }, 0);
  }

  setEmail(formData: NgForm): void {
    this.joinData.sendMessage = formData.value.sendMessage;
    this.joinData.message = formData.value.message;

    this.stepperState.step4 = true;
    setTimeout(() => {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.insights[JOIN_CLASS_DIALOG][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
      this.stepper.next();
    },0);
  }

  onJoinClass(): void {
    const rtn = _.clone(this.joinData);
    rtn.sendMessage = this.initialState.sendNotification;
    this.dialogRef.close(rtn);
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.insights[JOIN_CLASS_DIALOG][JOIN_CLASS_DIALOG_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);
  }

  selectedClassCount(formData: NgForm): number {
    return Object.values(formData.value).filter(value => value === true).length;
  }

  getChipTextColor(hex: string): string {
    return this.utilService.isColorDark(hex) ? 'white' : '1a1a1a';
  }

  getDefaultPhotoUrl(): string {
    return UtilService.defaultPhotoUrl;
  }


  goBack(): number {
    this.stepper.previous();
    return this.stepper.selectedIndex;
  }

  close(): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.insights[JOIN_CLASS_DIALOG][this.stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}
