import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ProductTermsComponent} from './components/terms/terms.component';
import {ProductPrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';
import {ProductTermAcceptanceComponent} from './components/term-acceptance/term-acceptance.component';

const productRoutes: Routes = [
  {path: 'terms', component: ProductTermsComponent},
  {path: 'privacy', component: ProductPrivacyPolicyComponent},
  {path: 'accept-terms', component: ProductTermAcceptanceComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(productRoutes)
  ],
  exports: [RouterModule]
})

export class ProductRoutingModule {}
