import { IGetRowsParams, IServerSideGetRowsParams } from '@ag-grid-community/core';

import { Timeframe } from '../../../../services/timeframes.service';
import { ColumnSettings } from '../../services/grid-state.service';

export interface Columns {
  [name: string]: ColumnSettings;
}

export interface LSIGetRowsParams extends IGetRowsParams {
  refreshWithCurrentData: boolean;
}

export interface LSIServerSideGetRowsParams extends IServerSideGetRowsParams {
  refreshWithCurrentData: boolean;
}

export interface GridTimeframe extends Timeframe {
  strId: string;
}

export enum BulkClassesActions {
  archive = 'archive',
  delete = 'delete',
  clear = 'clear',
}
