import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import {Subject} from 'rxjs';
import {
  filter,
  takeUntil,
} from 'rxjs/operators';

import {AppNavigationService} from '@amplabs/components/navigation/navigation.service';
import {AppSidebarService} from '@amplabs/components/sidebar/sidebar.service';
import {AppConfigService} from '@amplabs/services/config.service';

@Component({
    selector     : 'navbar-horizontal-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarHorizontalStyle1Component implements OnInit, OnDestroy
{
    appConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {AppConfigService} _AppConfigService
     * @param {AppNavigationService} _appNavigationService
     * @param {AppSidebarService} _appSidebarService
     */
    constructor(
        private _AppConfigService: AppConfigService,
        private _appNavigationService: AppNavigationService,
        private _appSidebarService: AppSidebarService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get current navigation
        this._appNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._appNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._AppConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.appConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
