import { NgModule} from '@angular/core';
import {MaterialModule} from '../../core/services/material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CoModule} from '../common/co.module';
import {ProductRoutingModule} from './product-routing.module';
import {LayoutModule} from '../../layout/layout.module';
import {ProductPrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';
import {ProductTermsComponent} from './components/terms/terms.component';

@NgModule({
  imports: [
    LayoutModule,
    MaterialModule,
    FlexLayoutModule,
    CoModule,
    ProductRoutingModule
  ],
  declarations: [
    ProductPrivacyPolicyComponent,
    ProductTermsComponent
  ],
  // entryComponents: [],
  providers: []

})

export class ProductModule { }
