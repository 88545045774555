import { IToolPanel, IToolPanelParams } from '@ag-grid-community/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';

import { ConfirmDialogComponent } from '../../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { LscEvents } from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { UserService } from '../../../../../../services/user.service';
import { AuthService, User } from '../../../../../auth/services/Auth/auth.service';
import { GridSettings } from '../../../../services/grid-state.service';
import { SaveGridSettingsDialogComponent } from '../../../dialogs/save-grid-settings/save-grid-settings';
import { AccessFiltersService } from './access-filters.service';

// TODO: Move this out to a shared file
enum FilterTypes {
  'all' = 'All',
  'core' = 'Core',
  'my' = 'My',
  'shared' = 'Shared',
}

@Component({
  selector: 'access-filters-tool-panel',
  templateUrl: './access-filters-tool-panel.component.html',
  styleUrls: ['./access-filters-tool-panel.component.scss'],
})
export class AccessFiltersToolPanel implements IToolPanel {

  @Input() panelType = 'filter';
  accessFilters = [];
  activeFilter;
  originalFilter;
  busy: boolean;
  filter: FilterTypes = FilterTypes.all;
  currentUser: User;
  appFilters;
  isSuperAdmin: boolean;
  filterApplied: boolean; // Do we need this?
  debug: boolean;

  private api;

  constructor(
    private _userSvc: UserService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _accessFilterSvc: AccessFiltersService,
    private _authSvc: AuthService,
    private _firebaseAnalytics: FirebaseAnalyticsService,
  ) {

    this.debug = false;

    this.currentUser = this._authSvc.getCurrentUser();

    this.isSuperAdmin = this._authSvc.isSuperAdmin();

    this.filterApplied = false;

    this.appFilters = [
      {
        key: 'activeClasses',
        label: 'Active classes',
        settings: {sort:[],filters:{courseState:{type:'equals',values:['ACTIVE'],filterType:'set'}}}
      },
      {
        key: 'emptyClasses',
        label: 'Empty classes',
        settings: {sort:[],filters:{studentCount:{type:'equals',filter:'0',filterType:'number'}}}
      },
      {
        key: 'myClasses',
        label: 'My Classes',
        settings: {sort:[],filters:{'coteachers.primaryEmail':{type:'equals',filter: this.currentUser.profile.primaryEmail, filterType:'text'}}}
      },
      {
        key: 'hasCoTeachers',
        label: 'Has co-teachers',
        settings: {sort:[],filters:{teacherCount:{type:'greaterThan',filter:'1',filterType:'number'}}}
      },
      {
        key: 'guardiansEnabled',
        label: 'Guardians enabled',
        settings: {sort:[],filters:{guardiansEnabled:{type:'equals',values:['1'],filterType:'set'}}}
      },
    ];

    this._accessFilterSvc.getActiveAccessFilter().subscribe((filter) => {
      if (filter === null) {
        this.filterApplied = false;
        this.activeFilter = null;
      } else {
        this.activeFilter = filter.key || filter;
        this.filterApplied = true;
      }
    });

  }

  agInit(params: IToolPanelParams): void {

    this.api = params.api;

    this.busy = true;

    this.getAccessFilters();

  }

  refresh() {
    return true;
  }

  loadGridSettings($event: Event, index: number | string): void {

    this.busy = true;
    const target = $event.target as HTMLElement;

    if (typeof index === 'number' && target.childNodes[0] && target.childNodes[0].nodeValue === 'delete') {
      const item = this.accessFilters[index];

      // TODO: Check to see impact of removing this access filter. Is it being used to constrain views?

      const dialogData = {
        message: `Are you sure that you want to delete the saved ${this.panelType} "${item?.label}"?${
          item?.shared ? ' It is currently shared.' : ''
        }`,
        okBtnLabel: 'Confirm',
        cancelBtnLabel: 'Cancel'
      };

      const dialogRef = this._dialog.open(ConfirmDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: dialogData,
        width: '360px',
        height: '220px'
      });

      dialogRef.afterClosed().subscribe(async result => {

        this.busy = false;

        if (result) {
          return this.deleteAccessFilter(index);
        }

      });

      return;

    }

    let collection;

    if (this.activeFilter !== null) {
      collection = (typeof this.activeFilter === 'string') ? 'appFilters' : 'accessFilters';

      // If clicked on item is the same as current active then clear
      if (
        (typeof this.activeFilter === 'string' && this.activeFilter === index)
        || (typeof this.activeFilter !== 'string' && this[collection][index] && this.activeFilter.id === this[collection][index].id)
      ) {
        this.busy = false;
        this.clearFilter();
        this.filterApplied = false;
        return;
      }
    }

    if (typeof index === 'string') {
      const state = _.find(this.appFilters, { key: index });

      if (this.activeFilter === null) {
        this.originalFilter = _.cloneDeep(state);
      } else if (this.activeFilter !== index) {
        this[collection][this.activeFilter] =  _.cloneDeep(this.originalFilter);
        this.originalFilter =  _.cloneDeep(state);
      }

      this._accessFilterSvc.setActiveAccessFilter(this.originalFilter);
      this.filterApplied = true;
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.switchedToPredefinedFilter as AnalyticsEvent);
      return this.applyGridSettings(this.originalFilter.settings);
    } else {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.switchedToUserDefinedFilter as AnalyticsEvent);

    }

    if (this.activeFilter === null) {
      this.originalFilter = _.cloneDeep(this.accessFilters[index]);
    } else if (this.activeFilter !== index) {
      this[collection][this.activeFilter] =  _.cloneDeep(this.originalFilter);
      this.originalFilter =  _.cloneDeep(this.accessFilters[index]);
    }

    this._accessFilterSvc.setActiveAccessFilter(this.originalFilter);
    this.filterApplied = true;

    this.applyGridSettings(this.originalFilter.settings);
  }

  saveFilter() {

    this.busy = true;

    const filter = _.find(this.accessFilters, { id: this.activeFilter.id });

    filter.settings = {
      filters: this.api.getFilterModel()
    };

    const toSave = filter;

    this._userSvc.updateAccessFilters(toSave).subscribe( results => {

      this.busy = false;

      if (results) {

        this.accessFilters[this.activeFilter] =  _.cloneDeep(results.filter);
        this.originalFilter =  _.cloneDeep(this.accessFilters[this.activeFilter]);

        this._snackBar.open(results.message, 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 10000,
        });
      }

    });

  }

  disableSaveFilter() {
    return this.activeFilter === null || !this.createdByCurrentUser(this.activeFilter.user_id) || !this.stateUpdated();
  }

  shareFilter() {

    this.busy = true;

    const filter = _.find(this.accessFilters, { id: this.activeFilter.id });

    filter.shared = !filter.shared;

    this._userSvc.updateAccessFilters(filter).subscribe( results => {

      this.busy = false;

      if (results) {

        this.accessFilters[this.activeFilter] =  _.cloneDeep(results.filter);
        this.originalFilter =  _.cloneDeep(this.accessFilters[this.activeFilter]);

        this._snackBar.open(results.message, 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 10000,
        });
      }

    });

  }

  disableShareFilter() {
    return this.activeFilter === null || !this.createdByCurrentUser(this.activeFilter.user_id);
  }

  isFilterAlreadyShared() {
    return this.activeFilter?.shared || false;
  }

  isStateActive(stateIndex: number, state: any) {
    return this.activeFilter?.id === state.id;
  }

  createdByCurrentUser(userId: number) {
    return this.currentUser?.profile?.id === userId;
  }

  saveAccessFilterAs() {

    const state = {
      filters: this.api.getFilterModel()
    };

    const confirmRef = this._dialog.open( SaveGridSettingsDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: state,
      height: '320px'
    });

    confirmRef.afterClosed().subscribe(gridStateChanges => {

      if (gridStateChanges !== 'cancelled') {

        this.busy = true;

        this._userSvc.updateAccessFilters(gridStateChanges).subscribe( results => {

          this.busy = false;

          if (results) {
            // @ts-ignore
            this.accessFilters.push(results.filter);

            // Reset the original
            this.accessFilters[this.activeFilter] =  _.cloneDeep(this.originalFilter);

            this._accessFilterSvc.setActiveAccessFilter(results.filter);
            this.originalFilter =  _.cloneDeep(results.filter);

            this._snackBar.open(results.message, 'close', {
              verticalPosition: 'top',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }
        });

      }

    });

  }

  clearFilter() {
    this.api.setFilterModel(null);
    // this.api.onFilterChanged();
    this._accessFilterSvc.setActiveAccessFilter(null);

    this._snackBar.open('All filters cleared', null, {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 3000,
    });

  }

  printState() {

    const filterState = this.api.getFilterModel();

    console.log('***********************');
    console.log('filterState: ', filterState);
    console.log('***********************');
  }

  updateFilter(filterState?: string) {
    if (filterState && FilterTypes[filterState]) {
      this.filter = FilterTypes[filterState];
    } else {
      this.filter = FilterTypes.all;
    }
  }

  getFilteredSavedStates() {
    if (this.filter === 'Core') {
      return [];
    }
    if (this.filter === 'My') {
      return this.accessFilters.filter(state => this.createdByCurrentUser(state.user_id));
    }
    if (this.filter === 'Shared') {
      return this.accessFilters.filter(state => state.shared);
    }
    return this.accessFilters;
  }

  private stateUpdated() {
    const filter = _.find(this.accessFilters, { id: this.activeFilter.id });
    const currentState = {
      filters: this.api.getFilterModel()
    };

    return !_.isEqual(filter.settings, currentState);
  }

  private getAccessFilters() {

    this._userSvc.getUserFilters().subscribe(results => {

      this.busy = false;

      if (results.data.accessFilters.length) {
        this.accessFilters = results.data.accessFilters;
      }

    });
  }

  private applyGridSettings(gridSettings: GridSettings): void {
    const toApply = _.cloneDeep(gridSettings);

    this.api.setFilterModel(toApply.filters);
    this.api.onFilterChanged();

    this._snackBar.open('Filter applied', 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 7000,
    });

    setTimeout(() => {
      this.busy = false;
    }, 200);
  }

  private async deleteAccessFilter(index: number) {

    const gridFilterId = this.accessFilters[index].id;

    this.busy = true;

    await this._userSvc.deleteAccessFilters(gridFilterId).subscribe( results => {

      this.busy = false;

      if (results) {

        this.accessFilters.splice(index, 1);

        this._snackBar.open(results.message, 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 10000,
        });
      }
    });

  }

}
