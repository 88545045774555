import {
  Coursework,
  CourseworkApiResponse,
} from '../coursework.interface';

export const convertCourseworkFromApiToCoursework = (courseworkItem: CourseworkApiResponse): Coursework => {
  const coursework: Coursework = {
    ...courseworkItem,
    due: null,
    creationTime: new Date(courseworkItem.creationTime),
    updateTime: new Date(courseworkItem.updateTime),
    materials: courseworkItem.materials,
  };

  // TODO: Ger - Error handling for the following if block
  if (courseworkItem.dueDate) {
    const dueDate = JSON.parse(courseworkItem.dueDate);
    coursework.due = new Date(0);
    coursework.due.setFullYear(dueDate.year);
    coursework.due.setMonth(dueDate.month - 1);
    coursework.due.setDate(dueDate.day);
    if (courseworkItem.dueTime) {
      const dueTime = JSON.parse(courseworkItem.dueTime);
      coursework.due.setHours(dueTime.hours);
      coursework.due.setHours(dueTime.minutes);
      coursework.due.setHours(dueTime.seconds);
    }
  }
  return coursework;
};
