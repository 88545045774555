import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { BulkJob } from '../../bulk-job.interface';

@Component({
  selector: 'app-bulk-task-progress-item',
  templateUrl: './bulk-task-progress-item.component.html',
  styleUrls: ['bulk-task-progress-item.component.scss']
})
export class BulkTaskProgressItemComponent implements OnInit {
  isExpanded = false;
  @Output() cancel: EventEmitter<string> = new EventEmitter();
  @Output() restart: EventEmitter<string> = new EventEmitter();
  @Input() job: BulkJob;
  secondsSinceStart = 0;
  runtimeInterval: number;

  constructor() {}

  ngOnInit() {
    this.runtimeInterval = window.setInterval(() => {
      const now = new Date();
      const nowInSeconds = Math.floor(now.getTime() / 1000);

      if (this.job.start) {
        this.secondsSinceStart = nowInSeconds - this.job.start.seconds;
      }

      if (this.isJobFinished()) {
        clearInterval(this.runtimeInterval);
      }
    }, 1000);
  }

  toPercent(value, total): number {
    return Math.ceil(value / total * 100);
  }

  getFailureCount() {
    if (this.job.failures) {
      return this.job.failures.length;
    } else {
      return 0;
    }
  }

  getActionButtonText(status: string): string {
    const statusActionMap = {
      active: 'Cancel',
      cancelling: 'Cancelling',
      cancelled: 'Restart'
    };

    return statusActionMap[status];
  }

  onToggle(): boolean {
    this.isExpanded = !this.isExpanded;
    return this.isExpanded;
  }

  onActionButtonClicked(status: string) {
    if (status === 'active') {
      this.cancel.emit(this.job.method);
    }
    // LSC-1897 - Removing restart until we update tasks UI
    // else if (status === 'cancelled') {
    //   this.restart.emit(this.job.method);
    // }
  }

  isJobFinished(): boolean {
    // Determining the job completion status is not very straightforward as different jobs have slightly different properties.
    // It would be better to just use the finished property more effectively which would negate the need to use this function.
    // Until then, I've refactored the completed logic here to re-use through the component.
    if (this.job.end || this.job.status === 'cancelled' || this.job.complete) {
      return true;
    } else {
      return false;
    }
  }
}
