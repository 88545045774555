import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../Auth/auth.service';
import {LittlesisLicensingService} from '../../../licensing/services/littlesis-licensing.service';

@Injectable({ providedIn: 'root' })
export class PremiumAccessResolver implements Resolve<boolean> {
  constructor(
    private authService: AuthService,
    private licenseService: LittlesisLicensingService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.licenseService.isPremium() &&
      (this.authService.hasPermission('sync_admin') ||
       this.authService.hasPermission('sync_agent_info'));
  }
}
