
import {map,  shareReplay, take } from 'rxjs/operators';
import {Injectable, Optional} from '@angular/core';

import {environment} from '../../environments/environment';

import * as observable from 'rxjs';
import { BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import * as _ from 'lodash';

import {ApiResponse} from './util.service';
import {ListResponse} from '../core/models/api.interface';

import {LocalStorageService} from '../core/services/LocalStorage/local-storage.service';
import { AuthService } from 'app/modules/auth/services/Auth/auth.service';


export interface School {

  /**
   * Unique id for the school
   */
  id?: number;

  /**
   * Name for the school
   */
  name?: string;

  /**
   * School's 5 character code
   */
  shortCode?: string;

  /**
   * Organisational Unit root for students in this school
   */
  studentOU?: string;

  /**
   * Total number of students associated with the school
   */
  studentCount?: number;

  /**
   * Students can either be identified by their org unit or their membership of a group.
   */
  studentIdentifiedBy?: 'orgUnit' | 'group';

  /**
   * Group whose members are students associated with this school
   */
  studentGroupEmail?: string;

  /**
   * Is the studentOU set to allow for syncing daily
   */
  syncStudents?: boolean;


  lastSyncStudents?: Date;

  /**
   * Organisational Unit root for teachers in this school
   */
  teacherOU?: string;

  // /**
  //  * Text description for the access group
  //  */
  // description?: string;

  /**
   * Customer Id for the Customer associated with the school
   */
  customer_id?: string;

  /**
   * Hex color to be used when rendering the tag
   */
  hex: string;

  /**
   * The number of teachers associated with the school
   */
  teachersCount?: number;

  /**
   * The number of classrooms associated with the school
   */
  classroomCount?: number;

  /**
   * The group email address on the customer's Google domain that should be synced with the current academic year
   */
  groupEmail?: string;


  /**
   * Teachers can either be identified by their org unit or their membership of a group.
   */
  teacherIdentifiedBy?: 'orgUnit' | 'group' | 'static';

  lastSync?: Date;

  sync: boolean;

  totalClassroomCount?: number;
}

export interface GridSchool extends School {
  strId: string;
}

export interface SchoolDialogData {

  id?: number;
  name: string;
  studentOU: string;
  hex: string;
  customer_id?: string;

}

interface ApiOptions {
  useCache: boolean;
}

interface Schools {
  [id: string]: School;
}

@Injectable()
export class SchoolService {
  // XXX: Ger - Refactoring tasks:
  //  Use observables more consistently (we're writing to a variable and updating that from an observable to push onto other observables)
  //  Don't use behavior subjects if no initial value
  //  Consistent types (places where school$ emits [] and {})
  private active = new BehaviorSubject<School>(null);

  // private userSchools$ = new ReplaySubject<Schools>();

  schools: Schools; // XXX: Ger - This is not necessary. I'll remove it during the refactor.

  // school$ = new BehaviorSubject<Schools>({});
  school$ = new BehaviorSubject({}); // XXX: Ger - Use the line above for proper type (but commented out to get it working for demo with PL)

  observable$: { // NOTE - This observable$ variable isn't actually a variable so should be renamed and called something more descriptive. It is actually a record of all the requests to get individual schools so we can cancel and resend them if needed, and does not duplicate the functionality of schools
    schools: {
      [id: string]: Observable<School>;
    };
  };

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;

  cacheKey = 'school$';


  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private _localStorage: LocalStorageService
  ) {

    const stored = this._localStorage.get(this.cacheKey);

    if (stored) {
      this.schools = JSON.parse(stored);
      // this.school$ = new BehaviorSubject(this.schools);
      this.school$.next(this.schools);
    } else {
      this.schools = {};
      // this.school$ = new BehaviorSubject({});
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.observable$ = {
      schools: {}
    };

    this.init();
  }

  init() {
    // this._getSchoolsFromServer('useraccess').subscribe(
    //   (schools) => this.userSchools$.next(schools)
    // );

    this._getSchoolsFromServer('none').subscribe(
      (data) => {
        this._localStorage.set(this.cacheKey, JSON.stringify(data));
        this.school$.next(data);
      }
    );
  }

  _getSchoolsFromServer(accessRestrictions: 'useraccess' |'none') {
    const url = this.serviceUrls.schools.route;
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams().set('restrict', accessRestrictions)
    };

    return this.http.get<ListResponse>(url, options)
    .pipe(
      map(response => {
        this.schools = response.data.reduce((list, s) => {
          list[s.id] = s;
          return list;
        }, {});
        return this.schools;
      })
    );
  }

  compactFilter(filterObj) {
    return {
      c: filterObj.field,
      f: {
        type: 'contains',
        filterType: 'text',
        filter: filterObj.value
      }
    };
  }

  compactAGFilter(filterObj, field) {
    return {
      c: field,
      f: filterObj
    };
  }


  orderComparision(a, b) {
    let comparison = 0;
    if (a.name > b.name) {
      comparison = 1;
    } else if (a.name < b.name) {
      comparison = -1;
    }
    return comparison;
  }

  getActive(): Observable<School> {
    return this.active.asObservable();
  }

  setActive(id) {
    const school = this.schools[id];
    if (school) {
      this.active.next(school);
    } else {
      this.active.next(null);
    }
  }

  getAll(): Observable<Schools> {
    return this.school$.asObservable();
  }

  getAccessRestrictedSchools(): Observable<Schools> {
    return this.school$.asObservable()
    .pipe(
      map((schools: Schools) => {
        if (this.authService.hasAllSchoolAccess()) {
          return schools;
        }
        const permittedSchools = this.authService.getCurrentUser().auth.schools;

        return Object.keys(schools).reduce((schoolObject, thisSchool) => {
          if (permittedSchools.includes(parseInt(thisSchool, 10))) {
            schoolObject[thisSchool] = schools[thisSchool];
          }
          return schoolObject;
        }, {});
      })
    );
  }

  list(): Observable<School[]> {

    const stored = this._localStorage.get(this.cacheKey);

    if (stored) {
      this.schools = JSON.parse(stored);
      return observable.of(Object.values(this.schools).sort(this.orderComparision));
    }

    const url = this.serviceUrls.schools.route;

    return this.http.get<ListResponse>(url, this.httpOptions).pipe(
      map(response => {
        this.schools = response.data.reduce((list, s) => {
          list[s.id] = s;
          return list;
        }, {});
        return response.data;
      }));

  }

  find(options = {}, opts: ApiOptions = { useCache: true }): Observable<ListResponse> {

    let url = this.serviceUrls.schools.route;

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filterModel') {

          _.each(options[param], (filter, field) => {

            if (options[param][field].filterType === 'date') {
              url += `filter[]=${JSON.stringify(_this.compactAGFilter(options[param][field], field))}&`;
            } else {
              url += `filter[]=${JSON.stringify(_this.compactAGFilter(filter, field))}&`;
            }

          });

        } else if (param === 'filter') {

          _.each(options[param].filters, (f) => {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });


        } else if (param === 'sortModel') {

          if (options[param].length) {

            _.each(options[param], (srt) => {

              if (srt.sort) {
                url += `sortBy[]=${srt.colId}&`;
                url += `sortDir[]=${srt.sort}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }

      }

      url += opts.useCache ? `useCache=true` : `useCache=false`;

    }

    return this.http.get<ListResponse>(url, this.httpOptions).pipe(
      map(response => {
        const found = response.data.reduce((list, s) => {
          list[s.id] = s;
          return list;
        }, {});
        this.schools = {...this.schools, ...found};

        if (this.active.value) {
          this.setActive(this.active.value.id);
        }

        return response;
      }));
  }

  search(term: string = null): Observable<any[]> {

    let items = [
      {
        id: 12,
        name: 'Test',
        hex: '#000000'
      }];

    if (term) {
      items = items.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return observable.of(items);
  }

  get(id: string | number): Observable<School> {
    const school = this.schools[id];

    if (school) {
      // ensure that original observable is deleted
      if (this.observable$.schools[id]) {
        delete this.observable$.schools[id];
      }

      return observable.of(school);

    } else {

      // Is there a request in flight?

      if (!this.observable$.schools[id]) {
        const url = this.serviceUrls.schools.single.replace(':id', id.toString());

        this.observable$.schools[id] = this.http.get<School>(url, this.httpOptions).pipe(
          map(response => {

            this.schools[id] = response;
            return response;

          })).pipe(shareReplay(1));

      } else {
      }

      return this.observable$.schools[id];
    }
  }

  // single(id: string): School | boolean {
  single(id: string): School { // XXX: Ger - The dual types are causing issues so I'm trialing returning a value or undefined/null which should be easier to handle
    // return this.schools[id] || false;
    return this.schools[id] || null;
  }

  // create(opts: {school: School, teachers: string[]}): Observable<School> {
  //   const url = this.serviceUrls.schools.route;
  //
  //   const school = opts.school;
  //   delete school.totalClassroomCount;
  //
  //   const body = { school, teachers: opts.teachers };
  //   return this.http.post<School>(url, body, this.httpOptions);
  // }

  initialise(opts: {school: School}): Observable<School> {
    const url = this.serviceUrls.schools.route;
    const body = { school: opts.school };
    return this.http.post<School>(url, body, this.httpOptions).pipe(
      map(response => {
        this.schools[response.id] = response;
        return response;
      })).pipe(shareReplay(1));
  }

  update(opts: {school: School; teachers: string[]}): Observable<School> {
    const url = this.serviceUrls.schools.single.replace(':id', opts.school.id.toString());

    // Quick update hack...
    delete opts.school.totalClassroomCount;

    const body = { school: opts.school, teachers: opts.teachers };

    return this.http.put<School>(url, body, this.httpOptions);
  }

  delete(id: string): Observable<void> {
    const url = this.serviceUrls.schools.single.replace(':id', id);
    return this.http.delete<void>(url, this.httpOptions);
  }

  apply(opts): Observable<ApiResponse> {
    const url = this.serviceUrls.schools.apply;
    return this.http.post(url, opts, this.httpOptions);
  }

  refresh(opts): void {
    // const url = this.serviceUrls.schools.single.replace(':id', opts.school.id.toString());
    //
    // this.http.get<School>(url, this.httpOptions).pipe(
    //   map(response => {
    //     this.schools[opts.school.id] = response;
    //     this.setActive(opts.school.id);
    //   })).subscribe();
  }

  updateOU(schoolId: number, orgUnit: string): Observable<any> {
    const url = this.serviceUrls.schools.single.replace(':id', schoolId.toString()) + '/orgUnit';
    return this.http.put(url, {orgUnit});
  }

  schoolExists(schoolName: string) {
    const schools: School[] = Object.values(this.schools);
      const exists = schools.find((school) => school.name.toLowerCase() === schoolName.toLowerCase());
      if(exists) {
        return true;
      }
      return false;
  }
}
