import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {environment} from '../../../../../environments/environment';
import {versions} from '../../../../../environments/versions';
import {LocalStorageService} from '../../../../core/services/LocalStorage/local-storage.service';

@Component({
  selector: 'app-product-privacy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class ProductPrivacyPolicyComponent {

  supportEmail: string = environment.support.email;
  loggedInUser: string;

  versions: {
    version: string;
    branch: string;
    revision: string;
  };

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog,private localStorage: LocalStorageService) {
    this.loggedInUser = localStorage.get('user');
    this.versions = versions;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }


}
