import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { combineLatest } from 'rxjs';

import { School, SchoolService } from '../../../../services/schools.service';
import { StudentsService } from '../../../../services/students.service';
import { UtilService } from '../../../../services/util.service';
import { LocalStorageService } from '../../../../services/storage.service';


// This component shouldn't be specific to students and should be added to a shared module.
interface StudentConfigState {
  school: School;
  hasStudents: boolean;
}


@Component({
  selector: 'app-schools-student-config-state',
  templateUrl: './schools-student-config-state.component.html',
  styleUrls: ['./schools-student-config-state.component.scss']
})
export class SchoolsStudentConfigStateComponent implements OnInit {

  schools: StudentConfigState[] = [];
  countOfSchoolsWithoutStudents = 0;

  constructor(
    private router: Router,
    private schoolService: SchoolService,
    private studentsService: StudentsService,
    private utilService: UtilService,
    private _localstorageSvc: LocalStorageService,
  ) {}

  ngOnInit() {
    combineLatest(
      this.schoolService.list(),
      this.studentsService.countStudentsForUsersSchools()
    ).subscribe(
      ([schools, studentCountsPerSchool]) => {
        this.schools = studentCountsPerSchool.map(studentCountObject => {
          // const school = schools[studentCountObject.school_id];
          const school = schools.find(thisSchool => thisSchool.id === studentCountObject.school_id);
          return {
            school,
            hasStudents: studentCountObject.hasStudents
          };
        });

        const withStudents = this.getSchoolsWithStudents();
        this.countOfSchoolsWithoutStudents = this.schools.length - withStudents.length;

      }
    );
  }

  // getQueryParamsForNoOUs() {
  //   const schoolsWithNoOU = this.getSchoolsWithoutStudentOUs();
  //   const schoolIds = schoolsWithNoOU.map(school => school.school.name);
  //   return {name: schoolIds};
  // }

  onEditSchool(school: School) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        name: [school.name]
      }
    };
    this.router.navigate(['/tags/schools'], navigationExtras);
  }

  getTotalsClass() {
    const percentageComplete = (this.getSchoolsWithCompleteStudentConfig().length / this.schools.length) * 100;
    if (percentageComplete === 100) {
      return 'success';
    } else if (percentageComplete > 70) {
      return 'warn';
    } else {
      return 'danger';
    }
  }

  getSchoolsWithCompleteStudentConfig(): StudentConfigState[] {
    return this.schools.filter(schoolConfigState => {
      if (!schoolConfigState || !schoolConfigState.school || !schoolConfigState.school.studentOU) {
        return false;
      }
      return schoolConfigState.hasStudents;
    });
  }

  getSchoolsWithStudents(): StudentConfigState[] {
    return this.schools.filter(schoolConfigState => schoolConfigState.hasStudents);
  }

  schoolsNotSyncingStudents() {

    let state = this._localstorageSvc.get('schoolGridState');

    if (state === null) {
      state = {
        filters: {
          syncStudents: {
            values: ['0'],
            filterType: 'set'
          }
        }
      };
    }

    this._localstorageSvc.set('schoolGridState', state);

    this.router.navigate(['/tags/schools']);

  }

  getChipTextColor(hex: string): string {
    return this.utilService.isColorDark(hex) ? 'white' : '1a1a1a';
  }
}
