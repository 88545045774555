import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../modules/auth/services/Auth/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-page-not-found-page',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  signedIn: boolean;
  private onDestroy$: Subject<void> = new Subject<void>();


  constructor(
    private _authSvc: AuthService
  ) {

    this._authSvc.isUserSignedIn().pipe(takeUntil(this.onDestroy$)).subscribe(resp => {
      this.signedIn = resp;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngOnInit() {

    console.log(this);

  }

}
