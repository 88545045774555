import {LicenseResponse} from './littlesis-licensing.service';

export const testResponse: LicenseResponse = {
    'success': true,
    'response': {
      'domain': 'gafetest.com',
      'subscriptions': {
        'LSW': {
          'key': 'LSW',
          'handle': 'ait-lsw',
          'id': 5546468,
          'plan': {
            'name': 'Lapsed',
            'timestamp': '2022-01-01T11:56:47-04:00',
             'paid_ended_at': new Date('2022-01-01T11:56:47-04:00'), // Uncomment to trigger paid tier lapsed messaging
            // 'trial_ended_at': Date; //Uncomment to trigger trial tier lapsed messaging
          },
          'expiry': new Date('2022-06-01T01:00:00-04:00'),
          'bundle': 'TBC'
        }
      }
    }
  }

