import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppSharedModule } from '@amplabs/shared.module';

import { NavTogglerComponent } from './nav-toggler.component';

@NgModule({
  declarations: [
    NavTogglerComponent
  ],
  imports     : [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    AppSharedModule
  ],
  exports     : [
    NavTogglerComponent
  ]
})
export class NavTogglerModule {
}
