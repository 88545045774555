import {
  Inject,
  Injectable,
} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

import * as moment from 'moment';
import {
  SESSION_STORAGE,
  StorageService,
} from 'ngx-webstorage-service';
import {BehaviorSubject} from 'rxjs';

import {AuthService} from '../../auth/services/Auth/auth.service';

@Injectable()
export class SheetsService {

  callableCF: {
    checkSheetsExport: any;
    initialiseSheetsExport: any;
    removeExport: any;
    exportUsers: any;
  };

  private $auth = new BehaviorSubject(null);

  constructor(
    private authService: AuthService,
    private fns: AngularFireFunctions,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {

    this.callableCF = {
      checkSheetsExport: fns.httpsCallable('checkSheetsExport'),
      initialiseSheetsExport: fns.httpsCallable('export-initialiseSheetsExport'),
      removeExport: fns.httpsCallable('export-removeExport'),
      exportUsers: fns.httpsCallable('export-generateUsersExport'),
    };

  }
  async authCheck() {

    if (this.$auth.value) {
      this.$auth.next(this.$auth.value);
      return this.$auth.value;
    } else {
      const scopes = ['https://www.googleapis.com/auth/drive.file'];
      let response;

      try {
        response = await this.authService.getAuthForScopes(scopes);
        this.$auth.next(response);
        return response;
      } catch (e) {
        this.$auth.next(false);
        throw e;
      }
    }
  }

  triggerSheetExport(type, auth, academicYearId, timeframe, lastRefreshed) {
    return this.callableCF.initialiseSheetsExport({
      type,
      auth,
      academicYear: academicYearId,
      timeframe: {
        startDate: moment(timeframe.range.startDate).toJSON(),
        endDate: moment(timeframe.range.endDate).toJSON()
      },
      lastRefreshed
    }).toPromise();
  }

  removeExport(id: string) {
    return this.callableCF.removeExport({
      id
    }).toPromise();
  }

  exportUsers(auth, columnOrder, sortOrder, filters, limit) {
    return this.callableCF.exportUsers({ auth, columnOrder, sortOrder, filters, limit }).toPromise();
  }

}
