import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ListResponse } from '../core/models/api.interface';
import { AuthService } from '../modules/auth/services/Auth/auth.service';
import { ClassJoinData } from '../modules/insights/components/dialogs/join-class-dialog/join-class-dialog.component';
import {
  Coursework,
  CourseworkFullApiResponse,
} from '../modules/insights/coursework.interface';
import { convertCourseworkFromApiToCoursework } from '../modules/insights/helpers/coursework.helpers';
import { GridDataService } from './gridData.service';
import { ClassroomAnnouncement } from './models/announcement.model';
import { School } from './schools.service';
import { Tag } from './tags.service';
import { Timeframe } from './timeframes.service';
import { User } from './user.service';
import { ApiResponse } from './util.service';

export interface CourseWork {
  recordId: number;
  customer_id: number;
  id: string;
  courseId: string;
  title?: string;
  description?: string;
  materials?: string;
  state?: string;
  alternateLink?: string;
  creationTime?: string;
  updateTime?: string;
  dueDate?: string;
  dueTime?: string;
  scheduledTime?: string;
  maxPoints?: number;
  workType?: string;
  associatedWithDeveloper?: string;
  assigneeMode?: string;
  individualStudentsOptions?: string;
  submissionModificationMode?: string;
  assignment?: string;
  multipleChoiceQuestion?: string;
  creatorUserId?: string;
}

export interface RosterCoursework {
  total: number;
  data: CourseWork[];
}

export interface RosterUser {
  user_id: string;
  student: {
    name: string;
    primaryEmail: string;
  };
}

export interface Roster {
  total: number;
  data: RosterUser[];
}

export interface ClassActions {
  selectedClasses: Course[];
  alreadyInClass?: boolean;
  allowNotification?: boolean;
  requireNotification?: boolean;
}

export interface Course {
  /**
   * Unique id within LSC
   */
  id: string;
  /**
   * Associated customer id (used for sharding/constraining query results)
   */
  customer_id: number;
  /**
   * Name/Title of the course
   */
  name: string;
  /**
   * Section of the course
   */
  section?: string;
  /**
   * Description title
   */
  descriptionHeading?: string;
  /**
   * Description of the course
   */
  description?: string;
  /**
   * Google Id of the course owner (Primary Teacher)
   */
  ownerId?: string;
  /**
   * Timestamp of the course creation
   */
  creationTime?: Date; // was string
  /**
   * Timestamp of the most recent course meta data change
   */
  updateTime?: Date; // was string
  /**
   * Current state of the course
   */
  courseState?: string;
  /**
   * Url for the course within Classroom
   */
  alternateLink?: string;
  /**
   * Email address for the teachers of the given class
   */
  teacherGroupEmail?: string;
  /**
   * Email address of all accounts associated with the class (teachers and students)
   */
  courseGroupEmail?: string;
  /**
   * Id of the teacher's Drive Folder for this course
   */
  teacherFolder?: string;
  /**
   * Enrollment code that can be used to join the class within Classroom
   */
  enrollmentCode?: string;
  /**
   * Any assets associated with the course
   */
  courseMaterialSets?: string;
  /**
   * Are guardian notifications enabled for this class?
   */
  guardiansEnabled?: boolean;
  /**
   * Id for the Google calendar associated with this class
   */
  calendarId?: string;
  /**
   * Related Directory User account of the primary teacher of this class
   */
  Owner?: User; // TODO: Ger - LSC-873 # Owner (capital O) is inconsistent with the other properties. Needs fixing.
  /**
   * Related tags
   */
  tags?: Tag[];
  /**
   * Email address of the primary teacher
   */
  primaryTeacherEmail?: string;

  /**
   * TagId of associated school tag
   */
  school?: number;

  /**
   * TagId of associated timeframe tag
   */
  timeframe?: number;
  /**
   * coteachers of associated classroom
   */
  coteachers?: any; // TODO: Ger - LSC-873 # Not any. The AddSelfToClasses component requires a specific shape here to check if the user is already a member
  /**
   * Number of students in classroom
   */
  studentCount?: number;

  teacherCount?: number;

  hasCoTeachers?: boolean;

  hasStudents?: boolean;

  studentOUs?: string;

  room: string;

  tf?: any;
  sch?: any;

  aliases?: string[];

}

interface ClassJoinDataBody extends Omit<ClassJoinData, 'courses'> {
  classIds: string[];
  email: string;
}

interface ClassroomApiResponse {
  success: string[];
  error: ClassroomApiError[];
}

interface ClassroomApiError {
  classId: string;
  error: string;
}

// XXX: Ger - Move these archive interfaces to interface files in the bulk archive folder to reduce bloat here
export interface ArchiveQueryData {
  createdBefore: string;
  schools: number[];
  teachers: string[];
  status: string[];
  onlyLSPManaged?: boolean;
  onlySuspendedPrimaryTeachers?: boolean;
}

export interface ArchiveMetaItem {
  // teacher: {
  //   gId: string;
  //   name: string;
  //   primaryEmail: string;
  //   thumbnailPhotoUrl: string;
  // };
  // classes: basicClassroom[];
  primaryTeacher: {
    gId: string;
    thumbnailPhotoUrl: string;
    name: string;
    primaryEmail: string;
  };
  classroom: {
    id: string;
    name: string;
    section: string;
    creationTime: Date;
    updateTime: Date;
    alternateLink: string;
  };
}

interface ArchiveRequest {
  primaryTeacherId: string;
  courses: string[];
}

export interface NotificationData {
  replyEmail: string;
  sendNotification: boolean;
  customerActionTemplate: string;
  userActionTemplate: string;
}
// XXX: Ger - NotificationData is common for all notificatinos so should be moved somewhere where it's easier to share


// Should these interfaces be moved to monorepo to be shared back to front end and back end?
export interface ValidateBulkActionRequestData {
  action: string;
  classIds: string[];
  teacherIds: string[];
}

export interface ValidateBulkActionResponseData {
  success: boolean;
  classrooms: CheckedClassroom[];
  teachers: ClassroomOwner[];
}

export interface CheckedClassroom {
  id: string;
  name: string;
  section?: string;
  ownerId: string;
  valid: boolean;
  notes: 'New primary teacher' | 'Primary teacher active';
}

export interface ClassroomOwner {
  gid: string;
  name: string;
  primaryEmail: string;
  thumbnailPhotoUrl: string;
  suspended: boolean;
}



@Injectable()
export class ClassroomService extends GridDataService {

  private httpOptions: { headers: HttpHeaders };
  private getHttpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;

  constructor(
    private authService: AuthService,
    private fns: AngularFireFunctions,
    private http: HttpClient
  ) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.getHttpOptions = {
      headers: new HttpHeaders()
    };
  }

  getRoster(rosterId: string, options, opts): Observable<ListResponse> {
    const url = this.generateGridUrl(this.serviceUrls.classrooms.roster.replace(':id', rosterId), options, opts);
    return this.http.get<ListResponse>(url, this.httpOptions);
  }

  getCoursework(rosterId: string, options, opts): Observable<ListResponse> {
    const url = this.generateGridUrl(this.serviceUrls.classrooms.courseWork.replace(':id', rosterId), options, opts);
    return this.http.get<ListResponse>(url, this.httpOptions);
  }

  getMembershipChanges(rosterId: string, options, opts): Observable<ListResponse> {
    const url = this.generateGridUrl(this.serviceUrls.classrooms.membershipChanges.replace(':id', rosterId), options, opts);
    return this.http.get<ListResponse>(url, this.httpOptions);
  }

  getAnnouncements(classId: string): Observable<ClassroomAnnouncement[]> {
    return this.fns.httpsCallable('classrooms-getAnnouncements')({classId});
  }

  getCourses( options, opts): Observable<ListResponse> {
    const url = this.generateGridUrl(this.serviceUrls.getCourses, options, opts);
    return this.http.get<ListResponse>(url, this.getHttpOptions);
  }

  refreshCourses(classIds: string[]): Observable<ListResponse> {

    let url = this.serviceUrls.getCourses + '?';
    url += `filter[]=${JSON.stringify(this.compactAGFilter({ filterType: 'set', values: classIds}, 'id'))}&`;
    url += `useCache=false`;

    return this.http.get<ListResponse>(url, this.getHttpOptions);

  }

  coursesSync(): Observable<any> {
    const url = this.serviceUrls.tasks;
    return this.http.post<any>(url, { type: 'classroom-sync' }, this.httpOptions);
  }

  refresh(request): Observable<any> {

    if (!request.refresh) {
      request.refresh = { roster: true };
    }

    const postBody = request;
    const url = this.serviceUrls.classrooms.refresh;
    return this.http.post<ListResponse>(url, postBody, this.httpOptions);
  }

  /**
   * Has tag?
   *
   * @param tagId
   * @param cls
   * @returns whether classroom has the given tag
   */
  hasTag(tagId, cls): any {

    if ( !cls.tags ) {
      return false;
    }

    return cls.tags.filter(tag => tag.id === tagId).length;
  }

  /**
   * Update the classroom tags
   *
   * @param classId Id of the classroom
   * @param {object} data
   * @param {array} data.tags Array of active tags
   * @returns {Promise<any>}
   */

  updateClassTags(classId: string, data: { schools?: School[]; timeframes?: Timeframe[]; tags: Tag[]}): Observable<void> {

    const url = this.serviceUrls.tags.setClassTags.replace(':id', classId);
    const body = {
      timeframeIds: data.timeframes.map((tf) => tf.id),
      schoolIds: data.schools.map((s) => s.id),
      tagIds: data.tags.map(tag => tag.id)
    };

    return this.http.post<void>(url, body, this.httpOptions);

  }

  bulkUpdateClassTags(data: {ids: string[]; schools?: School[]; timeframes?: Timeframe[]; tags: Tag[]; changes: { timeframes: boolean; tags: boolean; schools: boolean}}): Observable<void> {

    const url = this.serviceUrls.tags.bulkSetClassTags;

    const body = {
      schoolIds: data.schools.map((s) => s.id),
      timeframeIds: data.timeframes.map((tf) => tf.id),
      tagIds: data.tags.map(tag => tag.id),
      ids: data.ids,
      changes: data.changes
    };

    return this.http.post<void>(url, body, this.httpOptions);

  }

  archiveClasses(changes) {
    const url = this.serviceUrls.archiveClasses;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  deleteClasses(changes) {
    const url = this.serviceUrls.deleteClasses;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  bulkAddCoTeacher(changes) {
    const url = this.serviceUrls.teachers.add;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  addStudents(changes) {
    const url = this.serviceUrls.students.add;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  removeStudents(changes) {
    const url = this.serviceUrls.students.remove;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  addSelfToClasses(changes) {
    const url = this.serviceUrls.teachers.join;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  bulkRemoveCoTeacher(changes) {
    const url = this.serviceUrls.teachers.remove;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  joinAsStudent(changes) {
    const url = this.serviceUrls.students.join;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  joinAsCoTeacher(joinData: ClassJoinDataBody): Observable<ClassroomApiResponse> {
    // return of({
    //   success: ['1456787654', '7984385845092', '34345345434'],
    //   error: [{
    //     "classId": "92476973652",
    //       "error": "Requested entity already exists"
    //     }, {
    //       "classId": "538820018",
    //       "error": "Some other error"
    //     }]
    // });
    return this.fns.httpsCallable('classrooms-joinClass')(joinData);
  }

  changePrimaryTeacher(changes) {
    const url = this.serviceUrls.teachers.change;
    return this.http.post<ApiResponse>(url, changes, this.httpOptions);
  }

  getRegistration() {
    const url = this.serviceUrls.classrooms.register;
    return this.http.get<ApiResponse>(url, this.httpOptions);
  }

  updateRegistration() {
    return this.fns.httpsCallable('membershipNotifications-refreshRegistration')({});
  }

  // Data state - used for assessing metadata null states and number of classes

  getClassroomDataState() {
    const url = this.serviceUrls.classrooms.dataState;
    return this.http.get<ApiResponse>(url, this.httpOptions);
  }

  getRostersOfClassrooms(classIds): Observable <ApiResponse> {
    const body = { classIds };
    const url = this.serviceUrls.classrooms.rosters;
    return this.http.post<ApiResponse>(url, body, this.httpOptions);
  }

  getCourseworkDetail(courseId: string, startDate, endDate, action: string): Observable<Coursework[]> { // XXX: Ger - Types for dates are strings here?
    // TODO: Ger - Review - there's some duplication here with teacher service and also with an existing getCoursework function
    const url = this.serviceUrls.classrooms.rest + '/' + courseId + '/courseworkDetail';

    const params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('action', action);

    return this.http.get<CourseworkFullApiResponse>(url, {params})
      .pipe(
        map((response: CourseworkFullApiResponse) => {
          const courseworkList = response.data;
          return courseworkList.map(convertCourseworkFromApiToCoursework);
        })
      );
  }

  validateBulkActionRequest(request: ValidateBulkActionRequestData): Observable<ValidateBulkActionResponseData> {
    return this.fns.httpsCallable('classrooms-validateBulkActionRequest')(request);
  }

  getClassesFromBulkActionCriteria(queryData: ArchiveQueryData): Observable<ArchiveMetaItem[]> {
    return this.fns.httpsCallable('classrooms-getClassesFromBulkActionCriteria')(queryData).pipe(
      map((archiveMetaItems: ArchiveMetaItem[]) => archiveMetaItems.map((archiveMetaItem: ArchiveMetaItem) => {
        archiveMetaItem.classroom.creationTime = new Date(archiveMetaItem.classroom.creationTime);
        archiveMetaItem.classroom.updateTime = new Date(archiveMetaItem.classroom.updateTime);
        return archiveMetaItem;
      }))
    );
  }

  bulkArchiveClasses(classesByTeacher: ArchiveRequest[], notificationData: NotificationData): Observable<boolean> {
    return this.fns.httpsCallable('classrooms-archive')({classesByTeacher, notification: notificationData});
  }

  bulkDeleteClasses(classesByTeacher: ArchiveRequest[], notificationData: NotificationData): Observable<boolean> {
    return this.fns.httpsCallable('classrooms-delete')({classesByTeacher, notification: notificationData});
  }

  bulkClearRosters(classesByTeacher: ArchiveRequest[], notificationData: NotificationData, totalClasses: number): Observable<boolean> {
    return this.fns.httpsCallable('classrooms-clearRosters')({classesByTeacher, notification: notificationData, totalClasses});
  }

  checkIfBulkJobIsRunning(): Observable<boolean> {
    return this.fns.httpsCallable('classrooms-checkIfBulkJobIsRunning')({}).pipe(
      map(responseBody => responseBody.result)
    );
  }

  // This function checks the current user has access to the classroom in the Little SIS application, based on school permissions. It does not necessarily mean the user has access in Google Classroom and may not have joined the classroom.
  checkIfCurrentUserHasAccess(classroom: any): boolean {
    // TODO: Ger - LSC-552 # I need unit tests for this
    if (this.authService.hasAllSchoolAccess()) {
      return true;
    }

    const userSchools = this.authService.getCurrentUser().auth.schools;
    const classroomSchools = classroom.schools; //.map(school => school.id);
    if (userSchools && userSchools.length) {
      // If any of the classroom's schools match the current users permissions settings, the user can view the classroom
      const permittedSchool = classroomSchools.find(classroomSchool => userSchools.indexOf(classroomSchool.id) !== -1);
      if (permittedSchool) {
        return true;
      }
    }

    return false;
  }
}
