import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface SnackbarListInterface {
  leadMessage: string;
  messageList: string[];
}

@Component({
  selector: 'app-snackbar-list',
  templateUrl: './snackbar-list.component.html',
  styles: [`
    ul {list-style-type: none;}
  `]
})
export class SnackbarListComponent {
  constructor(
    public snackbarRef: MatSnackBarRef<SnackbarListComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarListInterface
  ) {}

  onClose() {
    this.snackbarRef.dismiss();
  }
}
