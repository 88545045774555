import {Component, OnChanges, OnInit, OnDestroy, EventEmitter, Output, Input, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import {ConfirmDialogComponent, ConfirmDialogData} from '../../../../core/components/confirm-dialog/confirm-dialog.component';
import {ScheduleService} from '../../services/schedule.service';
import { JobListStateService } from '../../services/job-list-state.service';


@Component({
  selector: 'app-schedule-list-item',
  templateUrl: './schedule-list-item.component.html',
  styleUrls: ['./schedule-list-item.component.scss']
})

export class ScheduleListItemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() ref;
  @Input() exp;

  trigger: number;
  poll: any;
  showDetails = false;
  panelOpenState = false;
  busy = false;

  dialogData: ConfirmDialogData;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _scheduleSvc: ScheduleService,
    private _jobListStateservice: JobListStateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {

    if (!changes.exp.currentValue) {
      return;
    }

    // Status change
    if (!changes.exp.currentValue.complete) {
      if (!this.poll) {
        this.poll = setInterval(() => {

          this.trigger = Math.random();
        }, 1000);
      }
    } else {
      clearInterval(this.poll);
    }

  }

  ngOnDestroy() {
    clearInterval(this.poll);
    this.onDestroy$.next();
  }

  onEdit(): void {
    this._jobListStateservice.setEditing({id: this.ref, job: this.exp});
  }

  async removeScheduled() {
  // TODO: Add details of action in the dialog
    const dialogData = {
      header: 'Confirmation required',
      message: 'Are you sure that you want to remove this scheduled action?',
      okBtnLabel: 'Confirm',
      cancelBtnLabel: 'Cancel'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async result => {

      if (result) {

        // Display overlay/spinner
        this.busy = true;

        try {
          await this._scheduleSvc.removeScheduled(this.ref);
          this.busy = false;
        } catch (e) {
          this.busy = false;
          console.error(e);
        }
      }

    });

  }

  async actionNow() {

    const dialogData = {
      header: 'Confirmation required',
      message: 'Are you sure that you want to run this action now?',
      okBtnLabel: 'Yes',
      cancelBtnLabel: 'No'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async result => {

      if (result) {

        // Display overlay/spinner
        this.busy = true;

        try {
          await this._scheduleSvc.actionNow(this.ref);
          this.busy = false;
        } catch (e) {
          this.busy = false;
          console.error(e);
        }
      }

    });

  }

}
