import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';

declare var gapi: any;

@Injectable()
export class AdminSDKService {

  private AdminSDKDiscoveryDoc: string = 'https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest'
  private Required_Scopes = ['https://www.googleapis.com/auth/admin.directory.user.readonly']

  public async loadClient() {
    gapi.load('client',  {
      callback: () => this.initClient(),
      onerror: () => console.error('Failed to Load GAPI'),
      timeout: 5000, // 5 seconds.
      ontimeout: () => console.error('gapi.client could not load in a timely manner!')
    });
  }

  public async initClient() {
    try {
      const a = await gapi.client.init({
        apiKey: environment.firebaseConfig.apiKey,
        discoveryDocs: [this.AdminSDKDiscoveryDoc],
        clientId: environment.web_client_id,
        scope: this.Required_Scopes.join(' ')
      }).catch(error => console.error(`Unable to init Directory API service`, error));
    } catch (error) {
      console.warn('Is this an API loading error?', error);
    }
  }

  async getDirectoryUser(userEmail: string, token: string): Promise<any> {
    gapi.client.setToken({access_token: token});
    // @ts-ignore
    return (await gapi.client.directory.users.get({
      userKey: userEmail,
      fields: '*'
    })).result;
  }
}
