import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {UtilService} from '../../../../services/util.service';
import {User, UserService} from '../../../../services/user.service';
import {BehaviorSubject} from 'rxjs';

const NL = '\n';

interface EmailHash {
  [email: string]: boolean;
}

@Component({
  selector: 'app-user-search-and-validate',
  templateUrl: './user-search-and-validate.component.html',
  styleUrls: ['./user-search-and-validate.component.scss']
})
export class UserSearchAndValidateComponent implements OnInit {

  @Input() formArray: FormArray;
  @Input() existingUserIds: string[];
  userInput: FormGroup;
  busy: boolean;
  hasValidated: boolean;
  invalidUsers$: BehaviorSubject<string[]>;
  notFoundUsers$: BehaviorSubject<string[]>;
  duplicateUsers$: BehaviorSubject<User[]>;
  constructor(private fb: FormBuilder,
              private utils: UtilService,
              private userService: UserService) {
    this.busy = false;
    this.hasValidated = false;
  }

  ngOnInit(): void {
    this.invalidUsers$ = new BehaviorSubject<string[]>(null);
    this.notFoundUsers$ = new BehaviorSubject<string[]>(null);
    this.duplicateUsers$ = new BehaviorSubject<User[]>(null);
    this.userInput = this.fb.group({
      input: this.fb.control(null),
      invalid: this.fb.control(null)
    });
  }

  private extractFamilyName_(name) {
    if (!name) {return '';}
    const nameParts = name.split(' ');
    return nameParts[nameParts.length - 1].toLowerCase();
  }

  private compareUserLastNames_(a, b) {
    const aFam = this.extractFamilyName_(a.name);
    const bFam = this.extractFamilyName_(b.name);
    return aFam > bFam ? 1 : aFam < bFam ? -1 : 0;
  }

  private isValidEmail_(email: string): boolean {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
  }

  private async validateUsersExist_(validEmailsHash: EmailHash): Promise<void> {
    const validCtrl = this.userInput.get('input') as FormControl;
    const emails = Object.keys(validEmailsHash);
    if (!emails.length) {return;}
    const existingCollectionMemberIdsHash = this.existingUserIds ? this.existingUserIds.reduce((acc, id) => {acc[id]=true; return acc;}, {}): [];
    const pendingAddEmailAddresses = this.formArray.value.reduce((acc, user) => {
      acc[user.primaryEmail.toLowerCase()] = true;
      return acc;
    }, {});
    this.userService.findStudentsByList({
      list: JSON.stringify(emails)
    }).subscribe((results) => {
      this.hasValidated = true;
      this.busy = false;
      const usersInCollection = [];
      results.data.forEach((user: User) => {
        if (existingCollectionMemberIdsHash[user.gId]) {
          usersInCollection.push(user);
        } else if (!pendingAddEmailAddresses[user.primaryEmail.toLowerCase()]) {
          this.formArray.push(this.fb.control(user));
        }
        delete validEmailsHash[user.primaryEmail.toLowerCase()];
      });
      if (usersInCollection.length) {
        this.duplicateUsers$.next(usersInCollection.sort((user) => user.displayName));
      } else {
        this.duplicateUsers$.next(null);
      }
      if (Object.keys(validEmailsHash).length) {
        const notFoundArray = Object.keys(validEmailsHash).sort();
        validCtrl.setValue(notFoundArray.join(NL));
        this.notFoundUsers$.next(notFoundArray);
      } else {
        validCtrl.setValue(null);
        this.notFoundUsers$.next(null);
      }
      this.formArray.setValue(this.formArray.value.sort((a, b) => this.compareUserLastNames_(a, b)));
    });
  }

  validateEmail() {

    let list = this.userInput.get('input').value;
    const otherList = this.userInput.get('invalid').value;
    if (list && otherList) {
      list += NL + otherList;
    } else if (!list && otherList) {
      list = otherList;
    }

    if (list) {
      this.busy = true;
      const emails = list.split(/\r?\n|\r/); // Regex match on any line break
      const validEmails = {}; // Hash all valid email addresses to deduplicate
      const invalidEmails = {}; // Hash all invalid email addresses
      emails.forEach((email) => {
        email = email.trim().toLowerCase(); // Force lower-case for comparison
        if (!email.length) {
          return; // This is an empty line break between email addresses
        }
        if (email && !this.isValidEmail_(email)) {
          invalidEmails[email] = true;
        } else {
          validEmails[email] = true;
        }
      });
      const invalidCtrl = this.userInput.get('invalid');
      const invalidEmailList = Object.keys(invalidEmails).sort();
      if (invalidEmailList.length) {
        const invalidEmailString = invalidEmailList.join(NL);
        invalidCtrl.setValue(invalidEmailString);
        this.invalidUsers$.next(Object.keys(invalidEmails));
      } else {
        invalidCtrl.setValue(null);
        this.invalidUsers$.next(null);
      }
      return this.validateUsersExist_(validEmails);
    }
  }

  removeAllEmails() {
    while (this.formArray.controls.length) {
      this.formArray.removeAt(0);
    }
  }

  getDefaultPhotoUrl() {
    return UtilService.defaultPhotoUrl;
  }

  removeValidEmail(index: number) {
    this.formArray.removeAt(index);
  }

  removeAllDuplicateEmails() {
    this.duplicateUsers$.next(null);
  }

  removeDuplicateEmail(user: User, duplicateUsers: User[]) {
    this.duplicateUsers$.next(duplicateUsers.filter((user) => user.gId !== user.gId));
  }
}
