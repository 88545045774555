import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  ElementRef,
  Injectable,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import {
  AuthService,
  User,
} from '../../auth/services/Auth/auth.service';
import { HelpDialogComponent } from '../components/help-dialog/help-dialog.component';
import { LicenseSyncResponse } from '../models/licensing.models';
import {
  License,
  LicenseState,
  LocalOverride,
} from './licensing.interfaces';
import { testResponse } from './testLicenseResponse';

export interface LicenseResponse {
  success: boolean;
  response: License;
}

const DEFAULT_LICENSE_STATE = {
  licensed: false,
  loaded: false,
  hasPremium: false,
  bundle: null
};

@Injectable()
export class LittlesisLicensingService {

  static notAvailableMessage = 'This action is not available with your current license.';
  static limitWarningText = 'Due to your license being expired, <strong>all Little SIS actions are disabled</strong>.<br/>You can still perform <strong>analysis</strong>, but you will not be able to perform updates or make changes <strong>until your license is renewed</strong>.';
  serviceUrls = environment.urls.userServices;

  httpOptions: { headers: HttpHeaders };
  initialised: boolean;

  license = {} as License;
  originalLicense: string;
  overrideStatus$ = new BehaviorSubject(false);
  public license$ = new BehaviorSubject<LicenseState>({...DEFAULT_LICENSE_STATE});

  private lastEntitlementCheck: Date;


  validLicenseTypes = [
    'paid',
    'trial',
    'lapsed',
    'consultant',
    'unlicensed'
  ];

  localOverride = {
    subscriptions: {}
  } as LocalOverride;

  private triggerForcedRecheck$: Subject<void> = new Subject<void>();
  private onDestroy$: Subject<void> = new Subject<void>();


  private localOverride$ = new BehaviorSubject<LocalOverride>({subscriptions: {}});

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private angularFirestore: AngularFirestore,
    private authService: AuthService
  ) {
  }


  triggerEntitlementRefresh(): Observable<boolean> {
    return this.authService.getCustomerId().pipe(
      filter(customerId => !!customerId),
      switchMap((customerId: string) =>
        this.angularFirestore
          .doc<LicenseSyncResponse>(`/customers/${customerId}/entitlements/licenseSync`)
          .valueChanges()
      ),
      filter((resp: LicenseSyncResponse) => !!resp),
      map((resp: LicenseSyncResponse) => {
          if (resp.lastSyncRequest) {
            let needsRefresh = true;
            if (this.lastEntitlementCheck) {
              needsRefresh = this.lastEntitlementCheck?.getTime() !== resp.lastSyncRequest?.toDate()?.getTime();
            }
            if(needsRefresh) {
              this.license$.next({...DEFAULT_LICENSE_STATE});
              this.lastEntitlementCheck = resp.lastSyncRequest.toDate();
              return true;
            }
          }
          return false;
        }
      ));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  triggerForcedRecheck(): void {
    this.triggerForcedRecheck$.next();
  }

  getForcedRecheckTrigger(): Observable<void> {
    return this.triggerForcedRecheck$.asObservable();
  }

  getOverrideStatus(): Observable<boolean> {
    return this.overrideStatus$.asObservable();
  }

  setLocalOverride(tool, planName, expiry): void {

    this.localOverride = {
      subscriptions: {
        [tool]: {
          plan: planName,
          expiry
        }
      }
    };
    this.overrideStatus$.next(true);

    this.localOverride$.next({
      subscriptions: {
        [tool]: {
          plan: {
            name: planName
          },
          expiry
        }
      }
    });
  }

  fetchLicense(user: User): Observable<LicenseResponse> {
    const url = this.serviceUrls.license.route;
    const body = {
      tools: environment.licensing.toolLicenseKeys,
      domain: user.profile.domain
    };

    return (environment.licensing.useTestResponse) ?
      of(testResponse) :
      this.http.post<LicenseResponse>(url, body, this.httpOptions);
  }

  mergeOverride(license: License): Observable<License> {
    return this.localOverride$.asObservable().pipe(map((override: LocalOverride) => {
      Object.entries(override).forEach(([tool, value]) => {
        if (license.subscriptions.hasOwnProperty(tool)) {
          license.subscriptions[tool].plan.name = value.plan.name;
          license.subscriptions[tool].expiry = value.expiry;
        }
      });
      return license;
    }));
  }

  checkLicense(forceRefresh: boolean = false): Observable<LicenseState> {
    return this.authService.getActiveUser().pipe(
      filter((user: User) => !!user?.profile.domain),
      switchMap((user: User) => this.triggerEntitlementRefresh().pipe(
        startWith(true),
        filter((refresh: boolean) => refresh === true),
        switchMap(() => {
          if (!forceRefresh) {
            return this.license$.asObservable().pipe(
              switchMap(licenseState => {
                if (licenseState.loaded === true) {
                  // cached license check for overrides
                  return this.mergeOverride(licenseState.license)
                    .pipe(switchMap(license => {
                      const state = this.buildState(license);
                      return of(state);
                    }));
                } else {
                  return this.checkLicense(true);
                }
              }));
          }
          return this.fetchLicense(user).pipe(
            switchMap((response: LicenseResponse) => this.mergeOverride(response.response)),
            switchMap((license: License) => {
              const state = this.buildState(license);
              this.license$.next(state);
              return this.license$.asObservable();
            })
          );
        }),
      )));
  }

  buildState(licenseResponse: License): LicenseState {
    const now = moment();
    const license: License = licenseResponse;

    const state: LicenseState = {
      licensed: false,
      loaded: true,
      hasPremium: false,
      license,
      bundle: null
    };

    if(license.subscriptions?.['ait-lsp'].bundle) {
      state.bundle = license.subscriptions['ait-lsp'].bundle;
    } else if (license.subscriptions.LSW.bundle) {
      state.bundle = license.subscriptions.LSW.bundle;
    }

    if (license.subscriptions.LSW.expiry) {
      const expires = moment(license.subscriptions.LSW.expiry).endOf('d');
      let licenseDiff = expires.diff(now, 'd');

      // lowercase the license to ensure consistency and reduce
      license.subscriptions.LSW.plan['name'] = license.subscriptions.LSW.plan['name'].toLowerCase();


      // Fixes if license is less then full day expired
      if (expires.diff(now, 'h') < 0 && (expires.diff(now, 'h') > -24)) {
        licenseDiff = -1;
      }

      state.licensed = this.validLicenseTypes.indexOf(license.subscriptions.LSW.plan['name']) > -1;
      state.expiresIn = licenseDiff;

      if (licenseDiff >= 0) {
        state.details = 'ACTIVE';
      } else {
        state.details = 'EXPIRED';
      }

    } else {
      state.licensed = (this.validLicenseTypes.indexOf(license.subscriptions.LSW.plan['name']) > -1);
    }

    if (!state.licensed) {
      if (license.subscriptions.LSW.plan['name'] === 'unlicensed') {
        state.details = 'NEVER';
      } else {
        state.details = 'EXPIRED';
      }

    } else {
      state.tier = license.subscriptions.LSW.plan.name;
      if (state.tier === 'lapsed') {
        state.lastTierExpiration =
          {
            type: 'paid',
            date: license.subscriptions.LSW.plan.paid_ended_at
          };
      }

      if (state.tier === 'free') {
        state.lastTierExpiration = (license.subscriptions.LSW.plan.paid_ended_at)
          ? {date: license.subscriptions.LSW.plan.paid_ended_at, type: 'paid'}
          : {date: license.subscriptions.LSW.plan.trial_ended_at, type: 'trial'};
      }
    }
    if(license.subscriptions?.['ait-lsp'].plan.name !== 'Unlicensed') {
      state.hasPremium = true;
    }
    return state;
  }

  isPremium(): Observable<boolean> {
    return this.license$.pipe(
      filter((licenseState: LicenseState) => licenseState.loaded),
      map((licenseState: LicenseState) => licenseState.hasPremium)
    );
  }

  openHelpDialog({
                   positionRelativeToElement,
                   hasBackdrop = false, height = '400px', width = '450px'
                 }: {
    positionRelativeToElement: ElementRef;
    hasBackdrop?: boolean;
    height?: string;
    width?: string;
  }): MatDialogRef<any> {

    return this.dialog.open(HelpDialogComponent, {
      panelClass: 'ait-tight-panel',
      hasBackdrop,
      height,
      width,
      data: {positionRelativeToElement}
    });
  }

}
