import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import * as _ from 'lodash';
import {
  concat,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  ClassActions,
  ClassroomService,
  Course,
} from '../../../../../services/classroom.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import {
  User,
  UserService,
} from '../../../../../services/user.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';
import { GridDataService } from '@cdw-ae/little-sis-for-classroom-ag-grid';

export const ADD_STUDENTS_STEPPER_STEPS = 4;
export const ADD_STUDENTS = 'explorerBulkAddStudents';

@Component({
  selector: 'app-bulk-add-student',
  templateUrl: './bulk-add-students.html',
  styleUrls: ['./bulk-add-students.scss']
})

export class BulkAddStudentDialogComponent extends GenericBulkActionComponent implements OnInit {

  busy: boolean;

  constructor(
            public apiLookup: ApiLookupService,
            public dialogRef: MatDialogRef<BulkAddStudentDialogComponent>,
            protected _authSvc: AuthService,
            public userSvc: UserService,
            public classroomSvc: ClassroomService,
            protected _firestoreCustomerService: FirestoreCustomerService,
            public dialog: MatDialog,
            private _firebaseAnalytics: FirebaseAnalyticsService,
            @Inject(MAT_DIALOG_DATA) public data: ClassActions
  ) {
    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    // Summary Grid

    this.columnDefsSummary = [
      {
        headerName: 'Student',
        field: 'teacher',
        width: 80,
        filter: false,
        hide: false,
        cellRenderer: 'profileCellRenderer',
        suppressMenu: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 60,
        filter: false,
        cellClass: 'text-center',
        hide: false
      },
      {
        headerName: 'Course',
        field: 'name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: 'classroomCellRenderer',
        autoHeight: true
      }

    ];


    this.gridOptionsSummary = {
      columnDefs: this.columnDefsSummary,
      components: this.frameworkComponents,
      context: this.context,

      rowModelType: 'clientSide',
      rowSelection: false,
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      rowData: this.summaryOfChanges,
      animateRows: true,
      clipboardDelimiter: ',',
      pagination: false,
      defaultColDef: {
        filter: false,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: false,
        hide: false,
        resizable: true,
        sortable: true
      },
      rowClassRules: {
        'declined-50': 'data && data.action === "Student already exists"',
      },
    };

  }

  private emailParse = {
    notEmails: [],
    emails: [],
  };

  selectedItems: string[] = [];
  selectedClasses: Course[] = [];

  columnDefsSummary;
  gridOptionsSummary;

  coTeacherTemplate: string | object;
  primaryTeacherTemplate: string | object;
  addStudentTemplate: string;

  customerActionTemplatePrimaryTeacher: string | object;

  summaryOfChanges = [];

  students = [];

  sendNotification: boolean;

  student$: Observable<User[]>;
  studentsLoading = false;
  studentsInput$ = new Subject<string>();
  accountSelection: string;
  allRostersOfClasses: object;

  priTeacherReplacements: object;
  additions = 0;
  nochanges = 0;
  emails: string;
  invalidCount: any;
  dialogData: ConfirmDialogData;
  isCompleted: boolean;
  showWarning: boolean;

  grids: {
    classes: {
      height: number;
    };
    summary: {
      height: number;
    };
  };

  step1Complete: boolean;
  step2Complete: boolean;




  ngOnInit() {

    const analyticsEvent = {
      action: 'bulkAddStudents',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);

    this.accountSelection = 'select';
    this.emails = '';
    this.sendNotification = true;

    this.step1Complete = false;
    this.step2Complete = false;

    //step1
    this.gridOptions.rowData = this.data.selectedClasses;
    this.grids = {
      classes: {
        height: Math.min(80 + this.data.selectedClasses.length * 60, 300)
      },
      summary:{
        height : Math.min(80 + this.data.selectedClasses.length * this.students.length * 60, 300)
      }
    };

    //start step2
    this.loadStudents();
    //step 3

    // need to create a template for add students

    this.apiLookup.getCustomerSettings('add_student').subscribe(  results => {

      if (results) {
        // @ts-ignore
        this.customerActionTemplatePrimaryTeacher = results.customerActionTemplate;
        if (!this.isSuperAdmin) {
          this.notificationEmail.setValue(this._authSvc.getCurrentUser().profile.primaryEmail);
          this.notificationEmail.disable();
        } else {
          this.notificationEmail.setValue(results.replyEmail);
        }

        if (results.userActionTemplate) {
          // @ts-ignore
          this.coTeacherTemplate = results.userActionTemplate.coTeacher;
          // @ts-ignore
          this.primaryTeacherTemplate = results.userActionTemplate.primaryTeacher;
        } else {
          this.coTeacherTemplate = this.defaultUserTemplate;
          this.primaryTeacherTemplate = this.defaultUserTemplate;
        }
      }
    });

    this.loadRosters();
  }

  onChange(event) {
    this.nav.nextDisabled = !this.students.length;
    this.processChanges();
  }

  loadRosters() {

    const classIds = this.data.selectedClasses.map((cls) => cls.id);

    this.classroomSvc.getRostersOfClassrooms(classIds).subscribe(  (results) => {
      this.allRostersOfClasses = results.data;
    });

  }

  private loadStudents() {
    this.student$ = concat(
      of([]), // default items
      this.studentsInput$.pipe(
        debounceTime(750),
        distinctUntilChanged(),
        tap(() => this.studentsLoading = true),
        switchMap(term => this.userSvc.getTeachers({
          filter: {
            op: 'OR',
            filters: [
              {field: 'name', operator: 'contains', value: term},
              {field: 'primaryEmail', operator: 'contains', value: term}
            ]
          }
        }).pipe(
          map(response => response.data),
          catchError(() => of([])), // empty list on error
          tap(() => this.studentsLoading = false)
        ))
      )
    );
  }

  //end step 2

  //step 3
  // eslint-disable-next-line @typescript-eslint/member-ordering
  async goForward(stepper: MatStepper) {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ADD_STUDENTS][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    switch(stepper.selectedIndex) {

      case 0:
        stepper.next();
        break;

      case 1:

        await this.processChanges();

        if (!this.step2Completed) {
          this.showWarning = true;
        } else if (this.step2Completed && !this.showWarning) {
          stepper.next();
        }
        break;

      default:
        stepper.next();
        break;
    }

  }


  validateEmail() {

    if (this.emails === '') {
      return;
    }

    const rows = this.emails.split('\n');

    this.emailParse.notEmails = rows.filter( emailEntries => {
      if (!emailEntries.match(/[\w'\.-]+@[\w\.-]+\.\w+/g)) {
        return emailEntries;
      }
    });

    const actualEmails = this.emails.match(/[\w'\.-]+@[\w\.-]+\.\w+/g);

    this.busy = true;

    this.userSvc.getUserFromEmails(actualEmails).subscribe(  results => {
      this.busy = false;
      this.emails = '';
      this.emails = results.invalidUsers.join('\n');

      //bind the emails which are not valid email formats
      if (this.emails !== '') {
        this.emails = this.emails + '\n' + this.emailParse.notEmails.join('\n');
      } else if (this.emailParse.notEmails){
        this.emails = this.emailParse.notEmails.join('\n');
      }

      //using valid email in form of array
      this.students = _.uniqBy(this.students.concat(results.validUsers), (user) => user.primaryEmail);

      this.step2Completed = Boolean(this.students.length);

      this.invalidCount = results.invalidUsers.length + this.emailParse.notEmails.length;
      this.processChanges();
    });
  }

  removeValidEmail(user) {
    this.students = this.students.filter( emailEntries => {
     if(emailEntries.primaryEmail !== user.primaryEmail) {
       return emailEntries;
     }
    });

    this.processChanges();
  }

  removeAllEmails() {

    this.dialogData = {
      message: 'Are you sure you want to remove all selected accounts?',
      okBtnLabel: 'Confirm',
      cancelBtnLabel: 'Cancel'
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: this.dialogData
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.students.length = 0;
        this.processChanges();
      }
    });

  }

  processChanges() {
    this.summaryOfChanges.length = 0;

    //changes
    const classIds = [];
    this.selectedClasses.forEach((cls) => {
      classIds.push(cls.id);
    });

    const classMap = this.selectedClasses.reduce((map, cls) => { map[cls.id] = cls; return map; }, {});
    const hasChanges = this.selectedClasses.reduce((map, cls) => { map[cls.id] = false; return map; }, {});

     this.additions = 0;
     this.nochanges = 0;

     this.summaryOfChanges = this.students.reduce( (accum, userToAdd) => {
       // @ts-ignore
       this.allRostersOfClasses.classes.forEach((cls) => {
         const hasStudent = Boolean(cls.roster.find(row => row['student.primaryEmail'] === userToAdd.primaryEmail));
         if(!hasStudent) {
            const relevantData = {
             student: userToAdd,
             action: 'Add student',
             class: classMap[cls.classId]
           };
           accum.push(relevantData);
           this.additions++;
            hasChanges[cls.classId] = true;
         }
         else {
           const relevantData = {
             student: userToAdd,
             action: 'Student already exists',
             class: classMap[cls.classId]
           };
           accum.push(relevantData);
         }
       });
       return accum;
     }, []);

     const noChanges = _.reduce(hasChanges, (col, c, clsId) => {
       if (!c) {
         col.push(clsId);
       }
       return col;
     }, []);

     this.nochanges = noChanges.length;

     if (!this.additions && this.students.length) {
       this.step2Completed = false;
     } else {
       this.step2Completed = Boolean(this.students.length);
       this.showWarning = false;
     }

     this.grids = {
       classes: {
         height: Math.min(80 + this.data.selectedClasses.length * 60, 300)
       },
       summary:{
         height : Math.min(80 + this.data.selectedClasses.length * this.students.length * 60, 300)
       }
     };

     this.gridOptionsSummary.api.setRowData(this.summaryOfChanges);

     this.processReplacements();
  }

  processReplacements() {

    if (!this.summaryOfChanges.length) {
      this.templateReplacements = {};
      return;
    }

    this.generateReplacements(this.selectedClasses);

    // Primary Teacher sample
    const firstTeacher = this.summaryOfChanges[0].class.Owner;
    let primaryTeacherClassCount = 0;

    const priTeacherClassList = this.summaryOfChanges.reduce((accum, curr) => {
      if (curr.action === 'Add student' && curr.class.Owner.primaryEmail === firstTeacher.primaryEmail) {

        primaryTeacherClassCount++;

        accum += `<li><a href="${GridDataService.validateAlternateLink(curr.class.alternateLink)}" target="_blank">${curr.class.name}`;

        if (curr.class.section) {
          accum += ' [' + curr.class.section + ']';
        }

        accum += `</a> (<strong>${curr.student.name}</strong> added)</li>`;
      }
      return accum;
    }, '');


    this.priTeacherReplacements = _.clone(this.templateReplacements);
    this.priTeacherReplacements['{{teacherName}}'] = firstTeacher.name;
    this.priTeacherReplacements['{{classList}}'] = '<ul>' + priTeacherClassList + '</ul>';
    this.priTeacherReplacements['{{classCount}}'] = primaryTeacherClassCount;
    this.priTeacherReplacements['{{classPlural}}'] = primaryTeacherClassCount > 1 ? 'Google Classroom classes' : 'a Google Classroom class';

  }

  //end step 3

  addStudents() {

    const classes = this.selectedClasses.reduce((obj, cls) => {
      obj[cls.id] ={
        clsId: cls.id,
        toAdd: []
      };

      return obj;
    }, {});

    this.summaryOfChanges.forEach((cls) => {
      if (cls.action === 'Add student') {
        classes[cls.class.id].toAdd.push(cls.student.primaryEmail);
      }
    });

    const updatedData = {
      classrooms: Object.values(classes),
      actionType: 'add_student',
      customerActionTemplate: {
        primaryTeacher:  this.customerActionTemplatePrimaryTeacher
      },
      userActionTemplate: {
        primaryTeacher: this.addStudentTemplate
      },
      replyEmail: this.notificationEmail.value,
      sendNotification: this.sendNotification,
    };

    const analyticsEvent = {
      action: 'bulkAddStudents',
      properties: {
        category: 'admin',
        label: 'end'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ADD_STUDENTS][ADD_STUDENTS_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);

    this.dialogRef.close(updatedData);
  }

  onGridReadySummary(params: any) {
    params.api.sizeColumnsToFit();
  }


  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[ADD_STUDENTS][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

}
