import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { CoModule } from '../common/co.module';
import { MaterialModule } from '../../core/services/material/material.module';
import { LicensingCardComponent } from './components/licensing-card/licensing-card.component';
import { LittlesisLicensingService } from './services/littlesis-licensing.service';
import { LicensingOverlayComponent } from './components/licensing-overlay/licensing-overlay.component';
import { LicensingBarComponent } from './components/licensing-bar/licensing-bar.component';
import { LicensingRenewalDialogComponent } from './components/licensing-renewal-dialog/licensing-renewal-dialog.component';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import { LicensingTesterComponent } from './components/licensing-tester/licensing-tester.component';
import { PaidLicensingInfoComponent } from './components/licensing-renewal-dialog/tiers/paid-licensing-info/paid-licensing-info.component';
import { LapsedLicensingInfoComponent } from './components/licensing-renewal-dialog/tiers/lapsed-licensing-info/lapsed-licensing-info.component';
import { TrialLicensingInfoComponent } from './components/licensing-renewal-dialog/tiers/trial-licensing-info/trial-licensing-info.component';
import { ConsultantLicensingInfoComponent } from './components/licensing-renewal-dialog/tiers/consultant-licensing-info/consultant-licensing-info.component';
import { UnlicensedLicensingInfoComponent } from './components/licensing-renewal-dialog/tiers/unlicensed-licensing-info/unlicensed-licensing-info.component';
import {LicensingTierDirective} from './components/licensing-renewal-dialog/directive/licensing-tier.directive';
import { BaseLicensingInfoComponent } from './components/licensing-renewal-dialog/tiers/base-licensing-info/base-licensing-info.component';
import {HelpPopoverComponent} from './components/lapsed-tier-popover/help-popover.component';
import {HelpDialogComponent} from './components/help-dialog/help-dialog.component';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiRequestInterceptorService} from '../auth/services/api-request-interceptor/api-request-interceptor.service';

@NgModule({
    imports: [
      RouterModule,
      CoModule,
      FlexLayoutModule,
      MaterialModule,
      FormsModule,
      NgSelectModule
    ],
  declarations: [
    LicensingTierDirective,
    LicensingCardComponent,
    LicensingOverlayComponent,
    LicensingBarComponent,
    LicensingRenewalDialogComponent,
    LicensingTesterComponent,
    PaidLicensingInfoComponent,
    LapsedLicensingInfoComponent,
    TrialLicensingInfoComponent,
    ConsultantLicensingInfoComponent,
    UnlicensedLicensingInfoComponent,
    BaseLicensingInfoComponent,
    HelpPopoverComponent,
    HelpDialogComponent
  ],
  exports: [
    LicensingCardComponent,
    LicensingOverlayComponent,
    LicensingBarComponent
  ],
  // entryComponents: [],
  providers: [
    LittlesisLicensingService,
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptorService, multi: true}
  ]
})
export class LittlesisLicensingModule { }
