import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

import {AuthService} from '../Auth/auth.service';
import {Observable, of} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';


@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const requiredPermissions = route.data.permissions;

    return this.auth.getActiveUser().pipe(filter(activeUser => !!activeUser),
      switchMap(activeUser => {
        const userPermissions = activeUser.auth.permissions;
        const permissionsArray = userPermissions.filter(permission => requiredPermissions.includes(permission.name));
        if (permissionsArray.length !== requiredPermissions.length) {
          this.router.navigate(['/'])
          return of(false)
        }
        return of(true);
      }))
  }
}
