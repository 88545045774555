import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppSharedModule } from '@amplabs/shared.module';

import { FooterComponent } from 'app/layout/components/footer/footer.component';

import { LittlesisLicensingModule} from '../../../modules/licensing/littlesis-licensing.module';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports     : [
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatToolbarModule,

    AppSharedModule,

    LittlesisLicensingModule
  ],
  exports     : [
    FooterComponent
  ]
})
export class FooterModule {}
