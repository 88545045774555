import {DatePipe} from '@angular/common';
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

import {CustomerService} from 'app/modules/common/services/customer.service';

import {FirebaseAnalyticsService} from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {LocalStorageService} from '../../../../../../core/services/LocalStorage/local-storage.service';
import {
  AuthService,
  User,
} from '../../../../../auth/services/Auth/auth.service';
import {LicenseState} from '../../../../services/licensing.interfaces';
import {LicensingTierTemplate} from '../../interface/licensingTier.interface';
import {LicensingRenewalDialogComponent} from '../../licensing-renewal-dialog.component';
import {BaseLicensingInfoComponent} from '../base-licensing-info/base-licensing-info.component';

@Component({
  selector: 'app-unlicensed-licensing-info',
  templateUrl: './unlicensed-licensing-info.component.html',
  styleUrls: ['../../licensing-renewal-dialog.component.scss']
})
export class UnlicensedLicensingInfoComponent extends BaseLicensingInfoComponent implements OnInit, LicensingTierTemplate {

  @Input() data: {
    licenseState: LicenseState;
    activeUser: User;
    dialogRef: MatDialogRef<LicensingRenewalDialogComponent>;
  };

  constructor( public datePipe: DatePipe,
               public firebaseAnalytics: FirebaseAnalyticsService,
               public localStorageService: LocalStorageService,
               public authService: AuthService,
               public customerService: CustomerService) {
    super(datePipe, firebaseAnalytics, localStorageService, authService, customerService);
  }

  ngOnInit(): void {
    this.sourceData = this.data;
    super.ngOnInit();
    this.buildTemplateData(this.sourceData.licenseState);
  }

  buildTemplateData(licenseState: LicenseState): void {
    this.templateData = {
      domain: licenseState.license.domain,
      plan: 'Unlicensed',
      expiryMessage: `Whoops! It looks like ${licenseState.license.domain} does not have a license for Little SIS for Classroom.`,
    };
  }

}
