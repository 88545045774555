
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { User as SyncUser } from '../Auth/auth.service';

import { Customer } from 'app/modules/common/models/Customer';


import { User, UserResponse } from '../../../../services/user.service';




export interface CustomerSettings {
  /**
   *  Optional reply email to be given to all teachers effected by the changes
   */
  replyEmail?: string;

  /**
   *  action type context for which entry in the database to get
   */
  actionType?: string;

  /**
   *  default domain template related to the customer Id
   */
  customerActionTemplate?: object | string;

  /**
   *  default user template related to the user Id
   */
  userActionTemplate?: object | string;

}

interface ClientInstallResponse {
  success: boolean;
  message: string;
}


@Injectable()
export class ApiLookupService {

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;


  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }


  login(user: SyncUser): Observable<UserResponse> {
    const postBody: User = {
      gId: user.profile.gId,
      primaryEmail: user.profile.primaryEmail,
      photoUrl: user.profile.photoUrl,
      displayName: user.profile.displayName,
      role: user.auth.role,
      token: user.auth.token,
      customerId: user.profile.customerId,
      domain: user.profile.domain
    };
    return this.http.post<UserResponse>(this.serviceUrls.login, postBody, this.httpOptions);
  }

  create(user: SyncUser): Observable<UserResponse> {
    const postBody: User = {
      role_key: user.auth.role_key,
      gId: user.profile.gId,
      primaryEmail: user.profile.primaryEmail,
      photoUrl: user.profile.photoUrl,
      displayName: user.profile.displayName,
      token: user.auth.token,
      customerId: user.profile.customerId,
      domain: user.profile.domain
    };
    return this.http.post<UserResponse>(this.serviceUrls.create, postBody, this.httpOptions);
  }

  offlineLogin(offlineRequestCode: string): Observable<void> {
    const postBody = {
      code: offlineRequestCode
    };
    return this.http.post<void>(this.serviceUrls.offlineLogin, postBody, this.httpOptions).pipe(catchError( (error: HttpErrorResponse) => throwError(() => ({status: 'FAIL'}) )));
  }


  getCustomer(): Observable<Customer> {
    // XXX: Ger - LSC-982 - This appears to be almost a clone of the getCustomer/refreshCustomer on the CustomerService, but without hte side effects of emitting a change on the customer observable. Why do we need both?
    const url = this.serviceUrls.getCustomer;
    return this.http.get<Customer>(url, this.httpOptions);
  }

  getCustomerSettings(actionType?: string): Observable<CustomerSettings> {
    // XXX: Ger  - LSC-982 - This is a clone of the getCustomerSettings on the Customerservice. Why do we need both?
    const url = this.serviceUrls.getCustomerSettings.replace(':actionType', actionType);
    return this.http.get<CustomerSettings>(url, this.httpOptions);
  }

  updateCustomer(customerData: Customer): Observable<Customer> {
    const url = this.serviceUrls.updateCustomer;
    return this.http.patch<Customer>(url, customerData, this.httpOptions);
  }

  checkAuth(): Observable<ClientInstallResponse> {
    const url = this.serviceUrls.adminConsole.validate;
    return this.http.get<ClientInstallResponse>(url, this.httpOptions);
  }

}
