import {AfterContentChecked, Component, ElementRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements AfterContentChecked   {

  passwordVisible = false;
  emailStatus: string;

  private positionRelativeToElement: ElementRef;

  constructor(public dialogRef: MatDialogRef<HelpDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public options: { positionRelativeToElement: ElementRef }
  ) {

    this.positionRelativeToElement = options.positionRelativeToElement;
  }

  ngAfterContentChecked() {
    const matDialogConfig = new MatDialogConfig();
    // @ts-ignore
    const rect: DOMRect = this.positionRelativeToElement._elementRef.nativeElement.getBoundingClientRect();
    matDialogConfig.position = {left: `${rect.left - 260}px`, top: `${rect.top - 230}px`};
    this.dialogRef.updatePosition(matDialogConfig.position);
  }

}
