import { DatePipe } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';

import {
  combineLatest,
  Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Customer } from 'app/modules/common/models/Customer';
import { environment } from '../../../../../../../environments/environment';
import { FirebaseAnalyticsService } from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { LocalStorageService } from '../../../../../../core/services/LocalStorage/local-storage.service';
import {
  AuthService,
  User,
} from '../../../../../auth/services/Auth/auth.service';
import {
  CustomerService,
} from '../../../../../common/services/customer.service';
import { LicenseState } from '../../../../services/licensing.interfaces';
import { LicensingRenewalDialogComponent } from '../../licensing-renewal-dialog.component';

const RENEW_DIALOG_KEY_30DAYS = 'mute_renew_dialog_30DAYS';
const RENEW_DIALOG_KEY_14DAYS = 'mute_renew_dialog_14DAYS';

@Component({
  selector: 'app-parent-licensing-info',
  template: `<p></p>`,
  styles: []
})
export class BaseLicensingInfoComponent implements OnInit, OnDestroy {

  muteNotification: boolean;

  sourceData: {
    licenseState: LicenseState;
    activeUser: User;
    dialogRef: MatDialogRef<LicensingRenewalDialogComponent>;
  };

  templateData: {
    domain: string;
    expireDate?: string;
    expireDays?: number;
    expiryMessage?: string;
    showIcon?: boolean;
    showSupportButton?: boolean;
    showRecheckButton?: boolean;
    showRenewalButton?: boolean;
    plan?: string;
    pastDeleteDataWindow?: boolean;
    lastTierExpiration?: {
      type: string;
      date: Date;
    }
  };

  private onDestroy$: Subject<void> = new Subject<void>();
  private user: User;
  private customer: Customer;

  constructor(
    public datePipe: DatePipe,
    public firebaseAnalytics: FirebaseAnalyticsService,
    public localStorageService: LocalStorageService,
    public authService: AuthService,
    public customerService: CustomerService
   ) {
    combineLatest([
      this.authService.getActiveUser(),
      this.customerService.getCurrentCustomer()
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([user, customer]) => {
        this.user = user;
        this.customer = customer;
      });
  }

  ngOnInit(): void {
    if (this.sourceData.licenseState.expiresIn <= 7) {
      this.muteNotification = false;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  checkMuteStatus(): void {
    if (this.sourceData.licenseState.expiresIn > 7 && this.sourceData.licenseState.expiresIn < 15) {
      this.muteNotification = this.localStorageService.get(RENEW_DIALOG_KEY_14DAYS) === 'true';
    }

    if (this.sourceData.licenseState.expiresIn >= 15 && this.sourceData.licenseState.expiresIn <=30) {
      this.muteNotification = this.localStorageService.get(RENEW_DIALOG_KEY_30DAYS) === 'true';
    }
  }

  requestRenewal(): void {
    const url = environment.links.renew
      .replace(/{app_name}/g, 'Little SIS for Classroom')
      .replace(/{customerId}/g, this.customer.customerId)
      .replace(/{domain}/g, this.customer.domain)
      .replace(/{user_name}/g, this.user.profile.displayName);

    this.firebaseAnalytics.sendEvent({action: 'Quote request', properties: {category: 'App', label: 'Quote'}});
    window.open(url);
  }

  requestSupport(domain: string): void {
    // eslint-disable-next-line max-len
    const url = `https://mail.google.com/mail/?view=cm&source=mailto&to=${environment.support.email}&su=Little SIS for Classroom license support request for ${domain}.`;
    window.open(url);
  }

  requestTrial(): void {
    const url = 'https://resources.amplifiedit.com/little-sis-for-classroom-trial';
    window.open(url);
  }

  openHelpCenter(): void {
    const url = environment.support.helpCenterLink;
    window.open(url);
  }

  toggleReminder(e: MatCheckboxChange): void {
    if (this.templateData.expireDays > 7 && this.templateData.expireDays < 15) {
      this.localStorageService.set(RENEW_DIALOG_KEY_14DAYS, String(e.checked));
    }

    if (this.templateData.expireDays >= 15 && this.templateData.expireDays <= 30) {
      this.localStorageService.set(RENEW_DIALOG_KEY_30DAYS, String(e.checked));
    }
  }

  logout(): void {
    this.authService.signOut(true);
  }

  closeDialog(): void {
    this.sourceData.dialogRef.close();
  }

  isSuperAdmin(): boolean {
    return this.sourceData.activeUser.auth.role_key === 'sa';
  }
}
