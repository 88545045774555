import {GridSettings} from '../modules/explorer/services/grid-state.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {}

  get(token: string) {
    const settings = localStorage.getItem(token);
    return settings ? JSON.parse(settings) : settings;
  }

  set(label: string, gridConfig: GridSettings): void {
    localStorage.setItem(label, JSON.stringify(gridConfig));
  }

  remove(label: string): void {
    localStorage.removeItem(label);
  }

}
