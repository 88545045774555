import { GridReadyEvent } from '@ag-grid-community/core';
import {
  Inject,
  Injectable,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import * as _ from 'lodash';
import * as moment from 'moment';
import {
  Observable,
  Subject,
} from 'rxjs';
import {
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  AgGridBooleanCheckCellRendererComponent,
  AgGridClassroomCellRendererComponent,
  AgGridPrimaryTeacherProfileCellRendererComponent,
  AgGridProfileCellRendererComponent,
  AgGridSelectAllHeaderComponent,
  GridDataService
} from '@cdw-ae/little-sis-for-classroom-ag-grid';
import {
  ClassActions,
  Course,
} from '../../../../services/classroom.service';
import {
  CustomerFirestore,
  CustomerSettings,
  FirestoreCustomerService,
} from '../../../../services/firestore-customer.service';
import { UtilService } from '../../../../services/util.service';
import { AuthService } from '../../../auth/services/Auth/auth.service';
import { ApiLookupService } from '../../../auth/services/api-lookup/api-lookup.service';

@Injectable()
export abstract class GenericBulkActionComponent implements OnDestroy {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  gridOptions;
  columnDefs;
  context;
  frameworkComponents;

  busy = false;

  nav: {
    nextDisabled: boolean;
    prevDisabled: boolean;
  };

  step1Completed: boolean;
  step2Completed: boolean;

  templateReplacements: object;

  defaultTMPL: string;
  defaultUserTemplate: string;
  defaultTemplate: string;
  customerActionTemplate: string | object;
  userActionTemplate: string | object;
  tokens: object[];

  classList: string;

  selectedItems: string[] = [];
  selectedClasses: Course[] = [];
  invalidClasses = [];
  validClasses = [];

  notificationEmail = new FormControl('', [
    Validators.email
  ]);

  isSuperAdmin: boolean;
  sendNotification: boolean;

  // Customer level setting from firebase customer to enforce customer notifications
  requireNotification: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor( public apiLookup: ApiLookupService,
               @Inject(MAT_DIALOG_DATA) public data: ClassActions,
               protected _authSvc: AuthService,
               protected _firestoreCustomerService: FirestoreCustomerService,
              //  private injector: Injector
               ) {

    this.context = { componentParent: this };

    this.frameworkComponents = {
      primaryTeacherProfileCellRenderer: AgGridPrimaryTeacherProfileCellRendererComponent,
      classroomCellRenderer: AgGridClassroomCellRendererComponent,
      booleanCellRenderer: AgGridBooleanCheckCellRendererComponent,
      profileCellRenderer: AgGridProfileCellRendererComponent,
      selectAllHeader: AgGridSelectAllHeaderComponent,
    };

    this.columnDefs = [
      {
        field: '',
        headerName: '',
        width: 40,
        sortable: false,
        resizable: false,
        filter: false,
        hide: false,
        checkboxSelection: true,
        headerComponent: 'selectAllHeader',
        headerComponentParams: {
          selectedItems: this.selectedItems
        },
        pinned: 'left',
        lockPinned: true,
        lockPosition: true,
        lockVisible: true,
        suppressSizeToFit: true
      },

      {
        headerName: 'Course',
        field: 'name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: 'classroomCellRenderer',
        autoHeight: true,
        suppressMenu: false
      },

      {
        headerName: 'Primary Teacher',
        field: 'Owner.name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: 'primaryTeacherProfileCellRenderer',
        autoHeight: true,
        hide: true,
        suppressMenu: true
      },

      {
        headerName: 'Section',
        field: 'section',
        width: 80,
        filter: false,
        cellClass: 'text-center',
        hide: true,
        suppressMenu: true
      },
      {
        headerName: 'Created',
        field: 'creationTime',
        width: 60,
        cellClass: 'text-center',
        filter: 'agDateColumnFilter',
        sortable: true,
        suppressMenu: true,
        hide: false,
        valueFormatter: (cellParams) => moment(cellParams.value).format('MM/DD/YY HH:mm'),
      },
      {
        headerName: 'Updated',
        field: 'updateTime',
        width: 60,
        cellClass: 'text-center',
        filter: 'agDateColumnFilter',
        sortable: true,
        suppressMenu: true,
        hide: false,
        valueFormatter: (cellParams) => moment(cellParams.value).format('MM/DD/YY HH:mm'),
      },
    ];


    this.gridOptions = {
      columnDefs: this.columnDefs,
      components: this.frameworkComponents,
      context: this.context,
      getContextMenuItems: this.getContextMenuItems,

      rowModelType: 'clientSide',
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      rowBuffer: 100,
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      animateRows: true,
      clipboardDelimiter: ',',
      suppressCopyRowsToClipboard: true,
      pagination: false,
      defaultColDef: {
        filter: true,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: true,
        hide: false,
        resizable: true,
        sortable: true
      },
    };


    this.nav = {
      nextDisabled: true,
      prevDisabled: false
    };

    this.step1Completed = false;

    this.isSuperAdmin = this._authSvc.isSuperAdmin();

    this.defaultUserTemplate = '<p>If you have any questions, please contact me.</p><p>{{myName}}</p>';
    this.userActionTemplate = this.defaultUserTemplate;

    this.tokens = [
      { id: '1', toolTip: 'Insert teacher name', icon: 'person_outline', insertString: '{{teacherName}}' },
      { id: '2', toolTip: 'Insert class list', icon: 'assignment', insertString: '{{classList}}'},
      { id: '3', toolTip: 'Insert your name', icon: 'account_box', insertString: '{{myName}}'}
    ];

    this.requireNotification = data.requireNotification;

    this._firestoreCustomerService.getCustomer()
      .pipe(
        takeUntil(this.onDestroy$),
        map((customer: CustomerFirestore) => {
          const settings = Object.assign({
            forceTeacherNotification: false
          }, customer.settings);
          return settings;
        })
      )
      .subscribe((settings: CustomerSettings) => {
        this.requireNotification = settings.forceTeacherNotification || this.requireNotification || false;
      });

  }

  getContextMenuItems(params) {

    return [
      {
        name: 'Clear filters',
        action: () => {
          params.context.componentParent.gridOptions.api.setFilterModel(null);
        },
        tooltip: 'Clear filters'
      }
    ];

  }

  onSelectionChanged(event): void {

    const rows = event.api.getSelectedNodes();

    if (!rows.length) {
      this.clearSelectedItems();
    } else {
      this.selectedClasses = _.map(rows, 'data');
      this.selectedItems = _.map(rows, 'data.id');
    }

    this.validateSelection();
  }

  clearSelectedItems(): void {
    const rows = this.gridOptions.api.getSelectedNodes();

    if (rows.length) {
      this.gridOptions.api.deselectAll();
    }

    this.selectedItems.length = 0;
    this.selectedClasses.length = 0;
  }

  onGridReady(params: GridReadyEvent): void {

    params.api.sizeColumnsToFit();

  }


  /**
   * Listen for templateChange events and update local properties when set
   *
   * @param event
   */
  templateChanged(event): void {

    for (const key in event) {
      this[key] = event[key];
    }

  }

  navigation(event): void {
    this.validateSelection();
  }

  getDefaultPhotoUrl(): string {
    return UtilService.defaultPhotoUrl;
  }

  move(index: number): void {
    this.stepper.selectedIndex = index;
  }

  goBack(stepper: MatStepper): void {
    stepper.previous();

    if (stepper.selectedIndex === 0 ) {
      this.validateSelection();
    }
  }

  goForward(stepper: MatStepper): void {
    stepper.next();
  }

  initialize(type: string): Observable<void> {

    this.sendNotification = true;

    return this.apiLookup.getCustomerSettings(type)
    .pipe(
      tap((results) => {
        if (results) {
          this.customerActionTemplate = results.customerActionTemplate || this.defaultTMPL;
          this.userActionTemplate = results.userActionTemplate || this.defaultUserTemplate;

          if (!this.isSuperAdmin) {
            this.notificationEmail.setValue(this._authSvc.getCurrentUser().profile.primaryEmail);
            this.notificationEmail.disable();
          } else {
            this.notificationEmail.setValue(results.replyEmail);
          }

        } else {

          this.customerActionTemplate = this.defaultTMPL;
          this.userActionTemplate = this.defaultUserTemplate;
        }
      }),
      map(() => null)
    );
  }

  generateReplacements(classes): void {

    const firstTeacherClassList = classes.reduce((accum, curr) => {
      if (curr.Owner?.gId === classes[0].Owner?.gId) {
        accum = accum + `<li><a href='${GridDataService.validateAlternateLink(curr.alternateLink)}' target="_blank">${curr.name}`;

        if (curr.section) {
          accum += ' [' + curr.section + ']';
        }

        accum += '</a></li>';
      }
      return accum;
    }, '');

    const firstTeacherClassesLength = classes.filter( (_class) => _class.Owner?.primaryEmail === classes[0].Owner?.primaryEmail).length ;

    this.templateReplacements =  {
      '{{teacherName}}': (classes.length ? classes[0].Owner?.name : ''),
      '{{classList}}': '<ul>' + firstTeacherClassList + '</ul>',
      '{{classPlural}}': (classes.length > 1 ? 'Google Classroom classes' : 'Google Classroom class'),
      '{{hasPlural}}': (firstTeacherClassesLength === 1 ? 'has' : 'have'),
      '{{thisPlural}}': (classes.length === 1 ? 'this class' : 'these classes'),
      '{{classCount}}': firstTeacherClassesLength,
      '{{userName}}': this._authSvc.getCurrentUser().profile.displayName,
      '{{myName}}': this._authSvc.getCurrentUser().profile.displayName,
      '{{actingUserName}}': this._authSvc.getCurrentUser().profile.displayName,
      '{{actingUserProfileImageUrl}}': this._authSvc.getCurrentUser().profile.photoUrl || UtilService.defaultPhotoUrl
    };

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  protected validateSelection(): void {

    const selectableCount = (this.validClasses.length) ? this.validClasses.length : this.data.selectedClasses.length;

    if (this.selectedItems.length === selectableCount) {
      this.nav.nextDisabled = false;
      this.step1Completed = true;

    } else {
      this.nav.nextDisabled = true;
      this.step1Completed = false;
    }

  }

}
