import {Injectable} from '@angular/core';

import * as introJs from 'intro.js/intro.js';

interface Step {
  intro: string;
  tooltipClass?: string;
  selectorMethod?: string;
  selectors?: string;
  selectorIdx?: number;
}

interface Tour {
  steps: Step[];
}

@Injectable({
  providedIn: 'root'
})
export class IntroJsService {

  introJS = introJs();
  introOptions: {};
  tours: {
    [index: string]: Tour;
  };

  constructor() {

    this.introOptions = {
      hidePrev: true,
      hideNext: true,
      highlightClass: 'tour',
      disableInteraction: true,
      overlayOpacity: 0.6,
      showButtons: true,
      showStepNumbers: false,
    };

    this.tours = {
      explorer: {
        steps: [
          {
            intro: 'The following guide provides a summary of some of the key features of the Classroom Explorer.',
            tooltipClass: 'wide'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '.ag-header-container .ag-header-row',
            selectorIdx: 0,
            intro: 'You can drag grid column headers to reorder and resize them'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '.ag-header-container .ag-header-row',
            selectorIdx: 0,
            intro: 'Additionally, clicking on a column will toggle the current sort'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '.ag-header-container .ag-header-row',
            selectorIdx: 1,
            intro: 'The 2nd header row allows you to filter the Google Classroom classes by any column'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '#columnVis',
            selectorIdx: 0,
            intro: 'This number indicates how many columns are hidden'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '.ag-side-button',
            selectorIdx: 0,
            intro: 'Select which columns are visible in the Grid'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '.ag-side-button',
            selectorIdx: 1,
            intro: 'Saved Layouts allows you to store the visible columns, including the column order and width. You can load a previously saved layout'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '.ag-side-button',
            selectorIdx: 1,
            intro: 'Saved layouts can be shared with other users from your domain'
          },

          {
            selectorMethod: 'querySelectorAll',
            selectors: '.ag-side-button',
            selectorIdx: 2,
            intro: 'The Filters tool panel allows you to edit filters from a different context'
          }

        ]
      },

      insights: {
        steps: [
          {
            selectorMethod: 'querySelectorAll',
            selectors: '.timeframe-input',
            selectorIdx: 0,
            intro: 'Use this to control the date range you are interested in'
          }
        ]
      },

      welcome: {
        steps: [
          {
            intro: 'Welcome to Little SIS for Classroom. You will be able to get a better understanding of the use of Google Classroom within your Google domain.',
            tooltipClass: 'wide'
          }]
      }

    };

  }

  private bindToDOM(tour) {
    tour.steps = tour.steps.map((step) => {

      if (step.selectorMethod) {
        step.element = document[step.selectorMethod](step.selectors)[step.selectorIdx];
      }

      return step;
    });
    return tour;
  }

  guidedTour(which) {

    if (this.tours[which] === undefined) {
      console.error(`No guide found for [${which}]`);
      return;
    }

    const opts = Object.assign({}, this.introOptions, this.bindToDOM(this.tours[which]));

    this.introJS.setOptions(opts);

    this.introJS.start();
  }

}
