import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';


@Directive({
  selector: '[appExactNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ExactNumberValidatorDirective,
      multi: true
    }
  ]
})
export class ExactNumberValidatorDirective implements Validator {
  @Input('appExactNumberValidator') exactNumber: number;

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.exactNumber ? this.exactNumberValidator(this.exactNumber, control.value) : null;
  }

  exactNumberValidator(exactNumber: number, controlValue: number): {[key: string]: any} {
    if (exactNumber === controlValue) {
      return null;
    }
    return {
      ecaxtNumberValidator: {
        valid: false
      }
    };
  }
}
