import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  permission: {
    description: string;
    help_link: string;
    label: string;
  };
  group: {
    name: string;
  };
}

@Component({
  selector: 'app-permission-description',
  templateUrl: './permission-description.component.html',
  styleUrls: ['./permission-description.component.scss']
})

export class PermissionDescriptionDialogComponent implements OnInit {

  constructor(

    public dialogRef: MatDialogRef<PermissionDescriptionDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

  }

  async confirm(): Promise<void> {
    this.dialogRef.close(this.data);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
