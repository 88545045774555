import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scheduledTaskType'
})

export class ScheduledTaskTypePipe implements PipeTransform {

  transform(type: string): string {

    let str;

    switch (type) {
      case 'removeCoTeacher':
        str = 'Co-teacher removal';
        break;
      case 'removeStudent':
        str = 'Student removal';
        break;
      default:
        str = 'Scheduled task';
        break;
    }

    return str;
  }
}
