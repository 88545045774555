import { NgModule } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { LocalStorageService } from '../../core/services/LocalStorage/local-storage.service';
import { InstallationService } from '../../core/services/installation/installation.service';
import { MessagesService } from '../../core/services/messages/messages.service';
import { AnalyticsService } from '../../services/analytics.service';
import { DriveService } from '../../services/drive.service';
import { FirestoreGridService } from '../../services/grids.service';
import { MailService } from '../../services/mail.service';
import { SchoolService } from '../../services/schools.service';
import { TimeframeService } from '../../services/timeframes.service';
import { UtilService } from '../../services/util.service';
import { ContextService } from '../common/services/context.service';
import { CustomerService } from '../common/services/customer.service';
import { SupportService } from '../common/services/support.service';
import { SheetsService } from '../exports/services/sheets.service';

@NgModule({
  imports: [
  ],
  declarations: [
  ],
  // entryComponents: [
  // ],
  providers: [
    AngularFirestore,
    LocalStorageService,
    SchoolService,
    UtilService,
    TimeframeService,
    CustomerService,
    ContextService,
    InstallationService,
    MessagesService,
    FirestoreGridService,
    AnalyticsService,
    SheetsService,
    MailService,
    SupportService,
    DriveService
  ]

})

export class CoreModule { }
