import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { SplashPageComponent } from './core/components/splash-page/splash-page.component';
import { LoginComponent} from './layout/components/login/login.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { WelcomeComponent} from './core/components/welcome/welcome.component';
import { AuthGuard } from './modules/auth/services/admin-auth-guard/auth.guard';


const appRoutes: Routes = [

  { path: '', component: LoginComponent },

  { path: 'setup', canActivate: [AuthGuard], component: SplashPageComponent },

  { path: 'welcome', canActivate: [AuthGuard], component: WelcomeComponent },

  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule) },

  { path: 'tags', loadChildren: () => import('./modules/tags/tags.module').then((m) => m.TagsModule) },

  { path: 'home', loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule) },

  { path: 'explorer', loadChildren: () => import('./modules/explorer/explorer.module').then((m) => m.ExplorerModule) },

  { path: 'students', loadChildren: () => import('./modules/students/students.module').then((m) => m.StudentsModule) },

  { path: 'sync', loadChildren: () => import('./modules/navSync/navSync.module').then((m) => m.NavSyncModule) },

  { path: 'insights', loadChildren: () => import('./modules/insights/insights.module').then((m) => m.InsightsModule) },

  { path: 'product', loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule) },

  { path: 'collections', loadChildren: () => import('./modules/collections/collections.module').then((m) => m.CollectionsModule) },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false, useHash: true }),
  ],
  declarations: [],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }

