import * as _ from 'lodash';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ListResponse } from '../core/models/api.interface';

export class GenericService {

  protected serviceUrls = environment.urls.userServices;
  protected type;

  httpOptions: { headers: HttpHeaders; body?: any };

  constructor(protected http: HttpClient, opts) {

    this.type = opts.type;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }

  compactFilter(filterObj) {

    return {
      c: filterObj.field,
      f: {
        type: 'contains',
        filterType: 'text',
        filter: filterObj.value
      }
    };

  }

  compactAGFilter(filterObj, field) {

    return {
      c: field,
      f: filterObj
    };

  }

  find(options = {}, opts: any = {useCache: true}): Observable<ListResponse> {

    let url = this.serviceUrls[this.type].route;
    // LSC-2231 - this is commented out until after the audits table
    // has migrated to the central database
    // if(this.type === 'audits'){
    //   url = this.serviceUrls[this.type].legacyGet;
    // }

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filterModel') {

          _.each(options[param], (filter, field) => {

            // if (options[param][field].filterType === "set") {
            //   _.each(filter.values, function(val) {
            //
            //     url += `filter[]=${JSON.stringify(_this.compactAGFilter({filter: val, operator: 'equals' }, field))}&`;
            //   });
            //
            // } else
            if (options[param][field].filterType === 'date') {

              url += `filter[]=${JSON.stringify(_this.compactAGFilter(options[param][field], field))}&`;

            } else {
              // _.each(options[param], (filter, field) => {
              url += `filter[]=${JSON.stringify(_this.compactAGFilter(filter, field))}&`;
              // });
            }

          });

        } else if (param === 'filter') {

          _.each(options[param].filters, function(f) {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });
        } else if (param === 'sortModel') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.sort) {
                url += `sortBy[]=${srt.colId}&`;
                url += `sortDir[]=${srt.sort}&`;
              }

            });

          }

        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<ListResponse>(url, this.httpOptions);

  }

}
