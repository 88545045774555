import {
  FilterChangedEvent,
  GetContextMenuItemsParams,
  LoadSuccessParams,
  MenuItemDef,
  ProcessCellForExportParams,
} from '@ag-grid-community/core';
import { KeyValue } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  SESSION_STORAGE,
  StorageService,
} from 'ngx-webstorage-service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  skip,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  AgGridIconHeaderComponent,
  AgGridLittleSisCoTeacherCellRendererComponent,
  AgGridTextWithDefaultIconCellRendererComponent,
} from '@cdw-ae/angular-ui-cdw-ag-grid';
import {
  AgGridAliasesCellRendererComponent,
  AgGridAliasesCountCellRendererComponent,
  AgGridBooleanCheckCellRendererComponent,
  AgGridClassroomStateCellRendererComponent,
  AgGridDefaultTextCellRendererComponent,
  AgGridLoadingCellRendererComponent,
  AgGridOrgUnitCellRendererComponent,
  AgGridPrimaryTeacherProfileCellRendererComponent,
  AgGridSchoolCellRendererComponent,
  AgGridSchoolFilterComponent,
  AgGridSelectAllHeaderComponent,
  AgGridSelectedCountFloatingFilterComponent,
  AgGridTagCellRendererComponent,
  AgGridTimeframeFilterComponent,
  AgGridValueLabelCellRendererComponent,
  ClassroomCellRendererComponent,
} from '@cdw-ae/little-sis-for-classroom-ag-grid';

import { ListResponse } from '../../../../core/models/api.interface';
import { LscEvents } from '../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  ClassroomService,
  Course,
} from '../../../../services/classroom.service';
import { FeatureToggleService } from '../../../../services/feature-toggle.service';
import { IntroJsService } from '../../../../services/intro-js.service';
import { MessageService } from '../../../../services/message.service';
import {
  GridSchool,
  SchoolService,
} from '../../../../services/schools.service';
import { LocalStorageService } from '../../../../services/storage.service';
import { Tag } from '../../../../services/tags.service';
import { TimeframeService } from '../../../../services/timeframes.service';
import { UtilService } from '../../../../services/util.service';
import { AuthService } from '../../../auth/services/Auth/auth.service';
import { ContextService } from '../../../common/services/context.service';
import { AddSelfToClassesComponent } from '../../../custom-dialogs/components/add-self-to-classes/add-self-to-classes.component';
import { InsightFilters } from '../../../insights/services/insights.service';
import { TaskService } from '../../../jobs/services/task.service';
import { LicenseState } from '../../../licensing/services/licensing.interfaces';
import { ClassroomGridService } from '../../services/classroom-grid.service';
import { ExportClassroomService } from '../../services/export-classroom.service';
import { GridStateService } from '../../services/grid-state.service';
import {
  ColumnDefinitionHash,
  GridService,
} from '../../services/grid.service';
import { AccessFiltersToolPanel } from '../ag-grid/tool-panels/access-filters-tool-panel/access-filters-tool-panel.component';
import { AccessFiltersService } from '../ag-grid/tool-panels/access-filters-tool-panel/access-filters.service';
import { SavedViewsToolPanelComponent } from '../ag-grid/tool-panels/saved-views-tool-panel/saved-views-tool-panel.component';
import { ClassArchiveDialogComponent } from '../dialogs/archive-classes/archive-class-dialog.component';
import { BulkAddCoTeacherDialogComponent } from '../dialogs/bulk-add-coTeacher/bulk-addCoTeacher.component';
import { BulkAddStudentDialogComponent } from '../dialogs/bulk-add-students/bulk-add-students.component';
import { BulkDeleteClassesDialogComponent } from '../dialogs/bulk-deleteClasses/bulk-deleteClasses-dialog.component';
import { BulkRemoveCoteacherDialogComponent } from '../dialogs/bulk-removeCoteacher/bulk-removeCoteacher-dialog.component';
import { BulkRemoveStudentDialogComponent } from '../dialogs/bulk-removeStudents/bulk-removeStudent-dialog.component';
import { ChangePrimaryTeacherComponent } from '../dialogs/change-primary-teacher/change-primary-teacher.component';
import { ClassesBulkActionDialogComponent } from '../dialogs/classes-bulk-action/classes-bulk-action-dialog.component';
import { ErrorDialogComponent } from '../dialogs/error-confirm/error-dialog.component';
import { JoinClassAsStudentComponent } from '../dialogs/join-class-as-student/join-class-as-student.component';
import { RefreshSelectedDialogComponent } from '../dialogs/refresh-selected/refresh-selected.component';
import { UpdateTagsDialogComponent } from '../dialogs/update-tags/update-tags.component';
import { getColumnDefs } from './classroom-explorer-column-definitions';
import { sideBar } from './classroom-explorer-sidebar-definition';
import {
  BulkClassesActions,
  GridTimeframe,
  LSIServerSideGetRowsParams,
} from './grid.interfaces';
import { LittlesisLicensingService } from 'app/modules/licensing/services/littlesis-licensing.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-grd',
  templateUrl: './grd.component.html',
  styleUrls: ['./grd.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class GrdComponent implements OnInit, OnDestroy {
  exportEndpoint = environment.cloudRunInstances.exports;

  frameworkComponents;
  sideBar = sideBar;
  detailCellRendererParams;
  gridOptions;
  columnDefs; // Almost type ColumnSettings[] (from '../../services/grid-state.service') but needs some tweeks to get it to fit
  context;
  datasource;

  filtersChanged$ = new Subject<FilterChangedEvent>();

  columnDefHash: ColumnDefinitionHash;

  columns$ = new BehaviorSubject([]);

  customerId: string;
  superAdmin: boolean;
  currentUserEmail: string;
  gridView: ListResponse;

  // message: any;
  subscription: Subscription;

  multiple = true;

  classes: Course[];

  selectedItems: string[] = [];
  selectedClasses: Course[] = [];

  busy: boolean;
  state: {
    busy: boolean;
  };

  tag: Tag;

  custAccessStates = [];

  originalAccessState;

  activeState: number;
  activeAccessState: number;

  showAccessState: boolean;
  prevAccessState: any;

  taskState: any;
  activeAccessFilter: any;

  successCallbackRef: (params: LoadSuccessParams) => void;
  total = 0;
  autoHeightActive = false;
  checked = false;
  indeterminate = false;

  filtersActive: boolean;
  activeFilters: any[] = [];

  filters: InsightFilters;
  creationTime: any;
  presets: {[name: string]: any[]};

  schools: GridSchool[];
  schoolIds: string[];

  timeframes: GridTimeframe[];
  timeframeIds: string[];
  licenseState$: Observable<LicenseState>;
  limitWarningText = LittlesisLicensingService.limitWarningText;
  gridApi;
  private actionsLimited = false;
  private initialAction: {
    action: string;
    classroomId?: string;
  };
  // private loadingCellRenderer;
  // private loadingCellRendererParams;
  private gridColumnApi;
  private originalState;
  private onDestroy$: Subject<void> = new Subject<void>();

  private useCache = true;

  constructor(
    private route: ActivatedRoute,
    private apiLookup: ClassroomService,
    private authService: AuthService,
    private gridService: GridService,
    public gridStateService: GridStateService,
    public cgs: ClassroomGridService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private messageService: MessageService,
    public featureToggleService: FeatureToggleService,
    private schoolSvc: SchoolService,
    private timeframeSvc: TimeframeService,
    private taskSvc: TaskService,
    private auth: AuthService,
    private _accessFilterSvc: AccessFiltersService,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    private _intro: IntroJsService,
    private localstorageSvc: LocalStorageService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private _contextSvc: ContextService,
    private _exportClassroomSvc: ExportClassroomService,
    private _licenseSvc: LittlesisLicensingService,
  ) {

    // this.activeFilters = [];

    this._accessFilterSvc.getActiveAccessFilter().pipe(takeUntil(this.onDestroy$)).subscribe((filter) => {
      this.activeAccessFilter = filter;
    });

    this._contextSvc.watchPresets().pipe(takeUntil(this.onDestroy$)).subscribe((presets) => {
      this.presets = presets;
    });

    this.schoolSvc.getAccessRestrictedSchools()
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((schools) => {
      this.schoolIds = Object.values(schools).map((s)=> s.id.toString());
      this.schoolIds.push('(Missing)');
    });


    this.timeframeSvc.getAll().subscribe((timeframes) => {
      this.timeframeIds = Object.values(timeframes).map((s)=> s.id.toString());
      this.timeframeIds.push('(Missing)');
    });

    this.filters = {
      timeframe: {
        range: {
          startDate: null,
          endDate: null
        }
      }
    };

    this.authService.getCustomerId().subscribe((data) => {
      if(!data) {return;}
      this.customerId = data;
    });

    this.superAdmin = (this.auth.getCurrentUser() && this.auth.getCurrentUser().auth.role_key === 'sa');

    this.currentUserEmail = this.auth.getCurrentUser().profile.primaryEmail;

    // Main grid column definitions
    this.columnDefs = getColumnDefs(this);

    this.columnDefHash = this.columnDefs.reduce((collection, col) => {
      collection[col.field] = {
        headerName: col.headerName
      };
      return collection;
    }, {});

    /**
     * Custom getRowHeight method - can be updated and used rather than relying on autoHeight
     *
     * @param params
     * @returns {number}
     */
    // let getRowHeightFn = (params) => {
    //
    //   const visibleAutoHeight = params.node.columnModel.displayedAutoHeightCols.map((c) => c.colDef.headerName);
    //
    //   const heightOptions = _.reduce(visibleAutoHeight, (heights, col) => {
    //
    //     switch(col) {
    //       case 'Primary Teacher Profile':
    //         heights.push(60);
    //         break;
    //
    //       case 'Teachers':
    //         heights.push(params.data.coteachers.length * 25 + 10);
    //         break;
    //
    //       default:
    //         break;
    //
    //     }
    //
    //     return heights;
    //
    //   }, [28]);
    //
    //   return Math.max(...heightOptions);
    //
    // };

    const isExternalFilterPresent = () => true;

    const doesExternalFilterPass = (node) => true;

    const processCellForClipboard = (params: ProcessCellForExportParams) =>
      (params?.column?.getColDef() as any)?.processCellForClipboard?.(params) || params?.value;

    this.context = { componentParent: this };

    this.frameworkComponents = {
      aliasesCellRenderer: AgGridAliasesCellRendererComponent,
      aliasesCountCellRenderer: AgGridAliasesCountCellRendererComponent,
      coteacherCellRenderer: AgGridLittleSisCoTeacherCellRendererComponent,
      tagCellRenderer: AgGridTagCellRendererComponent,
      schoolCellRenderer: AgGridSchoolCellRendererComponent,
      primaryTeacherProfileCellRenderer: AgGridPrimaryTeacherProfileCellRendererComponent,
      oUsCellRenderer: AgGridOrgUnitCellRendererComponent,
      booleanCheckCellRenderer: AgGridBooleanCheckCellRendererComponent,
      classroomCellRenderer: ClassroomCellRendererComponent,
      valueCellRenderer: AgGridValueLabelCellRendererComponent,
      defaultTextCellRenderer: AgGridDefaultTextCellRendererComponent,
      textWithDefaultIconCellRenderer: AgGridTextWithDefaultIconCellRendererComponent,

      iconHeader: AgGridIconHeaderComponent,
      selectAllHeader: AgGridSelectAllHeaderComponent,
      accessFiltersToolPanel: AccessFiltersToolPanel,
      savedViewsToolPanel: SavedViewsToolPanelComponent,

      timeframeFilter: AgGridTimeframeFilterComponent,
      schoolFilter: AgGridSchoolFilterComponent,

      selectedCountFloatingFilter: AgGridSelectedCountFloatingFilterComponent,
      classroomStateCellRenderer: AgGridClassroomStateCellRendererComponent,
      customLoadingCellRenderer: AgGridLoadingCellRendererComponent,
    };

    this.gridOptions = {
      columnDefs: this.columnDefs,
      components: this.frameworkComponents,
      context: this.context,
      api: this.gridApi,
      columnApi: this.gridColumnApi,
      sideBar: this.sideBar,
      getContextMenuItems: this.getContextMenuItems,
      getRowId: (data) => data?.data?.id,
      isExternalFilterPresent,
      doesExternalFilterPass,
      processCellForClipboard,

      rowModelType: 'serverSide',
      rowSelection: 'multiple',
      rowBuffer: 4000,
      rowMultiSelectWithClick: true,
      animateRows: true,
      cacheBlockSize: 100,
      // maxBlocksInCache: 2,
      blockLoadDebounceMillis: 300, // Prevent multiple hits to the database if state changes swiftly
      clipboardDelimiter: ',',
      suppressCopyRowsToClipboard: true,
      pagination: true,
      paginationAutoPageSize: false,
      paginationPageSize: 100,
      serverSideInfiniteScroll: true,
      enableRangeSelection: true,
      allowContextMenuWithControlKey: true,
      defaultColDef: {
        autoHeight: true,
        filter: true,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: true,
        resizable: true,
        sortable: true
      },
      rowClassRules: {
        loading: (params) => params.data && Boolean(params.data.loading)
      },
      statusBar: {
        statusPanels: []
      },
    };

    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { field: 'callId' },
          { field: 'direction' },
          { field: 'number' },
          {
            field: 'duration',
            valueFormatter: 'x.toLocaleString() + \'s\''
          },
          { field: 'switchCode' }
        ],
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
        }
      },
      // getDetailRowData: function(params) {
      //   params.successCallback(params.data.callRecords);
      // }
    };

    this.activeState = null;
    this.activeAccessState = null;

    // Bind to background taskService state
    this.taskSvc.getUser().pipe(skip(1), takeUntil(this.onDestroy$)).subscribe(user => {

      this.taskState = user;

      // Only refresh data from the database if the active job completed in the last 5 mins
      const refreshData = user.active.end && moment().diff(moment(user.active.end * 1000), 'minutes') < 5;

      if (refreshData && user.active.hasOwnProperty('classIds')) {
        // refresh of class meta data required...
        user.active.classIds.forEach((cls) => {

          const rowNode = this.gridApi.getRowNode(cls);
          if (rowNode && rowNode.data) {
            const data = rowNode.data;
            data.loading = true;
            rowNode.setData(data);
          }
        });

        this.gridApi.redrawRows();

        setTimeout(() => {
          this.apiLookup.refreshCourses(user.active.classIds).subscribe((newData) => {

            this.busy = true;

            newData.data.forEach((cls) => {
              const row = this.gridApi.getRowNode(cls.id);
              cls['coteachers.primaryEmail'] = new Date(); // Trick grid into refreshing coteachers columns...
              if (row) {
                row.setData(cls);
              }
            });

            // Refresh cells does not trigger the refresh
            this.gridApi.redrawRows();

            this.busy = false;

          });
        }, 4000);

      }

    });


    // Subscription to the message service to get notifications about class record updates
    this.subscription = this.messageService.getMessage().pipe(takeUntil(this.onDestroy$)).subscribe(message => {

      switch (message.action) {

        case 'updateClassrooms':

          this.busy = true;

          const cls = message.classes[0].cls;
          const row = this.gridApi.getRowNode(cls.id);
          const data = row.data;

          data.tf = cls.tf;
          data.tags = cls.tags;
          data.school = cls.school;
          data.sch = cls.sch;

          const now = new Date();
          data['tf.id'] = now; // Trigger a refresh of the timeframe column

          row.setData(data);

          // Refresh cells does not trigger the refresh
          // this.gridApi.refreshCells();

          this.gridApi.redrawRows();
          this.refreshRowsWithCurrentData();

          this.busy = false;

          break;

        default:
          break;

      }


    });

    this.columns$.next(this.columnDefs);

    this.datasource = {
      getRows: (params: LSIServerSideGetRowsParams) => {
        this.getRowData(params)
          .pipe(
            tap(data => {
              // Handle initial actions (if any)
              if (!this.initialAction || this.initialAction.action !== 'showClassroomDetails') {
                return;
              }
              if (!data || !data.data || !data.data.length) {
                this.snackBar.open('The selected class could not be found', 'close', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  duration: 5000,
                });
                return;
              }
              const selectedClass = data.data.find(classroom => classroom.id === this.initialAction.classroomId);
              if (!selectedClass) {
                this.snackBar.open('The selected class could not be found', 'close', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  duration: 5000,
                });
              }
              this.initialAction = null;
              this.cgs.viewInfo_(selectedClass);
            })
          )
          .subscribe(data => {
            this.total = data.total;
            this.successCallbackRef = params.success;
            this.successCallbackRef({rowData: data.data, rowCount: data.total});
          });

      }
    };
  }

  refreshRowsWithCurrentData(): void {
    const rowData = [];

    if (!this.successCallbackRef) {
      return;
    } else {

      const firstRow = this.gridApi.getFirstDisplayedRow();
      const lastRow = this.gridApi.getLastDisplayedRow();

      for (let idx = firstRow; idx <= lastRow; idx++) {
        rowData.push(this.gridApi.getDisplayedRowAtIndex(idx).data);
      }

      this.successCallbackRef({rowData, rowCount: this.total});
      this.setLbLastRowOnPage();
      return;
    }
  }

  externalFilterChanged(newValue): void {

    if (this.gridApi) {

      if (this.filters.timeframe.range.startDate === null || this.filters.timeframe.range.endDate === null) {
        const filterComponent = this.gridApi.getFilterInstance('creationTime');
        filterComponent.setModel(null);
        // filterComponent.onFilterChanged();
        this.gridApi.onFilterChanged();
      } else {
        this.setColumnFilterForCreated_('creationTime', this.filters.timeframe.range);
        this.filtersActive = true;

        const filters = this.gridApi.getFilterModel();
        const fields = _.keys(filters);

        this.activeFilters = fields.map((col) => ({
            key: col,
            name: this.columnDefHash[col].headerName
          }));

        // Get filters and store list in activeFilters array

        // TODO: Only store this if different... ?
        this._contextSvc.setTimeframe(this.filters.timeframe.range);
      }
    }

  }

  setColumnFilterForCreated_(field: string, fieldOptions): void {
    if (!this.gridApi) {
      return;
    }

    const startDate = (fieldOptions.startDate).format('YYYY-MM-DD');
    const endDate = (fieldOptions.endDate).format('YYYY-MM-DD');
    this.gridApi.getFilterInstance(field, (filterInstance) => {
      filterInstance.setModel({
        type: 'inRange',
        dateFrom: startDate,
        dateTo: endDate
      });

      this.gridApi.onFilterChanged();
    });
  }


  //server side select all
  selectAllRows(e): void {
    const firstRow = this.gridApi.getFirstDisplayedRow();
    const lastRow = this.gridApi.getLastDisplayedRow();

    if (e.checked) {
      for (let idx = firstRow; idx <= lastRow; idx++) {
        this.gridApi.getDisplayedRowAtIndex(idx).setSelected(true);
        // this.gridApi.selectIndex(idx, true);
      }
      // this.gridApi.selectionController.selectAllRowNodes(true);
    } else {
      this.gridOptions.api.deselectAll();
    }
  }

  getVisibleRowCount(): number {
    const firstRow = this.gridApi.getFirstDisplayedRow();
    const lastRow = this.gridApi.getLastDisplayedRow();
    return (lastRow !== firstRow) ? 1 + lastRow - firstRow : (firstRow === undefined) ? 0 : 1;
  }

  getAllRowData(): any[] {
    const rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  onFilterChanged(event: FilterChangedEvent): void {
    const filters = this.gridApi.getFilterModel();
    const fields = _.keys(filters);

    this._firebaseAnalytics.sendEvent({
      action: 'explorer',
      properties: {
        category: 'filter',
        label: fields.join('|')
      }
    });

    this.gridApi.hideOverlay();
    this.filtersChanged$.next(event);
    if (!this.gridApi.getDisplayedRowCount()) {
      this.gridApi.showNoRowsOverlay();
    }
  }

  /**
   * Used to update the hidden column count indicator
   *
   * @param event
   */
  onColumnVisible(event) {
    // The event is of type ColumnVisibleEvent (imported from '@ag-grid-community/core') but columnController is a private method so throws a type error. The proper way to achieve the row hight resize is to use resetRowHeights(), but I can't get that to work.
    this.columns$.next(this.gridOptions.columnApi.getColumnState());

    // Refresh grid to ensure row height is correct

    // As columns have changed - we should assess whether the auto-height columns have changed and apply the heights
    // if appropriate.
    // This was more difficult than first anticipated as we are using the serverSide rowModel

    // Get all rows with 'autoHeight' set and that are visible. If we have any visible we should check/reapply heights.
    const rowAutoHeightVisible = event.columnApi.columnModel.displayedAutoHeightCols;

    if (rowAutoHeightVisible.length || (!rowAutoHeightVisible.length && this.autoHeightActive)) {

      this.autoHeightActive = (rowAutoHeightVisible.length > 0);

      // Attempted to use: api.redrawRows, api.refreshCells, dispatchEvent, checkGridSize, rowModel.reset, columnApi.autoSizeColumns
      // and a variety of other methods with no success. Instead - 'hacking' the getRows method to use the last obtained data

      this.refreshRowsWithCurrentData();

    }

    this.gridApi.gridOptionsWrapper.gridOptions.context.componentParent.saveState();
  }

  async onGridReady(params): Promise<void> {
    // The params is of type ColumnVisibleEvent (imported from '@ag-grid-community/core') but columnController is a private method so throws a type error. The proper way to achieve the row hight resize is to use resetRowHeights(), but I can't get that to work.
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const currentFilters = await this._contextSvc.getFilters().getValue();
    this.filters = {...this.filters, ...currentFilters};

    this.externalFilterChanged(this.filters);

    this.resetState();

    this.gridApi.setServerSideDatasource(this.datasource);

    const rowAutoHeightVisible = params.columnApi.columnModel.displayedAutoHeightCols;
    this.autoHeightActive = (rowAutoHeightVisible.length > 0);

    // Switch to more event targeted event listener rather than global
    this.gridApi.addEventListener('displayedColumnsChanged', (event) => {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.changedVisibleColumns as AnalyticsEvent);
      // Get column state and emit to allow visible column count to update
      this.columns$.next(this.gridOptions.columnApi.getColumnState());
      this.onColumnVisible(event);
    });

    this.gridApi.addEventListener('filterChanged', () => {
      this.gridApi.gridOptionsWrapper.gridOptions.context.componentParent.saveState();
    });

    this.gridApi.addEventListener('sortChanged', () => {
      this.gridApi.gridOptionsWrapper.gridOptions.context.componentParent.saveState();
    });

    // Handling for bad pagination
    this.gridApi.addEventListener('paginationChanged', () => {
      this.setLbLastRowOnPage();
    });

  }

  ngOnInit(): void {
    const initialAction = this.route.snapshot.queryParamMap.get('action');
    if (initialAction) {
      this.initialAction = {
        action: initialAction
      };
    }
    if (initialAction === 'showClassroomDetails') {
      const classroomId = this.route.snapshot.queryParamMap.get('classroomId');
      this.initialAction.classroomId = classroomId;
    }
    this.licenseState$ = this._licenseSvc.checkLicense(false).pipe(takeUntil(this.onDestroy$),
    tap((state) => this.actionsLimited = state.tier === 'lapsed'));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  saveState(): void {
    const state = {
      columns: this.gridOptions.columnApi.getColumnState(),
      filters: this.gridOptions.api.getFilterModel()
    };

    this.localstorageSvc.set('explorerGridState', state);
  }

  resetState(): void {

    let state = this.localstorageSvc.get('explorerGridState');

    if (!state || !state.filters) {
      state = {
        filters: {}
      };
    }

    if (this.filters.timeframe.range.startDate) {
      state.filters.creationTime = {
        dateFrom: moment(this.filters.timeframe.range.startDate).format('YYYY-MM-DD'),
        dateTo: moment(this.filters.timeframe.range.endDate).format('YYYY-MM-DD'),
        type: 'inRange',
        filterType: 'date'
      };
    }

    if (state && state.columns) {
      const courseStateRef = state.columns.find(columnState => columnState.colId === 'courseState');
      if (courseStateRef) {
        // Force the visible pinned state of courseState, incase someone changed this before the update.
        courseStateRef.pinned = 'left';
        courseStateRef.hide = false;
      } else {
        // Something is wrong with the config. The state column is missing so reset back to initial state.
        state = {
          filters: state.filters
        };
        return;
      }
    }

    if (state.columns && this.gridOptions) {
      // Ensure order of the columns is also applied
      setTimeout(() => {
        this.gridOptions.columnApi.applyColumnState({state: state.columns, applyOrder: true});
      }, 0);
    }

    if (this.initialAction && this.initialAction.action === 'showClassroomDetails') {
      state.filters = {
        id: {
          filter: this.initialAction.classroomId,
          filterType: 'text',
          type: 'equals'
        }
      };
    }

    if (state.filters && this.gridOptions) {
      setTimeout(() => {
        this.gridOptions.api.setFilterModel(state.filters);
      }, 0);
    }

    const fields = _.keys(state.filters);
    this.activeFilters = this.gridService.mapGridStateFiltersToActiveFilters(fields, this.columnDefHash);

  }

  getContextMenuItems = (params: GetContextMenuItemsParams): (string|MenuItemDef)[] => {

    if (params.node === null) {
      return;
    }

    const orphanedClass = !params.node.data.Owner?.gId;

    let label = `<strong>${params.node.data.name}</strong>`;

    if (params.node.data.section) {
      label += ` (${params.node.data.section})`;
    }

    const menu = [
      {
        name: label
      },
      'separator',
      {
        name: 'View class details',
        action: () => {
          params.context.componentParent.cgs.viewInfo_(params.node.data);
        },
        tooltip: 'View detailed class information'
      },

      this.getTeacherFilterOption(params)
    ];

    if (orphanedClass) {
      menu.push({
        name: `Show all orphaned classes`,
        action: () => params.context.componentParent.setColumnFilter_('Owner.primaryEmail', {
          type: 'equals',
          filter: 'Unknown'
        }),
        tooltip: `Add filter to show classes taught by missing teachers`
      });
    }

    menu.push('separator');
    menu.push({
      name: 'Refresh class data',
      action: () => {
        params.context.componentParent.refreshSelected({ data: params.node.data });
      },
      tooltip: 'Refresh selected'
    });

    if (params.context.componentParent.hasPermission('update_tags')) {
      menu.push({
        name: 'Update School or Timeframe tags',
        action: () => {
          params.context.componentParent.bulkUpdateTags({ data: params.node.data });
        },
        tooltip: 'Update School or Timeframe tags'
      });
    }

    menu.push('separator');

    // Teacher Actions

    const teacherActions = {
      name: 'Teacher actions',
      subMenu: []
    };

    // Check permissions...

    let teacherAdded = false;

    if (params.context.componentParent.hasPermission('add_coteacher')) {
      teacherActions.subMenu.push({
        name: 'Add co-teachers',
        action: () => {
          params.context.componentParent.addCoTeacher({ data: params.node.data });
        },
        tooltip: 'Add co-teachers',
        disabled: this.actionsLimited || orphanedClass
      });
      teacherAdded = true;
    }

    if (params.context.componentParent.hasPermission('remove_coteacher')) {
      teacherActions.subMenu.push({
        name: 'Remove co-teachers',
        action: () => {
          params.context.componentParent.removeCoTeacher({ data: params.node.data });
        },
        tooltip: 'Remove co-teachers',
        disabled: this.actionsLimited
      });
      teacherAdded = true;
    }

    if (teacherAdded) {
      teacherActions.subMenu.push('separator');
    }

    if (params.context.componentParent.hasPermission('join_class_as_coteacher')) {
      teacherActions.subMenu.push({
        name: 'Join class as co-teacher',
        action: () => {
          params.context.componentParent.joinAsCoTeacher({ data: params.node.data });
        },
        tooltip: 'Join a classroom as a coteacher',
        disabled: this.actionsLimited || orphanedClass
      });
      teacherAdded = true;
    }

    if (params.context.componentParent.hasPermission('change_primary_teacher')) {
      teacherActions.subMenu.push({
        name: 'Change the primary teacher',
        action: () => {
          params.context.componentParent.changePrimaryTeacher({ data: params.node.data });
        },
        tooltip: 'Change the primary teacher',
        disabled: this.actionsLimited || orphanedClass
      });
      teacherAdded = true;
    }

    if (teacherAdded) {
      menu.push(teacherActions);
    }

    // Student actions

    const studentActions = {
      name: 'Student actions',
      subMenu: []
    };

    // Check permissions...

    let addedStudentsMenu = false;

    if (params.context.componentParent.hasPermission('add_students_with_optional_email')) {
      studentActions.subMenu.push({
        name: 'Add students',
        action: () => {
          params.context.componentParent.addStudent({ data: params.node.data });
        },
        tooltip: 'Add students to this class',
        disabled: this.actionsLimited || orphanedClass
      });
      addedStudentsMenu = true;
    }

    if (params.context.componentParent.hasPermission('remove_students_with_mandatory_email_notification')
      || params.context.componentParent.hasPermission('remove_students_without_email_notification')) {
      studentActions.subMenu.push({
        name: 'Remove students',
        action: () => {
          params.context.componentParent.removeStudent({ data: params.node.data });
        },
        tooltip: 'Remove students from this class',
        disabled: this.actionsLimited
      });
      addedStudentsMenu = true;
    }

    if (addedStudentsMenu) {
      studentActions.subMenu.push('separator');
    }

    if (params.context.componentParent.hasPermission('join_class_as_student')) {
      studentActions.subMenu.push({
        name: 'Join class as student',
        action: () => {
          params.context.componentParent.joinAsStudent({ data: params.node.data });
        },
        tooltip: 'Join a classroom as a student to be able to view and interact with assignments and the classroom thread',
        disabled: this.actionsLimited || orphanedClass
      });
      addedStudentsMenu = true;
    }

    if (addedStudentsMenu) {
      menu.push(studentActions);
    }

    const actions = {
      name: 'Grid actions',
      subMenu: [
        'autoSizeAll',
        'copy',
        'copyWithHeaders',
        'separator',
        {
          name: 'Export selected classes to CSV',
          action: () => {
            params.context.componentParent.exportToCSV(true);
          },
          tooltip: 'Download a CSV with details of the selected classes'
        },
        'separator',
        {
          name: 'Clear selection',
          action: () => {
            params.context.componentParent.clearSelectedItems(true);
          },
          tooltip: 'Clear selection'
        },
        {
          name: 'Clear filters',
          action: () => {
            params.context.componentParent.gridOptions.api.setFilterModel(null);
          },
          tooltip: 'Clear filters'
        }
      ]
    };

    menu.push(actions);

    return menu;

  };


  getTeacherFilterOption(params) {

    let name: string;
    let action;
    let tooltip: string;

    if (params.node.data.Owner?.primaryEmail) {
      name = `Show all classes taught by ${params.node.data.Owner.primaryEmail}`;
      action = () => params.context.componentParent.setColumnFilter_('Owner.primaryEmail', {
        type: 'equals',
        filter: params.node.data.Owner.primaryEmail
      });
      tooltip = `Add filter to show classes taught by ${params.node.data.Owner.primaryEmail}`;
    } else {
      name = `Show all classes taught by ${params.node.data.ownerId}`;
      action = () => params.context.componentParent.setColumnFilter_('ownerId', {
        type: 'equals',
        filter: params.node.data.ownerId
      });
      tooltip =  `Add filter to show classes taught by this missing teacher`;
    }

    return {
      name,
      action,
      tooltip
    };
  }

  /**
   * Fired when a classroom is selected/deselected on the explorer grid
   * Used to update whether the 'select all' is active
   *
   * @param event
   */
  onSelectionChanged(event) {

    // TODO: Resolve issue with deselect from column header (select-all-header component)
    // TODO: Resolve application of filter recalculating the selectAll state
    // Code below is now more performant

    const rows = event.api.getSelectedNodes();
    const visible = this.getVisibleRowCount();

    if (visible > rows.length && rows.length !== 0) {

      this.indeterminate = true;

    } else if (visible === rows.length) {

      this.indeterminate = false;
      this.checked = true;

    } else if (visible > rows.length && rows.length === 0) {

      this.indeterminate = false;
      this.checked = false;

    }

    if (!rows.length) {
      this.clearSelectedItems(false);
    } else {
      this.selectedClasses = _.map(rows, 'data');
      this.selectedItems = _.map(rows, 'data.id');
    }
  }

  clearSelectedItems(clear: boolean) {
    if (clear) {
      this.gridOptions.api.deselectAll();
    }
    this.selectedItems.length = 0;
    this.selectedClasses.length = 0;
  }

  exportToCSV(selected) {
    const params = this._exportClassroomSvc.getExportParams(selected);

    if (selected && !this.selectedItems.length) {
      this.snackBar.open('Please select the classes you want to export...', 'close', {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        duration: 5000,
      });
    } else {
      this.gridOptions.api.exportDataAsCsv(params);
    }
  }

  refreshSelected(event) {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    if (classes.length) {

      // Remove any classes with a courseState of NOT_FOUND

      const dialogData = {
        selected: classes.filter( (currentClass) => (currentClass.courseState !== 'NOT_FOUND'))
      };

      const dialogRef = this.dialog.open(RefreshSelectedDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: dialogData,
        width: '920px'
      });

      dialogRef.afterClosed().subscribe(async result => {

        if (result) {

          this.busy = true;

          const request = {
            type: 'refresh-classroom-data',
            refresh: result.refreshing,
            ids: result.selected.map(cls => cls.id)
          };

          this.taskSvc.sendTask(request).subscribe(response => {

            this.busy = false;

            this.clearSelectedItems(true);

          });

        }

      });

    }

  }

  bulkUpdateTags(event) {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    if (classes.length) {

      // If values are the same for all selected classes, then default them, otherwise... (opacity for multiselects?)
      let schoolMatch = true;
      let timeframeMatch = true;
      let chosenTimeframeIds: string[] = [];
      let chosenSchoolIds: string[] = [];
      let tagMatch = true;
      let chosenTagIds = [];

      const dialogData = {
        schools: [],
        timeframes: [],
        selected: [],
        tags: [],
        changes: {
          schools: false,
          timeframes: false,
          tags: false
        }
      };

      classes.forEach( (cls) => {

        if (schoolMatch && !dialogData.schools.length) {
          dialogData.schools = _.cloneDeep(cls.sch);
          chosenSchoolIds = cls.sch.map((s) => s.id);
        } else if (schoolMatch) {

          // Check cls.schoolIds against dialogData
          const schoolIds = cls.sch.map((s) => s.id);

          const difference = chosenSchoolIds
            .filter(x => !schoolIds.includes(x))
            .concat(schoolIds.filter(x => !chosenSchoolIds.includes(x)));

          if (difference.length) {
            dialogData.schools.length = 0;
            schoolMatch = false;
          }

        }

        if (timeframeMatch && !dialogData.timeframes.length) {
          dialogData.timeframes = _.cloneDeep(cls.tf);
          chosenTimeframeIds = cls.tf.map((tf) => tf.id);
        } else if (timeframeMatch) {
          // Check cls.timeframeIds against dialogData
          const timeframeIds = cls.tf.map((tf) => tf.id);

          const difference = chosenTimeframeIds
            .filter(x => !timeframeIds.includes(x))
            .concat(timeframeIds.filter(x => !chosenTimeframeIds.includes(x)));

          if (difference.length) {
            dialogData.timeframes.length = 0;
            timeframeMatch = false;
          }

        }

        if (tagMatch && !dialogData.tags.length) {
          dialogData.tags = _.cloneDeep(cls.tags);
          chosenTagIds = cls.tags.map((t) => t.id);
        } else {
          // Check cls.tagIds against dialogData
          const tagIds = cls.tags.map((t) => t.id);
          if (_.difference(chosenTagIds, tagIds).length) {
            dialogData.tags.length = 0;
            tagMatch = false;
          }
        }
      });

      dialogData.selected = classes;

      const dialogRef = this.dialog.open(UpdateTagsDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: dialogData,
        width: '920px',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(async result => {

        if (result) {

          this.busy = true;

          const request = {
            schools: result.schools,
            timeframes: result.timeframes,
            tags: result.tags,
            ids: result.selected.map(cls => cls.id),
            changes: result.changes
          };

          return this.apiLookup.bulkUpdateClassTags(request).subscribe(saveResult => {

            this.busy = false;
            this.clearSelectedItems(true);
            // Inject tag changes here...

            classes.forEach(cls => {

              const row = this.gridApi.getRowNode(cls.id);

              if (row) {
                const data = row.data;

                if (result.changes.timeframes) {
                  data.tf = request.timeframes;
                  data['tf.id'] = new Date(); // Trigger cell refresh
                }
                if (result.changes.tags) {
                  data.tags = request.tags;
                }

                if (result.changes.schools) {
                  data.sch = request.schools;
                  data['sch.id'] = new Date(); // Trigger cell refresh
                  data.schoolCount = request.schools.length;
                }

                row.setData(data);
              }

            });

            this.snackBar.open('Tags updated', 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 7000,
            });

            this.gridApi.redrawRows();
            this.refreshRowsWithCurrentData();

          });

        }

      });

    }

  }


  resetAccessFilters(): void {

    this.gridStateService.applyGridSettings(this.prevAccessState);

  }

  editAccessFilter(): void {
    if (this.activeAccessState === null) {
      return;
    }
  }

  hasPermission(permissionName: string): boolean {
    return this.authService.hasPermission(permissionName);
  }

  async addCoTeacher(event): Promise<void> {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    const invalidClasses = classes.filter( currentClass => (currentClass.courseState === 'DECLINED' || currentClass.courseState === 'NOT_FOUND'));

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'ADD_CO_TEACHER' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open( BulkAddCoTeacherDialogComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: { selectedClasses: classes, allowNotification: this.hasPermission('notification_optional') }

      });

      await dialogRef.afterClosed().subscribe(async (clsChanges) => {

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        this.busy = true;

        await this.apiLookup.bulkAddCoTeacher(clsChanges).subscribe(results => {

          this.busy = false;
          this.clearSelectedItems(true);

          if (results.success) {
            // @ts-ignore
            this.snackBar.open(results.message, 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }

          // this.loadData();

        });

      });
    }
  }

  async addStudent(event) {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    const invalidClasses = classes.filter( currentClass => (currentClass.courseState === 'DECLINED'));

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'ADD_STUDENT' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open( BulkAddStudentDialogComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {
          selectedClasses: classes,
          allowNotification: this.hasPermission('add_students_with_optional_email'),
          requireNotification: !this.hasPermission('add_students_with_optional_email'),
        }

      });

      await dialogRef.afterClosed().subscribe(async (clsChanges) => {

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        this.busy = true;

        await this.apiLookup.addStudents(clsChanges).subscribe(results => {

          this.busy = false;
          this.clearSelectedItems(true);

          if (results.success) {

            this.snackBar.open(results.message, 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }

        });

      });
    }
  }


  async removeCoTeacher(event): Promise<void> {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    const invalidClasses = classes.filter( currentClass => (currentClass.courseState === 'DECLINED' || currentClass.courseState === 'NOT_FOUND'));

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'REMOVE_COTEACHER' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open(BulkRemoveCoteacherDialogComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {selectedClasses: classes, allowNotification: this.hasPermission('notification_optional')}
      });

      await dialogRef.afterClosed().subscribe(async (clsChanges) => {

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        this.busy = true;

        await this.apiLookup.bulkRemoveCoTeacher(clsChanges).subscribe(results => {

          this.busy = false;
          this.clearSelectedItems(true);

          if (results.success) {
            // @ts-ignore
            this.snackBar.open(results.message, 'Ok', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }

        });

      });
    }

  }


  async archiveClasses(event): Promise<void> {

    const invalidClasses = this.selectedClasses.filter( currentClass => currentClass.courseState !== 'ACTIVE');

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'ARCHIVED' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open( ClassArchiveDialogComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {
          selectedClasses: this.selectedClasses,
          allowNotification: this.hasPermission('notification_optional')
        }
      });

      await dialogRef.afterClosed().subscribe(async (userChanges) => {

        if (userChanges === undefined || userChanges === 'cancelled') {
          return;
        }

        await this.apiLookup.archiveClasses(userChanges).subscribe(results => {
          if (results.success) {
            // @ts-ignore
            this.snackBar.open(results.message, 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }
          this.clearSelectedItems(true);
          this.reloadData(false);

        });

      });
    }
  }


  bulkArchiveClasses(): void {
    this.dialog.open(ClassesBulkActionDialogComponent, {
      panelClass: ['ait-little-sis-panel', 'full'],
      data: {
        function: 'archive',
        title: 'Identify and archive classes',
        subtitle: 'identify and archive',
        searchTitle: 'Find classes to be archived',
        selectResultsTitle: 'Select classes to archive',
        finalStepTitle: 'Review and archive',
        finalStepConfirmation: 'You are about to archive',
        action: BulkClassesActions.archive,
      }
    }).afterClosed().subscribe((shouldClearSelected) => {
      if (shouldClearSelected) {
        this.clearSelectedItems(true);
      }
    });
  }

  bulkArchiveSuspendedTeacherClasses(): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.archiveClassesWithSuspendedTeachersStarted as AnalyticsEvent);
    this.dialog.open(ClassesBulkActionDialogComponent, {
      panelClass: ['ait-little-sis-panel', 'full'],
      data: {
        function: 'archiveSuspended',
        title: 'Identify and archive classes taught by suspended teachers',
        subtitle: `identify and archive suspended teachers'`,
        searchTitle: 'Find classes taught by suspended teachers',
        selectResultsTitle: 'Select classes to archive',
        finalStepTitle: 'Review and archive',
        finalStepConfirmation: 'You are about to archive',
        action: BulkClassesActions.archive,
        hideFilterOptions: true,
      }
    }).afterClosed().subscribe((shouldClearSelected) => {
      if (shouldClearSelected) {
        this.clearSelectedItems(true);
      }
    });
  }

  bulkDeleteClasses(): void {
    // There is scope to refactor. Look at existing BulkDeleteClassesDialogComponent because we don't want two. Perhaps rename the original inline with ClassArchiveDialogComponent. At the same time, I've rolled up the new bulk archive component as a classes bulk action component which will do archive and delete.
    this.dialog.open(ClassesBulkActionDialogComponent, {
      panelClass: ['ait-little-sis-panel', 'full'],
      data: {
        function: 'delete',
        title: 'Identify and delete classes',
        subtitle: 'identify and delete',
        searchTitle: 'Find classes to be deleted',
        selectResultsTitle: 'Select classes to delete',
        finalStepTitle: 'Review and delete',
        finalStepConfirmation: 'You are about to delete',
        action: BulkClassesActions.delete,
      }
    });
  }

  bulkClearRosters(): void {
    this.dialog.open(ClassesBulkActionDialogComponent, {
      panelClass: ['ait-little-sis-panel', 'full'],
      data: {
        function: 'remove_student',
        title: 'Remove all students from Archived classes',
        subtitle: 'clear the rosters of',
        searchTitle: 'Find classes that are archived',
        selectResultsTitle: 'Select the classes you wish to clear the rosters for',
        finalStepTitle: 'Review and clear rosters',
        finalStepConfirmation: 'You are about to clear rosters for',
        action: BulkClassesActions.clear,
      }
    });
  }

  async deleteClasses(event): Promise<void> {
    const invalidClass = this.selectedClasses.filter( currentClass => (currentClass.courseState !== 'PROVISIONED' && currentClass.courseState !== 'DECLINED'));

    if (invalidClass.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'DELETE' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open( BulkDeleteClassesDialogComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {
          selectedClasses: this.selectedClasses
        },

      });

      await dialogRef.afterClosed().subscribe(async (request) => {
        if (request === undefined || request === 'cancelled') {
          return;
        }

        await this.apiLookup.deleteClasses(request).subscribe(results => {

          this.clearSelectedItems(true);
          this.reloadData(false);

          if (results) {
            // @ts-ignore
            this.snackBar.open(results.message, 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }
        });

      });
    }
  }

  async joinAsCoTeacher(event): Promise<void> {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    const invalidClasses = classes.filter( currentClass => (currentClass.courseState === 'NOT_FOUND'));

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'JOIN_AS_COTEACHER' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open(AddSelfToClassesComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {selectedClasses: classes, allowNotification: this.hasPermission('notification_optional')}
      });

      await dialogRef.afterClosed().subscribe(async (clsChanges) => {

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        await this.apiLookup.addSelfToClasses(clsChanges).subscribe(results => {
          this.clearSelectedItems(true);
          if (results) {
            // @ts-ignore
            this.snackBar.open(results.message, 'Ok', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }

        });

      });
    }

  }

  async joinAsStudent(event): Promise<void> {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    const invalidClasses = classes.filter( currentClass => (currentClass.courseState === 'DECLINED' || currentClass.courseState === 'ARCHIVED' || currentClass.courseState === 'PROVISIONED' || currentClass.courseState === 'NOT_FOUND'));

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'JOIN_AS_STUDENT' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open( JoinClassAsStudentComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {
          selectedClasses: classes,
          allowNotification: this.hasPermission('notification_optional')
        }
      });

      await dialogRef.afterClosed().subscribe(async (clsChanges) => {

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        this.busy = true;

        await this.apiLookup.joinAsStudent(clsChanges).subscribe(results => {

          this.busy = false;
          this.clearSelectedItems(true);

          if (results.success) {
            // @ts-ignore
            this.snackBar.open(results.message, 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }

        });

      });
    }
  }


  async removeStudent(event): Promise<void> {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    const invalidClasses = classes.filter( currentClass => (currentClass.courseState === 'DECLINED' || currentClass.courseState === 'NOT_FOUND'));

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'REMOVE_STUDENT' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open(BulkRemoveStudentDialogComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {
          selectedClasses: classes,
          allowNotification: this.hasPermission('remove_students_without_email_notification') || this.hasPermission('remove_students_with_mandatory_email_notification'),
          requireNotification: !this.hasPermission('remove_students_without_email_notification')
        }
      });

      await dialogRef.afterClosed().subscribe(async (clsChanges) => {

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        this.busy = true;

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        this.busy = true;

        await this.apiLookup.removeStudents(clsChanges).subscribe(results => {

          this.busy = false;
          this.clearSelectedItems(true);

          if (results.success) {

            this.snackBar.open(results.message, 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }

        });

      });
    }

  }

  async changePrimaryTeacher(event): Promise<void> {

    const classes = (event.data) ? [event.data] : this.selectedClasses;

    const invalidClasses = classes.filter( currentClass => (currentClass.courseState === 'NOT_FOUND'));

    if (invalidClasses.length) {

      this.dialog.open( ErrorDialogComponent, {
        panelClass: 'ait-little-sis-panel',
        data: { okBtnLabel: 'I understand', actionTrigger: 'CHANGE_PRIMARY_TEACHER' },
        width: '600px',
        height: '350px'
      });

    } else {

      const dialogRef = this.dialog.open(ChangePrimaryTeacherComponent, {
        panelClass: ['ait-little-sis-panel', 'full'],
        data: {
          selectedClasses: classes,
          allowNotification: this.hasPermission('notification_optional')
        }
      });

      await dialogRef.afterClosed().subscribe(async (clsChanges) => {

        if (clsChanges === undefined || clsChanges === 'cancelled') {
          return;
        }

        await this.apiLookup.changePrimaryTeacher(clsChanges).subscribe(results => {

          if (results) {
            // @ts-ignore
            this.snackBar.open(results.message, 'close', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }

        });

      });
    }

  }

  getDefaultPhotoUrl(): string {
    return UtilService.defaultPhotoUrl;
  }

  keyDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.value > b.value? -1 : (b.value> a.value? 1 : 0);

  openAccessFilters(): void {
    this.gridOptions.api.openToolPanel('accessFiltersToolPanel');
  }

  reloadData(useCache?: boolean): void {
    if (useCache !== undefined) {
      this.useCache = useCache;
    }
    setTimeout(() => {
      this.gridOptions.api.setServerSideDatasource(this.datasource);
    },0);
  }

  clearAccessFilter(col = null): void {

    if (col === null) {
      this.gridOptions.api.setFilterModel(null);
      this._accessFilterSvc.clearAccessFilter();
      this._contextSvc.setTimeframe({startDate: null, endDate: null});
    } else {

      const filterComponent = this.gridApi.getFilterInstance(col.key);
      filterComponent.setModel(null);
      // filterComponent.onFilterChanged();
      if (this.gridApi) {
        this.gridApi.onFilterChanged();
      }

      this.activeAccessFilter = false;

      if (col.key === 'sch.id' || col.key === 'tf.id') {
        // Due to the use of apply on the filter, the UI doesn't update on changing the model. This workaround manually
        // updates the UI then refreshes the data
        this.reloadData(true);
        this.activeFilters = this.activeFilters.filter(filterItem => filterItem.key !== col.key);
      }

    }

  }

  guidedTour(): void {
    this._intro.guidedTour('explorer');
  }

  private getRowData(params): Observable<ListResponse> {
    const opts = {useCache: (this.useCache === undefined) ? true : this.useCache };
    const req = {
      take: params.request.endRow - params.request.startRow,
      skip: params.request.startRow,
      filterModel: params.request.filterModel,
      sortModel: params.request.sortModel,
    };

    return this.apiLookup.getCourses(req, opts).pipe(tap(() => this.useCache = true));
  }

  private setColumnFilter_(field, filterOpts): void {
    const filterComponent = this.gridOptions.api.getFilterInstance(field);
    filterComponent.setModel(filterOpts);

    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  }

  /**
   * Workaround to show the actual number of rows for a given page
   * Issue: https://github.com/ag-grid/ag-grid/issues/4295
   */
  private setLbLastRowOnPage(): void { // LSC-1099
    if (!this.gridApi) {
      return;
    }

    const lbLastRowOnPageEl = document.querySelector(`#explorer-grid [ref='lbLastRowOnPage']`);
    if (lbLastRowOnPageEl) {
      const isLastPage = this.gridApi.paginationGetTotalPages() === (this.gridApi.paginationGetCurrentPage() + 1);
      const endRow = isLastPage ? this.gridApi.paginationGetRowCount() : (this.gridApi.paginationGetCurrentPage() + 1) * this.gridApi.paginationGetPageSize();
      lbLastRowOnPageEl.innerHTML = endRow.toString();
    }
  }

}
