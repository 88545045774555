import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataUser } from 'app/modules/admin/components/user-accounts/dialog-data-user.interface';
import { DialogData } from 'app/modules/admin/components/user-accounts/user-accounts.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-install-additional-scope-dialog',
  templateUrl: './install-additional-scope-dialog.component.html',
  styleUrls: ['./install-additional-scope-dialog.component.scss']
})

export class InstallAdditionalScopeDialogComponent implements OnInit {

  busy = false;
  installed = false;
  showCloseButton = this.data.isAdmin ? true : false;

  constructor(
    public dialogRef: MatDialogRef<InstallAdditionalScopeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {

  }

  goToAdminConsole() {
    const clientId = environment.adminConsole.client_name;
    const scopesList = environment.adminConsole.scopes;

    const queryParams = `clientScopeToAdd=${scopesList}%26clientIdToAdd=${clientId}`.replace(/ /g, '');

    let url = 'https://accounts.google.com/ServiceLogin/signinchooser?';
    url += 'passive=1209600&osid=1&continue=https%3A%2F%2Fadmin.google.com%2Fac%2Fowl%2Fdomainwidedelegation%3F';
    url += queryParams;

    window.open(url);
  }

  startChecking(): void {
    console.log('start checking');
  }
}
