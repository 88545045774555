import { Component, OnInit } from '@angular/core';
import {License, LicenseState} from '../../services/licensing.interfaces';
import {LittlesisLicensingService} from '../../services/littlesis-licensing.service';


@Component({
  selector: 'app-licensing-tester',
  templateUrl: './licensing-tester.component.html',
  styleUrls: ['./licensing-tester.component.scss']
})
export class LicensingTesterComponent implements OnInit {

  public license: License;
  public state: LicenseState;
  public initialised = false;
  overrideActive = false;
  tool = 'LSW';

  constructor(private _licensingSvc: LittlesisLicensingService) {
    this._licensingSvc.getOverrideStatus().subscribe((status) => {
      this.overrideActive = status;
    });
  }

  ngOnInit(): void {
    this._licensingSvc.checkLicense().subscribe((state)=> {
      this.license = state.license;
      this.state = state;
      this.initialised = true;
    });
  }

  handleExpireOverride(expireDate) {
    this._licensingSvc.setLocalOverride(this.tool, this.license.subscriptions[this.tool].plan.name, expireDate);
  }

  handleTierOverride(tierName) {
    this._licensingSvc.setLocalOverride(this.tool, tierName, this.license.subscriptions[this.tool].expiry);
  }

  // handleToolOverride(tool) {
  //   if (!this.license.subscriptions[tool]) {
  //     this.license.subscriptions[tool] = this.license.subscriptions.LSW;
  //   }
  //   this._licensingSvc.setLocalOverride(tool, this.license.subscriptions.LSW.plan, this.license.subscriptions.LSW.expiry)
  // }
}
