import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@amplabs/components';
import { AppSharedModule } from '@amplabs/shared.module';

import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';
import { NavTogglerModule } from '../../components/nav-toggler/nav-toggler.module';

import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';
import { JobModule } from '../../../modules/jobs/job.module';
import { CoModule } from '../../../modules/common/co.module';
import { LittlesisLicensingModule } from '../../../modules/licensing/littlesis-licensing.module';
import {MaterialModule} from '../../../core/services/material/material.module';
import { CdwOverlayModule } from '@cdw-ae/common-ui-components-overlay';

@NgModule({
  declarations: [
    VerticalLayout1Component
  ],
  imports: [
    RouterModule,
    AppSharedModule,
    FuseSidebarModule,
    CoModule,
    ContentModule,
    FooterModule,
    NavbarModule,
    QuickPanelModule,
    ToolbarModule,
    NavTogglerModule,
    JobModule,
    LittlesisLicensingModule,
    MaterialModule,
    CdwOverlayModule
  ],
  exports: [
    VerticalLayout1Component
  ]
})
export class VerticalLayout1Module {
}
