import {
  Component,
  OnInit,
} from '@angular/core';

import {LittlesisLicensingService} from '../../../licensing/services/littlesis-licensing.service';

@Component({
  selector: 'app-unlicensed-warning-bar',
  templateUrl: './unlicensed-warning-bar.component.html',
  styleUrls: ['./unlicensed-warning-bar.component.css']
})
export class UnlicensedWarningBarComponent implements OnInit {
  limitWarningText = LittlesisLicensingService.limitWarningText;
  constructor() { }

  ngOnInit(): void {
  }

}
