import {KeyValue} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import * as moment from 'moment';
import {
  Observable,
  Subject,
} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IGetRowsParams} from '../../../../core/models/agGrid.interface';
import {ListResponse} from '../../../../core/models/api.interface';
import {ClassroomService} from '../../../../services/classroom.service';

import {
  School,
  SchoolService,
} from '../../../../services/schools.service';
import {TagService} from '../../../../services/tags.service';
import {
  Timeframe,
  TimeframeService,
} from '../../../../services/timeframes.service';
import {UtilService} from '../../../../services/util.service';
import {AuthService} from '../../../auth/services/Auth/auth.service';
import {
  AgGridBooleanCheckCellRendererComponent,
  AgGridClassroomCellRendererComponent,
  AgGridPrimaryTeacherProfileCellRendererComponent,
  AgGridProfileCellRendererComponent,
  GridDataService,
} from '@cdw-ae/little-sis-for-classroom-ag-grid';
import {ClassroomTagDialogData} from '../../../explorer/services/classroom-grid.service';

import { ClassroomAnnouncement } from 'app/services/models/announcement.model';

@Component({
  selector: 'app-classroom-info-dialog',
  templateUrl: './classroom-info.component.html',
  styleUrls: ['./classroom-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class ClassroomInfoDialogComponent implements AfterViewInit, OnDestroy, OnInit {

  // @ViewChild('tabGroup') tabGroup: ElementRef;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;


  selectedTabIndex = 0;

  announcements: ClassroomAnnouncement[];
  announcementsLoading = true;
  announcementCount = 0;

  announcementsGridOptions;
  announcementsColumnDefs;
  announcementGridApi;

  rosterGridOptions;
  rosterColumnDefs;
  courseworkGridOptions;
  courseworkColumnDefs;
  membershipChangesGridOptions;
  membershipChangesColumnDefs;
  context;
  frameworkComponents;

  isSuperAdmin: boolean;

  roster: ListResponse;
  coursework: ListResponse;

  tags = [];

  schools$: Observable<School[]>;
  schoolsLoading = false;

  timeframes$: Observable<Timeframe[]>;
  timeframesLoading = false;

  isStudent = this.authService.hasPermission('join_class_as_student');

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private classroomService: ClassroomService, public dialogRef: MatDialogRef<ClassroomInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ClassroomTagDialogData,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private tagSvc: TagService,
              private schoolSvc: SchoolService,
              private timeframeSvc: TimeframeService) {

    this.isSuperAdmin = this.authService.isSuperAdmin();

    this.context = { componentParent: this };

    this.frameworkComponents = {
      primaryTeacherProfileCellRenderer: AgGridPrimaryTeacherProfileCellRendererComponent,
      classroomCellRenderer: AgGridClassroomCellRendererComponent,
      booleanCellRenderer: AgGridBooleanCheckCellRendererComponent,
      profileCellRenderer: AgGridProfileCellRendererComponent
    };

    this.announcementsColumnDefs = [

      {
        headerName: 'Creator',
        field: 'creator.name',
        width: 80,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        cellRenderer: 'profileCellRenderer'
      },

      {
        headerName: 'Text',
        field: 'text',
        width: 120,
        autoHeight: true,
        filter: 'agTextColumnFilter',
        hide: false,
      },

      {
        headerName: 'Publish State',
        field: 'state',
        width: 80,
        cellClass: 'text-center',
        filter: 'agSetColumnFilter',
        filterParams: { cellHeight: 20, values: ['PUBLISHED', 'DRAFT', 'DELETED'], newRowsAction: 'keep', debounceMs: 500 },
        hide: false
      },

      {
        headerName: 'Assignee Mode',
        field: 'assigneeMode',
        width: 80,
        cellClass: 'text-center',
        filter: 'agSetColumnFilter',
        filterParams: { cellHeight: 20, values: ['ALL_STUDENTS', 'INDIVIDUAL_STUDENTS'], newRowsAction: 'keep', debounceMs: 500 },
        hide: false
      },

      {
        headerName: 'Created',
        field: 'creationTime',
        width: 60,
        cellClass: 'text-center',
        filter: 'agDateColumnFilter',
        sortable: true,
        suppressMenu: true,
        hide: false,
        valueFormatter: (data) => moment(data.value).format('MM/DD/YY HH:mm')
      },
      {
        headerName: 'Updated',
        field: 'updateTime',
        width: 60,
        cellClass: 'text-center',
        filter: 'agDateColumnFilter',
        sortable: true,
        suppressMenu: true,
        hide: false,
        valueFormatter: (data) => moment(data.value).format('MM/DD/YY HH:mm')
      },
    ];


    this.announcementsGridOptions = {
      columnDefs: this.announcementsColumnDefs,
      components: this.frameworkComponents,
      context: this.context,

      rowModelType: 'clientSide',
      maxBlocksInCache: 2,
      rowSelection: false,
      cacheBlockSize: 100,
      animateRows: true,
      clipboardDelimiter: ',',
      serverSideInfiniteScroll: true,
      suppressCopyRowsToClipboard: true,
      pagination: true,
      paginationPageSize: 50,
      defaultColDef: {
        filter: true,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: true,
        hide: false,
        resizable: true,
        sortable: true
      },
    };

    this.rosterColumnDefs = [

      {
        headerName: 'Name',
        field: 'student.name',
        width: 80,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        cellRenderer: 'profileCellRenderer'
      },

      {
        headerName: 'Org Unit',
        field: 'student.orgUnitPath',
        width: 120,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        hide: false,
      }
    ];


    this.rosterGridOptions = {
      columnDefs: this.rosterColumnDefs,
      components: this.frameworkComponents,
      context: this.context,
      getContextMenuItems: this.getRosterContextMenuItems,
      processCellForClipboard: this.processCellForClipboard,

      rowModelType: 'serverSide',
      rowSelection: 'multiple',
      cacheBlockSize: 100,
      animateRows: true,
      clipboardDelimiter: ',',
      serverSideInfiniteScroll: true,
      suppressCopyRowsToClipboard: false,
      enableRangeSelection: true,
      pagination: true,
      defaultColDef: {
        filter: true,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: true,
        hide: false,
        resizable: true,
        sortable: true
      },
    };

    this.courseworkColumnDefs = [

      {
        headerName: 'Creator',
        field: 'creator.name',
        width: 140,
        filter: 'agTextColumnFilter',
        autoHeight: true,
        cellRenderer: 'profileCellRenderer'
      },

      {
        headerName: 'Title',
        field: 'title',
        width: 140,
        filter: 'agTextColumnFilter',
        autoHeight: true,
      },

      {
        headerName: 'Type',
        field: 'workType',
        width: 60,
        cellClass: 'text-center',
        filter: 'agSetColumnFilter',
        filterParams: { cellHeight: 20, values: ['ASSIGNMENT', 'SHORT_ANSWER_QUESTION', 'MULTIPLE_CHOICE_QUESTION'], newRowsAction: 'keep', debounceMs: 500 },
        autoHeight: true,
        hide: false,
      },
      {
        headerName: 'Publish State',
        field: 'state',
        width: 60,
        cellClass: 'text-center',
        filter: 'agSetColumnFilter',
        filterParams: { cellHeight: 20, values: ['PUBLISHED', 'DRAFT', 'DELETED'], newRowsAction: 'keep', debounceMs: 500 },
        autoHeight: true,
        hide: false,
      },
      {
        headerName: 'Created',
        field: 'creationTime',
        width: 60,
        cellClass: 'text-center',
        filter: 'agDateColumnFilter',
        sortable: true,
        suppressMenu: true,
        hide: false,
        valueFormatter: (data) => moment(data.value).format('MM/DD/YY HH:mm')
      },
      {
        headerName: 'Updated',
        field: 'updateTime',
        width: 60,
        cellClass: 'text-center',
        filter: 'agDateColumnFilter',
        sortable: true,
        suppressMenu: true,
        hide: false,
        valueFormatter: (data) => moment(data.value).format('MM/DD/YY HH:mm')
      }
    ];


    this.courseworkGridOptions = {
      columnDefs: this.courseworkColumnDefs,
      components: this.frameworkComponents,
      context: this.context,

      rowModelType: 'serverSide',
      rowSelection: false,
      cacheBlockSize: 100,
      animateRows: true,
      clipboardDelimiter: ',',
      serverSideInfiniteScroll: true,
      suppressCopyRowsToClipboard: true,
      pagination: true,
      defaultColDef: {
        filter: true,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: true,
        hide: false,
        resizable: true,
        sortable: true
      },
    };

    // Roster Changes

    this.membershipChangesColumnDefs = [

      {
        field: 'action_timestamp',
        headerName: 'Timestamp',
        width: 80,
        cellClass: 'text-center',
        filter: 'agDateColumnFilter',
        sortable: true,
        suppressMenu: true,
        hide: false,
        valueFormatter: (data) => moment(data.value).format('MM/DD/YY HH:mm')
      },
      {
        headerName: 'User',
        field: 'user.primaryEmail',
        width: 180,
        filter: 'agTextColumnFilter',
        cellRenderer: 'profileCellRenderer',
        autoHeight: true,
      },
      {
        headerName: 'Method',
        field: 'method',
        cellClass: 'text-center',
        width: 60,
        filter: 'agSetColumnFilter',
        filterParams: { cellHeight: 20, values: ['DELETED', 'CREATED'], newRowsAction: 'keep', debounceMs: 500 },
        valueFormatter: (data) => {
          switch (data.value) {

            case 'DELETED':
              return 'REMOVED';

            case 'CREATED':
            default:
              return 'ADDED';

          }
        }
      },
      {
        headerName: 'Type',
        field: 'type',
        cellClass: 'text-center',
        width: 60,
        filterParams: { cellHeight: 20, values: ['STUDENT', 'TEACHER'], newRowsAction: 'keep', debounceMs: 500 },
      }
    ];


    this.membershipChangesGridOptions = {
      columnDefs: this.membershipChangesColumnDefs,
      components: this.frameworkComponents,
      context: this.context,

      rowModelType: 'serverSide',
      rowSelection: false,
      cacheBlockSize: 100,
      animateRows: true,
      clipboardDelimiter: ',',
      serverSideInfiniteScroll: true,
      suppressCopyRowsToClipboard: true,
      pagination: true,
      defaultColDef: {
        filter: true,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: true,
        hide: false,
        resizable: true,
        sortable: true
      },
    };

  }

  async ngOnInit() {
    this.tagSvc.getTags()
      .subscribe(tags => {
          this.tags = tags.data;
        }
      );

    this.schools$ = this.schoolSvc.list();

    this.timeframes$ = this.timeframeSvc.list();

    // Loading announcements dynamically
    this.classroomService.getAnnouncements(this.data.cls.id)
      .subscribe((announcements: ClassroomAnnouncement[]) => {
        this.announcements = announcements;
        this.announcementCount = announcements.length;
        this.announcementsLoading = false;
        if (this.announcementGridApi) {
          this.announcementGridApi.setRowData(this.announcements);
        }
      });
  }

  // Allows for copying student emails from the roster grid
  processCellForClipboard(params) {
    if (params.column.colId === 'student.name' && params.node.data.student.primaryEmail) {
      return params.node.data.student.primaryEmail;
    } else {
      return params.value;
    }
  }

  capitalize = (s) => {
    if (typeof s !== 'string') {return '';}
    return s.charAt(0).toUpperCase() + s.slice(1);
  };


  onAnnouncementGridReady(params: any) {
    this.announcementGridApi = params.api;
    params.api.setRowData(this.announcements);
    params.api.refreshClientSideRowModel();
    params.api.sizeColumnsToFit();
  }


  onRosterGridReady(params: any) {
    params.api.setServerSideDatasource(this.getResourceDatasource('roster'));
    params.api.sizeColumnsToFit();
  }

  onCourseworkGridReady(params: any) {
    params.api.setServerSideDatasource(this.getResourceDatasource('coursework'));
    params.api.sizeColumnsToFit();
  }

  onMembershipChangeGridReady(params: any) {
    params.api.setServerSideDatasource(this.getResourceDatasource('membershipChange'));
    params.api.sizeColumnsToFit();
  }



  ngAfterViewInit() {
    this.tabGroup.selectedIndexChange.pipe(takeUntil(this.onDestroy$)).subscribe(
      tabIndex => this.selectedTabIndex = tabIndex
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  /**
   * Close the dialog and save changes
   */
  async save(): Promise<void> {

    this.dialogRef.close(this.data);

  }

  cancel(): void {
    this.dialogRef.close();
  }

  openClassroom(): void {
    window.open(GridDataService.validateAlternateLink(this.data.cls.alternateLink));
  }

  refreshClassroom(): void {
    this.classroomService.refresh({ ids: [this.data.cls.id]});
  }

  // End of Announcements code

  getDefaultPhotoUrl() {
    return UtilService.defaultPhotoUrl;
  }

  getErrorPhotoUrl() {
    return UtilService.errorPhotoUrl;
  }

  keyDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);

  valueDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.value > b.value ? -1 : (b.value > a.value ? 1 : 0);

  userIsTeacherOfClass() {
    const currentUser = this.authService.getCurrentUser();

    if (this.data.cls.coteachers) {
      const isCoTeacher = !!this.data.cls.coteachers.find(coTeacher => coTeacher.gId === currentUser.profile.gId);
      if (isCoTeacher) {
        return true;
      }
    } else {
      return this.data.cls.Owner && this.data.cls.Owner.gId === currentUser.profile.gId;
    }
  }

  onViewStudent(gId: string) {
    this.router.navigate(['/', 'students'], {
      queryParams: { gId },
      queryParamsHandling: 'merge'
    });
  }

  getRosterContextMenuItems(params) {
    if (params.node === null) {
      return;
    }

    const menu: any[] = [
      {
        name: `<strong>${params.node.data.student.name}</strong>`
      },
      'separator',
      {
        name: `View student`,
        action: () => {
          params.context.componentParent.onViewStudent(params.node.data.user_id);
        },
        tooltip: `View ${params.node.data.student.name} (${params.node.data.student.primaryEmail}) in the student explorer`
      }
    ];

    if (typeof params.value === 'string') {
      menu.push('separator');
      menu.push('copy');
    }

    return menu;
  }

  private getMembershipChangeRowData(params): Observable<ListResponse> {

    const opts = {useCache: true};

    const req = {
      take: params.request.endRow - params.request.startRow,
      skip: params.request.startRow,
      filterModel: params.request.filterModel,
      sortModel: params.request.sortModel,
    };

    return this.classroomService.getMembershipChanges(this.data.cls.id, req, opts);
  }

  private getCourseworkRowData(params): Observable<ListResponse> {

    const opts = {useCache: true};

    const req = {
      take: params.request.endRow - params.request.startRow,
      skip: params.request.startRow,
      filterModel: params.request.filterModel,
      sortModel: params.request.sortModel,
    };

    return this.classroomService.getCoursework(this.data.cls.id, req, opts);
  }

  private getRosterRowData(params): Observable<ListResponse> {

    const opts = {useCache: true};

    const req = {
      take: params.request.endRow - params.request.startRow,
      skip: params.request.startRow,
      filterModel: params.request.filterModel,
      sortModel: params.request.sortModel,
    };

    return this.classroomService.getRoster(this.data.cls.id, req, opts);
  }

  private getResourceDatasource(type) {

    const method = 'get' + this.capitalize(type) + 'RowData';

    return {
      getRows: (params: IGetRowsParams) => {

        // const info = "Getting datasource rows, start: " + params.startRow + ", end: " + params.endRow;

        this[method](params)
          .subscribe(data => {
            params.successCallback(data.data, data.total);
          });

      }
    };

  }

}
