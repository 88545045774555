import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { ClassActions } from '../../../../../services/classroom.service';
import { User, UserService } from '../../../../../services/user.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';

import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';
import { AgGridProfileCellRendererComponent, AgGridClassroomCellRendererComponent } from '@cdw-ae/little-sis-for-classroom-ag-grid';

export const CHANGE_PRIMARY_TEACHER_STEPPER_STEPS = 4;
export const CHANGE_PRIMARY_TEACHER = 'explorerChangePrimaryTeacher';

@Component({
  selector: 'app-change-primary-teacher',
  templateUrl: './change-primary-teacher.html',
  styleUrls: ['./change-primary-teacher.scss']
})

export class ChangePrimaryTeacherComponent extends GenericBulkActionComponent implements OnInit {

  changePrimaryTeacherEmailTMPL: string;

  newPrimaryTeacher: any; // TODO: associate with UserDirectory Type

  teachers$: Observable<User[]>;
  teachersLoading = false;
  teachersInput$ = new Subject<string>();
  showWarning = false;
  summaryOfChanges = [];

  additions: number;
  nochanges: number;

  columnDefsSummary;
  gridOptionsSummary;
  grids: {
    classes: {
      height: number;
    };
    summary: {
      height: number;
    };
  };

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  constructor( public apiLookup: ApiLookupService,
               public dialogRef: MatDialogRef<ChangePrimaryTeacherComponent>,
               public userSvc: UserService,
               protected _authSvc: AuthService,
               protected _firestoreCustomerService: FirestoreCustomerService,
               private _firebaseAnalytics: FirebaseAnalyticsService,
               @Inject(MAT_DIALOG_DATA) public data: ClassActions
  ) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    this.data.selectedClasses.sort((a, b) => {
      if (a.primaryTeacherEmail > b.primaryTeacherEmail) {
        return 1;
      } else if (a.primaryTeacherEmail < b.primaryTeacherEmail) {
        return -1;
      }
      return 0;
    });

    // Summary Grid

    this.columnDefsSummary = [
      {
        headerName: 'Primary Teacher',
        field: 'teacher.name',
        width: 80,
        filter: false,
        hide: false,
        cellRenderer: AgGridProfileCellRendererComponent,
        suppressMenu: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 60,
        filter: false,
        cellClass: 'text-center',
        hide: false,
        suppressMenu: true
      },
      {
        headerName: 'Course',
        field: 'class.name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: AgGridClassroomCellRendererComponent,
        autoHeight: true,
        suppressMenu: true
      }

    ];

    this.gridOptionsSummary = {
      columnDefs: this.columnDefsSummary,
      rowModelType: 'clientSide',
      rowSelection: false,
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      rowData: this.summaryOfChanges,

      animateRows: true,

      clipboardDelimiter: ',',

      pagination: false,

      defaultColDef: {
        filter: false,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: false,
        hide: false,
        resizable: true,
        sortable: true
      },

      rowClassRules: {
        'declined-50': 'data && data.action === "Already the primary teacher"',
      },

    };

    this.grids = {
      classes: {
        height: Math.min(80 + this.data.selectedClasses.length * 60, 300)
      },
      summary:{
        height : Math.min(80 + this.data.selectedClasses.length * 60, 300)
      }
    };

  }

  ngOnInit(): void {
    this.gridOptions.rowData = this.data.selectedClasses;

    this.sendNotification = true;

    this.initialize('change_primary_teacher').subscribe(() => {
      this.generateReplacements(this.selectedClasses);
      this.loadTeachers();
    });
  }

  onChange(event) {

    if (this.newPrimaryTeacher) {
      this.nav.nextDisabled = false;
      this.step2Completed = true;
    } else {
      this.nav.nextDisabled = true;
      this.step2Completed = false;
    }

    this.processChanges();

  }

  goForward(stepper: MatStepper) {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[CHANGE_PRIMARY_TEACHER][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    if(!this.step2Completed && this.newPrimaryTeacher) {
      this.showWarning=true;
    }
    this.processChanges();
    stepper.next();
  }

  processChanges() {

    this.additions = 0;
    this.nochanges = 0;
    this.summaryOfChanges = [];

    if (!this.newPrimaryTeacher) {
      return;
    }

    this.selectedClasses.sort((a, b) => {
      if (a.primaryTeacherEmail > b.primaryTeacherEmail) {
        return 1;
      } else if (a.primaryTeacherEmail < b.primaryTeacherEmail) {
        return -1;
      }
      return 0;
    }).forEach( currCls => {

      const hasTeacher = Boolean(currCls.Owner.primaryEmail === this.newPrimaryTeacher.primaryEmail);

      if (!hasTeacher) {
        const relevantData = {
          teacher: this.newPrimaryTeacher,
          action: 'Change primary teacher',
          class: currCls
        };
        this.summaryOfChanges.push(relevantData);
        this.additions++;
      }
      else {
        const relevantData = {
          teacher: this.newPrimaryTeacher,
          action: 'Already the primary teacher',
          class: currCls
        };
        this.summaryOfChanges.push(relevantData);
        this.nochanges++;

      }
    });

    if (this.additions === 0 && this.nochanges > 0) {
      this.step2Completed = false;
    } else {
      this.step2Completed = true;
      this.showWarning = false;
    }

    this.gridOptionsSummary.api.setRowData(this.summaryOfChanges);

    this.grids.summary.height = Math.min(80 + this.data.selectedClasses.length * 60, 300);

    this.gridOptionsSummary.api.sizeColumnsToFit();

    this.processReplacements();

  }

  processReplacements() {
    this.generateReplacements(this.selectedClasses);

    if (this.newPrimaryTeacher) {
      this.templateReplacements['{{userName}}'] = this.newPrimaryTeacher.name;
      this.templateReplacements['{{classPlural}}'] = (this.selectedClasses.length > 1) ? 'Google Classroom classes' : 'Google Classroom class';
    }
  }


  changePrimaryTeacher() {
    const selectedClassData = this.summaryOfChanges.reduce((classData, selection) => {
      if (selection.action === 'Change primary teacher') {
        classData.push({
          clsId: selection.class.id,
          originalOwner: {
            name: selection.class.Owner.name,
            email: selection.class.Owner.primaryEmail
          }
        });
      }
      return classData;
    },[]);

    const updatedData = {
      teacher: this.newPrimaryTeacher.primaryEmail,
      classrooms: selectedClassData,
      sendNotification: this.sendNotification,
      customerActionTemplate: this.customerActionTemplate,
      userActionTemplate: this.userActionTemplate,
      replyEmail: this.notificationEmail.value,
      actionType: 'change_primary_teacher'
    };
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[CHANGE_PRIMARY_TEACHER][CHANGE_PRIMARY_TEACHER_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);
    this.dialogRef.close(updatedData);
  }

  private loadTeachers() {

    this.teachers$ = concat(
      of([]), // default items
      this.teachersInput$.pipe(
        debounceTime(750),
        distinctUntilChanged(),
        tap(() => this.teachersLoading = true),
        switchMap(term => this.userSvc.getTeachers({
          filter: {
            op: 'OR',
            filters: [
              {field: 'name', operator: 'contains', value: term},
              {field: 'primaryEmail', operator: 'contains', value: term}
              ]
          }
        }).pipe(
            map(response => response.data),
            catchError(() => of([])), // empty list on error
            tap(() => this.teachersLoading = false)
          ))
      )
    );

  }

  onGridReadySummary(params: any) {
    params.api.sizeColumnsToFit();
  }

  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[CHANGE_PRIMARY_TEACHER][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

}
