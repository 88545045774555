import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FirebaseAnalyticsService } from 'app/core/services/FirebaseAnalytics/firebase-analytics.service';
import { Customer } from 'app/modules/common/models/Customer';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../auth/services/Auth/auth.service';
import { CustomerService } from '../../../common/services/customer.service';
import { License, LicenseState } from '../../services/licensing.interfaces';
import { LittlesisLicensingService } from '../../services/littlesis-licensing.service';


@Component({
  selector: 'licensing-overlay',
  templateUrl: './licensing-overlay.component.html',
  styleUrls: ['./licensing-overlay.component.scss']
})
export class LicensingOverlayComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  customer: Customer;
  clientId = environment.adminConsole.client_name;
  scopesList = environment.adminConsole.scopes;

  maxRetries = 24;
  msBetweenRetries = 5000;
  progress = 0;

  license: License;
  licenseState: LicenseState;
  refreshing = false;
  verifyingLicense = false;
  failed = false;
  isSuperAdmin = false;

  expired;

  constructor(
    private _firebaseAnalytics: FirebaseAnalyticsService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _customerSvc: CustomerService,
    private _authSvc: AuthService,
    private _licensingSvc: LittlesisLicensingService) {

    this.licenseState = {
      licensed: true,
      loaded: false,
      bundle: null
    };
  }

  ngOnInit() {

    combineLatest(
      [this._authSvc.getActiveUser(),
      this._customerSvc.getCurrentCustomer()]
    ).pipe(takeUntil(this.onDestroy$))
     .pipe(map(([user, customer]) => ({
          user, customer
        }))).subscribe((pair) => {
        this.isSuperAdmin = this._authSvc.isSuperAdmin();
      });

    this._licensingSvc.checkLicense().pipe(takeUntil(this.onDestroy$)).subscribe((licenseState) => {
      if (licenseState.loaded) {
        this.license = licenseState.license;
        this.licenseState = licenseState;
      }
    });
  }

  goToLabs() {
    const url = 'https://labs.amplifiedit.com';
    window.open(url);
  }

  getQuote() {
    this._firebaseAnalytics.sendEvent({ action: 'Quote request',  properties: { category: 'App', label: 'Quote' } });
    window.open(environment.links.purchase);
  }

  requestDataDeletion() {
    this._firebaseAnalytics.sendEvent({ action: 'Data deletion request',  properties: { category: 'App', label: 'Deletion' } });
    window.open(environment.links.deletionRequest);
  }

  verifyLicense(notify = false) {

    this._firebaseAnalytics.sendEvent({ action: 'Check license',  properties: { category: 'App', label: 'Verify' } });

    this.verifyingLicense = true;

    return this._licensingSvc.checkLicense().subscribe((response) => {

      this.verifyingLicense = false;

      return true;
    });

  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  logout() {
    this._authSvc.signOut(true);
  }


}
