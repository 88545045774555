import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap, timeout } from 'rxjs/operators';
import { AuthService, User } from '../Auth/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  activeUser$: Observable<User>;
  constructor(private auth: AuthService, private router: Router) {
    this.activeUser$ = this.auth.getActiveUser();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {

    return this.activeUser$.pipe(
      timeout(6000),
      catchError(error => {
        console.log('User context unclear after 6s');
        return of({auth: undefined});
      }),
      filter(activeUser => !!activeUser),
      switchMap( activeUser => {
        if (activeUser?.auth === undefined) {
          this.router.navigate(['/']).catch(()=>{});
          return of(false);
        }

        if (activeUser?.auth.role_key === 'sa') {
          return of(true);
        }

        const requiredPermissions = route.data.access as string[];

        if (!requiredPermissions || !requiredPermissions.length) {
          return of(true);
        }

        if (activeUser.auth.permissions === undefined) {
          return of(false);
        }

        const userPermissions = activeUser.auth.permissions.map(permission => permission.name);

        const hasPermission = userPermissions.reduce((accum: boolean, curr) => {
          if (requiredPermissions !== undefined && requiredPermissions.includes(curr) || requiredPermissions.length === 0) {
            accum = true;
          }
          return accum;
        }, false);

        if (!hasPermission) {
          this.router.navigate(['/']).catch(()=>{});
        }
        return of(hasPermission);
      })
    );
  }
}
