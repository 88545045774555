import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription , Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClassroomGridService } from '../../services/classroom-grid.service';
import { ClassroomInfoDialogComponent } from '../../../custom-dialogs/components/classroom-info/classroom-info.component';
import { ClassroomService } from '../../../../services/classroom.service';
import { MessageService } from '../../../../services/message.service';



@Component({
    selector: 'app-grid-dialog',
    template: ``,
    styles: []
})
export class GridDialogComponent implements OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  sub: Subscription;

  constructor(
    private messageService: MessageService,
    public dialog: MatDialog,
    public cgs: ClassroomGridService,
    public classroomService: ClassroomService
  ) {

    this.cgs.dialogTrigger.pipe(takeUntil(this.onDestroy$)).subscribe(resp => {

      if (!resp) {
        return;
      }

      switch (JSON.parse(resp).type) {

        case 'info':
        default:
          this.classDialog_(JSON.parse(resp), this);
          break;
      }

    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private classDialog_(data, context): void {

    if (data.row) {

      const dialogData = {
        cls: data.row,
        tags: []
      };

      const dialogRef = context.dialog.open(ClassroomInfoDialogComponent, {
        panelClass: ['ait-little-sis-panel','full'],
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe(async result => {

        if (result) {

          // Save was clicked - save the class modifications and tags

          this.cgs.state.busy = true;

          const requestData = {
            tags: result.cls.tags,
            schools: result.cls.sch,
            timeframes: result.cls.tf
          };

          return this.classroomService.updateClassTags(result.cls.id, requestData).subscribe(saveResult => {
            this.cgs.state.busy = false;
            this.messageService.sendMessage({ action: 'updateClassrooms', classes: [result]});
          });

        }

      });

    }

  }

}
