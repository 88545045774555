import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { AppSidebarService } from '../../../../../@amplabs/components/sidebar/sidebar.service';
import { Message, MessagesService } from '../../../../core/services/messages/messages.service';
import { ExportsService } from '../../../exports/services/exports.service';
import { BulkJob } from '../../bulk-job.interface';
import { EditingState, JobListStateService } from '../../services/job-list-state.service';
import { ScheduleService, Scheduled } from '../../services/schedule.service';
import { TaskService } from '../../services/task.service';

import { IExportJobRepository } from '@cdw-ae/export-interfaces';


interface SyncState {
  metadata: {
    status: string;
  };
  classrooms: {
    status: string;
  };
  users: {
    status: string;
  };
}

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
})

export class JobListComponent implements OnInit, OnDestroy {

  jobListState: EditingState;

  messages: Message[] = [];

  exports: IExportJobRepository[] = [];

  scheduled: Scheduled[] = [];

  activeBulkJob: BulkJob = {
    method: 'archive',
    start: {
      nanoseconds: 0,
      seconds: 0,
    },
    end: {
      nanoseconds: 0,
      seconds: 0,
    },
    classCount: 0,
    successCount: 0,
    failCount: 0,
    failures: [],
    status: '',
    isActive: false,
    complete: false,
  };

  visibleArchiveCount = 2;

  archiveHistory: BulkJob[] = [];

  jobs = [];

  jobCount: number;

  trigger: number;

  sync: SyncState;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private _appSidebarService: AppSidebarService,
    private _jobListStateService: JobListStateService,
    private _taskSvc: TaskService,
    private _msgSvc: MessagesService,
    private _exportSvc: ExportsService,
    private _scheduleSvc: ScheduleService
  ) {

    this.sync = {
      metadata: {
        status: 'init',
      },
      classrooms: {
        status: 'init',
      },
      users: {
        status: 'init',
      },
    };

    this.jobCount = 0;

    this._taskSvc.getStatus().subscribe(jobs => {
      this.jobs = jobs?.jobs;

      this.jobCount = this.jobs?.length;

      this.sync.metadata = jobs?.metadata;
      this.sync.classrooms = jobs?.classrooms;
      this.sync.users = jobs?.users;

      if (this.sync.metadata && this.sync.metadata.status === 'active') {
        this.jobCount++;
      }

      if (this.sync.users && this.sync.users.status === 'active') {
        this.jobCount++;
      }

      if (this.sync.classrooms && this.sync.classrooms.status === 'active') {
        this.jobCount++;
      }

      if (this.jobCount) {
        setInterval(() => this.trigger = Math.random(), 1000);
      }

    });

    this._msgSvc.getMessages()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((messages: Message[]) => {
        this.messages = messages;
      });

    this._exportSvc.getUserExportJobs().pipe(takeUntil(this.onDestroy$))
      .subscribe((exports) => {
        this.exports = exports as unknown as IExportJobRepository[];
      });

    this._scheduleSvc.getUser().pipe(takeUntil(this.onDestroy$))
      .subscribe((scheduled) => {
        this.scheduled = scheduled;
      });

    this._taskSvc.getUser().pipe(
      takeUntil(this.onDestroy$),
      filter(tasks => tasks && (tasks.archive || tasks.bulkDelete || tasks.bulkClearRosters)),
      map((tasks: any) => {
        if (tasks.archive && !tasks.archive.complete) {
          return {
            method: tasks.archive.method,
            start: tasks.archive.start,
            end: tasks.archive.end,
            successCount: tasks.archive.successCount || 0,
            failCount: tasks.archive.failCount,
            failures: tasks.archive.failures,
            classCount: tasks.archive.classCount,
            status: tasks.archive.status,
            isActive: tasks.archive.isActive,
            complete: tasks.archive.complete,
          };
        }
        if (tasks.bulkDelete && !tasks.bulkDelete.complete) {
          return {
            method: tasks.bulkDelete.method,
            start: tasks.bulkDelete.start,
            end: tasks.bulkDelete.end,
            successCount: tasks.bulkDelete.successCount || 0,
            failCount: tasks.bulkDelete.failCount,
            failures: tasks.bulkDelete.failures,
            classCount: tasks.bulkDelete.classCount,
            status: tasks.bulkDelete.status,
            isActive: tasks.bulkDelete.isActive,
            complete: tasks.bulkDelete.complete,
          };
        }
        if (tasks.bulkClearRosters && !tasks.bulkClearRosters.complete) {
          return {
            method: tasks.bulkClearRosters.method,
            start: tasks.bulkClearRosters.start,
            end: tasks.bulkClearRosters.end,
            successCount: tasks.bulkClearRosters.successCount || 0,
            failCount: tasks.bulkClearRosters.failCount,
            failures: tasks.bulkClearRosters.failures,
            classCount: tasks.bulkClearRosters.classCount,
            status: tasks.bulkClearRosters.status,
            isActive: tasks.bulkClearRosters.isActive,
            complete: tasks.bulkClearRosters.complete,
          };
        }
        // Default object. The use of a default can be improved. Null would be better
        return {
          method: 'archive',
          start: {
            nanoseconds: 0,
            seconds: 0,
          },
          end: {
            nanoseconds: 0,
            seconds: 0,
          },
          classCount: 0,
          successCount: 0,
          failCount: 0,
          failures: [],
          status: '',
          isActive: false,
          complete: false,
        };
      })
    )
    .subscribe((bulkJob: BulkJob) => this.activeBulkJob = bulkJob);
  }

  ngOnInit() {
    this._jobListStateService.getState()
        .pipe(
          // tap(state => {
          // }),
          takeUntil(this.onDestroy$)
        )
        .subscribe(state => this.jobListState = state);

    this._taskSvc.getUserTaskHistory().pipe(
      takeUntil(this.onDestroy$),
      map(tasksHistory => tasksHistory.sort((a, b) => {
          // Sometimes a or b have no start time. This is a bug and should e fixed rather than worked around in this sort!
          if (!a.start) {
            return -1;
          }
          if (!b.start) {
            return 1;
          }
          if (a.start.seconds > b.start.seconds) {
            return -1;
          }
          if (a.start.seconds < b.start.seconds) {
            return 1;
          }
          return 0;
        }))
    ).subscribe(
      (tasksHistory) => this.archiveHistory = tasksHistory // XXX: Ger - When we add the delete story, the tasksHistory will contain more than just the archiveHistory and the implementation will need to be changed.
    );
  }

  goTo(path: string): void {
    if (path.substring(0, 4).toLowerCase() === 'http') {
      window.open(path, '_blank');
    } else {
      this.router.navigate([path]);
      this._appSidebarService.getSidebar('quickPanel').toggleOpen();
    }
  }

  onShowMoreArchiveHistory(): number {
    const additionalItemsIncrement = 5;
    this.visibleArchiveCount += additionalItemsIncrement;
    return this.visibleArchiveCount;
    // XXX: Ger - write unit tests
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  cancel(which: string) {
    this._taskSvc.cancel(which);
  }

  onCancelBulkJob(type: string) {
    this._taskSvc.cancelBulkJob(type);
  }

  onRestartBulkJob(type: string) {
    this._taskSvc.restartBulkJob(type);
  }

}
