import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// import { SupportService } from "../../../services/support.service";

interface DialogData {
  defaultMessage: string;
  description: string;
  url: string;
}


@Component({
  selector: 'app-custom-support-dialog',
  templateUrl: './custom-support-dialog.component.html',
  styleUrls: ['./custom-support-dialog.component.scss']
})
export class CustomSupportDialogComponent implements OnInit {

  defaultDescription: string;

  constructor(
    public dialogRef: MatDialogRef<CustomSupportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    // private _supportService: SupportService
  ) { }

  ngOnInit() {
    this.defaultDescription = this.data.defaultMessage; // this._supportService.defaultCustomSupportMessage;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
