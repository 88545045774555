import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';

import {
  Observable,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  map,
  takeUntil,
} from 'rxjs/operators';

import { AppNavigationService } from '@amplabs/components/navigation/navigation.service';

import { Customer } from 'app/modules/common/models/Customer';
import { LscEvents, StepAnalyticsEvent, StepAnalyticsEventEnum } from '../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { ClassroomService } from '../../../../services/classroom.service';
import { AuthService } from '../../../auth/services/Auth/auth.service';
import { ApiLookupService } from '../../../auth/services/api-lookup/api-lookup.service';
import {
  CustomerService,
} from '../../../common/services/customer.service';
import { TaskService } from '../../../jobs/services/task.service';
import { LittlesisLicensingService } from '../../../licensing/services/littlesis-licensing.service';

import { InstallState } from '../../../common/models/InstallState';

export interface SyncState {
  [index: string]: any;
  users?: {
    [index: string]: any;
    status?: string;
    message: string;
  };
  metadata?: {
    [index: string]: any;
    status?: string;
    message: string;
  };
  classrooms?: {
    [index: string]: any;
    status?: string;
    message: string;
  };
}

export const GETTING_STARTED_STEPPER_LENGTH = 6;

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  public hasPremiumAccess$: Observable<boolean>;
  customer: Customer;

  syncing = false;
  synced = false;

  clientInstalled = false;
  refreshing = false;
  verifyingAccess = false;
  failed = false;

  setupRequired = true;

  blankInstallState: InstallState = {
    acceptedTerms: false,
    designatedAdmin: false,
    users: false,
    classrooms: false,
    timeframes: false,
    client: false,
    metadata: false,
    supportConfigured: false,
    alertNotifications: false,
  };

  installState: InstallState;

  syncState: SyncState;
  firstNav = false;

  notificationRegistration: {};
  activeRegistration = false;
  processingTimeframes = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private authService: AuthService,
              private _customerSvc: CustomerService,
              private _classroomSvc: ClassroomService,
              private apiLookup: ApiLookupService,
              private _taskSvc: TaskService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              private _appNavigationService: AppNavigationService,
              private licenseService: LittlesisLicensingService,
              private _firebaseAnalytics: FirebaseAnalyticsService,
  ) {

    // this._customerSvc.getCustomer().subscribe(customer => {});
     this._customerSvc.getCurrentCustomer().subscribe(customer => {
       // called for some reason
     });

    // Use setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError error
    setTimeout(() => {
      this._taskSvc.getStatus().subscribe(data => {
        this.syncState = data;
      });
    }, 0);

  }

  ngOnInit(): void {

    this.hasPremiumAccess$ =  this.licenseService.isPremium()
    .pipe(
      map(isPremium => isPremium && this.authService.hasPermission('sync_admin'))
    );

    this._customerSvc.getSettings().pipe(takeUntil(this.onDestroy$)).subscribe(settings => {
      if (settings.installState) {
        this.installState = Object.assign(this.blankInstallState, settings.installState);
        if (this.installState.users && this.installState.classrooms && this.installState.metadata) {
          this.synced = true;
          this.syncing = false;
        }
      } else {
        this.installState = Object.assign({}, this.blankInstallState);
        this._customerSvc.updateInstallState(this.installState);
      }
    });

    this._customerSvc.getGettingStarted()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((gs) => {

        if (!gs) {
          return;
        }

        if (gs.start && (gs.next !== 'monitor' || !gs.complete)) {
          this.syncing = true;
        }

        if (gs.monitor && gs.end) {
          this.getRegistrationState();
        }

        if (this.installState) {
          let writeRequired = false;
          if (gs.metadata  && !this.installState.metadata) {
            this.installState.metadata = true;
            writeRequired = true;
          }

          if (gs.timeframes && !this.installState.timeframes) {
            this.installState.timeframes = true;
            writeRequired = true;
          }

          if (writeRequired) {
            this._customerSvc.updateInstallState({ metadata: this.installState.metadata, timeframes: this.installState.timeframes });
          }
        }

        this.processingTimeframes = (gs.next === 'timeframes');

      });
  }

  ngAfterViewInit() {

    // Use setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError error
    setTimeout(() => {
      this._customerSvc.getCurrentCustomer()
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(1000)
      )
      .subscribe(customer => {
        this.customer = customer as Customer;
        if (!this.firstNav) {
          this.goToLatestAction();
          this.firstNav = true;
        }
      });
    }, 0);

    this.getRegistrationState();

  }

  goToLatestAction() {
    if (!this.installState.acceptedTerms) {
      this.stepper.selectedIndex = 0;
    } else if (!this.installState.designatedAdmin || !this.customer.designatedAdmin) {
      this.stepper.selectedIndex = 1;
    } else if (!this.installState.alertNotifications) {
      this.stepper.selectedIndex = 2;
    } else if (!this.installState.supportConfigured) {
      this.stepper.selectedIndex = 3;
    } else if (!this.installState.client) {
      this.stepper.selectedIndex = 4;
    } else if (!this.installState.users) {
      this.stepper.selectedIndex = 5;
    } else if (!this.installState.classrooms) {
      this.stepper.selectedIndex = 5;
    } else if (!this.installState.metadata) {
      this.stepper.selectedIndex = 5;
    } else {
      this.stepper.selectedIndex = 6;
    }
  }

  navigation(event): void {
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  async goForward(stepper: MatStepper) {

    let proceed = false;
    let message;
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.home.gettingStartedWizard[stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    switch (stepper.selectedIndex) {

      case 0:
        // Check designated Admin
        if (this.installState.acceptedTerms) {
          proceed = true;
        } else {
          message = 'You must review and accept our Terms and Conditions before proceeding';
        }

        break;

      case 1:
        // Check designated Admin
        if (this.customer.designatedAdmin !== null) {
          proceed = true;
        } else {
          message = 'You must set the designated admin to proceed with installation';
        }

        break;

      case 2:
        // Check alertNotifications settings
        if (this.installState.alertNotifications !== null) {
          proceed = true;
        } else {
          message = 'Would you like to receive alert notifications from Little SIS?';
        }

        break;

      case 3:
        // Check support config
        if (this.installState.supportConfigured !== null) {
          proceed = true;
        } else {
          message = 'Would you like to update the support details for users from your Google domain?';
        }
        break;

      case 4:
        // Check Client install
        if (this.installState.client) {
          proceed = true;
        } else {
          message = 'You must install the client and scopes list to proceed with installation';
        }

        break;

      case 5:
        // Check loading of classroom data
        if (this.installState.metadata) {
          proceed = true;
        } else {
          message = 'You should complete the classroom data load...';
        }

        break;

      default:
        break;

    }

    if (proceed) {

      const requestData = {
        context: 'gettingStarted'
      };

      Object.assign(requestData, this.customer);

      // Store the customer?
      // @ts-ignore
      await this._customerSvc.updateCustomer(requestData).subscribe((response) => {
        if (stepper.selectedIndex < 6) {
          (stepper.selectedIndex as number)++;
        }
      });

    } else {
      // Trigger snackBar message
      this.snackBar.open(message, 'Ok', {duration: 6000});
    }
  }

  async setAlertNotifications() {
    this._customerSvc.updateInstallState({ alertNotifications: true });
    this.stepper.selectedIndex = 3;
  }

  async setSupportConfig() {
    this._customerSvc.updateInstallState({ supportConfigured: true });
    this.stepper.selectedIndex = 4;
  }

  startSync() {
    this.syncing = true;
    this._customerSvc.initGettingStarted();
    this._taskSvc.getStatus().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.syncState = data;
    });
  }

  getRegistrationState() {

    this._classroomSvc.getRegistration().subscribe((response) => {
      if (response.data.length) {
        this.notificationRegistration = response.data[0];
        // TODO check last refreshed data is current and registration is active
        this.activeRegistration = true;
      } else {
        this.activeRegistration = false;
      }
    });

  }

  goToPremiumGettingStartedStepper(): void {
    this._appNavigationService.navigationEvents$.next('launchSyncPremium');
    this._firebaseAnalytics.sendEvent(((LscEvents.lscEvents.home.gettingStartedWizard as StepAnalyticsEvent).stepLaunchSyncPremium as AnalyticsEvent));

  }

  // InstallState
  /*

  1 - Designated Admin
  2 - User Sync
  3 - Roster Sync
  4 - Client and Scopes
  5 - Classroom metadata

   */

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
