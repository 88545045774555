import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LittlesisLicensingService} from '../../../../services/littlesis-licensing.service';
import {LicenseState} from '../../../../services/licensing.interfaces';
import {AuthService, User} from '../../../../../auth/services/Auth/auth.service';
import {LicensingTierTemplate} from '../../interface/licensingTier.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {LicensingRenewalDialogComponent} from '../../licensing-renewal-dialog.component';
import {DatePipe} from '@angular/common';
import {FirebaseAnalyticsService} from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {LocalStorageService} from '../../../../../../core/services/LocalStorage/local-storage.service';
import {BaseLicensingInfoComponent} from '../base-licensing-info/base-licensing-info.component';
import * as moment from 'moment';
import {CustomerService} from '../../../../../common/services/customer.service';

@Component({
  selector: 'app-lapsed-licensing-info',
  templateUrl: './lapsed-licensing-info.component.html',
  styleUrls: ['../../licensing-renewal-dialog.component.scss']
})
export class LapsedLicensingInfoComponent extends BaseLicensingInfoComponent  implements OnInit, LicensingTierTemplate {

  @Input() data: {
    licenseState: LicenseState,
    activeUser: User,
    dialogRef: MatDialogRef<LicensingRenewalDialogComponent>
  };



  constructor( public datePipe: DatePipe,
               public firebaseAnalytics: FirebaseAnalyticsService,
               public localStorageService: LocalStorageService,
               public authService: AuthService,
               private licenseService: LittlesisLicensingService,
               public customerService: CustomerService
               ) {
    super( datePipe, firebaseAnalytics,localStorageService, authService, customerService)
  }

  ngOnInit(): void {
    this.sourceData = this.data
    super.ngOnInit();
    this.buildTemplateData(this.sourceData.licenseState)
  }

  buildTemplateData(licenseState: LicenseState){

    const expiryDisplayData = this.datePipe.transform(licenseState.license.subscriptions.LSW.expiry, 'MM/dd/yyyy');
    const pastDeleteDataWindow =  licenseState.expiresIn <= -90;
    const dataDeleteDate = this.datePipe.transform(moment(licenseState.license.subscriptions.LSW.expiry).endOf('d').add(90, 'd').toString(), 'MM/dd/yyyy');

    let expiryMessage;
    let showExpiredWarningIcon = false;
    if (licenseState.expiresIn > 0) {
      expiryMessage = `Your Lapsed license for ${licenseState.license.domain} expires on ${expiryDisplayData}.`
    } else {
      expiryMessage = `Your Lapsed license for ${licenseState.license.domain} expired on ${expiryDisplayData}.`
      showExpiredWarningIcon = true
    }


    if(this.isSuperAdmin()){

      if(licenseState.expiresIn <= -90) {
        expiryMessage = `Your license for ${licenseState.license.domain} expired on ${expiryDisplayData} and ALL associated data for your domain was deleted on ${dataDeleteDate}.`;
      }

      this.templateData = {
        domain: licenseState.license.domain,
        expireDays: licenseState.expiresIn,
        expireDate: this.datePipe.transform(licenseState.license.subscriptions.LSW.expiry, 'MM/dd/yyyy'),
        expiryMessage,
        showIcon: showExpiredWarningIcon,
        showRecheckButton: !pastDeleteDataWindow,
        showRenewalButton: true,
        plan: 'lapsed',
        pastDeleteDataWindow,
        lastTierExpiration: {type: licenseState.lastTierExpiration.type, date: licenseState.lastTierExpiration.date}
      };
    } else {
      this.templateData = {
        domain: licenseState.license.domain,
        expireDays: licenseState.expiresIn,
        expireDate: this.datePipe.transform(licenseState.license.subscriptions.LSW.expiry, 'MM/dd/yyyy'),
        expiryMessage: `${expiryMessage} Please contact the Google Workspace Administrator for your domain for more information.`,
        lastTierExpiration: {type: licenseState.lastTierExpiration.type, date: licenseState.lastTierExpiration.date},
        showIcon: showExpiredWarningIcon,
        plan: 'lapsed'
      };
    }
  }


  recheckLicenseState() {
    this.licenseService.triggerForcedRecheck();
  }
}
