import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService, User } from '../../../modules/auth/services/Auth/auth.service';
import { LscEvents, StepAnalyticsEvent } from '../../services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../services/FirebaseAnalytics/firebase-analytics.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  action: string;
  user: User;
  busy: boolean;

  constructor(
    private router: Router,
    public _authSvc: AuthService,
    private _firebaseAnalytics: FirebaseAnalyticsService,

  ) {
    this.busy = true;

    // The welcome component should check to see if the user is in the user directory table.
    // If so, the user can be logged in.
    // If not, we need to ascertain whether the customer exists in the customer table
    // If not, we need to see if the user is a super admin
    this._authSvc.getNextAction().pipe(takeUntil(this.onDestroy$)).subscribe(async (data) => {
      this.action = data;
      if(this.action === 'route') {
        await this.router.navigate(['/home'], { queryParams: { testLogin: null, role: null } });
      }
      this.busy = false;
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  async adminLogin(): Promise<void> {
    this.busy = true;
    await this._authSvc.checkAdminStatus();
  }


  exit() {
    this._firebaseAnalytics.sendEvent(((LscEvents.lscEvents.home.gettingStartedWizard as StepAnalyticsEvent).cancel as AnalyticsEvent) );
    this._authSvc.disconnect();
  }

}
