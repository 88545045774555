import {
  IToolPanel,
  IToolPanelParams,
} from '@ag-grid-community/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';


import { ConfirmDialogComponent } from '../../../../../../core/components/confirm-dialog/confirm-dialog.component';
import { LscEvents } from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  AuthService,
  User,
} from '../../../../../auth/services/Auth/auth.service';
import { GridStateService } from '../../../../services/grid-state.service';
import { SaveGridSettingsDialogComponent } from '../../../dialogs/save-grid-settings/save-grid-settings';

enum FilterTypes {
  'all' = 'All',
  'core' = 'Core',
  'my' = 'My',
  'shared' = 'Shared',
}

@Component({
  selector: 'saved-views-tool-panel',
  templateUrl: './saved-views-tool-panel.component.html',
  styleUrls: ['./saved-views-tool-panel.component.scss'],
})
export class SavedViewsToolPanelComponent implements IToolPanel {

  @Input() panelType = 'layout';
  savedStates = [];
  activeState = null;
  originalState;
  busy: boolean;
  filter: FilterTypes = FilterTypes.all;
  currentUser: User;
  isSuperAdmin: boolean;
  debug: boolean;

  predefinedStates = [

    {
      key: 'high_level_stats',
      label: 'High level stats',
      settings: [
        {colId:'0',hide:false,aggFunc:null,width:40,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, lockVisible: true, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'id',hide:true,aggFunc:null,width:110,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, lockVisible: true, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'isTeacher',hide:false,aggFunc:null,width:60,pivotIndex:null,pinned:'left',rowGroupIndex:null},

        {colId:'gAliasesCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'gAliases.alias',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},

        {colId:'tf.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'sch.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'schoolCount',hide:true,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'Owner.primaryEmail',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},

        {colId:'name',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},
        {colId:'section',hide:false,aggFunc:null,width:136,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'courseState',hide:true,aggFunc:null,width:123,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'guardiansEnabled',hide:false,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'ownerId',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.name',hide:true,aggFunc:null,width:280,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherCount',hide:false,aggFunc:null,width:65,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'coteachers.primaryEmail',hide:true,aggFunc:null,width:228,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherOUs',hide:true,aggFunc:null,width:240,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'studentCount',hide:false,aggFunc:null,width:65,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'studentOUs',hide:true,aggFunc:null,width:352,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'courseworkCount',hide:false,aggFunc:null,width:62,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'maxCDT',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'enrollmentCode',hide:true,aggFunc:null,width:100,pivotIndex:null,pinned:null,rowGroupIndex:null},
        // {"colId":"tags","hide":true,"aggFunc":null,"width":100,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
        {colId:'recentActivity',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'creationTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'updateTime',hide:true,aggFunc:null,width:130,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'refreshTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

      ]
    },

    {
      key: 'teachers',
      label: 'Teachers',
      settings: [
        {colId:'0',hide:false,aggFunc:null,width:40,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, lockVisible: true, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'id',hide:true,aggFunc:null,width:110,pivotIndex:null,pinned:'left',lockPinned: true, lockPosition: true, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'isTeacher',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:'left',rowGroupIndex:null},

        {colId:'gAliasesCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'gAliases.alias',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},

        {colId:'tf.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'sch.id',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'schoolCount',hide:true,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'Owner.primaryEmail',hide:false,aggFunc:null,width:228,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},

        {colId:'name',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},
        {colId:'section',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:'left',rowGroupIndex:null},
        {colId:'courseState',hide:true,aggFunc:null,width:120,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'guardiansEnabled',hide:true,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'ownerId',hide: true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.suspended',hide:false,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.name',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'coteachers.primaryEmail',hide:false,aggFunc:null,width:180,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherOUs',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'studentCount',hide:true,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'studentOUs',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},


        {colId:'courseworkCount',hide:true,aggFunc:null,width:62,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'maxCDT',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'enrollmentCode',hide:true,aggFunc:null,width:100,pivotIndex:null,pinned:null,rowGroupIndex:null},
        // {"colId":"tags","hide":true,"aggFunc":null,"width":100,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
        {colId:'recentActivity',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'creationTime',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'updateTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'refreshTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

      ]
    },

    {
      key: 'students',
      label: 'Students',
      settings: [
        {colId:'0',hide:false,aggFunc:null,width:40,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, lockVisible: true, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'id',hide:true,aggFunc:null,width:110,pivotIndex:null,pinned:'left',lockPinned: true, lockPosition: true, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'isTeacher',hide:false,aggFunc:null,width:60,pivotIndex:null,pinned:'left',rowGroupIndex:null},

        {colId:'gAliasesCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'gAliases.alias',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},

        {colId:'tf.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'sch.id',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'schoolCount',hide:true,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'Owner.primaryEmail',hide:false,aggFunc:null,width:228,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},

        {colId:'name',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},
        {colId:'section',hide:false,aggFunc:null,width:136,pivotIndex:null,pinned:'left',rowGroupIndex:null},
        {colId:'courseState',hide:true,aggFunc:null,width:123,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'guardiansEnabled',hide:false,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'ownerId',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.name',hide:true,aggFunc:null,width:280,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherCount',hide:true,aggFunc:null,width:65,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'coteachers.primaryEmail',hide:true,aggFunc:null,width:228,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherOUs',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'studentCount',hide:false,aggFunc:null,width:67,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'studentOUs',hide:false,aggFunc:null,width:352,pivotIndex:null,pinned:null,rowGroupIndex:null},


        {colId:'courseworkCount',hide:true,aggFunc:null,width:62,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'maxCDT',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'enrollmentCode',hide:true,aggFunc:null,width:100,pivotIndex:null,pinned:null,rowGroupIndex:null},
        // {"colId":"tags","hide":true,"aggFunc":null,"width":100,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
        {colId:'recentActivity',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'creationTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'updateTime',hide:true,aggFunc:null,width:130,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'refreshTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

      ]
    },

    {
      key: 'coursework',
      label: 'Classwork',
      settings: [
        {colId:'0',hide:false,aggFunc:null,width:40,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, lockVisible: true, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'id',hide:true,aggFunc:null,width:110,pivotIndex:null,pinned:'left',lockPinned: true, lockPosition: true, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'isTeacher',hide:false,aggFunc:null,width:60,pivotIndex:null,pinned:'left',rowGroupIndex:null},

        {colId:'gAliasesCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'gAliases.alias',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},

        {colId:'tf.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'sch.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'schoolCount',hide:true,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'Owner.primaryEmail',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},

        {colId:'name',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},
        {colId:'section',hide:false,aggFunc:null,width:136,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'courseState',hide:true,aggFunc:null,width:123,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'guardiansEnabled',hide:true,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'ownerId',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.name',hide:true,aggFunc:null,width:280,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherCount',hide:true,aggFunc:null,width:65,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'coteachers.primaryEmail',hide:true,aggFunc:null,width:228,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherOUs',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'studentCount',hide:true,aggFunc:null,width:67,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'studentOUs',hide:true,aggFunc:null,width:352,pivotIndex:null,pinned:null,rowGroupIndex:null},


        {colId:'courseworkCount',hide:false,aggFunc:null,width:62,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'maxCDT',hide:false,aggFunc:null,width:188,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'enrollmentCode',hide:true,aggFunc:null,width:100,pivotIndex:null,pinned:null,rowGroupIndex:null},
        // {"colId":"tags","hide":true,"aggFunc":null,"width":100,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
        {colId:'recentActivity',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'creationTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'updateTime',hide:true,aggFunc:null,width:130,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'refreshTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

      ]
    },

    {
      key: 'general',
      label: 'General',
      settings: [
        {colId:'0',hide:false,aggFunc:null,width:40,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, lockVisible: true, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'id',hide:true,aggFunc:null,width:110,pivotIndex:null,pinned:'left',lockPinned: true, lockPosition: true, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'isTeacher',hide:false,aggFunc:null,width:60,pivotIndex:null,pinned:'left',rowGroupIndex:null},

        {colId:'gAliasesCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'gAliases.alias',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},

        {colId:'tf.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'sch.id',hide:true,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'schoolCount',hide:true,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'Owner.primaryEmail',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},

        {colId:'name',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, rowGroupIndex:null},
        {colId:'section',hide:false,aggFunc:null,width:136,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'courseState',hide:false,aggFunc:null,width:123,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'guardiansEnabled',hide:false,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'ownerId',hide:true,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.suspended',hide:false,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.name',hide:false,aggFunc:null,width:280,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherCount',hide:false,aggFunc:null,width:65,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'coteachers.primaryEmail',hide:false,aggFunc:null,width:228,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherOUs',hide:true,aggFunc:null,width:240,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'studentCount',hide:false,aggFunc:null,width:65,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'studentOUs',hide:true,aggFunc:null,width:352,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'courseworkCount',hide:false,aggFunc:null,width:62,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'maxCDT',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'enrollmentCode',hide:true,aggFunc:null,width:100,pivotIndex:null,pinned:null,rowGroupIndex:null},
        // {"colId":"tags","hide":true,"aggFunc":null,"width":100,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
        {colId:'recentActivity',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'creationTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'updateTime',hide:true,aggFunc:null,width:130,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'refreshTime',hide:true,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

      ]
    },

    {
      key: 'allColumns',
      label: 'All Columns',
      settings: [
        {colId:'0',hide:false,aggFunc:null,width:40,pivotIndex:null,pinned:'left', lockPinned: true, lockPosition: true, lockVisible: true, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'id',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: true, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'isTeacher',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:'left',rowGroupIndex:null},

        {colId:'gAliasesCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},
        {colId:'gAliases.alias',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null, lockPinned: false, lockPosition: false, lockVisible: false, suppressSizeToFit: true, rowGroupIndex:null},

        {colId:'tf.id',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'sch.id',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'schoolCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'Owner.primaryEmail',hide:false,aggFunc:null,width:120,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'name',hide:false,aggFunc:null,width:220,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'section',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'courseState',hide:false,aggFunc:null,width:120,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'guardiansEnabled',hide:false,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'ownerId',hide:false,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.suspended',hide:false,aggFunc:null,width:80,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'Owner.name',hide:false,aggFunc:null,width:150,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'coteachers.primaryEmail',hide:false,aggFunc:null,width:180,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'teacherOUs',hide:false,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'studentCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'studentOUs',hide:false,aggFunc:null,width:200,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'courseworkCount',hide:false,aggFunc:null,width:50,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'maxCDT',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},

        {colId:'enrollmentCode',hide:false,aggFunc:null,width:100,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'recentActivity',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'creationTime',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        {colId:'updateTime',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
        // {"colId":"tags","hide":false,"aggFunc":null,"width":100,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
        {colId:'refreshTime',hide:false,aggFunc:null,width:140,pivotIndex:null,pinned:null,rowGroupIndex:null},
      ]
    },



  ];

  private api;
  private columnApi;

  constructor(
    private _gridStateService: GridStateService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _authSvc: AuthService,
    private _firebaseAnalytics: FirebaseAnalyticsService,
  ) {

    this.currentUser = this._authSvc.getCurrentUser();

    this.isSuperAdmin = this._authSvc.isSuperAdmin();

    this.debug = false;

  }

  agInit(params: IToolPanelParams): void {
    this.api = params.api;
    this.columnApi = params.columnApi;

    this.busy = true;

    this.getUserGridSettings();

  }

  refresh() {
    return true;
  }

  loadGridSettings($event: Event, index: number | string): void {

    this.busy = true;
    const target = $event.target as HTMLElement;

    if (typeof index === 'number' && target.childNodes[0] && target.childNodes[0].nodeValue === 'delete') {
        const item = this.savedStates[index];

        const dialogData = {
          message: `Are you sure that you want to delete the saved ${this.panelType} "${item?.label}"?${
            item?.shared ? ' It is currently shared.' : ''
          }`,
          okBtnLabel: 'Confirm',
          cancelBtnLabel: 'Cancel'
        };

        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
          panelClass: 'ait-little-sis-panel',
          data: dialogData,
          width: '360px',
          height: '220px'
        });

        dialogRef.afterClosed().subscribe(async result => {

          this.busy = false;

          if (result) {
            return this.deleteGridFilter(index);
          }

        });

        return;

      }

    let collection;

    if (this.activeState !== null) {
      collection = (typeof this.activeState === 'string') ? 'predefinedStates' : 'savedStates';
    }

    if (typeof index === 'string') {

      const state = _.find(this.predefinedStates, { key: index });

      if (this.activeState === null) {

        this.originalState = _.cloneDeep(state);

      } else if (this.activeState !== index) {

        this[collection][this.activeState] =  _.cloneDeep(this.originalState);
        this.originalState =  _.cloneDeep(state);

      }

      this.activeState = index;
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.switchedToPredefinedLayout as AnalyticsEvent);
      return this.applyGridSettings(this.originalState.settings);

    } else {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.switchedToUserDefinedLayout as AnalyticsEvent);
    }

    if (this.activeState === null) {

      this.originalState = _.cloneDeep(this.savedStates[index]);

    } else if (this.activeState !== index) {

      this[collection][this.activeState] =  _.cloneDeep(this.originalState);
      this.originalState =  _.cloneDeep(this.savedStates[index]);

    }

    this.activeState = index;

    this.applyGridSettings(this.originalState.settings);

  }

  saveState() {

    this.busy = true;

    // Use spread to create object noState which can then be saved to just save the column visibility
    this.savedStates[this.activeState].settings = this.columnApi.getColumnState();

    const toSave = this.savedStates[this.activeState];

    this._gridStateService.saveColumnView(toSave).subscribe( results => {

      this.busy = false;

      if (results) {

        this.savedStates[this.activeState] =  _.cloneDeep(results.filter);
        this.originalState =  _.cloneDeep(this.savedStates[this.activeState]);

        this._snackBar.open(results.message, 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 10000,
        });
      }

    });

  }

  disableSaveState() {
    return this.activeState === null || !this.createdByCurrentUser(this.originalState.user_id) || !this.stateUpdated();
  }

  shareState() {

    this.busy = true;

    const state = this.savedStates[this.activeState];

    state.shared = !state.shared;

    this._gridStateService.saveColumnView(state).subscribe( results => {

      this.busy = false;

      if (results) {

        this.savedStates[this.activeState] =  _.cloneDeep(results.filter);
        this.originalState =  _.cloneDeep(this.savedStates[this.activeState]);

        this._snackBar.open(results.message, 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 10000,
        });
      }

    });


  }

  disableShareState() {
    return this.activeState === null || !this.createdByCurrentUser(this.originalState.user_id);
  }

  isStateAlreadyShared() {
    return this.activeState && this.originalState.shared;
  }

  isStateActive(stateIndex: number) {
    return this.activeState === stateIndex;
  }

  createdByCurrentUser(userId: number) {
    return this.currentUser?.profile?.id === userId;
  }

  async saveGridStateAs() {

    const gridConfig = this.columnApi.getColumnState();

    const confirmRef = this._dialog.open( SaveGridSettingsDialogComponent, {
      panelClass: 'ait-little-sis-panel',
      data: gridConfig,
      height: '320px'
    });

    await confirmRef.afterClosed().subscribe(gridStateChanges => {

      if (gridStateChanges !== 'cancelled') {

        this.busy = true;

        this._gridStateService.saveColumnView(gridStateChanges).subscribe( results => {

          this.busy = false;

          if (results) {
            // @ts-ignore
            this.savedStates.push(results.filter);

            this.savedStates[this.activeState] =  _.cloneDeep(this.originalState);

            this.activeState = this.savedStates.length - 1;
            this.originalState =  _.cloneDeep(this.savedStates[this.activeState]);

            this._snackBar.open(results.message, 'close', {
              verticalPosition: 'top',
              horizontalPosition: 'center',
              duration: 10000,
            });
          }
        });

      }

    });

  }

  deselect() {
    this.activeState = null;

    this._snackBar.open(`Saved ${this.panelType} deselected`, null, {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 3000,
    });

  }

  printState() {

    const colState = this.columnApi.getColumnState();

    console.log('***********************');
    console.log('colState: ', colState);
    console.log('***********************');
  }

  updateFilter(filterState?: string) {
    if (filterState && FilterTypes[filterState]) {
      this.filter = FilterTypes[filterState];
    } else {
      this.filter = FilterTypes.all;
    }
  }

  getFilteredSavedStates() {
    if (this.filter === 'Core') {
      return [];
    }
    if (this.filter === 'My') {
      return this.savedStates.filter(state => this.createdByCurrentUser(state.user_id));
    }
    if (this.filter === 'Shared') {
      return this.savedStates.filter(state => state.shared);
    }
    return this.savedStates;
  }

  private stateUpdated() {
    return !_.isEqual(this.savedStates[this.activeState].settings, this.columnApi.getColumnState());
  }

  private getUserGridSettings() {

    this._gridStateService.getUserSavedViews().subscribe(results => {
      this.busy = false;
      this.savedStates = results;
    });

  }

  private applyGridSettings(columns): void {

    const toApply = _.cloneDeep(columns);

    let leftVisible = 0;

    // TODO Check for filters and ensure those columns remain visible

    const filterState = this.api.getFilterModel();

    if (_.keys(filterState).length) {

      _.each(filterState, (filter, column) => {

        if (column !== 'creationTime') {

          const c = _.find(toApply, (col) => (col.colId === column));

          if (c && c.hide) {
            c.hide = false;
            leftVisible++;
          }

        }

      });

    }

    this.columnApi.applyColumnState({ state: toApply, applyOrder: true });

    if (leftVisible) {

      this._snackBar.open('Some columns were left visible due to existing filters...', 'Close', {
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 7000,
      });

    }

    setTimeout(() => {
      this.busy = false;
    }, 200);

  }

  private async deleteGridFilter(index: number) {

    const gridFilterId = this.savedStates[index].id;

    this.busy = true;

    await this._gridStateService.deleteColumnView(gridFilterId).subscribe( results => {

      this.busy = false;

      if (results) {

        if (this.activeState > index) {
          this.activeState--;
        }

        this.savedStates.splice(index, 1);

        this._snackBar.open(results.message, 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 10000,
        });
      }
    });

  }

}
