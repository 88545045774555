import {Component, ElementRef, ViewChild} from '@angular/core';
import {LittlesisLicensingService} from '../../services/littlesis-licensing.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-help-popover-button',
  templateUrl: './help-popover.component.html',
  styleUrls: ['./help-popover.component.scss']
})
export class HelpPopoverComponent {

  @ViewChild('triggerButton') public triggerButton: ElementRef;

  dialogRef: MatDialogRef<any>;

  constructor(
    private licensingSvc: LittlesisLicensingService
  ) { }

  show() {
    this.dialogRef = this.licensingSvc.openHelpDialog({
      positionRelativeToElement: this.triggerButton,
      hasBackdrop: false,
      height: '220px',
      width: '280px'
    });
  }

  hide() {
    this.dialogRef.close();
  }

}
