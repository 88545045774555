import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.scss']
})
export class ProfileSummaryComponent implements OnInit {
  @Input() thumbnailPhotoUrl;
  @Input() name;
  @Input() email;
  @Input() suspended;
  @Input() profileType;

  constructor(

  ) {}

  ngOnInit() {}

  getEmailTooltip() {
    if (this.suspended && this.profileType === 'teacher') {
      return 'Teacher account suspended';
    } else if (this.suspended) {
      return 'Account suspended';
    }

    return undefined;
  }
}
