import { Injectable } from '@angular/core';
import { GridDataService } from '@cdw-ae/little-sis-for-classroom-ag-grid';

@Injectable({
  providedIn: 'root'
})

export class ExportClassroomService {

  constructor() {}

  getExportParams(selected) {
    const params = {
      columnKeys: [
        'id',
        'tf.id',
        'sch.id',
        'name',
        'section',
        'courseState',
        'guardiansEnabled',
        'Owner.primaryEmail',
        'teacherCount',
        'coteachers.primaryEmail',
        'teacherOUs',
        'studentCount',
        'studentOUs',
        'courseworkCount',
        'maxCDT',
        'enrollmentCode',
        'creationTime',
        'isTeacher',
      ],
      fileName: 'Little SIS Google Classroom export',
      processCellCallback: this.formatExportByColumn,
      processHeaderCallback: this.formatExportColumnTitle,
      onlySelected: !!selected
    };

    return params;
  }

  formatExportByColumn(params) {

    switch(params.column.colId) {

      case 'tf.id':
        if (params.node.data.tf.length) {
          return params.node.data.tf.map((tf) => tf.label).join(' | ');
        } else {
          return '';
        }

      case 'sch.id':
        if (params.node.data.sch.length) {
          return params.node.data.sch.map((sch) => sch.name).join(' | ');
        } else {
          return '';
        }

      case 'coteachers.primaryEmail':
        if (params.node.data.coteachers.length) {
          return params.node.data.coteachers.map((user) => `${user.name} (${user.primaryEmail})`).join(' | ');
        } else {
          return '';
        }

      case 'studentOUs':
      case 'teacherOUs':
        if (params.value) {
          return Object.keys(params.value).map((ou) => ou + ` (${params.value[ou]})`).join(' | ');
        } else {
          return '';
        }

      case 'guardiansEnabled':
        return (params.value) ? 'Y' : 'N';

      case 'Owner.primaryEmail':
        return `${params.node.data.Owner.name} (${params.node.data.Owner.primaryEmail})`;

      case 'isTeacher':
        return GridDataService.validateAlternateLink(params.node.data.alternateLink);

      case 'section':
      case 'name':
      default:
        return params.value;
    }

  }

  formatExportColumnTitle(params) {

    switch(params.column.colId) {
      case 'tf.id':
        return 'Timeframe';

      case 'sch.id':
        return 'Schools';

      case 'studentOUs':
        return 'Student OUs';

      case 'teacherOUs':
        return 'Teacher OUs';

      case 'guardiansEnabled':
        return 'Guardians Enabled?';

      case 'section':
        return 'Section';

      case 'name':
        return 'Course Name';

      case 'studentCount':
        return 'Student count';

      case 'Owner.primaryEmail':
        return 'Primary Teacher';

      case 'coteachers.primaryEmail':
        return 'All teachers';

      case 'teacherCount':
        return 'Teacher count';

      case 'courseworkCount':
        return 'Classwork count';

      case 'maxCDT':
        return 'Most recent classwork item';

      case 'isTeacher':
        return 'Link to classroom';

      case 'id':
        return 'Google Classroom Id';

      default:
        return params.column.colDef.headerName;
    }

  }

}
