
import * as observable from 'rxjs';

import {map,  shareReplay } from 'rxjs/operators';
import {Injectable} from '@angular/core';

import { HttpResponse} from '@angular/common/http';

import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import * as _ from 'lodash';

import {ApiResponse} from './util.service';
import {ListResponse} from '../core/models/api.interface';


export interface AccessGroupRule {

  /**
   * Unique id for the access group rule
   */
  id?: number;

  /**
   * Id for the parent / related Access Group
   */
  access_group_id?: number;

  /**
   * Type of the rule (school / academic year / any)
   */
  type: string;

  /**
   * Text description for the access group
   */
  operator: string;

  /**
   * Related tags
   */
  tags: number[];
}

export interface AccessGroup {

  /**
   * Unique id for the access group
   */
  id?: number;

  /**
   * Name for the access group
   */
  name?: string;

  /**
   * Text description for the access group
   */
  description?: string;

  /**
   * Customer Id for the Customer associated with the Access Group
   */
  customerId: number;

  /**
   * Ids of schools associated with the access group
   */
  schools: number[];

  /**
   * Ids of timeframes associated with the access group
   */
  timeframes: number[];

  /**
   * Rules
   */
  rules: AccessGroupRule[];
}


export interface Collection {

    /**
     * Unique id for the tag collection within LSC
     */
    id?: number;

    /**
     * Text representation of the collection
     */
    label?: string;

    /**
     * JSON stringified rule details
     */
    description?: string;

    /**
     * Customer Id for the Customer associated with the Collection
     */
    customerId: number;

    /**
     * JSON stringified rule details
     */
    tags: number[];
}

export interface Tag {

  /**
   * Unique id for the tag within LSC
   */
  id?: number;

  /**
   * Text respresentation of the tag
   */
  label: string;

  /**
   * Hex color to be used when rendering the tag
   */
  hex: string;

  /**
   * JSON stringified rule details
   */
  rule?: string;

  /**
   * Customer Id for the Customer associated with the Tag
   */
  customerId?: number;
}

export interface DimensionOpt {
  type: string;
  label: string;
}

@Injectable()
export class TagService {

  private callCount = 1;

  tags: Tag[];
  collections: Collection[];
  accessGroups: AccessGroup[];

  observable$: {
    tags: {
      [id: string]: observable.Observable<Tag>;
    };
    collections: {
      [id: string]: observable.Observable<Collection>;
    };
    access_groups: {
      [id: string]: observable.Observable<AccessGroup>;
    };
  };

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;

  compactFilter(filterObj) {
    const rtn = {
      c: filterObj.field,
      o: 'c',
      v: filterObj.value
    };

    switch (filterObj.operator) {
      case 'gte':
        rtn.o = 'date';
        break;
      case 'lte':
        rtn.o = 'date';
        break;
      case 'contains':
        rtn.o = 'c';
        break;
      case 'equals':
        rtn.o = 'e';
        break;
    }
    return rtn;

  }

  constructor(private http: HttpClient) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.observable$ = {
      tags: {},
      collections: {},
      access_groups: {}
    };

    this.tags = [{
      id: -100,
      hex: '#eeeeee',
      label: '!!faked!!'
    }];

    this.collections = [];

    this.getTags();
  }

  getTags(options = {}, opts = {useCache: true}): observable.Observable<ListResponse> {

    let url = this.serviceUrls.getTags;

    const _this = this;

    if (options) {

      url += '?';

      for (const param of Object.keys(options)) {

        if (param === 'filter') {

          _.each(options[param].filters, function(f) {
            url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
          });


        } else if (param === 'sort') {

          if (options[param].length) {

            _.each(options[param], function(srt) {

              if (srt.dir) {
                url += `sortBy[]=${srt.field}&`;
                url += `sortDir[]=${srt.dir}&`;
              }

            });

          }

        } else {
          url += `${param}=${options[param]}&`;
        }
      }

      if (opts.useCache) {
        url += `useCache=true`;
      }

    }

    return this.http.get<ListResponse>(url, this.httpOptions).pipe(
      map(response => {
        this.tags = [... response.data];
        return response; // this.tags;
      }));
  }

  getTag(tagId: string): observable.Observable<Tag> {
    const tag = this.tags.find(t => t.id === parseInt(tagId, 10));

    if (tag) {


      // ensure that original observable is deleted
      if (this.observable$.tags[tagId]) {
        delete this.observable$.tags[tagId];
      }

      return observable.of(tag);

    } else {

      // Is there a request in flight?

      if (!this.observable$.tags[tagId]) {
        const url = this.serviceUrls.tags.getTag.replace(':id', tagId);

        this.observable$.tags[tagId] = this.http.get<Tag>(url, this.httpOptions).pipe(
          map(response => {
            this.tags.push(response);
            return response;

          })).pipe(shareReplay(1));

      }

      return this.observable$.tags[tagId];
    }
  }

  createTag(newTag: Tag): observable.Observable<Tag> {
    const url = this.serviceUrls.createTag;
    return this.http.post<Tag>(url, {tag: newTag}, this.httpOptions);
  }

  updateTag(tag: Tag): observable.Observable<Tag> {
    const url = this.serviceUrls.updateTag.replace(':id', tag.id.toString());
    const body = { tag };
    return this.http.put<Tag>(url, body, this.httpOptions);
  }

  deleteTag(tagId: string): observable.Observable<void> {
    const url = this.serviceUrls.deleteTag.replace(':id', tagId);
    return this.http.delete<void>(url, this.httpOptions);
  }

  addUserTags(userId: number, tags: Tag[]): observable.Observable<void> {
    const url = this.serviceUrls.addUserTags.replace(':id', userId.toString());
    const body = {tagIds: tags.map(tag => tag.id)};
    return this.http.post<void>(url, body, this.httpOptions);
  }


  // Access Groups

  getAccessGroups(): observable.Observable<AccessGroup[]> {
    const url = this.serviceUrls.tags.access_groups.route;
    return this.http.get<AccessGroup[]>(url, this.httpOptions);
  }

  createAccessGroup(opts: {accessGroup: AccessGroup; users: string[]}): observable.Observable<AccessGroup> {
    const url = this.serviceUrls.tags.access_groups.route;
    const body = { access_group: opts.accessGroup, users: opts.users};
    return this.http.post<AccessGroup>(url, body, this.httpOptions);
  }

  updateAccessGroup(opts: {accessGroup: AccessGroup; users: string[]}): observable.Observable<AccessGroup> {

    const url = this.serviceUrls.tags.access_groups.single.replace(':id', opts.accessGroup.id.toString());

    const body = { access_group: opts.accessGroup, users: opts.users};
    return this.http.put<AccessGroup>(url, body, this.httpOptions);

  }

  deleteAccessGroup(id: string): observable.Observable<void> {
    const url = this.serviceUrls.tags.access_groups.single.replace(':id', id);
    return this.http.delete<void>(url, this.httpOptions);
  }

  accessGroupUsers(id: number, options): observable.Observable<ListResponse> {

    if (!id) {
      return observable.of({ data: [], count: 0});
    }

    let url = (this.serviceUrls.tags.access_groups.users.replace(':id', id.toString())) + '?';
    const _this = this;

    for (const param of Object.keys(options)) {

      if (param === 'filter') {

        _.each(options[param].filters, function(f) {
          url += `${param}[]=${JSON.stringify(_this.compactFilter(f))}&`;
        });


      } else if (param === 'sort') {

        if (options[param].length) {

          _.each(options[param], function(srt) {

            if (srt.dir) {
              url += `sortBy[]=${srt.field}&`;
              url += `sortDir[]=${srt.dir}&`;
            }

          });

        }

      } else {
        url += `${param}=${options[param]}&`;
      }
    }

    return this.http.get<ListResponse>(url, this.httpOptions);

  }


  deleteUsersFromAccessGroup(opts: {accessGroup: AccessGroup; users: number[]}): observable.Observable<void> {
    const url = this.serviceUrls.tags.access_groups.users.replace(':id', opts.accessGroup.id.toString());

    const requestOptions = this.httpOptions;
    // @ts-ignore
    requestOptions.body = { access_group: opts.accessGroup, users: opts.users};

    return this.http.request<void>('delete', url, this.httpOptions);
  }


  // Collections

  getCollections(): observable.Observable<Collection[]> {

    const url = this.serviceUrls.tags.getCollections;

    return this.http.get<Collection[]>(url, this.httpOptions).pipe(
      map(response => {
        this.collections = response;
        return this.collections;
      }));

  }

  getCollection(opts): observable.Observable<Collection> {

    const url = this.serviceUrls.tags.getCollection.replace(':id', opts.id.toString());

    return this.http.get<Collection>(url, this.httpOptions).pipe(
      map(response => response));

  }

  createCollection(newCollection: Collection): observable.Observable<Collection> {
      const url = this.serviceUrls.tags.createCollection;
      return this.http.post<Collection>(url, {collection: newCollection}, this.httpOptions);
  }

  updateCollection(collection: Collection): observable.Observable<Collection> {
      const url = this.serviceUrls.tags.updateCollection.replace(':id', collection.id.toString());
      const body = { collection };
      return this.http.put<Collection>(url, body, this.httpOptions);
  }

  deleteCollection(collectionId: string): observable.Observable<void> {
      const url = this.serviceUrls.tags.deleteCollection.replace(':id', collectionId);
      return this.http.delete<void>(url, this.httpOptions);
  }


  applyTags(opts): observable.Observable<ApiResponse> {
    const url = this.serviceUrls.tags.applyTags;
    return this.http.post(url, opts, this.httpOptions);
  }

}
