import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

import {BehaviorSubject, combineLatest, from, Observable, of} from 'rxjs';
import { Subject } from 'rxjs';
import {startWith, tap} from 'rxjs/operators';
import {isEqual} from 'lodash';

@Injectable()
export class StudentExplorerService {

  private httpOptions: { headers: HttpHeaders };
  private serviceUrls = environment.urls.userServices;
  private selectedUserId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private selectedCourses: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private coursework: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }

  fetchCourseworkData(userId: string, courseIds: string[]) {
    const courseworkObservers = courseIds.map(courseId => this.getStudentSubmissions(userId, courseId).pipe(startWith(null)));

    combineLatest(courseworkObservers).subscribe(resp => {
      const courseworkObjs = {};
      resp.forEach(course => Object.assign(courseworkObjs, course));
      this.coursework.next(courseworkObjs);
    });
  }

  getCourseworkData(){
    return this.coursework.asObservable();
  }

  clearCourseworkData(){
    this.coursework.next({});
  }

  getStudentCourses(userId: string, courseFilter: string[], timelineFilter: string, resetCache: boolean): Observable<any>{
    let url = this.serviceUrls.insights.student.courses.replace(':userId', userId);
    if(courseFilter || timelineFilter || resetCache) {
      url += `?${(courseFilter) ? courseFilter.map(filter => 'state=' + filter).join('&') : ''}${(timelineFilter) ? '&timeframe=' + timelineFilter : ''}${(resetCache)?'&resetCache=' + resetCache : '' } `;
    }
    return this.http.get(url, this.httpOptions);
  }

  getStudentSubmissions(userId: string, courseId: string): Observable<any>{
    const url = this.serviceUrls.insights.student.submissions
      .replace(':userId', userId)
      .replace(':courseId', courseId);
    return this.http.get(url, this.httpOptions);
  }


  addSelectedCourse(courses: any[]) {
    const uniqueNewCourses = [];
    this.selectedCourses.next([...this.selectedCourses.getValue(), ...courses.filter(course =>
      (this.selectedCourses.getValue().filter(selectedCourse => selectedCourse.courseId === course.courseId).length === 0)
    )]);
  }
  removeSelectedCourse(courses: any[]) {
    this.selectedCourses.next(this.selectedCourses.getValue().filter(val => !courses.includes(val)));
  }

  clearAllSelectedCourses(){
    this.selectedCourses.next([]);
  }

  getSelectedCourseValues(){
    return this.selectedCourses.getValue();
  }

  getSelectedCourses(){
    return this.selectedCourses.asObservable();
  }

  setSelectedUser(userId: string){
    this.selectedUserId.next(userId);
  }

  getSelectedUser() {
    return this.selectedUserId.asObservable();
  }

}
