import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';

import {Observable} from 'rxjs';
import {
  map,
  tap,
} from 'rxjs/operators';

export interface FirestoreResponse {
  notifications: FirestoreNotification[];
}

interface FirestoreNotification {
  active: boolean;
  message: string;
}

export interface Notification extends FirestoreNotification {
  dismissed: boolean;
}


@Injectable()
export class NotificationService {

  notifications$: Observable<Notification[]>;
  notifications: Notification[] = [];

  constructor(
    private firestore: AngularFirestore
  ) {
    this.notifications$ = this.firestore.doc<FirestoreResponse>('app/notifications')
    .valueChanges()
    .pipe(

      map((response: FirestoreResponse) => {
        const rawNotifications = response ? response.notifications : [];
        const notifications = this.updateNotificationState(this.notifications, rawNotifications);
        return notifications;
      }),

      tap((notifications: Notification[]) => {
        this.notifications = notifications;
      }),
    );
  }

  updateNotificationState(cachedNotifications: Notification[], firestoreNotifications: FirestoreNotification[]): Notification[] {
    const returnNotifications: Notification[] = [];

    firestoreNotifications.forEach((firestoreNotification: FirestoreNotification) => {
      if (!firestoreNotification.active) {
        return;
      }
      const notification: Notification = cachedNotifications.find((notification: Notification) => firestoreNotification.message === notification.message);
      if (notification) {
        returnNotifications.push(notification);
      } else {
        returnNotifications.push({
          ...firestoreNotification,
          dismissed: false
        });
      }
    });

    return returnNotifications;
  }

  dismiss(index: number): boolean {
    if (index >= this.notifications.length) {
      return false;
    }
    return this.notifications[index].dismissed = true;
  }

  clearNotifications(): void {
    this.notifications = [];
  }
}
