import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable } from 'rxjs';

import { LscEvents } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { Course } from '../../../../../services/classroom.service';
import { School, SchoolService } from '../../../../../services/schools.service';
import { Tag, TagService } from '../../../../../services/tags.service';
import { Timeframe, TimeframeService } from '../../../../../services/timeframes.service';


export interface UpdateTagsDialogData {
  selected: Course[];
  schools: School[];
  timeframes: Timeframe[];
  tags: number[];
  changes: {
    schools: boolean;
    timeframes: boolean;
    tags: boolean;
  };
}

@Component({
  selector: 'app-update-tags-dialog',
  templateUrl: './update-tags.component.html',
  styleUrls: ['./update-tags.component.scss']
})

export class UpdateTagsDialogComponent implements OnInit {

  disabled: boolean;
  all: boolean;

  changes: {
    schools: boolean;
    timeframes: boolean;
    tags: boolean;
  };

  tags: Tag[];

  schools$: Observable<School[]>;
  schoolsLoading = false;

  schools: School[];

  timeframes$: Observable<Timeframe[]>;
  timeframesLoading = false;

  constructor(public dialogRef: MatDialogRef<UpdateTagsDialogComponent>,
              public tagSvc: TagService,
              private schoolSvc: SchoolService,
              private _firebaseAnalytics: FirebaseAnalyticsService,
              private timeframeSvc: TimeframeService,
              @Inject(MAT_DIALOG_DATA) public data: UpdateTagsDialogData,
              @Inject(SESSION_STORAGE) private storage: StorageService) {

    this.disabled = false;

    this.changes = {
      schools: false,
      timeframes: false,
      tags: false
    };
  }

  ngOnInit() {

    const analyticsEvent = {
      action: 'updateTags',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);

    this.tagSvc.getTags()
      .subscribe(tags => {
          this.tags = tags.data;
        }
      );

    // this.schoolSvc.getAll().subscribe((schools) => {
    //   this.schools = _.values(schools).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    // });

    this.schools$ = this.schoolSvc.list();

    this.timeframes$ = this.timeframeSvc.list();

    // this.timeframeSvc.getAll().subscribe((schools) => {
    //   this.schools = _.values(schools).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    // });

  }

  toggle(prop) {
    this.changes[prop] = !this.changes[prop];
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    const analyticsEvent = {
      action: 'updateTags',
      properties: {
        category: 'admin',
        label: 'end'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);
    this.sendAnalyticsEventByTags();

    this.data.changes = this.changes;
    this.dialogRef.close(this.data);
  }

  private sendAnalyticsEventByTags(): void {
    Object.entries(this.changes).forEach((entry)=>{
      if(entry[1]===true){
        switch(entry[0]){
          case 'schools':
            this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.updateSchoolTags as AnalyticsEvent);
            break;
          case 'timeframes':
            this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.updateTimeframeTags as AnalyticsEvent);
            break;
          case 'tags':
            this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer.updateOtherTags as AnalyticsEvent);
            break;
          default:
            break;
        }
      }
    });
  }

}
