import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessFiltersService {

  private activeAccessFilter = new BehaviorSubject('');
  activeFilter = null;

  constructor() { }

  setActiveAccessFilter(filter) {
    this.activeFilter = filter;
    this.activeAccessFilter.next(this.activeFilter);
  }

  getActiveAccessFilter(): Observable<any> {
    return this.activeAccessFilter.asObservable();
  }

  clearAccessFilter(): void {
    this.setActiveAccessFilter(null);
    this.activeFilter = null;
  }

}
