import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { ClassActions, ClassroomService, Course } from '../../../../../services/classroom.service';
import { User, UserService } from '../../../../../services/user.service';
import { AuthService } from '../../../../auth/services/Auth/auth.service';
import { ApiLookupService } from '../../../../auth/services/api-lookup/api-lookup.service';

import { LscEvents, StepAnalyticsEventEnum } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { FirebaseAnalyticsService } from '../../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import { FirestoreCustomerService } from '../../../../../services/firestore-customer.service';
import { GenericBulkActionComponent } from '../generic-bulk-action.component';

export const REMOVE_STUDENTS_STEPPER_STEPS = 4;
export const REMOVE_STUDENTS = 'explorerRemoveStudents';
@Component({
  selector: 'app-bulk-removeStudent-dialog',
  templateUrl: './bulk-removeStudent-dialog.component.html',
  styleUrls: ['./bulk-removeStudent-dialog.component.scss']
})


export class BulkRemoveStudentDialogComponent extends GenericBulkActionComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  columnDefsSummary;
  gridOptionsSummary;

  customerActionTemplate: string | object;
  userActionTemplate: string | object;
  removeStudentTemplate: string;

  message;
  students = [];


  selectedItems: string[] = [];
  selectedClasses: Course[] = [];

  invalidClasses = [];
  validClasses = [];

  unaffectedClasses = [];
  summaryOfChanges = [];
  student$: Observable<User[]>;
  studentsLoading = false;
  studentsInput$ = new Subject<string>();
  allClassRosters = {classes : []};
  finalarray = [];

  grids: {
    classes: {
      height: number;
    };
    summary: {
      height: number;
    };
  };

  allOrphaned = false;

  constructor( public dialogRef: MatDialogRef<BulkRemoveStudentDialogComponent>,
               protected _authSvc: AuthService,
               protected _firestoreCustomerService: FirestoreCustomerService,
               public apiLookup: ApiLookupService,
               public userSvc:  UserService,
               public classroomSvc: ClassroomService,
               private _firebaseAnalytics: FirebaseAnalyticsService,
               @Inject(MAT_DIALOG_DATA) public data: ClassActions ) {

    super(apiLookup, data, _authSvc, _firestoreCustomerService);

    const additionalCols = [{
      headerName: 'Student count',
      field: 'studentCount',
      width: 80,
      filter: false,
      cellClass: 'text-center',
      hide: false,
    }];

    this.columnDefs = [...this.columnDefs, ...additionalCols];

    this.gridOptions = {...this.gridOptions, ...{
        isRowSelectable: (rowNode) => rowNode.data.studentOUs,
        rowClassRules: {
          'declined-50': 'data && !data.studentCount',
        },
        // defaultColDef: {
        //   tooltip: (params) => {
        //     if (params.alreadyInClass) {
        //       return 'You are already in this class';
        //     }
        //   }
        // }
      }
    };

    // Summary Grid

    this.columnDefsSummary = [
      {
        headerName: 'Student',
        field: 'student',
        width: 80,
        filter: false,
        hide: false,
        cellRenderer: 'profileCellRenderer',
        suppressMenu: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 60,
        filter: false,
        cellClass: 'text-center',
        hide: false,
        suppressMenu: true
      },
      {
        headerName: 'Course',
        field: 'name',
        width: 120,
        filter: 'agTextColumnFilter',
        cellRenderer: 'classroomCellRenderer',
        autoHeight: true,
        suppressMenu: true
      }
    ];


    this.gridOptionsSummary = {
      rowModelType: 'clientSide',
      rowSelection: false,
      cacheBlockSize: 100,
      maxBlocksInCache: 2,
      rowData: this.summaryOfChanges,

      animateRows: true,

      clipboardDelimiter: ',',

      pagination: false,

      defaultColDef: {
        filter: false,
        filterParams: {
          debounceMs: 500,
          newRowsAction: 'keep'
        },
        floatingFilter: false,
        hide: false,
        resizable: true,
        sortable: true
      }

    };

    this.tokens = [
      { id: '1', toolTip: 'Insert teacher name', icon: 'account_box', insertString: '{{teacherName}}' },
      { id: '2', toolTip: 'Insert class list', icon: 'assignment', insertString: '{{classList}}'},
      { id: '3', toolTip: 'Insert teacher to be removed', icon: 'delete', insertString: '{{removedStudent}}'}
    ];
  }

  ngOnInit() {

    const analyticsEvent = {
      action: 'removeStudents',
      properties: {
        category: 'admin',
        label: 'start'
      }
    };

    this.grids = {
      classes: {
        height: Math.min(32 + 32 + this.data.selectedClasses.length * 60, 300)
      },
      summary:{
        height : Math.min(32 + 32 + this.data.selectedClasses.length * this.students.length * 60, 300)
      }
    };

    this.sendNotification = true;
    this.gridOptions.rowData = this.data.selectedClasses;

    // @ts-ignore
    this.validClasses = this.data.selectedClasses.reduce((accum, curr) => {

      if (curr.studentCount > 0) {
        accum.push(curr);
      } else {
        this.invalidClasses.push(curr);
      }
      return accum;
    }, []);

    // @ts-ignore
    this.data.selectedClasses.map(cls => {
      if (cls.studentCount > 0) {
        cls.hasStudents = true;
      } else {
        cls.hasStudents = false;
      }
    });

    this.initialize('remove_student').subscribe(() => {});
  }

  removeStudents() {
    const removeStudents = this.students.reduce( (accum, curr) => {
      accum.push(curr.primaryEmail);
      return accum;

    }, []);

    const clsDetails = this.validClasses.map(selection => ({
        clsId: selection.id,
        toRemove: removeStudents
      }));

    const updatedData = {
      classrooms: clsDetails,
      actionType: 'remove_student',
      sendNotification: this.sendNotification,
      customerActionTemplate: {
        primaryTeacher:  this.customerActionTemplate
      },
      userActionTemplate: {
        primaryTeacher: this.removeStudentTemplate
      },
      replyEmail: this.notificationEmail.value
    };

    const analyticsEvent = {
      action: 'removeStudents',
      properties: {
        category: 'admin',
        label: 'end'
      }
    };
    this._firebaseAnalytics.sendEvent(analyticsEvent);
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[REMOVE_STUDENTS][REMOVE_STUDENTS_STEPPER_STEPS-1][StepAnalyticsEventEnum.completed]);

    this.sendNotification = true;
    this.dialogRef.close(updatedData);
  }


  // eslint-disable-next-line @typescript-eslint/member-ordering
  goForward(stepper: MatStepper) {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[REMOVE_STUDENTS][stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
    if (stepper.selectedIndex === 0) {
      this.getRosters();
    }

    this.processChanges();
    stepper.next();
  }

  getRosters() {
    const clsDetails = this.validClasses.map(selection => selection.id);
    this.classroomSvc.getRostersOfClassrooms(clsDetails).subscribe(  (results) => {
      this.allClassRosters = results.data;
    });
  }

  onChange(e: any) {
    this.processChanges();
  }

  processChanges() {

    this.loadStudents();

    this.summaryOfChanges.length = 0;
    const classMap = this.selectedClasses.reduce((map, cls) => { map[cls.id] = cls; return map; }, {});

    this.summaryOfChanges = this.students.reduce( (accum, removedStudent) => {

      this.allClassRosters.classes.forEach( currCls => {
        const hasStudent = Boolean(currCls.roster.find(row => row.primaryEmail === removedStudent.primaryEmail));
        if (hasStudent) {
          const relevantData = {
            student: removedStudent,
            action: 'Remove student from',
            class: classMap[currCls.classId]
          };
          accum.push(relevantData);
        }
      });

      return accum;
    }, []);

    this.gridOptionsSummary.api.setRowData(this.summaryOfChanges);

    this.unaffectedClasses = this.allClassRosters.classes.reduce( (accum, currCls) => {
      let affected = false;

      this.students.forEach(removedStudent => {

        const hasStudent = Boolean(currCls.roster.find(row => row.primaryEmail === removedStudent.primaryEmail));

        if (hasStudent) {
          affected = true;
        }
      });

      if (!affected) {
        accum.push(currCls);
      }

      return accum;
    }, []);

    this.processReplacements();

    // Recalculate height of summary grid
    this.grids.summary.height = Math.min(80 + (this.summaryOfChanges.length) * 60, 300);

  }

  processReplacements() {

    if (!this.summaryOfChanges.length) {
      this.templateReplacements = {};
      return;
    }

    let firstNonOrphanedTeacher;
    this.summaryOfChanges.find((cls) => {
      if (cls.class?.Owner) {
        firstNonOrphanedTeacher = cls.class.Owner;
        return;
      }
    });
    let primaryTeacherClassCount = 0;

    if (firstNonOrphanedTeacher) {
      const priTeacherClassList = this.summaryOfChanges.reduce((accum, curr) => {
        if (curr.class?.Owner) {
          if(curr.action === 'Remove student from' && curr.class.Owner.primaryEmail === firstNonOrphanedTeacher.primaryEmail) {
            primaryTeacherClassCount++;

            accum += `<li><a href="${curr.class.alternateLink}" target="_blank">${curr.class.name}`;

            if (curr.class.section) {
              accum += ' [' + curr.class.section + ']';
            }

            accum += `</a> (<strong>${curr.student.name}</strong> removed)</li>`;
          }
        }
        return accum;
      }, '');

      this.generateReplacements(this.validClasses);

      this.templateReplacements['{{teacherName}}'] = firstNonOrphanedTeacher.name;
      this.templateReplacements['{{classList}}'] = '<ul>' + priTeacherClassList + '</ul>';
      this.templateReplacements['{{classCount}}'] = primaryTeacherClassCount;
      this.templateReplacements['{{classPlural}}'] = primaryTeacherClassCount > 1 ? 'Google Classroom classes' : 'a Google Classroom class';
      this.allOrphaned = false;
    } else {
      this.generateReplacements([]);
      this.templateReplacements = {};
      this.allOrphaned = true;
    }
  }

  cancel(stepper: MatStepper): void {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.explorer[REMOVE_STUDENTS][stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close();
  }

  // Summary Grid

  onGridReadySummary(params) {
    params.api.sizeColumnsToFit();
  }

  private loadStudents() {

    const clsDetails = this.validClasses.map(selection => selection.id);

    this.student$ = concat(
      of([]), // default items
      this.studentsInput$.pipe(
        debounceTime(750),
        distinctUntilChanged(),
        tap(() => this.studentsLoading = true),
        switchMap((term) => this.userSvc.getStudentList({term, classIds : clsDetails })
            .pipe(
              map(results => results.data),
              catchError(() => of([])), // empty list on error
              tap(() => this.studentsLoading = false)
            ))
      )
    );
  }

}

