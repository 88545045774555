import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';


import { AuthService, User } from 'app/modules/auth/services/Auth/auth.service';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { versions } from '../../../../environments/versions';
import { LittlesisLicensingService } from '../../../modules/licensing/services/littlesis-licensing.service';
import { UserEncodingService } from './user-encoding.service';

declare global {
  interface Window {
    gtag: any;
  }
}

interface UserProperties {
  [key: string]: string | boolean;
}

export interface AnalyticsEvent {
  action: string;
  properties: {
    [key: string]: string | number | boolean;
  };
}

// LSC-1938 this is a hack to circumvent
// what appears to be a circular dependency
// this analytics event should be stored in our firebase-analytics-events.constant.ts
// but it prevents app loading when it is stored there
export const loginEvent: AnalyticsEvent = {
  action: 'login',
  properties:{
    category: 'home',
    label: 'login',
  }
};


@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService implements OnDestroy {
  currentScreen: string;
  userId: string;
  private userProperties: UserProperties = {
    app_name: 'Little Sis Classroom',
    app_version: versions.version,
  };

  private onDestroy$: Subject<void> = new Subject<void>();
  constructor(private router: Router,
    private authService: AuthService,
    private userEncodingService: UserEncodingService,
    private _licenseSvc: LittlesisLicensingService,
    ) {
    combineLatest([
        this.authService.getActiveUser().pipe(
            filter((user: User) => !!user?.profile?.gId),
            switchMap((user: User) =>
                this.userEncodingService.encodeUser(user.profile.primaryEmail).pipe(
                    map((encodedEmail) => {
                        user.encodedUser = encodedEmail;
                        return user;
                    })
                )
            )
        ),
        this._licenseSvc.license$.pipe(
            filter(licenseState => licenseState.loaded)
        )
    ])
    .pipe(
        take(1),
        takeUntil(this.onDestroy$)
    )
    .subscribe(([user, licenseState]) => {
        this.userProperties = {
            ...this.userProperties,
            uuid: user.profile.gId,
            customerId: user.gSuiteId,
            domain: user.profile.domain,
            encodedUser: user.encodedUser,
            licensed: licenseState.licensed,
            bundle: licenseState.bundle,
            tier: licenseState.tier,
            hasPremium: licenseState.hasPremium
        };

        window.gtag('set', 'user_id', user.encodedUser);
        window.gtag('set', 'user_properties', this.userProperties);
        this.sendEvent(loginEvent);
    });

    this.router.events
      .pipe(filter((ev: Event) => ev instanceof NavigationEnd))
      .subscribe((ev: Event) => {
        let path: string;
        const routerEvent: NavigationEnd = ev as NavigationEnd;
        if (routerEvent?.url) {
          path =
          routerEvent.url.indexOf('?') < 0
              ? routerEvent.url.slice(1, routerEvent.url.length)
              : routerEvent.url.slice(1, routerEvent.url.indexOf('?'));
        } else {
          path = routerEvent.url;
        }
        this.currentScreen = path;
        window.gtag('event', 'current_view', {view_url: this.currentScreen});
      });
  }

  public sendEvent(event: AnalyticsEvent): void {
    try {
      window.gtag('event', event.action, {
        ...this.userProperties,
        ...event.properties,
        view_url: this.currentScreen,
      });
    } catch (e) {
      console.error('Analytics error', e);
    }
  }


  public ngOnDestroy(): void {
    this.onDestroy$.next(null);
  }

}
