import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { ExportStatusCardModule } from '@cdw-ae/components-export-status-card';

import { FusePipesModule } from '../../../@amplabs/pipes/pipes.module';
import { MaterialModule } from '../../core/services/material/material.module';
import { ExportModule } from '../exports/export.module';
import { BulkTaskProgressItemComponent } from './components/bulk-task-progress-item/bulk-task-progress-item.component';
import { EditScheduledRemovalComponent } from './components/edit-scheduled-removal/edit-scheduled-removal.component';
import { JobBadgeComponent } from './components/job-badge/job-badge.component';
import { JobListItemComponent } from './components/job-list-item/job-list-item.component';
import { JobListComponent } from './components/job-list/job-list.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { ScheduleListItemComponent } from './components/scheduled-list-item/schedule-list-item.component';
import { ScheduledTaskTypePipe } from './pipes/scheduledTaskType.pipe';
import { ScheduleService } from './services/schedule.service';
import { TaskService } from './services/task.service';

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FusePipesModule,
    FlexLayoutModule,
    ExportModule,
    NgxMaterialTimepickerModule,
    ExportStatusCardModule,
  ],
  declarations: [
    ProgressIndicatorComponent,
    JobListComponent,
    JobListItemComponent,
    JobBadgeComponent,
    ScheduleListItemComponent,
    BulkTaskProgressItemComponent,
    EditScheduledRemovalComponent,
    ScheduledTaskTypePipe,
  ],
  exports: [
    ProgressIndicatorComponent,
    JobListComponent,
    JobListItemComponent,
    JobBadgeComponent,
    ScheduleListItemComponent,
    ScheduledTaskTypePipe,
  ],
  // entryComponents: [],
  providers: [
    TaskService,
    ScheduleService,
  ],
})
export class JobModule { }
