import {AppNavigation} from '@amplabs/types';

import {environment} from '../../environments/environment';

const helpCenterLink = environment.support.helpCenterLink;
export const navigation: AppNavigation[] = [

  {
    hidden: false,
    id       : 'user',
    title    : 'Home',
    type     : 'item',
    icon     : 'home',
    url      : '/home',
  },

  {
    hidden: true,
    id: 'gettingStarted',
    title: 'Getting started',
    type: 'item',
    icon: 'done_all',
    url: '/admin/getting-started',
    requiredPermissions: ['super_admin']
  },

  {
    disabled: false,
    hidden: true,
    id: 'explorer',
    title: 'Explorer',
    type: 'item',
    svgIcon: 'google_classroom_icon',
    url: '/explorer'
  },
  {
    id: 'student',
    hidden: true,
    title: 'Student explorer',
    type: 'collapsable',
    icon: 'school',
    children: [
      {
        id: 'studentExplorer',
        hidden: true,
        title: 'Search',
        type: 'item',
        icon: 'search',
        url: '/students',
        requiredPermissions: ['view_student_explorer'],
        feature: 'students',
      },
      {
        id: 'studentCollections',
        hidden: true,
        title: 'Collections',
        type: 'item',
        icon: 'visibility',
        url: '/collections',
        requiredPermissions: ['view_collections'],
        feature: 'students'
      }
    ]
  },
  {
    hidden: true,
    id: 'insights',
    title: 'Insights',
    type: 'collapsable',
    icon: 'insert_chart',
    children: [
      {
        hidden: true,
        id: 'insights-home',
        title: 'Overview',
        type: 'item',
        icon: 'pie_chart',
        url: '/insights/overview',
        requiredPermissions: [
          'view_reports',
          'view_roster_size_analysis'
        ]
      },
      {
        hidden: false,
        id: 'coursework-analysis',
        title: 'Classwork analysis',
        type: 'collapsable',
        icon: 'class',
        requiredPermissions: ['view_reports', 'view_coursework_analysis'],
        children: [
          {
            hidden: true,
            id: 'insights-coursework-analysis-by-teacher',
            title: 'By teacher',
            type: 'item',
            icon: 'people',
            url: '/insights/classwork/analysis/byTeacher',
            requiredPermissions: [
              'view_reports',
              'view_coursework_analysis'
            ]
          },
          {
            hidden: true,
            id: 'insights-coursework-analysis-by-course',
            title: 'By course',
            type: 'item',
            svgIcon: 'google_classroom_icon',
            url: '/insights/classwork/analysis/byCourse',
            requiredPermissions: [
              'view_reports',
              'view_coursework_analysis'
            ]
          },
          {
            hidden: true,
            id: 'insights-coursework-analysis-by-school',
            title: 'By school',
            type: 'item',
            icon: 'school',
            url: '/insights/classwork/analysis/bySchool',
            requiredPermissions: [
              'view_reports',
              'view_coursework_analysis'
            ]
          }
        ]
      },
      {
        hidden: true,
        id: 'insights-roster-analysis',
        title: 'Roster analysis',
        type: 'item',
        icon: 'people',
        url: '/insights/rosterAnalysis',
        requiredPermissions: [
          'view_reports',
          'view_roster_size_analysis'
        ]
      },
      {
        hidden: true,
        id: 'insights-roster-changes',
        title: 'Roster/teacher changes',
        type: 'item',
        icon: 'sync_alt',
        url: '/insights/membership',
        requiredPermissions: [
          'view_reports',
          'view_roster_teacher_changes'
        ]
      },
      {
        hidden: true,
        id: 'insights-teacher-analysis',
        title: 'Teacher analysis',
        type: 'item',
        icon: 'person_outline',
        url: '/insights/teachers/analysis',
        requiredPermissions: [
          'view_reports',
          'view_teacher_changes'
        ]
      }
    ],
  },

  {
    hidden: false,
    id: 'admin',
    title: 'Administration',
    type: 'collapsable',
    icon: 'settings',
    children: [

      {
        hidden: false,
        id: 'customerSettings',
        title: 'General Settings',
        type: 'item',
        icon: 'dashboard',
        url: '/admin/settings',
        requiredPermissions: ['super_admin'],
        showBadge: 'license'
      },

      {
        hidden: false,
        id: 'users',
        title: 'Users',
        type: 'collapsable',
        icon: 'people',
        requiredPermissions: ['user_management'],
        children: [
          {
            id: 'sourceUsers',
            title: 'Accounts',
            type: 'item',
            icon: 'person',
            url: '/admin/users',
            requiredPermissions: ['user_management']
          },
          {
            id: 'roles',
            title: 'Roles',
            type: 'item',
            icon: 'people',
            url: '/admin/roles',
            requiredPermissions: ['user_management']
          },
        ]
      },

      {
        hidden: true,
        id: 'tags-timeframes',
        title: 'Timeframes',
        type: 'item',
        icon: 'calendar_today',
        url: '/tags/timeframes',
        requiredPermissions: ['tag_management'],
      },
      {
        hidden: true,
        id: 'tags-schools',
        title: 'Schools',
        type: 'item',
        icon: 'business',
        url: '/tags/schools',
        requiredPermissions: ['tag_management'],
      },
      {
        hidden: true,
        id: 'sync',
        title: 'Refresh data',
        type: 'collapsable',
        icon: 'sync',
        requiredPermissions: ['super_admin'],
        children: [
          {
            id: 'userDirectory',
            title: 'User directory',
            type: 'item',
            icon: 'person',
            url: '/admin/refresh/users',
            requiredPermissions: ['super_admin'],
          },
          {
            id: 'classSync',
            title: 'Classes',
            type: 'item',
            icon: 'school',
            url: '/admin/refresh/classes',
            requiredPermissions: ['super_admin'],
          },
        ]
      },

      {
        hidden: true,
        id: 'logs',
        title: 'Audit log',
        type: 'item',
        icon: 'view_list',
        url: '/admin/logs',
        requiredPermissions: ['log_audit_reviewer']
      },
      {
        hidden: true,
        id: 'gettingStartedRepeat',
        title: 'Getting started',
        type: 'item',
        icon: 'done_all',
        url: '/admin/getting-started',
        requiredPermissions: ['super_admin']
      },
    ]
  },
  {
    hidden: false,
    id: 'support',
    title: 'Support',
    type: 'collapsable',
    icon: 'support',
    children: [
      {
        hidden: false,
        id: 'helpCenter',
        title: 'Little SIS help center',
        type: 'item',
        icon: 'help_center',
        url: helpCenterLink,
        externalUrl: true,
        openInNewTab: true
      },
      // {
      //   hidden: false,
      //   id: 'contactSupport',
      //   title: 'Contact support',
      //   type: 'item',
      //   icon: 'contact_support',
      //   url: 'https://amplifiedlabs.zendesk.com/hc/en-us/requests/new',
      //   externalUrl: true,
      //   openInNewTab: true
      // },
      {
        hidden: false,
        id: 'contactSupport',
        title: 'Contact support',
        type: 'item',
        icon: 'contact_support',
        function: (navigationEvents$) => {
          navigationEvents$.next('launchSupport');
        }
      },
      {
        hidden: false,
        id: 'feedback',
        title: 'Feedback',
        type: 'item',
        icon: 'campaign',
        function: (navigationEvents$) => {
          navigationEvents$.next('launchFeedback');
        }
      },
    ]
  },
];
