import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  MatStep,
  MatStepper,
} from '@angular/material/stepper';

import {
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import {
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { fuseAnimations } from '../../../../../@amplabs/animations';
import { LscEvents, StepAnalyticsEvent, StepAnalyticsEventEnum } from '../../../../core/services/FirebaseAnalytics/firebase-analytics-events.constant';
import { AnalyticsEvent, FirebaseAnalyticsService } from '../../../../core/services/FirebaseAnalytics/firebase-analytics.service';
import {
  CollectionService,
  StudentCollection,
} from '../../../../services/collection.service';
import { StudentExplorerStateService } from '../../../../services/student-explorer-state.service';
import {
  User,
  UserService,
} from '../../../../services/user.service';
import { Student } from '../../../students/students.interfaces';

export const ADD_TO_COLLECTION_STEPPER_STEPS = 4;
export const ADD_TO_COLLECTION_STEPPER = 'addToCollectionStepper';


interface StudentCollectionWithId extends StudentCollection {
  id: string;
}

@Component({
  selector: 'app-add-to-collection',
  templateUrl: './add-to-collection.component.html',
  styleUrls: ['./add-to-collection.component.scss'],
  animations: [fuseAnimations]
})
export class AddToCollectionComponent implements OnInit, AfterViewInit {

  static dialogConfig = {
    disableClose: false,
    panelClass: ['ait-little-sis-panel', 'modal'],
    width: '75%',
    minWidth: '850px',
    height: '640px',
  };

  @ViewChild('stepper') stepper: MatStepper;

  stepperState: {
    [key: string]: boolean;
  };
  stepForms: FormGroup;
  collectionCount: number;
  collectionMembers$: BehaviorSubject<string[]>; // This is an array of student user IDs
  addTo$: Observable<any>;
  userCollections: StudentCollectionWithId[];
  defaultSearchItems: BehaviorSubject<Student[]>;
  membershipExceededCount$: Observable<number>;
  formArrayMessage$: Subject<string>;
  selectedIndex: number;
  maxCollectionsPerUser: number;
  maxUsersPerCollection: number;
  maxCollectionWarning: string;
  maxCharacterLength:number = 50;

  private selectedStep: MatStep;
  private readonly onDestroy$: Subject<void>;

  constructor(private dialogRef: MatDialogRef<any>,
              private formBuilder: FormBuilder,
              private collectionService: CollectionService,
              private userService: UserService,
              private studentExplorerState: StudentExplorerStateService,
              private _firebaseAnalytics: FirebaseAnalyticsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.maxCollectionsPerUser = this.collectionService.maxCollectionsPerUser;
    this.maxUsersPerCollection = this.collectionService.maxUsersPerCollection;
    this.maxCollectionWarning = this.collectionService.maxCollectionWarning;
    this.defaultSearchItems = new BehaviorSubject([]);
    this.onDestroy$ = new Subject<void>();
    this.collectionCount = data.collectionCount;
    this.collectionMembers$ = new BehaviorSubject<string[]>([]);
    this.formArrayMessage$ = new Subject<string>();
    this.stepperState = {
      addTo: false,
      collectionDetails: false,
      addStudents: false
    };

  }

  ngOnInit(): void {

    this.stepForms = this.formBuilder.group({
      addTo: this.formBuilder.control(this.data.addTo, [Validators.required]),
      collectionDetails: this.formBuilder.group({
        collectionId: this.formBuilder.control({value: null, disabled: true}, [Validators.required]),
        name: this.formBuilder.control(null, [ Validators.required, Validators.maxLength(this.maxCharacterLength), Validators.pattern(/\S.*\S|\S/)]),
        description: this.formBuilder.control(null, [Validators.maxLength(150)])
      }),
      students: this.formBuilder.group({
        type: this.formBuilder.control(null, [Validators.required]),
        searchInput: this.formBuilder.control(null),
        searchResults: this.formBuilder.array([], [Validators.required])
      })
    });

    this.membershipExceededCount$ =
      this.stepForms.get('students').get('searchResults').valueChanges.pipe(
      map((pendingAdds) => {
        let userCount = pendingAdds ? pendingAdds.length : 0;
        const ctrl = this.stepForms.get('students').get('searchResults');
        const members = this.collectionMembers$.getValue();
        if (members) {
          userCount += members.length;
        }
        if (userCount > this.collectionService.maxUsersPerCollection) {
          const exceededBy = userCount - this.collectionService.maxUsersPerCollection;
          ctrl.setErrors({maxQuotaExceeded: `This action would exceed the maximum number of allowed users in this collection by ${exceededBy}`});
          return exceededBy;
        } else {
          ctrl.setErrors(null);
        }
        return 0;
      }),
      startWith(0)
    );

    if (this.data.user) {
      this.collectionService.loadForUser(this.data.user).pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
        this.userCollections = value.map((change) => Object.assign(change.payload.doc.data(), {id: change.payload.doc.ref.id}) as StudentCollectionWithId).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0);

        if (this.data.collectionId) {
          this.stepperState.addTo = true;
          this.stepperState.collectionDetails = true;
          this.stepForms.get('addTo').setValue('existing', {emitEvent: true});
          this.stepForms.get('collectionDetails').get('collectionId').setValue(this.data.collectionId, {emitEvent: true});
        }
      });
    }

    this.addTo$ = this.stepForms.get('addTo').valueChanges.pipe(
      takeUntil(this.onDestroy$),
      tap((value) => this.handleAddToChange_(value)),
      startWith(this.stepForms.get('addTo')));

    this.stepForms.get('collectionDetails').get('collectionId').valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((id: string) => {
      const chosenCollection = this.userCollections.find((coll) => coll.id === id);
      this.setActiveCollection_(chosenCollection);
    });

    this.stepForms.get('collectionDetails').get('name').valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((newCollectionName: string) => {
      const ctrl = this.stepForms.get('collectionDetails').get('name');
      if (this.collectionExists(newCollectionName)) {
        ctrl.setErrors({exists: `Collection already exists`});
      } else {
        if (ctrl.hasError('exists')) {
          delete ctrl.errors['exists'];
          ctrl.updateValueAndValidity;
        }
      }
    });

    this.defaultSearchItems.next(this.studentExplorerState.getSearchResultHistory());

    if (this.data.collectionId) {
      this.selectedIndex = 2;
    }
  }

  ngAfterViewInit(): void {
    this.selectedStep = this.stepper.selected as MatStep;
    this.stepper.selectionChange.asObservable().pipe(takeUntil(this.onDestroy$)).subscribe((change) => {
      this.selectedStep = change.selectedStep as MatStep;
    });

  }

  setActiveCollection_(collection: StudentCollection): void {
    this.collectionMembers$.next(collection && collection.members ? collection.members as string[] : []);
    this.stepForms.get('collectionDetails').get('name').setValue(collection.name, {emitEvent: false});
    this.stepForms.get('collectionDetails').get('description').setValue(collection.description, {emitEvent: false});
  }

  closeDialog() {
    this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.studentExplorer[ADD_TO_COLLECTION_STEPPER][this.stepper.selectedIndex][StepAnalyticsEventEnum.cancelled]);
    this.dialogRef.close({action: 'cancel'});
  }

  async saveAndClose() {
    await this.stepForms.updateValueAndValidity({emitEvent: false});
    if (this.stepForms.valid) {
      const submission = this.stepForms.value;
      const newMembers = submission.students.searchResults.map((user) => user.gId);
      const collection: any = {
        name: submission.collectionDetails.name,
        members: newMembers
      };
      if (submission.collectionDetails.description) {
        collection.description = submission.collectionDetails.description;
      }
      await this.collectionService.saveCollection(collection, submission.collectionDetails.collectionId);
      this.dialogRef.close({action: 'saved'});
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.studentExplorer[ADD_TO_COLLECTION_STEPPER][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
      if(this.stepForms.get('addTo').value === 'existing') {
        this._firebaseAnalytics.sendEvent(((LscEvents.lscEvents.studentExplorer[ADD_TO_COLLECTION_STEPPER] as StepAnalyticsEvent).addToExistingCollection as AnalyticsEvent));
      } else if (this.stepForms.get('addTo').value === 'new') {
        this._firebaseAnalytics.sendEvent(((LscEvents.lscEvents.studentExplorer[ADD_TO_COLLECTION_STEPPER] as StepAnalyticsEvent).createNewCollection as AnalyticsEvent));
      }
    }
  }

  nextStep() {
    if (this.selectedStep) {
      const ctrl = this.selectedStep.stepControl;
      ctrl.updateValueAndValidity({emitEvent: false});
      if (ctrl.valid) {
        this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.studentExplorer[ADD_TO_COLLECTION_STEPPER][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
        this.stepper.next();
      } else {
        ctrl.markAllAsTouched();
        ctrl.markAsDirty();
      }
    } else {
      this._firebaseAnalytics.sendEvent(LscEvents.lscEvents.studentExplorer[ADD_TO_COLLECTION_STEPPER][this.stepper.selectedIndex][StepAnalyticsEventEnum.completed]);
      this.stepper.next();
    }
  }

  public collectionExists(newCollectionName: string): boolean {
    if (newCollectionName !== null) {
      const exists = this.userCollections.find((collection) => collection.name.toLowerCase() === newCollectionName.toLowerCase());
      if (exists) {
        return true;
      } else {
        return false;
      };
    }
  }

  previousStep() {
    this.stepper.previous();
  }

  getErrors(ctrl: AbstractControl): string[] | null {
    const errObj = ctrl.errors;
    if (errObj) {
      if (errObj.required) {
        return ['Required'];
      } else {
        return Object.entries(errObj).map(([key, value]) => `${key} ${value}`);
      }
    }
  }

  getSearchResultsFormControls(): FormControl[] {
    const formArray = this.stepForms.get('students').get('searchResults') as FormArray;
    return formArray.controls as FormControl[];
  }

  getSummaryMessage(): string {
    const formArray = this.stepForms.get('students').get('searchResults');
    const addTo = this.stepForms.get('addTo').value;
    const collectionName = this.stepForms.get('collectionDetails').get('name').value;
    return `You are adding ${formArray.value.length} students to a ${addTo} Collection name ${collectionName}`;
  }

  onStudentSelected(student: User) {
    this.populateSearchResults_([student]); // This method will handle deduplication
    const searchResultsControl = this.stepForms.get('students').get('searchResults');
    let currentStudents = searchResultsControl.value;
    if (currentStudents) {
      currentStudents = currentStudents.concat(student);
    } else {
      currentStudents = [student];
    }

    // Enter a value into the search input form control to pass validation in UI.
    const searchEmails = currentStudents.map((student) => student.primaryEmail);
    this.stepForms.get('students').get('searchInput').setValue(searchEmails.join(', '));
  }

  getType() {
    return this.stepForms.get('students').get('type').value;
  }

  updateFormGroupValidity(groupName: string): void {
    this.stepForms.get(groupName).markAllAsTouched();
    this.stepForms.get(groupName).markAsDirty();
  }

  removeControl(index: number) {
    const formArray = this.stepForms.get('students.searchResults') as FormArray;
    formArray.removeAt(index);
  }

  private handleAddToChange_(addTo) {
    const formGroup = this.stepForms.get('collectionDetails');
    const idControl = formGroup.get('collectionId') as FormControl;
    if (addTo === 'existing') {
      idControl.enable({emitEvent: false});
    } else if (idControl.enabled) {
      idControl.disable({emitEvent: false});
    }
  }

  private populateSearchResults_(users: User[]) {
    const formArray = this.stepForms.get('students').get('searchResults') as FormArray;
    const alreadyInResults = new Set(formArray.value.map((val) => val.primaryEmail));
    const alreadyInCollection = new Set(this.collectionMembers$.getValue());
    const errs = [];
    users.forEach((user) => {
      if (alreadyInResults.has(user.primaryEmail)) {
        errs.push(`${user.primaryEmail} is already selected`);
      } else if (alreadyInCollection.has(user.gId)) {
        errs.push(`${user.primaryEmail} is already in the collection`);
      } else {
        formArray.setControl(formArray.controls.length, new FormControl(user));
      }
    });
    if (errs.length) {
      this.formArrayMessage$.next(errs.join(', '));
    } else {
      this.formArrayMessage$.next(null);
    }
  }
}
